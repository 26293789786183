<div fxLayout fxLayoutAlign="space-between">
  <div class="DisplayRegularHeading-5" [style.margin-bottom.px]="48">{{campaign.title}}</div>
  <button mat-icon-button (click)="dialogRef.close()" aria-label="Close button">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div fxLayout fxLayoutAlign="center">
  <img class="campaign-image" src="{{campaign.imageSmall}}" [style.margin-bottom.px]="24" alt="Campaign logo">
</div>
<div class="HeadingHeading-5" [style.margin-bottom.px]="24"
     [style.text-align]="'center'">{{campaign.shortDescription}}</div>
<div class="date ParagraphParagraph-1">{{'common_start' | translate}}: {{campaign.startDate | date: 'dd MMMM'}}</div>
<div class="date ParagraphParagraph-1" [style.margin-bottom.px]="48">
  <span *ngIf="campaign.endDate else units">{{'common_end' | translate}}: {{campaign.endDate | date: 'dd MMMM'}}</span>
  <ng-template
    #units>{{'common_end' | translate}}&nbsp;{{campaign.maxPoints}}&nbsp;{{campaign?.unit | unitToKey | plural: campaign.maxPoints | translate }}</ng-template>
</div>
<div fxLayout>
  <button fxFlex="50%" fxShow fxHide.xs mat-button color="secondary"
          vim-button (click)="dialogRef.close()">{{'common_dismiss' | translate}}</button>
  <div fxFlex="24px"></div>
  <button fxFlex="50%" fxFlex.xs="100%" mat-button color="primary"
          vim-button (click)="onJoinCampaign()">{{'common_join_campaign' | translate}}</button>
</div>
