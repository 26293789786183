import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { WorkspaceService } from 'src/app/client/workspaces/workspaces.service';
import { MessageService } from '../message.service';
import { AppState } from '../state';
import { WorkspacesActions } from './workspaces.actions';
import { of } from 'rxjs';
import { WorkspacesSelectors } from './workspaces.selectors';
import { HttpService } from '../../client/http.service';
import { StorageService } from '../../shared/shared-ui/services/storage-service/storage.service';
import { Router } from '@angular/router';

@Injectable()
export class WorkspacesEffects {
  getWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.getWorkspaces),
      switchMap(() =>
        this.workspacesService.getWorkspaces().pipe(
          map((workspaces) => WorkspacesActions.getWorkspacesSuccess({ workspaces })),
          catchError((e) => {
            this.messageService.showErrorSnackbar(e);
            return of(WorkspacesActions.getWorkspacesError());
          })
        )
      )
    )
  );

  selectWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.selectWorkspace),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectWorkspaces)),
      filter(([, workspaces]) => !!workspaces.length),
      map(([action, workspaces]) => {
        const workspace = workspaces.find((workspace) => workspace._id === action.workspaceId);
        if (workspace) {
          this.storageService.setWorkspaceId(workspace._id);
          this.messageService.showSuccessSnackbar('workspaces_selected_message');
          return WorkspacesActions.selectWorkspaceSuccess({ workspace, returnUrl: action.returnUrl });
        }
        this.messageService.showErrorSnackbar({});
        return WorkspacesActions.selectWorkspaceError();
      })
    )
  );

  constructor(
    private actions$: Actions,
    private workspacesService: WorkspaceService,
    private store$: Store<AppState>,
    private messageService: MessageService,
    private httpService: HttpService,
    private storageService: StorageService,
    private router: Router
  ) {}
}
