import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersStore } from './users.store';
import { StoreModule } from '@ngrx/store';
import { UsersReducer } from './users.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UsersEffects } from './users.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('users', UsersReducer.reducer),
    EffectsModule.forFeature([UsersEffects])
  ],
  providers: [UsersStore, UsersEffects]
})
export class UsersStoreModule {}
