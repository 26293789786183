import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { UpdateMeModel, UsersMeResponse } from './users.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private httpService: HttpService) {}

  private readonly apiBaseUrl = environment.baseUrl;

  getMe(): Observable<UsersMeResponse> {
    return this.httpService.get('/users/me', {}, this.apiBaseUrl);
  }

  updateMe(model: UpdateMeModel): Observable<string> {
    return this.httpService.patch('/users/me', {}, { ...model }, this.apiBaseUrl);
  }

  deleteMyAccount(): Observable<any> {
    return this.httpService.delete('/users/me', {}, environment.baseUrl);
  }
}
