import { Injectable } from '@angular/core';
import { AppState } from '../state';
import { select, Store } from '@ngrx/store';
import { LiveTickerSelectors } from './live-ticker.selectors';
import { LiveTickerActions } from './live-ticker.actions';

@Injectable()
export class LiveTickerStore {
  state$ = this.store$.pipe(select(LiveTickerSelectors.selectState));
  campaignLiveTicker$ = this.store$.pipe(select(LiveTickerSelectors.selectCampaignLiveTickerData));
  communityLiveTicker$ = this.store$.pipe(select(LiveTickerSelectors.selectCommunityLiveTickerData));

  getCampaignLiveTickerData(): void {
    this.store$.dispatch(LiveTickerActions.getCampaignLiveTickerData());
  }

  getCommunityLiveTickerData(): void {
    this.store$.dispatch(LiveTickerActions.getCommunityLiveTickerData());
  }

  clear(): void {
    this.store$.dispatch(LiveTickerActions.clearLiveTickerData());
  }

  constructor(private store$: Store<AppState>) {}
}
