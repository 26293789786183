import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { LegalState } from './legal.state';

export namespace LegalSelectors {
  const legal = (state: AppState) => state.legal;

  export const selectUpdatedDocuments = createSelector(legal, (state: LegalState) => state.updatedDocuments);

  export const selectLoading = createSelector(legal, (state: LegalState) => state.loading);
  export const selectLoaded = createSelector(legal, (state: LegalState) => state.loaded);
}
