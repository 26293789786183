import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryModel } from './country-selector/country-selector.component';
import { UsersMeResponse } from '../../../../client/users/users.model';
import { Subject } from 'rxjs';
import { SelectCountryService } from './country-selector/select-country.service';

@Component({
  selector: 'vim-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup<ProfileForm>;
  countrySelectorVisible = false;
  @Input() profile: UsersMeResponse;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() submitButtonText = 'profile_create_profile';
  @Output() updateProfile = new Subject<Profile>();

  constructor(private countriesService: SelectCountryService) {}

  get allowedYears(): Array<number> {
    const currentYear = new Date().getFullYear();
    return [...Array(100).keys()].map((e, i) => currentYear - i);
  }

  ngOnInit(): void {
    this.profileForm = new FormGroup<ProfileForm>({
      firstName: new FormControl('', Validators.required),
      familyName: new FormControl('', Validators.required),
      countryDisplayed: new FormControl(''),
      country: new FormControl('', Validators.required),
      birthYear: new FormControl(null, Validators.required),
      gender: new FormControl('', [Validators.required])
    });
    if (this.profile) {
      this.patchForm(this.profile);
    }
  }

  private patchForm(profileData: Profile): void {
    this.profileForm.patchValue(profileData);
    if (profileData.country) {
      const countryName = this.countriesService.getName(profileData.country);
      this.profileForm.controls.countryDisplayed.setValue(countryName || null);
    }
  }

  onUpdateProfile(): void {
    const { firstName, familyName, country, birthYear, gender } = this.profileForm.value;
    this.updateProfile.next({
      firstName,
      familyName,
      country,
      birthYear: birthYear !== 0 ? birthYear : null,
      gender
    } as Profile);
  }

  hideCountrySelect(): void {
    this.countrySelectorVisible = false;
  }

  setCountry(country: CountryModel): void {
    this.profileForm.controls.country.setValue(country.code);
    this.profileForm.controls.countryDisplayed.setValue(country.name);
    this.profileForm.markAsDirty();
    this.countrySelectorVisible = false;
  }
}

interface ProfileForm {
  firstName: FormControl<string | null>;
  familyName: FormControl<string | null>;
  country: FormControl<string | null>;
  countryDisplayed: FormControl<string | null>;
  birthYear: FormControl<number | null>;
  gender: FormControl<string | null>;
}

export interface Profile {
  firstName?: string;
  familyName?: string;
  country?: string;
  countryCode?: string;
  yearOfBirth?: number;
  gender?: string;
}
