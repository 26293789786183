import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { Notification } from './events.models';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private httpService: HttpService) {}

  getEventsCount(workspaceId: string): Observable<{ count: number }> {
    return this.httpService.get(`/workspaces/${workspaceId}/events/count`);
  }

  getEvents(workspaceId: string, skip?: number, limit?: number): Observable<{ data: Notification[]; total: number }> {
    return this.httpService.get(`/workspaces/${workspaceId}/events`, { skip, limit });
  }

  markAsRead(workspaceId: string, ids: string[]): Observable<any> {
    return this.httpService.put(`/workspaces/${workspaceId}/events`, {}, { ids });
  }
}
