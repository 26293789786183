<div fxLayout='row' class='member-row' ngClass.gt-sm='member-row-desktop' ngClass.lt-md='member-row-mobile'
     fxLayoutAlign='stretch center'>
  <div class="LabelLabel-3 ranking-place">{{member.rankingPlace}}</div>
  <div class='avatar-button' [class.out-of-campaign]="member.leftCampaign">
    <span>{{member?.name?.firstName.charAt(0)}}{{member?.name?.familyName.charAt(0)}}</span>
  </div>
  <p class="LabelLabel-2 member-name" [class.out-of-campaign]="member.leftCampaign"
     ngClass.lt-md='member-name-mobile'>{{fullName}}</p>
  <mat-chip-option class='admin' *ngIf='isAdmin' fxLayoutAlign='center center'>Admin</mat-chip-option>
  <div fxFlex='auto' fxLayoutAlign='flex-end center' fxLayout='row'>
    <!--    get count of points from component-->
    <p class='LabelLabel-3 trees-count'>{{member.points ? (member.points | roundDown:1) : '0'}}</p>
    <mat-icon [svgIcon]='campaignUnit | unitToIcon'></mat-icon>
  </div>
</div>
