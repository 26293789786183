import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vim-access-forbidden',
  templateUrl: './access-forbidden.component.html',
  styleUrls: ['./access-forbidden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessForbiddenComponent {
  constructor(public route: ActivatedRoute) {}

  get hideButton(): boolean {
    return this.route.snapshot.queryParamMap.has('hideButton');
  }
}
