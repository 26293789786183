<div fxLayout='row' fxLayoutAlign='center center' fxLayoutGap='12px'>
  <p class='confirmation-text'>{{confirmationText}}</p>
  <button (click)='onNoButtonClicked($event)' mat-stroked-button class='confirmation-button' color='primary'
          vim-button id='teamMemberConfirmationNo'>
    {{'common_no' | translate}}
  </button>
  <button class='confirmation-button' (click)='onYesButtonClicked($event)' mat-button color='primary' vim-button
          id='teamMemberConfirmationYes'>
    {{'common_yes' | translate}}
  </button>
</div>
