import { Injectable } from '@angular/core';
import { LanguageEnum } from '../models/language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageFlagService {
  constructor() {}

  getFlagPath(language: LanguageEnum): string {
    switch (language) {
      case LanguageEnum.english: {
        return 'assets/images/flags/Flag-EN.png';
      }
      case LanguageEnum.french: {
        return 'assets/images/flags/Flag-FR.png';
      }
      case LanguageEnum.italy: {
        return 'assets/images/flags/Flag-IT.png';
      }
      case LanguageEnum.german: {
        return 'assets/images/flags/Flag-DE.png';
      }
      case LanguageEnum.spanish: {
        return 'assets/images/flags/Flag-ES.png';
      }
      case LanguageEnum.polish: {
        return 'assets/images/flags/Flag-PL.png';
      }
    }
  }
}
