import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MessageService } from '../../../../store/message.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'vim-download-picture',
  templateUrl: './download-picture.component.html',
  styleUrls: ['./download-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadPictureComponent {
  @Input() fileName = 'download.png';

  @ViewChild('picture') picture: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  constructor(private messageService: MessageService) {}

  download(): Promise<any> {
    const pictureElement = this.picture.nativeElement as HTMLElement;
    const downloadLink = this.downloadLink.nativeElement as HTMLAnchorElement,
      canvasElement = this.canvas.nativeElement as HTMLImageElement;
    return html2canvas(pictureElement, { useCORS: true, allowTaint: true, scale: 2 }).then(
      (canvas) => {
        canvasElement.src = canvas.toDataURL();
        downloadLink.href = canvas.toDataURL('image/png');
        downloadLink.download = this.fileName;
        downloadLink.click();
      },
      (err: string) => this.messageService.showErrorSnackbar(err)
    );
  }
}
