import { TeamMember, TeamsData, TeamsItem } from '../../client/teams/teams.model';
import { CampaignUnit } from '../../client/campaigns/campaignsData';

export interface TeamsLeaderboardItem {
  rankingPlace: number;
  teamName: string;
  points: number;
  unit: CampaignUnit;
  id: string;
}

export interface TeamsLeaderboardData {
  data: TeamsLeaderboardItem[];
  total: number;
}

export interface TeamsState {
  myTeams: TeamsData | null;
  currentTeam: TeamsItem | null;
  currentTeamActiveMembers: TeamMember[];
  currentUser: TeamMember | undefined;
  currentTeamActiveMembersCount: number;
  currentTeamRemovedMembers: TeamMember[];
  leaders: TeamsLeaderboardData | null;
  createTeamStatus: { result: CreateTeamResult; name?: string };
  loadingStatuses: {
    getTeams: LoadingStatus;
    getTeam: LoadingStatus;
    getTeamMembers: LoadingStatus;
    getTeamMoreMembers: LoadingStatus;
    getTeamRemovedMembers: LoadingStatus;
    createTeam: LoadingStatus;
    updateTeam: LoadingStatus;
    removeTeamMember: LoadingStatus;
    reInviteTeamMember: LoadingStatus;
    leaveTeam: LoadingStatus;
    leaveTeamAsAdmin: LoadingStatus;
    acceptInvitation: LoadingStatus;
    inviteToTeam: LoadingStatus;
    deleteTeam: LoadingStatus;
    getLeaders: LoadingStatus;
    getMoreLeaders: LoadingStatus;
  };
}

interface LoadingStatus {
  isLoading: boolean | null;
  isLoaded: boolean | null;
}

export enum CreateTeamResult {
  unknown = 'unknown',
  success = 'success',
  error = 'error'
}
