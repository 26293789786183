<div fxLayout fxLayoutAlign="space-between">
  <div class="DisplayRegularHeading-5" [style.margin-bottom.px]="48">{{dialogData.title}}</div>
  <button mat-icon-button (click)="dialogRef.close()" aria-label="Close button">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div fxLayout fxLayoutAlign="center">
  <img class="campaign-image" src="{{dialogData.image}}" [style.margin-bottom.px]="24" alt="Campaign logo">
</div>
<div class="HeadingHeading-3" [style.margin-bottom.px]="24"
     [style.text-align]="'center'">{{dialogData.hashTag}}</div>
<div class="HeadingHeading-5" [style.margin-bottom.px]="24"
     [style.text-align]="'center'">{{'join_team_popup_message' | translate}}</div>
<div class="date ParagraphParagraph-1">{{'common_start' | translate}}: {{dialogData.startDate | date: 'dd MMMM'}}</div>
<div class="date ParagraphParagraph-1" [style.margin-bottom.px]="48">
  <span *ngIf="dialogData.endDate else units">{{'common_end' | translate}}
    : {{dialogData.endDate | date: 'dd MMMM'}}</span>
  <ng-template
    #units>{{'common_end' | translate}}
    :&nbsp;{{dialogData.maxPoints}}&nbsp;{{dialogData?.unit | unitToKey | plural: dialogData.maxPoints | translate }}</ng-template>
</div>
<div fxLayout>
  <button fxFlex="50%" fxShow fxHide.xs mat-button color="secondary"
          vim-button (click)="dialogRef.close()">{{'common_not_now' | translate}}</button>
  <div fxFlex="24px"></div>
  <button fxFlex="50%" fxFlex.xs="100%" mat-button color="quarternary"
          vim-button (click)="joinTeam()">{{'common_join_team' | translate}}</button>
</div>
