import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TeamsEffects } from './teams.effects';
import { TeamsReducer } from './teams.reducer';
import { TeamsStore } from './teams.store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('teams', TeamsReducer.reducer),
    EffectsModule.forFeature([TeamsEffects])
  ],
  providers: [TeamsStore]
})
export class TeamsStoreModule {}
