<div fxLayout='row' fxLayoutAlign.gt-sm='stretch' class='leaderboard-row'>
  <div fxFlexAlign='center' class='ranking-position' fxFlex.gt-sm='5%' fxFlex.lt-md='10%'>
    {{leaderboardData.rankingPlace}}
  </div>
  <div fxLayout='row' class='team-name' fxLayoutAlign='space-between center' fxFlex.lt-md='60%' fxFlex.gt-sm='16%'>
    <div>
      {{leaderboardData.teamName}}
    </div>
    <div *ngIf='leaderboardData.rankingPlace <= 3' [class]='getClassNameForEventIcon()'>
      <mat-icon class='event-icon' svgIcon='events'></mat-icon>
    </div>
  </div>
  <div fxHide.lt-md fxLayout='row' fxLayoutAlign='center center' fxFlex.gt-sm='64%'>
    <!--    <mat-chip-list *ngIf="showCategories" ngClass.gt-sm='chip-container'>-->
    <!--      <mat-chip class='team-category-chip' [class]='leaderboardData.category.toLowerCase()' selectable="false">-->
    <!--        {{('TEAM_CATEGORIES.' + leaderboardData.category) | translate}}-->
    <!--      </mat-chip>-->
    <!--    </mat-chip-list>-->
  </div>
  <div fxLayout='row' fxLayoutAlign='flex-end center' fxLayoutGap='8px' fxFlex.lt-md='30%' fxFlex.gt-sm='15%'
       ngClass.lt-md='trees-count-mobile' class='trees-count-column'>
    <span class='trees-count'>
          {{ leaderboardData.points | roundDown:1}}
    </span>
    <mat-icon svgIcon='{{campaignUnit | unitToIcon}}'></mat-icon>
  </div>
</div>
