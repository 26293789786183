import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AuthorizationGuardService } from './client/guards/authorization-guard.service';
import { AccessForbiddenComponent } from './ui/home/access-forbidden/access-forbidden.component';
import { LoginComponent } from './ui/login/login.component';
import { SocialWallWrapperComponent } from './ui/mobile-wrappers/social-wall-wrapper.component';
import { MobileGuardService } from './client/guards/mobile-guard.service';
import { PrivacyPolicyComponent } from './notAuthorized/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './notAuthorized/terms-of-use/terms-of-use.component';
import { JoinCampaignComponent } from './ui/join-campaign/join-campaign.component';
import { AuthorizedComponent } from './ui/authorized/authorized.component';
import { WorkspaceGuard } from './client/guards/workspace.guard';
import { NotFoundComponent } from './ui/home/not-found/not-found.component';
import { LoginGuard } from './client/guards/login-guard.service';
import { RedirectComponent } from './redirect/redirect.component';
import { adminGuard } from './client/guards/vimove-admin-guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'authorized',
    component: AuthorizedComponent
  },
  {
    path: 'insights',
    component: SocialWallWrapperComponent,
    canActivate: [MobileGuardService]
  },
  {
    path: 'home',
    loadChildren: () => import('./ui/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthorizationGuardService, WorkspaceGuard]
  },
  { path: 'campaigns/:campaignId', component: JoinCampaignComponent },
  {
    path: 'campaign-status',
    loadChildren: () => import('./ui/campaign-status/campaign-status.module').then((m) => m.CampaignStatusModule),
    canLoad: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./ui/help/help.module').then((m) => m.HelpModule)
  },
  {
    path: 'admin-portal',
    loadChildren: () => import('./ui/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
    canActivate: [AuthorizationGuardService, adminGuard]
  },
  {
    path: 'workspaces',
    loadChildren: () => import('./ui/workspaces/workspaces.module').then((m) => m.WorkspacesModule)
  },
  {
    path: 'create-profile',
    loadChildren: () => import('./ui/create-profile/create-profile.module').then((m) => m.CreateProfileModule),
    canActivate: [AuthorizationGuardService]
  },
  {
    path: 'dashboard',
    redirectTo: 'home'
  },
  {
    path: 'guards/signin',
    redirectTo: 'login'
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'termsofuse',
    component: TermsOfUseComponent
  },
  {
    path: 'campaigns',
    data: { externalUrl: 'vimove://campaigns' },
    component: RedirectComponent
  },
  {
    path: '403',
    component: AccessForbiddenComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/home/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
