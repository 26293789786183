import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { mergeMap, take } from 'rxjs/operators';
import { UsersService } from 'src/app/client/users/users.service';
import { MessageService } from 'src/app/store/message.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'vim-delete-account-confirmation',
  templateUrl: './delete-account-confirmation.component.html',
  styleUrls: ['./delete-account-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAccountConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteAccountConfirmationComponent>,
    private usersService: UsersService,
    private router: Router,
    private messageService: MessageService,
    private oidc: OidcSecurityService
  ) {}

  removeMyData(): void {
    this.usersService
      .deleteMyAccount()
      .pipe(
        take(1),
        mergeMap(() => this.oidc.logoffAndRevokeTokens())
      )
      .subscribe(
        () => {
          this.dialogRef.close();
        },
        (err) => this.messageService.showErrorSnackbar(err)
      );
  }
}
