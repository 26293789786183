import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NotificationsSelectors } from './notifications-selectors';
import { AppState } from '../state';
import { NotificationsActions } from './notifications.actions';

@Injectable()
export class NotificationsStore {
  notificationsCount$ = this.store$.pipe(select(NotificationsSelectors.selectNotificationsCount));
  unreadNotificationsCount$ = this.store$.pipe(select(NotificationsSelectors.selectUnreadNotificationsCount));
  notifications$ = this.store$.pipe(select(NotificationsSelectors.selectNotifications));

  getNotificationsCount(): void {
    this.store$.dispatch(NotificationsActions.getNotificationsCount());
  }

  getNotifications(): void {
    this.store$.dispatch(NotificationsActions.getNotifications({}));
  }

  markAsRead(ids: string[]): void {
    this.store$.dispatch(NotificationsActions.markAsRead({ ids }));
  }

  constructor(private store$: Store<AppState>) {}
}
