import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { StravaLink, StravaStatus } from './strava.model';

@Injectable({
  providedIn: 'root'
})
export class StravaService {
  constructor(private httpService: HttpService) {}

  getStatus(): Observable<StravaStatus> {
    return this.httpService.get('/strava/status');
  }

  getLink(): Observable<StravaLink> {
    return this.httpService.get('/strava/link', { redirectUrl: `${window.location.origin}/home/dashboard` });
  }
}
