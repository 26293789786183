<div class="content">
  <header>
    <div ngClass.gt-sm="DisplayBoldHeading-3" ngClass.lt-md="DisplayBoldHeading-5"
         [style.margin-bottom.px]="24">{{title | translate}}</div>
    <div class="ParagraphParagraph-2">{{subtitle | translate}}</div>
  </header>
  <form [formGroup]="profileForm" (submit)="onUpdateProfile()">
    <mat-form-field appearance="outline">
      <mat-label>{{'edit_profile_first_name' | translate}}</mat-label>
      <input matInput formControlName="firstName" type="text"
             placeholder="{{'edit_profile_first_name' | translate}}">
    </mat-form-field>
    <vim-form-control-errors *ngIf="profileForm.dirty"
                             [errors]="profileForm.controls.firstName.errors"></vim-form-control-errors>
    <mat-form-field appearance="outline">
      <mat-label>{{'edit_profile_last_name' | translate}}</mat-label>
      <input matInput formControlName="familyName" placeholder="{{'edit_profile_last_name' | translate}}">
    </mat-form-field>
    <vim-form-control-errors *ngIf="profileForm.dirty"
                             [errors]="profileForm.controls.familyName.errors"></vim-form-control-errors>
    <vim-country-selector formControlName="country"></vim-country-selector>
    <mat-form-field appearance="outline">
      <mat-label>{{'profile_year_of_birth' | translate}}</mat-label>
      <mat-select formControlName="birthYear">
        <ng-scrollbar class="scrollbar" style="height: 250px">
          <mat-option [value]="0">--</mat-option>
          <mat-option *ngFor="let year of allowedYears" [value]="year">
            {{year}}
          </mat-option>
        </ng-scrollbar>
      </mat-select>
    </mat-form-field>
    <vim-form-control-errors *ngIf="profileForm.dirty"
                             [errors]="profileForm.controls.birthYear.errors"></vim-form-control-errors>
    <label id="gender-group-label" class="ParagraphParagraph-2">{{'profile_gender' | translate}}:</label>
    <mat-radio-group
      aria-labelledby="gender-group-label"
      class="gender-radio-group"
      formControlName="gender">
      <mat-radio-button class="gender-radio-group__button ParagraphParagraph-2" value="M">
        {{'gender_male' | translate}}
      </mat-radio-button>
      <mat-radio-button class="gender-radio-group__button" value="F">
        {{'gender_female' | translate}}
      </mat-radio-button>
      <mat-radio-button class="gender-radio-group__button" value="N">
        {{'gender_not_say' | translate}}
      </mat-radio-button>
    </mat-radio-group>
    <vim-form-control-errors *ngIf="profileForm.dirty"
                             [errors]="profileForm.controls.gender.errors"></vim-form-control-errors>
    <vim-button [style.margin-top.px]="40" type="submit" class="secondary" width="100%"
                [disabled]="profileForm.pristine || profileForm.invalid">{{submitButtonText | translate}}</vim-button>
  </form>
</div>
