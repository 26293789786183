import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TeamsLeaderboardItem } from 'src/app/store/teams/teams.state';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-leaderboard-overview',
  templateUrl: './leaderboard-overview.component.html',
  styleUrls: ['./leaderboard-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardOverviewComponent {
  @Input() leaderboardData!: TeamsLeaderboardItem;
  @Input() showCategories: boolean;
  @Input() campaignUnit: CampaignUnit;

  constructor() {}

  getClassNameForEventIcon(): string {
    switch (this.leaderboardData.rankingPlace) {
      case 1: {
        return 'first-place';
      }
      case 2: {
        return 'second-place';
      }
      case 3: {
        return 'third-place';
      }
      default: {
        throw new Error('ranking position has to be greater than 0 and less than 3');
      }
    }
  }
}
