<header class="title DisplayRegularHeading-5">{{ 'whats_new_title' | translate }}</header>
<mat-tab-group #tabGroup>
  <mat-tab label="First">
    <div fxLayout="{{ popupMode ? 'row' : 'column' }}" fxLayout.lt-md="column" fxLayoutGap="32px">
      <img *ngIf="!popupMode" alt="image" class="image"
           src="../../../../assets/svg/vimove-onboarding-onboarding-small.svg"/>
      <img
        *ngIf="popupMode"
        fxShow=""
        fxHide.lt-md=""
        alt="image"
        class="image"
        src="../../../../assets/svg/vimove-onboarding-onboarding.svg"
      />
      <img
        *ngIf="popupMode"
        fxShow.lt-md=""
        fxHide=""
        alt="image"
        class="image"
        src="../../../../assets/svg/vimove-onboarding-onboarding-small.svg"
      />
      <div class="description" fxLayout="column">
        <div ngClass.gt-sm="DisplayRegularHeading-6" ngClass.lt-md="DisplayBoldHeading-5">
          {{ 'whats_new_in_vimove_title' | translate | sprintf: ('app_name_vimove'| translate) }}
        </div>
        <div class="tab-index Label-Medium">{{ tabGroup.selectedIndex! + 1 }}/{{ tabCount }}</div>
        <div
          *ngFor="let row of textRows$ | async"
          class="list-item"
          ngClass.gt-xs="ParagraphParagraph-1"
          ngClass.lt-sm="ParagraphParagraph-2"
        >
          {{ row }}
        </div>
        <ng-container *ngTemplateOutlet="matTabButtons"></ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Second">
    <div fxLayout="{{ popupMode ? 'row' : 'column' }}" fxLayout.lt-md="column" fxLayoutGap="32px">
      <img *ngIf="!popupMode" alt="image" class="image"
           src="../../../../assets/svg/vimove-onboarding-delete-activities-small.svg"/>
      <img
        *ngIf="popupMode"
        fxShow=""
        fxHide.lt-md=""
        alt="image"
        class="image"
        src="../../../../assets/svg/vimove-onboarding-delete-activities.svg"
      />
      <img
        *ngIf="popupMode"
        fxShow.lt-md=""
        fxHide=""
        alt="image"
        class="image"
        src="../../../../assets/svg/vimove-onboarding-delete-activities-small.svg"
      />
      <div fxLayout="column" class="description">
        <div ngClass.gt-sm="DisplayRegularHeading-6" ngClass.lt-md="DisplayBoldHeading-5">
          {{ 'whats_new_delete_activities_title' | translate }}
        </div>
        <div class="tab-index Label-2">{{ tabGroup.selectedIndex! + 1 }}/{{ tabCount }}</div>
        <p ngClass.gt-xs="ParagraphParagraph-1" ngClass.lt-sm="ParagraphParagraph-2">
          {{ 'whats_new_delete_activities_body' | translate }}
        </p>
        <ng-container *ngTemplateOutlet="matTabButtons"></ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Third">
    <div fxLayout="{{ popupMode ? 'row' : 'column' }}" fxLayout.lt-md="column" fxLayoutGap="32px">
      <img *ngIf="!popupMode" alt="image" class="image"
           src="../../../../assets/svg/vimove-onboarding-whats-new-small.svg"/>
      <img
        *ngIf="popupMode"
        fxShow=""
        fxHide.lt-md=""
        alt="image"
        class="image"
        src="../../../../assets/svg/vimove-onboarding-whats-new.svg"
      />
      <img
        *ngIf="popupMode"
        fxShow.lt-md=""
        fxHide=""
        alt="image"
        class="image"
        src="../../../../assets/svg/vimove-onboarding-whats-new-small.svg"
      />
      <div fxLayout="column" class="description">
        <div ngClass.gt-sm="DisplayRegularHeading-6" ngClass.lt-md="DisplayBoldHeading-5">
          {{ 'whats_new_onboarding_title' | translate }}
        </div>
        <div class="tab-index Label-2">{{ tabGroup.selectedIndex! + 1 }}/{{ tabCount }}</div>
        <p ngClass.gt-xs="ParagraphParagraph-1" ngClass.lt-sm="ParagraphParagraph-2">
          {{ 'whats_new_onboarding_body' | translate | sprintf: ('app_name_vimove'| translate) }}
        </p>
        <ng-container *ngTemplateOutlet="matTabButtons"></ng-container>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<ng-template #matTabButtons>
  <div
    class="button-row"
    *ngIf="popupMode; else defaultButtonRow"
    fxFlex
    fxLayout
    fxLayoutAlign="center end"
    fxLayoutGap="24px"
    fxLayoutGap.xs="16px"
  >
    <div fxFlex="50%">
      <button
        *ngIf="tabGroup.selectedIndex! > 0"
        fxFlex="152px"
        fxFlex.lt-md="100%"
        vim-button
        mat-button
        color="secondary"
        (click)="tabGroup.selectedIndex = tabGroup.selectedIndex! - 1"
      >
        {{ 'common_BackButtonTitle' | translate }}
      </button>
    </div>
    <div fxFlex="50%" style="justify-content: end">
      <button
        *ngIf="tabGroup.selectedIndex! < tabCount - 1; else finish"
        fxFlex="152px"
        fxFlex.lt-md="100%"
        vim-button
        mat-button
        color="primary"
        (click)="tabGroup.selectedIndex = tabGroup.selectedIndex! + 1"
      >
        {{ 'common_next' | translate }}
      </button>
      <ng-template #finish>
        <button fxFlex="152px" fxFlex.lt-md="100%" vim-button mat-button color="primary"
                (click)="finished.emit(true)">
          {{ 'common_finish' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
  <ng-template #defaultButtonRow>
    <div class="button-row" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="24px">
      <button
        *ngIf="tabGroup.selectedIndex! > 0"
        fxFlex="152px"
        vim-button
        mat-button
        color="secondary"
        (click)="tabGroup.selectedIndex = tabGroup.selectedIndex! - 1"
      >
        {{ 'common_BackButtonTitle' | translate }}
      </button>
      <button
        *ngIf="tabGroup.selectedIndex! < tabCount - 1; else finishDefault"
        fxFlex="152px"
        fxFlexAlign="end"
        vim-button
        mat-button
        color="primary"
        (click)="tabGroup.selectedIndex = tabGroup.selectedIndex! + 1"
      >
        {{ 'common_next' | translate }}
      </button>
      <ng-template #finishDefault>
        <button fxFlex="152px" fxFlexAlign="end" vim-button mat-button color="primary"
                (click)="finished.emit(true)">
          {{ 'common_finish' | translate }}
        </button>
      </ng-template>
    </div>
  </ng-template>
</ng-template>
