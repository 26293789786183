import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'vim-join-campaign',
  templateUrl: './join-campaign.component.html',
  styleUrls: ['./join-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JoinCampaignComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceDetector: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const campaignId: string = this.route.snapshot.params.campaignId;
    if (this.deviceDetector.os === 'Android') {
      this.document.location.href = `vimove://campaigns?campaignId=${campaignId}`;
    }
    setTimeout((): undefined => void this.router.navigate([`/home/campaigns?campaignId=${campaignId}`]), 100);
  }
}
