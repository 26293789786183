import { InsightDetails } from '../../../store/admin-panel/models/insight-details.model';
import { TranslatedString } from './admin-panel.model';

export interface InsightItem {
  _id: string;
  title: string;
}

export class InsightDetailsPostPayload {
  campaignId?: string;
  country?: string | null;
  content: TranslatedString[];
  title: TranslatedString[];
  subtitle: TranslatedString[];
  buttonLabel: TranslatedString[];
  backgroundColor: string;
  backgroundColorVariant: string;
  image: string;

  static fromInsightDetails(details: InsightDetails, image: string) {
    const instance = new InsightDetailsPostPayload();
    instance.campaignId = details.campaignId || undefined;
    instance.country = details.country || undefined;
    instance.image = image;
    instance.backgroundColor = details.backgroundColor;
    instance.backgroundColorVariant = details.backgroundColorVariant;
    instance.title = details.translatedContent.map(({ language: { shortname }, title }) => ({
      language: shortname,
      value: title
    }));
    instance.subtitle = details.translatedContent.map(({ language: { shortname }, subtitle }) => ({
      language: shortname,
      value: subtitle
    }));
    instance.content = details.translatedContent.map(({ language: { shortname }, content }) => ({
      language: shortname,
      value: content
    }));
    instance.buttonLabel = details.translatedContent.map(({ language: { shortname }, buttonLabel }) => ({
      language: shortname,
      value: buttonLabel
    }));
    return instance;
  }
}

export class InsightDetailsPatchPayload {
  campaignId?: string | null;
  country?: string | null;
  content?: TranslatedString[];
  title?: TranslatedString[];
  subtitle?: TranslatedString[];
  buttonLabel?: TranslatedString[];
  backgroundColor?: string;
  backgroundColorVariant?: string;
  image?: string;

  static fromInsightDetails(details: Partial<InsightDetails>, image?: string) {
    const instance = new InsightDetailsPatchPayload();
    instance.campaignId = details.campaignId;
    instance.country = details.country;
    instance.image = image;
    instance.backgroundColor = details.backgroundColor;
    instance.backgroundColorVariant = details.backgroundColorVariant;
    instance.title = details.translatedContent?.map(({ language: { shortname }, title }) => ({
      language: shortname,
      value: title
    }));
    instance.subtitle = details.translatedContent?.map(({ language: { shortname }, subtitle }) => ({
      language: shortname,
      value: subtitle
    }));
    instance.content = details.translatedContent?.map(({ language: { shortname }, content }) => ({
      language: shortname,
      value: content
    }));
    instance.buttonLabel = details.translatedContent?.map(({ language: { shortname }, buttonLabel }) => ({
      language: shortname,
      value: buttonLabel
    }));
    return instance;
  }
}

export interface InsightDetailsResponse {
  _id?: string;
  campaignId?: string;
  country?: string | null;
  content: TranslatedString[];
  title: TranslatedString[];
  subtitle: TranslatedString[];
  buttonLabel: TranslatedString[];
  backgroundColor: string;
  backgroundColorVariant: string;
  image: string;
}
