<vim-dialog-template [title]="'teams_leave_title' | translate" (closeDialog)="dialogRef.close()">
  <div fxLayout="column" fxFlex="1 1 auto">
    <p>{{'teams_admin_rights_will_be_lost' | translate}}</p>
    <h2>{{'teams_assign_new_admin' | translate}}</h2>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{'leave_team_select_member' | translate}}</mat-label>
      <mat-select [formControl]="memberControl" required>
        <mat-option *ngFor="let member of members" [value]="member._id">
          {{ member.name.firstName }} {{member.name.familyName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="memberControl.hasError('required')">{{'input_text_error_required' | translate}}</mat-error>
    </mat-form-field>
    <div class="action-buttons" ngClass.lt-md="mobile-action-buttons" ngClass.gt-md="desktop-action-buttons">
      <button mat-button color="primary" vim-button (click)='leaveTeam()'
              id='leaveTeam'>{{'teams_leave' | translate}}</button>
      <button mat-button color="secondary" vim-button (click)='dialogRef.close()'
              id='close'>{{'common_cancel' | translate}}</button>
    </div>
  </div>
</vim-dialog-template>
