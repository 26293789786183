import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';
import { MobileVersionService } from '../../ui/common/mobile-version.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private route: ActivatedRoute,
    private mobileVersionService: MobileVersionService,
    public deviceDetector: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const teamId = this.route.snapshot.queryParamMap.get('teamId');
    const workspaceId = this.route.snapshot.queryParamMap.get('workspaceId');
    if (teamId && workspaceId) {
      if (this.deviceDetector.os !== 'Android') {
        return true;
      }
      setTimeout(() => {
        this.document.location.href = this.mobileVersionService.storeUrl;
      }, 100);
      this.document.location.href = `vimove://teaminvitation?teamId=${teamId}&workspaceId=${workspaceId}`;
    }
    return true;
  }
}
