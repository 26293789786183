import { Injectable } from '@angular/core';
import { AdminPanelService } from './admin-panel.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampaignDetailsImages } from './models/admin-panel-campaign.model';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelCampaignImagesService {
  constructor(private service: AdminPanelService) {}

  uploadCampaignImages(
    workspaceId: string,
    images: CampaignDetailsImages<File | string>
  ): Observable<CampaignDetailsImages<string>> {
    return forkJoin([
      this.uploadImageIfRequired(workspaceId, ImagePath.image, images.image),
      this.uploadImageIfRequired(workspaceId, ImagePath.imageSmall, images.imageSmall),
      this.uploadImageIfRequired(workspaceId, ImagePath.imageLarge, images.imageLarge),
      this.uploadImageIfRequired(workspaceId, ImagePath.imageDashboardShare, images.imageDashboardShare),
      this.uploadImageIfRequired(workspaceId, ImagePath.companyLogo, images.companyLogo)
    ]).pipe(
      map(([image, imageSmall, imageLarge, imageDashboardShare, companyLogo]) => ({
        image,
        imageSmall,
        imageLarge,
        imageDashboardShare,
        companyLogo
      }))
    );
  }

  private uploadImageIfRequired(
    workspaceId: string,
    imagePath: ImagePath,
    image?: File | string
  ): Observable<string | undefined> {
    if (!image) {
      return of(undefined);
    }
    if (typeof image === 'string') {
      return of(image.split('/').slice(-1)[0]);
    }
    return this.service.uploadCampaignImage(workspaceId, image, imagePath);
  }

  uploadSharedImages(
    workspaceId: string,
    imagesData: { image: File; color: string }[],
    path: ImagePath
  ): Observable<{ image: string; color: string }[]> {
    if (!imagesData.length) {
      return of([]);
    }
    return forkJoin([...imagesData.map((image) => this.uploadImageIfRequired(workspaceId, path, image.image))]).pipe(
      map((images) =>
        images.map((image, index) => ({
          image: image || '',
          color: imagesData[index].color
        }))
      )
    );
  }
}

export enum ImagePath {
  activityShareImage = 'activity-share-image',
  companyLogo = 'company-logo',
  image = 'image',
  imageDashboardShare = 'image-dashboard-share',
  imageLarge = 'image-large',
  imageSmall = 'image-small',
  shareImage = 'share-image'
}
