<vim-dialog-template [title]="'teams_edit_team' | translate" (closeDialog)="dialogRef.close()">
  <form [formGroup]='form' (keydown.enter)='$event.preventDefault();' (ngSubmit)='saveTeam()'>
    <div fxLayout='column' fxFlex='1 1 auto'>
      <h3>Details</h3>
      <mat-form-field appearance='outline' floatLabel='auto'>
        <mat-label>{{'teams_team_name' | translate}}</mat-label>
        <input formControlName='name' matInput placeholder='Design Berlin Team' id='name'/>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between center" [style.margin-bottom.px]="20">
        <h2 class='HeadingHeading-6'>{{'team_privacy_filter' | translate}}*</h2>
        <mat-chip-listbox class="chip-list" ngClass.lt-md='mobile-chip-list' formControlName="privacy"
                          aria-label="Team privacy">
          <mat-chip-option value="open"
                           (click)="setTeamPrivacy(teamPrivacy.open)">{{'team_privacy_public' | translate}}</mat-chip-option>
          <mat-chip-option value="private"
                           (click)="setTeamPrivacy(teamPrivacy.closed)">{{'team_privacy_private' | translate}}</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <vim-campaign-name></vim-campaign-name>
      <div class='members' fxLayout='column'>
        <h3>{{ maxMembersText[0] | translate}}<span>{{ maxMembersText[1] | translate | sprintf:15}}</span></h3>
        <vim-edit-team-members *ngIf='team?.membersCount' [teamId]='team._id'
                               [members]='teamsStore.teamMembers$ | async'
                               [removedMembers]="teamsStore.removedMembers$ | async"
                               (membersChanged)='membersChanged()'>
        </vim-edit-team-members>
      </div>
      <div fxLayout='row' fxLayoutGap='0' fxLayoutGap.lt-sm='24px' fxLayoutAlign='space-between'
           fxLayout.lt-sm='column-reverse'>
        <button mat-stroked-button color='secondary' (click)='closeDialog($event)' vim-button id='cancelSavingTeam'
                class="cancel-button">
          {{'common_cancel' | translate}}
        </button>
        <button [disabled]='!form.valid || form.pristine' class='save-button' mat-button color='primary' vim-button
                id='saveTeam'>
          {{'common_save' | translate}}
        </button>
      </div>
    </div>
  </form>
</vim-dialog-template>
