import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { BadgeDetailsModel, BadgeModel, UserBadgeModel } from './badges.model';

@Injectable({
  providedIn: 'root'
})
export class BadgesService {
  constructor(private httpService: HttpService) {}

  getBadges(workspaceId: string): Observable<BadgeModel[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/badges`);
  }

  getUserBadges(workspaceId: string): Observable<UserBadgeModel[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/me/badges`);
  }

  getBadgeDetails(badgeId: string, workspaceId: string): Observable<BadgeDetailsModel> {
    return this.httpService.get(`/workspaces/${workspaceId}/badges/${badgeId}`);
  }
}
