import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsNewDialogComponent } from './whats-new-dialog/whats-new-dialog.component';
import { SharedModule } from '../../shared';
import { LayoutModule } from '../home/layout/layout.module';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [WhatsNewComponent, WhatsNewDialogComponent],
  imports: [CommonModule, SharedModule, LayoutModule, NgScrollbarModule],
  exports: [WhatsNewComponent],
  providers: [WhatsNewDialogComponent]
})
export class WhatsNewModule {}
