import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppSettingsService } from '../app-settings.service';

@Component({
  selector: 'vim-use-mobile-app-banner',
  templateUrl: './use-mobile-app-banner.component.html',
  styleUrls: ['./use-mobile-app-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UseMobileAppBannerComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private deviceDetector: DeviceDetectorService
  ) {}

  useApp(): void {
    setTimeout(() => {
      this.document.location.href = this.storeUrl;
    }, 100);
    this.document.location.href = `vimove://home`;
  }

  get showMobileAppBanner(): boolean {
    return !sessionStorage.getItem('showMobileAppBanner');
  }

  close(): void {
    sessionStorage.setItem('showMobileAppBanner', 'true');
  }

  get storeUrl(): string {
    if (this.deviceDetector.os === 'Android') {
      return AppSettingsService.GOOGLE_PLAY_URL;
    } else if (this.deviceDetector.os === 'iOS') {
      return AppSettingsService.APPLE_STORE_URL;
    }
    return '';
  }
}
