import { UsersState } from './users.state';
import { Action, createReducer, on } from '@ngrx/store';
import { UsersActions } from './users.actions';

export namespace UsersReducer {
  export const initialState: UsersState = {
    loading: false,
    loaded: false,
    me: null
  };

  export const usersReducer = createReducer(
    initialState,
    on(UsersActions.getMe, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(UsersActions.getMeSuccess, (state, { me }) => ({
      ...state,
      me: me,
      loading: false,
      loaded: true
    })),
    on(UsersActions.getMeError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(UsersActions.updateMe, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(UsersActions.updateMeSuccess, (state, { me }) => ({
      ...state,
      me: { ...state.me, ...me },
      loading: false,
      loaded: true
    })),
    on(UsersActions.updateMeError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(UsersActions.clear, () => ({
      ...initialState,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: UsersState, action: Action): UsersState {
    return usersReducer(state, action);
  }
}
