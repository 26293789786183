<div class='site' fxLayout='column'>
  <vim-header class='header'></vim-header>
  <div>
    <vim-download-app *ngIf="deviceDetectorService.isDesktop()"></vim-download-app>
  </div>
  <vim-campaign-banner [vm]='campaignBannerViewModel$ | async'></vim-campaign-banner>
  <div fxFlex='grow' class='main-content'>
    <router-outlet></router-outlet>
  </div>
  <vim-footer ngClass.lt-md='footer-mobile' ngClass.gt-sm='footer'></vim-footer>
</div>
