import { NgModule } from '@angular/core';
import { SharedDependenciesModule } from '../shared-dependencies.module';
import { ContainerComponent } from './components/container/container.component';
import { ButtonComponent } from './components/button/button.component';
import { ArrowComponent } from './components/arrow/arrow.component';
import { GarminLinkSuccessModalComponent } from './components/garmin-link-success-modal/garmin-link-success-modal.component';
import { SecondsToHoursPipe } from './pipes/seconds-to-hours/seconds-to-hours.pipe';
import { MetersToKilometersPipe } from './pipes/meters-to-kilometers/meters-to-kilometers.pipe';
import { KilosToTonsPipe } from './pipes/kilos-to-tons/kilos-to-tons.pipe';
import { VimButtonDirective } from './directives/vim-button.directive';
import { VimTabsDirective } from './directives/vim-tabs.directive';
import { MinutesOrHoursPipe } from './pipes/minutes-or-hours/minutes-or-hours.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { SprintfPipe } from './pipes/sprintf.pipe';
import { MetersOrKilometersPipe } from './pipes/meters-or-kilometers.pipe';
import { SecureImagePipe } from './pipes/secure-image-pipe';
import { EmailListComponent } from './components/email-list/email-list.component';
import { SocialWallComponent } from './components/social-wall/social-wall.component';
import { LiveTickerItemComponent } from './components/live-ticker-item/live-ticker-item.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { UnitToTranslationKeyPipe } from './pipes/campaign-unit/campaign-unit.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CampaignUnitComponent } from './components/campaign-unit/campaign-unit.component';
import { UnitToIconPipe } from './pipes/unit-to-icon/unit-to-icon.pipe';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { IndefiniteProgressBarComponent } from './components/indefinite-progress-bar/indefinite-progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DownloadPictureComponent } from './components/download-picture/download-picture.component';
import { RoundTimePipe } from './pipes/round-time.pipe';
import { SharedPictureComponent } from './components/shared-picture/shared-picture.component';
import { NgChartsModule } from 'ng2-charts';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { RoundDownPipe } from './pipes/round-down.pipe';
import { SingleTimeUnitPipe } from './pipes/single-time-unit.pipe';
import { SingleDistanceUnitPipe } from './pipes/single-distance-unit.pipe';
import { MetersAndOrKilometersPipe } from './pipes/meters-and-or-kilometers.pipe';
import { FormControlErrorsComponent } from './components/form-control-errors/form-control-errors.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ProfileComponent } from './components/profile/profile.component';
import { CountrySelectorComponent } from './components/profile/country-selector/country-selector.component';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MetersPerSecondToKilometersPerHourPipe } from './pipes/meters-per-second-to-kilometers-per-hour.pipe';
import { InsightComponent } from './components/insight/insight.component';
import { ProgressButtonComponent } from './components/progress-button/progress-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ExpandableComponent } from './components/expandable/expandable.component';
import { DialogTemplateComponent } from './components/dialog-template/dialog-template.component';
import { ShowIfAdminEditorDirective } from './directives/admin/show-if-admin-editor.directive';
import { DisableIfNotAdminEditorDirective } from './directives/admin/disable-if-not-admin-editor.directive';
import { HideIfNotAdminEditorDirective } from './directives/admin/hide-if-not-admin-editor.directive';
import { NullIfEmptyDirective } from './directives/null-if-empty.directive';
import { CampaignImageDialogComponent } from '../../ui/admin-panel/admin-panel-campaigns/admin-panel-campaign-details/image-preview/campaign-image-dialog/campaign-image-dialog.component';
import { UndefinedIfEmptyDirective } from './directives/undefined-if-empty.directive';
import { ActivityTypeTranslate } from './pipes/activity-type-translate';

const COMPONENTS = [
  ContainerComponent,
  ButtonComponent,
  ArrowComponent,
  GarminLinkSuccessModalComponent,
  EmailListComponent,
  SocialWallComponent,
  LiveTickerItemComponent,
  ProgressIndicatorComponent,
  FormControlErrorsComponent,
  ProfileComponent,
  CountrySelectorComponent,
  ProgressButtonComponent,
  ProgressBarComponent,
  CampaignUnitComponent,
  DownloadAppComponent,
  IndefiniteProgressBarComponent,
  DownloadPictureComponent,
  SharedPictureComponent,
  PieChartComponent,
  LineChartComponent,
  BarChartComponent,
  InsightComponent,
  ExpandableComponent,
  DialogTemplateComponent,
  ConfirmationDialogComponent
];
const PIPES = [
  SecondsToHoursPipe,
  MetersToKilometersPipe,
  KilosToTonsPipe,
  MinutesOrHoursPipe,
  PluralPipe,
  SprintfPipe,
  MetersOrKilometersPipe,
  SecureImagePipe,
  UnitToIconPipe,
  UnitToTranslationKeyPipe,
  UnitToTranslationKeyPipe,
  RoundTimePipe,
  RoundDownPipe,
  SingleTimeUnitPipe,
  SingleDistanceUnitPipe,
  MetersAndOrKilometersPipe,
  AutofocusDirective,
  MetersPerSecondToKilometersPerHourPipe,
  ActivityTypeTranslate
];
const DIRECTIVES = [
  VimButtonDirective,
  VimTabsDirective,
  ShowIfAdminEditorDirective,
  DisableIfNotAdminEditorDirective,
  NullIfEmptyDirective,
  UndefinedIfEmptyDirective
];

@NgModule({
  providers: [PluralPipe],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES, HideIfNotAdminEditorDirective, CampaignImageDialogComponent],
  imports: [
    SharedDependenciesModule,
    NgScrollbarModule,
    MatProgressBarModule,
    NgChartsModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule
  ],
  exports: [...COMPONENTS, ...PIPES, ...DIRECTIVES, HideIfNotAdminEditorDirective]
})
export class SharedUiModule {}
