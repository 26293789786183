import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';
import { take } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NotificationsStore } from '../../../../store/notifications/notifications-store.service';
import { NotificationsListDialogComponent } from '../mobile-menu/notifications-list-dialog/notifications-list-dialog.component';
import Timeout = NodeJS.Timeout;
import { AuthService } from '../../../common/auth.service';
import { AdminPanelStore } from '../../../../store/admin-panel/admin-panel.store';
import { HeaderViewModel, HeaderSelectors } from './header.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { Observable } from 'rxjs';

const CHECK_NOTIFICATIONS_INTERVAL = 1000 * 60 * 5;

@Component({
  selector: 'vim-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  isMobileMenuOpened: boolean = false;
  isProfileMenuOpened = false;

  interval: Timeout;

  vm$: Observable<HeaderViewModel> = this.store$.pipe(select(HeaderSelectors.headerViewModel));

  constructor(
    public dialog: MatDialog,
    public oidcSecurityService: OidcSecurityService,
    public notificationsStore: NotificationsStore,
    public adminPanelStore: AdminPanelStore,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.interval = setInterval(() => this.notificationsStore.getNotificationsCount(), CHECK_NOTIFICATIONS_INTERVAL);
  }

  ngOnInit() {
    this.adminPanelStore.getAdminData();
    this.notificationsStore.getNotificationsCount();
    this.notificationsStore.getNotifications();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  toggleDialog(): void {
    if (this.isMobileMenuOpened) {
      this.dialog.closeAll();
    } else {
      const dialogRef = this.dialog.open(MobileMenuComponent, {
        width: '100%',
        maxWidth: '100vh',
        hasBackdrop: false,
        panelClass: 'menu-dialog-container'
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.isMobileMenuOpened = false;
          this.cdr.markForCheck();
        });
    }

    this.isMobileMenuOpened = !this.isMobileMenuOpened;
  }

  showNotifications(): void {
    this.dialog.open(NotificationsListDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vh',
      hasBackdrop: false,
      disableClose: true,
      panelClass: 'notifications-dialog-container'
    });
  }

  markNotificationsAsRead(): void {
    this.notificationsStore.markAsRead([]);
  }

  logout(): void {
    this.authService.logout();
  }
}
