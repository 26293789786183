import { Action, createReducer, on } from '@ngrx/store';
import { StravaState } from './strava.state';
import { StravaActions } from './strava.actions';

export namespace StravaReducer {
  export const initialState: StravaState = {
    status: null,
    authorizationUrl: '',
    loading: false,
    loaded: false
  };

  export const stravaReducer = createReducer(
    initialState,
    on(StravaActions.getStatus, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(StravaActions.getStatusSuccess, (state, { status }) => ({
      ...state,
      status: status,
      loading: false,
      loaded: true
    })),
    on(StravaActions.getStatusError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(StravaActions.getLink, (state) => ({
      ...state
    })),
    on(StravaActions.getLinkSuccess, (state, { authorizationUrl }) => ({
      ...state,
      authorizationUrl: authorizationUrl
    })),
    on(StravaActions.getLinkError, (state) => ({
      ...state
    })),
    on(StravaActions.clear, () => ({
      ...initialState,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: StravaState, action: Action): StravaState {
    return stravaReducer(state, action);
  }
}
