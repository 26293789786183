import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { MessageService } from '../message.service';
import { BadgesService } from '../../client/badges/badges.service';
import { BadgesActions } from './badges.actions';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';

@Injectable()
export class BadgesEffects {
  getAvailableBadges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BadgesActions.getBadges),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        forkJoin([this.badgesService.getBadges(workspaceId!), this.badgesService.getUserBadges(workspaceId!)]).pipe(
          map(([allBadges, userBadges]) => BadgesActions.getBadgesSuccess({ allBadges, userBadges })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(BadgesActions.getBadgesError);
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private badgesService: BadgesService,
    private store$: Store<AppState>,
    private messageService: MessageService
  ) {}
}
