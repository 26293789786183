import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';
import {
  CreateTeamModel,
  GetTeamsParams,
  LeadersData,
  TeamMember,
  TeamMembersData,
  TeamsData,
  TeamsItem,
  UpdateTeamMemberModel,
  UpdateTeamMemberParams,
  UpdateTeamModel
} from './teams.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  constructor(private httpService: HttpService) {}

  getTeams(workspaceId: string, params?: GetTeamsParams): Observable<TeamsData> {
    const parameters: GetTeamsParams = {
      limit: params?.limit || 10000,
      skip: params?.skip || 0,
      filter: params?.filter || undefined
    };
    return this.httpService.get(`/workspaces/${workspaceId}/teams`, { ...params, ...parameters }, undefined);
  }

  getLeaders(workspaceId: string, params: { skip?: number; limit?: number }): Observable<LeadersData> {
    return this.httpService.get(`/workspaces/${workspaceId}/rankings/campaign/current/teams`, { ...params });
  }

  getTeam(teamId: string, workspaceId: string): Observable<TeamsItem> {
    return this.httpService.get(`/workspaces/${workspaceId}/teams/${teamId}`);
  }

  getTeamMembers(teamId: string, skip: number, limit: number, workspaceId: string): Observable<TeamMembersData> {
    return this.httpService.get(`/workspaces/${workspaceId}/teams/${teamId}/members`, { skip, limit });
  }

  getTeamRemovedMembers(teamId: string, workspaceId: string): Observable<TeamMember[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/teams/${teamId}/removed`);
  }

  createTeam(model: CreateTeamModel, workspaceId: string): Observable<any> {
    return this.httpService.post(`/workspaces/${workspaceId}/teams`, {}, model);
  }

  updateTeam(teamId: string, model: UpdateTeamModel, workspaceId: string): Observable<any> {
    return this.httpService.patch(`/workspaces/${workspaceId}/teams/${teamId}`, {}, model);
  }

  deleteTeam(teamId: string, workspaceId: string): Observable<any> {
    return this.httpService.delete(`/workspaces/${workspaceId}/teams/${teamId}`, {});
  }

  updateTeamMember(params: UpdateTeamMemberParams, model: UpdateTeamMemberModel, workspaceId: string): Observable<any> {
    return this.httpService.patch(
      `/workspaces/${workspaceId}/teams/${params.teamId}/members/${params.userId}`,
      {},
      model
    );
  }

  inviteToTeam(teamId: string, emails: string[], workspaceId: string): Observable<any> {
    return this.httpService.post(`/workspaces/${workspaceId}/teams/${teamId}/invite`, {}, { inviteEmails: emails });
  }
}
