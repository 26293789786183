import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService, DistanceUnitKey, SingleDistanceUnit } from '../services/converter.service';

@Pipe({
  name: 'singleDistanceUnit'
})
export class SingleDistanceUnitPipe implements PipeTransform {
  transform(meters: number, distanceFractionalPoints = 0): SingleDistanceUnit {
    if (isNaN(meters)) {
      return { value: '', unit: DistanceUnitKey.meter };
    }
    return ConverterService.metersToNearestDistanceUnit(meters, distanceFractionalPoints);
  }
}
