import { Injectable } from '@angular/core';
import { CampaignUnit } from '../../../client/campaigns/campaignsData';
import { AppSettingsService } from '../../../ui/common/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {
  static addPluralization(translationKey: string, value: number): string {
    return `${translationKey}.${Math.floor(value) === 0 ? 'zero' : Math.floor(value) === 1 ? 'one' : 'other'}`;
  }

  static campaignUnitToTranslationKey(unit: CampaignUnit, shortVersion?: boolean): string {
    if (!unit) {
      return '';
    }
    if (shortVersion) {
      return CAMPAIGN_UNIT_TO_TRANSLATION_KEY_SHORT[unit].translationKey;
    }
    return CAMPAIGN_UNIT_TO_TRANSLATION_KEY[unit].translationKey;
  }

  static metersToNearestDistanceUnit(meters: number | null | undefined, fractionDigits = 0): SingleDistanceUnit {
    const isNull = meters === undefined || meters === null;
    const isNumberCorrect = typeof meters === 'number' && !isNaN(meters) && meters >= 0;
    if (isNull || !isNumberCorrect) {
      return { value: '0', unit: DistanceUnitKey.meter };
    }

    if (meters < 1000) {
      return {
        value: meters?.toLocaleString(navigator.language, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        }),
        unit: DistanceUnitKey.meter
      };
    }

    return {
      value: (Math.floor((meters / 1000) * 10 ** fractionDigits) / 10 ** fractionDigits).toLocaleString(
        navigator.language,
        {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        }
      ),
      unit: DistanceUnitKey.kilometer
    };
  }

  static metersPerSecondToKilometersPerHour(mps: number | null | undefined): number {
    const isNull = mps === undefined || mps === null;
    const isNumberCorrect = typeof mps === 'number' && !isNaN(mps) && mps >= 0;
    if (isNull || !isNumberCorrect) {
      return 0;
    }
    return (mps / 1000) * 3600;
  }

  static secondsToNearestTimeUnit(seconds: number | null | undefined): SingleTimeUnit {
    const isNull = seconds === undefined || seconds === null;
    const isNumberCorrect = typeof seconds === 'number' && !isNaN(seconds) && seconds >= 0;
    if (isNull || !isNumberCorrect) {
      return { value: '0', unit: TimeUnitKey.seconds };
    }

    if (seconds < 60) {
      return { value: seconds?.toFixed(), unit: TimeUnitKey.seconds };
    }

    if (seconds < 3600) {
      return { value: Math.floor(seconds / 60).toFixed(), unit: TimeUnitKey.minutes };
    }

    return { value: Math.floor(seconds / 3600).toFixed(), unit: TimeUnitKey.hours };
  }

  static metersToMetersAndOrKilometers(meters: number | null | undefined): MetersOrKilometers {
    const isNull = meters === undefined || meters === null;
    const isNumberCorrect = typeof meters === 'number' && !isNaN(meters) && meters >= 0;

    if (isNull || !isNumberCorrect) {
      return {};
    }

    meters = Number(meters);

    if (meters < 1000) {
      return { meters: Math.floor(meters) };
    }
    if (meters % 1000 === 0) {
      return { kilometers: meters / 1000 };
    }
    return { kilometers: Math.floor(meters / 1000), meters: Math.floor(meters % 1000) };
  }

  static secondsToMinutesOrHours(seconds: number | null | undefined): MinutesOrHours {
    const isNull = seconds === undefined || seconds === null;
    const isNumberCorrect = typeof seconds === 'number' && !isNaN(seconds) && seconds >= 0;

    if (isNull || !isNumberCorrect) {
      return {};
    }

    seconds = Number(seconds);

    if (seconds < 3600) {
      return { minutes: Math.floor(seconds / 60) };
    }

    return { hours: Math.floor(seconds / 3600), minutes: Math.floor((seconds % 3600) / 60) };
  }

  static roundDown(value: number, fractionDigits = 0): string {
    if (isNaN(value)) {
      return '';
    }
    return (Math.floor(value * 10 ** fractionDigits) / 10 ** fractionDigits).toLocaleString(navigator.language, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    });
  }

  static getPluralForm(translation: Translation, value: number): string {
    if (Math.round(value) === 0) {
      return translation.zero;
    }
    if (Math.round(value) === 1) {
      return translation.one;
    }
    return translation.other;
  }

  static secondsToMinutesAndHours(time: number): MinutesOrHours {
    if (!time) {
      return {};
    }
    const result = ConverterService.secondsToMinutesOrHours(time);
    if (result.hours && result.hours >= AppSettingsService.SHOW_ONLY_HOURS_FROM) {
      result.minutes = undefined;
    }
    return result;
  }
}

export const CAMPAIGN_UNIT_TO_TRANSLATION_KEY: { [key: string]: { translationKey: string } } = {
  [CampaignUnit.POINTS]: { translationKey: 'unit_point' },
  [CampaignUnit.TREES]: { translationKey: 'unit_tree' },
  [CampaignUnit.EURO]: { translationKey: 'unit_euro' },
  [CampaignUnit.BEES]: { translationKey: 'unit_bee' }
};

export const CAMPAIGN_UNIT_TO_TRANSLATION_KEY_SHORT: { [key: string]: { translationKey: string } } = {
  [CampaignUnit.POINTS]: { translationKey: 'unit_point_short' },
  [CampaignUnit.TREES]: { translationKey: 'unit_tree' },
  [CampaignUnit.EURO]: { translationKey: 'unit_euro' },
  [CampaignUnit.BEES]: { translationKey: 'unit_bee' }
};

export interface MinutesOrHours {
  hours?: number;
  minutes?: number;
}

export interface MetersOrKilometers {
  meters?: number;
  kilometers?: number;
}

export interface SingleDistanceUnit {
  value: string;
  unit: DistanceUnitKey;
}

export interface SingleTimeUnit {
  value: string;
  unit: TimeUnitKey;
}

export enum TimeUnitKey {
  seconds = 'unit_second',
  minutes = 'unit_minute_si',
  hours = 'unit_hour_si'
}

export enum DistanceUnitKey {
  meter = 'unit_meter',
  kilometer = 'unit_km'
}

export interface Translation {
  zero: string;
  one: string;
  other: string;
}
