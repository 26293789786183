import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, flatMap, map } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { StorageService } from '../shared/shared-ui/services/storage-service/storage.service';

export interface ClientResponse<T> {
  response: T;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private readonly apiBaseUrl = environment.baseUrl;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly oidcSecurityService: OidcSecurityService,
    private translateService: TranslateService,
    private storageService: StorageService
  ) {}

  get<T>(endpoint: string, params?: any, url?: string): Observable<T> {
    return this.token$.pipe(
      flatMap((token) =>
        this.httpClient.get<T>(`${url || this.apiBaseUrl}${endpoint}`, {
          headers: this.getHeaders(token),
          params: this.filterParams(params)
        })
      ),
      map((res) => res),
      catchError(this.handleError)
    );
  }

  getForWebView<T>(endpoint: string, params?: any, url?: string): Observable<T> {
    return this.httpClient.get<T>(`${url || this.apiBaseUrl}${endpoint}`, {
      headers: this.getHeaders(this.storageService.getAccessToken() as string),
      params: this.filterParams(params)
    });
  }

  getUnprotected<T>(endpoint: string, acceptedLanguage: string): Observable<T> {
    return this.httpClient.get<T>(`${this.apiBaseUrl}${endpoint}`, {
      headers: new HttpHeaders({ 'accept-language': acceptedLanguage })
    });
  }

  getBlob(endpoint: string, params?: any, url?: string): Observable<Blob> {
    return this.token$.pipe(
      flatMap((token) =>
        this.httpClient.get(`${url || this.apiBaseUrl}${endpoint}`, {
          headers: this.getHeaders(token),
          params: this.filterParams(params),
          responseType: 'blob'
        })
      ),
      map((res) => res),
      catchError(this.handleError)
    );
  }

  post<T>(
    endpoint: string,
    params: Record<string, string | number | boolean>,
    body?: Record<string, unknown>,
    url?: string
  ): Observable<T> {
    return this.token$.pipe(
      flatMap((token) =>
        this.httpClient.post<ClientResponse<T>>(`${url || this.apiBaseUrl}${endpoint}`, body, {
          headers: this.getHeaders(token),
          params: this.filterParams(params)
        })
      ),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-argument
      map((res) => <any>res),
      catchError(this.handleError)
    );
  }

  put<T>(
    endpoint: string,
    params: Record<string, string | number | boolean>,
    body?: Record<string, unknown>,
    url?: string
  ): Observable<T> {
    return this.token$.pipe(
      flatMap((token) =>
        this.httpClient.put<ClientResponse<T>>(`${url || this.apiBaseUrl}${endpoint}`, body, {
          headers: this.getHeaders(token),
          params: this.filterParams(params)
        })
      ),
      map((res) => res?.response),
      catchError(this.handleError)
    );
  }

  patch<T>(
    endpoint: string,
    params: Record<string, string | number | boolean>,
    body?: Record<string, unknown>,
    url?: string
  ): Observable<T> {
    return this.token$.pipe(
      flatMap((token) =>
        this.httpClient.patch<T>(`${url || this.apiBaseUrl}${endpoint}`, body, {
          headers: this.getHeaders(token),
          params: this.filterParams(params)
        })
      ),
      map((res) => res),
      catchError(this.handleError)
    );
  }

  delete<T>(endpoint: string, params: Record<string, string | number | boolean>, url?: string): Observable<T> {
    return this.token$.pipe(
      flatMap((token) =>
        this.httpClient.delete<T>(`${url || this.apiBaseUrl}${endpoint}`, {
          headers: this.getHeaders(token),
          params: this.filterParams(params)
        })
      ),
      map((res) => res),
      catchError(this.handleError)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  private filterParams(params: Record<string, string | number | boolean> | any): HttpParams {
    let httpParams = new HttpParams();

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (params[key] !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    return httpParams;
  }

  private getHeaders(token?: string): HttpHeaders {
    return new HttpHeaders({
      ...(token ? { Authorization: 'Bearer ' + token } : {}),
      'accept-language': this.translateService.currentLang
    });
  }

  private token$ = this.oidcSecurityService.getAccessToken().pipe(filter((resp) => !!resp));

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status === 0) {
        // eslint-disable-next-line no-console
        console.log(error);
        // eslint-disable-next-line no-console
        console.error(`Backend returned code ${error.status}, ` + `CORS issue`);
      }
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }
}
