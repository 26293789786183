import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamMember, TeamsItem } from '../../../../client/teams/teams.model';
import { StorageService } from '../../../../shared/shared-ui/services/storage-service/storage.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { TeamSelectors, TeamViewModel } from './team.selectors';
import { MessageService } from '../../../../store/message.service';
import { TeamsStore } from '../../../../store/teams/teams.store';

@Component({
  selector: 'vim-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamComponent implements OnInit, OnDestroy {
  private currentTeamId: string = this.route.snapshot.params?.teamId;
  public teamSelectedByUser = false;
  public hideButtons$ = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private teamsStore: TeamsStore,
    private storageService: StorageService,
    private store$: Store<AppState>,
    private messageService: MessageService
  ) {
    this.teamSelectedByUser = this.router.getCurrentNavigation()?.extras?.state?.teamSelectedByUser;
  }

  vm$: Observable<TeamViewModel> = this.store$.pipe(
    select(TeamSelectors.teamViewModel),
    tap((vm) => this.checkInvitation(vm.userAlreadyInCurrentTeam))
  );

  checkInvitation(userAlreadyInCurrentTeam: boolean): void {
    if (userAlreadyInCurrentTeam && !!this.isItInvite) {
      this.messageService.showErrorSnackbar('error_join_team_while_already_in_team');
    }
  }

  get isItInvite(): boolean {
    const invitation = this.route.snapshot.queryParamMap.get('invitation');
    return !!invitation;
  }

  onScroll(teamMembers: TeamMember[], currentTeam: TeamsItem): void {
    const loadedMembersCount = teamMembers.length;
    if (loadedMembersCount >= currentTeam.membersCount) {
      return;
    }
    this.teamsStore.getMoreMembers(this.currentTeamId, loadedMembersCount, 50);
  }

  userInTeam(teamMembers: TeamMember[], userId: string): boolean {
    return !!teamMembers.find((member) => member._id === userId);
  }

  ngOnInit(): void {
    this.teamsStore.getTeam(this.currentTeamId);
    this.teamsStore.getTeamMembers(this.currentTeamId);
  }

  acceptInvitation(currentUserId: string, currentTeam: TeamsItem): void {
    this.storageService.clearInvitationParams();
    this.teamsStore.acceptInvitation(
      {
        userId: currentUserId,
        teamId: this.currentTeamId,
        teamName: currentTeam.name
      },
      { active: true }
    );
    if (this.isItInvite) {
      void this.router.navigate(['/home/dashboard']);
    }
    this.hideButtons$.next(true);
  }

  declineInvitation(): void {
    this.storageService.clearInvitationParams();
    void this.router.navigate(['/home']);
  }

  ngOnDestroy(): void {
    this.storageService.clearInvitationParams();
  }
}
