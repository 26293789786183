import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'vim-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressButtonComponent {
  @Input() class: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() type = 'button';
  @Input() showProgress: boolean | null = false;
  @Input() width: string = '';

  @HostBinding('style.pointer-events')
  public get pointerEvents(): string {
    return this.disabled ? 'none' : 'initial';
  }

  constructor() {}
}
