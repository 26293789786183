import { Action, createReducer, on } from '@ngrx/store';
import { CampaignsState } from './campaigns.state';
import { CampaignsActions } from './campaigns.actions';
import { CampaignData } from '../../client/campaigns/campaignsData';

export namespace CampaignsReducer {
  export const initialState: CampaignsState = {
    userCampaign: null,
    activeCampaigns: [],
    pastCampaigns: [],
    futureCampaigns: [],
    campaignUserData: null,
    noActiveCampaign: false,
    campaignSharingData: [],
    loadingStatuses: {
      userCampaign: { isLoading: null, isLoaded: null },
      allCampaigns: { isLoading: null, isLoaded: null },
      campaignUserData: { isLoading: null, isLoaded: null },
      campaignSharingData: { isLoading: null, isLoaded: null },
      leaveCampaign: { isLoading: null, isLoaded: null },
      joinCampaign: { isLoading: null, isLoaded: null }
    }
  };

  export const campaignsReducer = createReducer(
    initialState,
    on(CampaignsActions.getAllCampaignsData, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, allCampaigns: { isLoading: true, isLoaded: false } }
    })),
    on(CampaignsActions.getAllCampaignsDataSuccess, (state, { activeCampaigns, pastCampaigns, futureCampaigns }) => ({
      ...state,
      activeCampaigns: [...activeCampaigns].sort(
        (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      ),
      pastCampaigns: [...pastCampaigns].sort(
        (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      ),
      futureCampaigns: [...futureCampaigns].sort(
        (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      ),
      noActiveCampaign: activeCampaigns.length === 0,
      loadingStatuses: { ...state.loadingStatuses, allCampaigns: { isLoading: false, isLoaded: true } }
    })),
    on(CampaignsActions.getAllCampaignsDataError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, allCampaigns: { isLoading: false, isLoaded: false } }
    })),
    on(CampaignsActions.getUserCampaign, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, userCampaign: { isLoading: true, isLoaded: false } }
    })),
    on(CampaignsActions.getUserCampaignSuccess, (state, { campaignDetails, campaignSharingData }) => ({
      ...state,
      userCampaign: campaignDetails,
      campaignSharingData:
        campaignSharingData.length > 0
          ? campaignSharingData
          : [
              {
                image: '/assets/images/social-media/default-sharing.png',
                color: '#fff'
              }
            ],
      loadingStatuses: { ...state.loadingStatuses, userCampaign: { isLoading: false, isLoaded: true } }
    })),
    on(CampaignsActions.getUserCampaignError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, userCampaign: { isLoading: false, isLoaded: false } }
    })),

    on(CampaignsActions.getCampaignUserData, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, campaignUserData: { isLoading: true, isLoaded: false } }
    })),
    on(CampaignsActions.getCampaignUserDataSuccess, (state, { campaignUserData }) => ({
      ...state,
      activeCampaigns: sortActiveCampaigns(state.activeCampaigns, campaignUserData?.campaign),
      campaignUserData,
      loadingStatuses: { ...state.loadingStatuses, campaignUserData: { isLoading: false, isLoaded: true } }
    })),
    on(CampaignsActions.getCampaignUserDataError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, campaignUserData: { isLoading: false, isLoaded: false } }
    })),
    on(CampaignsActions.joinCampaign, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, joinCampaign: { isLoading: true, isLoaded: false } }
    })),
    on(CampaignsActions.joinCampaignSuccess, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, joinCampaign: { isLoading: false, isLoaded: true } }
    })),
    on(CampaignsActions.joinCampaignError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, joinCampaign: { isLoading: false, isLoaded: false } }
    })),
    on(CampaignsActions.leaveCampaign, (state) => ({
      ...state,
      userCampaign: null,
      campaignSharingData: [],
      loadingStatuses: { ...state.loadingStatuses, leaveCampaign: { isLoading: true, isLoaded: false } }
    })),
    on(CampaignsActions.leaveCampaignSuccess, (state) => ({
      ...state,
      userCampaign: null,
      campaignSharingData: [],
      loadingStatuses: { ...state.loadingStatuses, leaveCampaign: { isLoading: false, isLoaded: true } }
    })),
    on(CampaignsActions.leaveCampaignError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, leaveCampaign: { isLoading: false, isLoaded: false } }
    })),
    on(CampaignsActions.clearCampaignsData, () => ({
      ...initialState
    }))
  );

  export function reducer(state: CampaignsState, action: Action): CampaignsState {
    return campaignsReducer(state, action);
  }
}

function sortActiveCampaigns(activeCampaigns: CampaignData[], userCampaignId?: string): CampaignData[] {
  const sortedByStartDate = [...activeCampaigns].sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  );
  if (userCampaignId) {
    const userCampaignIndex = sortedByStartDate.findIndex((campaign) => campaign._id === userCampaignId);
    if (userCampaignIndex > -1) {
      const userCampaign = sortedByStartDate[userCampaignIndex];
      sortedByStartDate.splice(userCampaignIndex, 1);
      sortedByStartDate.unshift(userCampaign);
    }
  }
  return sortedByStartDate;
}
