import { ErrorHandler, Injectable } from '@angular/core';
import { MessageService } from './store/message.service';

@Injectable()
export class ViErrorHandler implements ErrorHandler {
  constructor(private messageService: MessageService) {}

  handleError(error: any) {
    this.messageService.showErrorSnackbar(error);
  }
}
