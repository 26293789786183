import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ChartData } from 'chart.js/dist/types';

@Component({
  selector: 'vim-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent {
  @Input()
  public chartData: ChartData<'line'> | undefined;

  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  constructor() {}
}
