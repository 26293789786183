import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersService } from '../../client/users/users.service';
import { UsersActions } from './users.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessageService } from '../message.service';
import { Router } from '@angular/router';

@Injectable()
export class UsersEffects {
  getMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getMe),
      switchMap(() =>
        this.usersService.getMe().pipe(
          map((usersResponse) => UsersActions.getMeSuccess({ me: usersResponse })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(UsersActions.updateMeError());
          })
        )
      )
    )
  );

  updateMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.updateMe),
      switchMap((action) =>
        this.usersService.updateMe(action.me).pipe(
          tap(() => this.messageService.showSuccessSnackbar('edit_profile_save_success')),
          map(() => UsersActions.updateMeSuccess({ me: action.me })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(UsersActions.updateMeError());
          })
        )
      )
    )
  );

  selectWorkspaceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersActions.updateMeSuccess),
        map(() => void this.router.navigateByUrl('/home'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private messageService: MessageService,
    private router: Router
  ) {}
}
