import { createAction, props } from '@ngrx/store';
import { BadgeModel, UserBadgeModel } from '../../client/badges/badges.model';

export namespace BadgesActions {
  export const getBadges = createAction('[BADGES] Get badges');
  export const getBadgesSuccess = createAction(
    '[BADGES] Get badges success',
    props<{ allBadges: BadgeModel[]; userBadges: UserBadgeModel[] }>()
  );
  export const getBadgesError = createAction('[BADGES] Get badges error');

  export const getEarnedBadges = createAction('[BADGES] Get earned badges');
  export const getEarnedBadgesSuccess = createAction(
    '[BADGES] Get earned badges success',
    props<{ earnedBadges: UserBadgeModel[] }>()
  );
  export const getEarnedBadgesError = createAction('[BADGES] Get earned badges error');
  export const clear = createAction('[BADGES] Clear badges data');
}
