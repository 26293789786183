import { Injectable } from '@angular/core';
import { AppState } from '../state';
import { select, Store } from '@ngrx/store';
import { UsersActions } from './users.actions';
import { UsersSelectors } from './users.selectors';
import { UpdateMeModel } from '../../client/users/users.model';

@Injectable()
export class UsersStore {
  state$ = this.store$.pipe(select(UsersSelectors.selectState));
  me$ = this.store$.pipe(select(UsersSelectors.selectMe));
  loading$ = this.store$.pipe(select(UsersSelectors.selectLoading));
  loaded$ = this.store$.pipe(select(UsersSelectors.selectLoaded));

  getMe(): void {
    this.store$.dispatch(UsersActions.getMe());
  }

  updateMe(me: UpdateMeModel): void {
    this.store$.dispatch(UsersActions.updateMe({ me }));
  }

  clear(): void {
    this.store$.dispatch(UsersActions.clear());
  }

  constructor(private store$: Store<AppState>) {}
}
