<vim-dialog-template *ngIf="(notificationsStore.notifications$ | async) as notifications"
                     [title]="'common_notifications' | translate" (closeDialog)="close()">
  <div class="notifications-list">
    <div *ngIf="notifications.length === 0"
         class="notifications-list-item">{{'notifications_empty_list_title' | translate}}</div>
    <div *ngFor="let notification of notifications"
         class="notifications-list-item {{notification.status}}"
         fxLayout>
      <img class="notifications-list-image" [src]="notification.image" alt="Notification icon">
      <div class="notifications-list-texts">
        <div class="title LabelLabel-3">{{ notification.title }}</div>
        <div class="description LabelLabel-4">{{ notification.description }}</div>
        <div class="time LabelLabel-7">{{ notification.date | roundTime }}</div>
      </div>
    </div>
  </div>
</vim-dialog-template>
