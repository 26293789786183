import { createSelector, MemoizedSelector } from '@ngrx/store';
import { WorkspacesSelectors } from '../../../../store/workspaces/workspaces.selectors';
import { TeamsSelectors } from '../../../../store/teams/teams.selectors';
import { AppState } from '../../../../store/state';
import { CampaignUnit } from '../../../../client/campaigns/campaignsData';
import { CampaignsSelectors } from '../../../../store/campaigns/campaigns.selectors';
import { TeamMember, TeamPrivacy, TeamsItem } from '../../../../client/teams/teams.model';
import { UsersSelectors } from '../../../../store/users/users.selectors';

export namespace TeamSelectors {
  export const teamViewModel: MemoizedSelector<AppState, TeamViewModel> = createSelector(
    CampaignsSelectors.selectMultipleTeamsEnabled,
    UsersSelectors.selectMe,
    TeamsSelectors.selectMyTeams,
    TeamsSelectors.selectTeam,
    TeamsSelectors.selectTeamMembers,
    CampaignsSelectors.selectCurrentCampaignUnit,
    WorkspacesSelectors.isLoading,
    TeamsSelectors.selectIsLoading,
    (
      multipleTeamsEnabled,
      me,
      myTeams,
      currentTeam,
      currentTeamActiveMembers,
      campaignUnit,
      workspacesIsLoading,
      teamsIsLoading
    ) => ({
      currentUserId: me?.id,
      canJoinTeam:
        currentTeam?.privacy === TeamPrivacy.open &&
        (multipleTeamsEnabled || myTeams?.data.length === 0) &&
        currentTeamActiveMembers.find((member) => member._id === me?.id) === undefined,
      currentTeam,
      userAlreadyInCurrentTeam: !!currentTeamActiveMembers.find((member) => member._id === me?.id),
      currentTeamActiveMembers,
      campaignUnit: campaignUnit || CampaignUnit.POINTS,
      isLoading: workspacesIsLoading || teamsIsLoading
    })
  );
}

export interface TeamViewModel {
  currentUserId: string | undefined;
  canJoinTeam: boolean;
  currentTeam: TeamsItem | null;
  currentTeamActiveMembers: TeamMember[];
  isLoading: boolean;
  userAlreadyInCurrentTeam: boolean;
  campaignUnit: CampaignUnit;
}
