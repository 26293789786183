<ng-container *ngIf="errors">
  <mat-error class="LabelLabel-3" [style.padding-left.px]="16" *ngIf="errors.required">
    {{'input_text_error_required' | translate}}
  </mat-error>
  <mat-error class="LabelLabel-3" [style.padding-left.px]="16" *ngIf="errors.maxlength">
    {{'input_text_error_too_long' | translate | sprintf:errors.maxlength?.requiredLength}}
  </mat-error>
  <mat-error class="LabelLabel-3" [style.padding-left.px]="16" *ngIf="errors.minlength">
    {{'input_text_error_too_short' | translate | sprintf:errors.minlength?.requiredLength}}
  </mat-error>
  <mat-error class="LabelLabel-3" [style.padding-left.px]="16" *ngIf="errors.min">
    {{'input_number_error_min' | translate | sprintf:errors.min?.min}}
  </mat-error>
  <mat-error class="LabelLabel-3" [style.padding-left.px]="16" *ngIf="errors.max">
    {{'input_number_error_max' | translate | sprintf:errors.max?.max}}
  </mat-error>
  <mat-error class="LabelLabel-3" [style.padding-left.px]="16" *ngIf="errors.pattern">
    {{'input_text_error_incorrect' | translate }}
  </mat-error>
</ng-container>
