<div class="header" fxLayout fxLayoutAlign="space-between">
  <div class="DisplayRegularHeading-6">{{'teams_create_teams_title' | translate}}</div>
  <button mat-icon-button (click)="dialogRef.close()" aria-label="Close button">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div [style.padding.px]="24">
  <form [formGroup]='form' (keydown.enter)='$event.preventDefault();' (ngSubmit)='createTeam()'>
    <div fxLayout='column' fxFlex='1 1 auto'>
      <p class='title ParagraphParagraph-2'>{{'teams_create_description' | translate}}</p>
      <h2 class='HeadingHeading-6'>{{'teams_create_details' | translate}}</h2>
      <mat-form-field appearance='outline' floatLabel='auto'>
        <mat-label>{{'teams_team_name' | translate}}</mat-label>
        <input matInput class='team-name-input' formControlName='teamName' id='teamName'/>
        <mat-error
          *ngIf='form.controls.teamName.errors?.required'>{{'input_text_error_required' | translate}}</mat-error>
        <mat-error
          *ngIf='form.controls.teamName.errors?.maxlength'>{{'input_text_error_too_long' | translate | sprintf:20}}</mat-error>
        <mat-error
          *ngIf='form.controls.teamName.errors?.minlength'>{{'input_text_error_too_short' | translate | sprintf:3}}</mat-error>
      </mat-form-field>
      <div fxLayout fxLayoutAlign="space-between center" [style.margin-bottom.px]="20">
        <h2 class='HeadingHeading-6'>{{'team_privacy_filter' | translate}}*</h2>
        <mat-chip-listbox fxLayout fxLayoutALign="start center" class="chip-list" ngClass.lt-md='mobile-chip-list'
                          formControlName="privacy"
                          aria-label="Team privacy">
          <mat-chip-option value="open"
                           (click)="setTeamPrivacy(teamPrivacy.open)">{{'team_privacy_public' | translate}}</mat-chip-option>
          <mat-chip-option value="private"
                           (click)="setTeamPrivacy(teamPrivacy.closed)">{{'team_privacy_private' | translate}}</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <vim-campaign-name></vim-campaign-name>
      <h2 class='HeadingHeading-6'>{{'teams_create_invite_member_header' | translate}}&nbsp;<span
        class='members-max-header'>(Max. 15)</span></h2>
      <vim-email-list [formGroup]='form' controlName='inviteEmails' [style.margin-bottom.px]="46"></vim-email-list>
      <div fxLayout fxLayoutAlign="end" class='action-buttons' ngClass.lt-md='mobile-action-buttons'
           ngClass.gt-md='desktop-action-buttons'>
        <button [disabled]='!form.valid' mat-button color='primary' vim-button id='createTeam'>
          <div fxLayout fxLayoutAlign="center">
            <span>{{'teams_create_teams_btn' | translate}}&nbsp;</span>
            <mat-spinner *ngIf="teamsStore.isLoading$ | async else arrow" diameter="30"></mat-spinner>
            <ng-template #arrow>
              <mat-icon>arrow_forward</mat-icon>
            </ng-template>
          </div>
        </button>
      </div>
    </div>
  </form>
</div>
