import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { AdminPanelSelectors } from './admin-panel.selectors';
import { AdminPanelActions } from './admin-panel.actions';
import { BadgeDetails } from './models/badge-details.model';
import { InsightDetails } from './models/insight-details.model';
import {
  MarketingNotification,
  MarketingNotificationPost
} from '../../client/admin-panel/models/admin-panel-marketing-notification.model';
import { CampaignDetailsEditorModel } from './models/campaign-details.model';

@Injectable()
export class AdminPanelStore {
  activeCampaigns$ = this.store$.select(AdminPanelSelectors.selectActiveCampaigns);
  finishedCampaigns$ = this.store$.select(AdminPanelSelectors.selectFinishedCampaigns);
  firstUpcomingCampaign$ = this.store$.select(AdminPanelSelectors.selectFirstUpcomingCampaign);
  showStatistics$ = this.store$.select(AdminPanelSelectors.selectShowStatistics);
  insights$ = this.store$.select(AdminPanelSelectors.selectInsights);
  insightDetails$ = this.store$.select(AdminPanelSelectors.selectInsightDetails);
  badgeDetails$ = this.store$.select(AdminPanelSelectors.selectBadgeDetails);
  isLoaded$ = this.store$.select(AdminPanelSelectors.isLoaded);
  isLoading$ = this.store$.select(AdminPanelSelectors.isLoading);
  canEditAdminContent$ = this.store$.select(AdminPanelSelectors.selectCanEditAdminContent);
  canViewOrEditAdminContent$ = this.store$.select(AdminPanelSelectors.selectCanViewOrEditAdminContent);
  availableCampaigns$ = this.store$.select(AdminPanelSelectors.selectAvailableCampaigns);
  campaigns$ = this.store$.select(AdminPanelSelectors.selectCampaigns);
  campaignDetails$ = this.store$.select(AdminPanelSelectors.selectCampaignDetails);
  activities$ = this.store$.select(AdminPanelSelectors.selectActivities);
  badges$ = this.store$.select(AdminPanelSelectors.selectBadges);
  units$ = this.store$.select(AdminPanelSelectors.selectUnits);

  getUnits(): void {
    this.store$.dispatch(AdminPanelActions.getUnits());
  }

  getAdminData(): void {
    this.store$.dispatch(AdminPanelActions.getAdminData());
  }

  getBadges(): void {
    this.store$.dispatch(AdminPanelActions.getBadges());
  }

  getActivities(): void {
    this.store$.dispatch(AdminPanelActions.getActivities());
  }

  getInsights(): void {
    this.store$.dispatch(AdminPanelActions.getInsights());
  }

  getCampaignsLight(): void {
    this.store$.dispatch(AdminPanelActions.getCampaignsLight());
  }

  getCampaigns(): void {
    this.store$.dispatch(AdminPanelActions.getCampaigns());
  }

  getCampaignDetails(campaignId: string): void {
    this.store$.dispatch(AdminPanelActions.getCampaignDetails({ campaignId }));
  }

  createCampaign(campaignDetails: CampaignDetailsEditorModel, draftId?: string): void {
    this.store$.dispatch(AdminPanelActions.createCampaign({ campaignDetails, draftId }));
  }

  createCampaignDraft(campaignDetails: Partial<CampaignDetailsEditorModel>): void {
    this.store$.dispatch(AdminPanelActions.createCampaignDraft({ campaignDetails }));
  }

  updateCampaignDraft(campaignDetails: Partial<CampaignDetailsEditorModel>, campaignId: string): void {
    this.store$.dispatch(AdminPanelActions.updateCampaignDraft({ campaignDetails, campaignId }));
  }

  updateCampaign(campaignDetails: CampaignDetailsEditorModel, campaignId: string): void {
    this.store$.dispatch(AdminPanelActions.updateCampaign({ campaignDetails, campaignId }));
  }

  getInsightDetails(insightId: string): void {
    this.store$.dispatch(AdminPanelActions.getInsightDetails({ insightId }));
  }

  getBadgeDetails(badgeId: string): void {
    this.store$.dispatch(AdminPanelActions.getBadgeDetails({ badgeId }));
  }

  deleteInsight(insightId: string): void {
    this.store$.dispatch(AdminPanelActions.deleteInsight({ insightId }));
  }

  deleteBadge(badgeId: string): void {
    this.store$.dispatch(AdminPanelActions.deleteBadge({ badgeId }));
  }

  deleteCampaign(campaignId: string): void {
    this.store$.dispatch(AdminPanelActions.deleteCampaign({ campaignId }));
  }

  deleteCampaignDraft(draftId: string): void {
    this.store$.dispatch(AdminPanelActions.deleteCampaignDraft({ draftId }));
  }

  getCampaignsData(): void {
    this.store$.dispatch(AdminPanelActions.getCampaignsData());
  }

  updateInsight(insightDetails: Partial<InsightDetails>, insightId: string): void {
    this.store$.dispatch(AdminPanelActions.updateInsight({ insightDetails, insightId }));
  }

  createInsight(insightDetails: InsightDetails): void {
    this.store$.dispatch(AdminPanelActions.createInsight({ insightDetails }));
  }

  createMarketingNotification(notification: MarketingNotification): void {
    this.store$.dispatch(
      AdminPanelActions.createMarketingNotification({
        notification: AdminPanelStore.toMarketingNotificationRequest(notification)
      })
    );
  }

  clearInsightDetails(): void {
    this.store$.dispatch(AdminPanelActions.clearInsightDetails());
  }

  clearBadgeDetails(): void {
    this.store$.dispatch(AdminPanelActions.clearBadgeDetails());
  }

  createBadge(badgeDetails: BadgeDetails): void {
    this.store$.dispatch(AdminPanelActions.createBadge({ badgeDetails }));
  }

  updateBadge(badgeDetails: Partial<BadgeDetails>, badgeId: string): void {
    this.store$.dispatch(AdminPanelActions.updateBadge({ badgeDetails, badgeId }));
  }

  private static toMarketingNotificationRequest({
    campaignIds,
    content
  }: MarketingNotification): MarketingNotificationPost {
    return {
      campaignId: campaignIds,
      title: content.map(({ language, title }) => ({ language, value: title })),
      body: content.map(({ language, text }) => ({ language, value: text }))
    };
  }

  constructor(private store$: Store<AppState>) {}
}
