import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminPanelActions } from './admin-panel.actions';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { MessageService } from '../message.service';
import { adminPanelDataMock } from './admin-panel-data.mock';
import { CampaignsData } from '../../client/admin-panel/admin-panel.data';
import { AdminPanelService } from '../../client/admin-panel/admin-panel.service';
import { AdminData } from '../../client/admin-panel/models/admin-panel.model';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';
import { Router } from '@angular/router';
import {
  CampaignDetailsImages,
  CampaignDetailsPatchPayload,
  CampaignDetailsPostPayload,
  CampaignItem,
  CampaignItemLightResponse
} from '../../client/admin-panel/models/admin-panel-campaign.model';
import {
  InsightDetailsPatchPayload,
  InsightDetailsPostPayload,
  InsightItem
} from '../../client/admin-panel/models/admin-panel-insight.model';
import {
  BadgeDetailsPatch,
  BadgeDetailsPost,
  BadgeItemResponse
} from '../../client/admin-panel/models/admin-panel-badge.model';
import {
  AdminPanelCampaignImagesService,
  ImagePath
} from '../../client/admin-panel/admin-panel-campaign-images.service';
import { CampaignDetailsEditorModel } from './models/campaign-details.model';

@Injectable()
export class AdminPanelEffects {
  getCampaignsData = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getCampaignsData),
      switchMap(() =>
        of(adminPanelDataMock).pipe(
          map((data: CampaignsData) => {
            const upcomingCampaigns = data.upcomingCampaigns;
            const activeCampaigns = data.activeCampaigns;
            const finishedCampaigns = data.finishedCampaigns;
            return AdminPanelActions.getCampaignsDataSuccess({ upcomingCampaigns, activeCampaigns, finishedCampaigns });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getCampaignsDataError());
          })
        )
      )
    )
  );
  getAdminData = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getAdminData),
      switchMap(() =>
        this.adminPanelService.getAdminData().pipe(
          map((adminData: AdminData) => AdminPanelActions.getAdminDataSuccess({ adminData })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getAdminDataError());
          })
        )
      )
    )
  );
  getActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getActivities),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.adminPanelService.getActivities(workspaceId!).pipe(
          map((activities) => AdminPanelActions.getActivitiesSuccess({ activities })),
          catchError((err) => {
            //ToDo: handle admin viewer role
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (err?.statusCode !== 403) {
              this.messageService.showErrorSnackbar(err);
            }
            return of(AdminPanelActions.getActivitiesError());
          })
        )
      )
    )
  );
  getInsights = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getInsights),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.adminPanelService.getInsights(workspaceId!).pipe(
          map((insights: InsightItem[]) => AdminPanelActions.getInsightsSuccess({ insights })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getInsightsError());
          })
        )
      )
    )
  );

  getUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getUnits),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.adminPanelService.getUnits(workspaceId!).pipe(
          map((units) => AdminPanelActions.getUnitsSuccess({ units })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getUnitsError());
          })
        )
      )
    )
  );

  getCampaignsLight = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getCampaignsLight),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.adminPanelService.getCampaignsLight(workspaceId!).pipe(
          map((campaigns: CampaignItemLightResponse[]) => AdminPanelActions.getCampaignsLightSuccess({ campaigns })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getCampaignsLightError());
          })
        )
      )
    )
  );

  getCampaigns = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getCampaigns),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.adminPanelService.getCampaigns(workspaceId!).pipe(
          map((campaigns: { data: CampaignItem[]; total: number }) =>
            AdminPanelActions.getCampaignsSuccess({ campaigns: campaigns.data })
          ),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getCampaignsError());
          })
        )
      )
    )
  );

  getCampaignDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getCampaignDetails),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignId }, workspaceId]) =>
        this.adminPanelService.getCampaignDetails(workspaceId!, campaignId).pipe(
          map((campaignDetails) =>
            AdminPanelActions.getCampaignDetailsSuccess({
              campaignDetails
            })
          ),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getCampaignDetailsError());
          })
        )
      )
    )
  );

  getBadges = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getBadges),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.adminPanelService.getBadges(workspaceId!).pipe(
          map((badges: BadgeItemResponse[]) => AdminPanelActions.getBadgesSuccess({ badges })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getBadgesError());
          })
        )
      )
    )
  );

  getBadgeDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getBadgeDetails),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ badgeId }, workspaceId]) =>
        this.adminPanelService.getBadgeDetails(workspaceId!, badgeId).pipe(
          map((badgeDetails) => AdminPanelActions.getBadgeDetailsSuccess({ badgeDetails })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getBadgeDetailsError());
          })
        )
      )
    )
  );

  createBadge = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.createBadge),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      concatMap(([{ badgeDetails }, workspaceId]) =>
        this.adminPanelService.uploadBadgeImage(workspaceId!, badgeDetails.image).pipe(
          concatMap(({ id }) =>
            this.adminPanelService.createBadge(workspaceId!, BadgeDetailsPost.fromBadgeDetails(badgeDetails, id))
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_badge_created_message');
            void this.router.navigate(['/admin-portal/badges']);
            return AdminPanelActions.createBadgeSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createBadgeError());
          })
        )
      )
    )
  );

  updateBadge = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.updateBadge),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      concatMap(([{ badgeDetails, badgeId }, workspaceId]) =>
        (badgeDetails.image ? this.adminPanelService.uploadBadgeImage(workspaceId!, badgeDetails.image) : of({})).pipe(
          concatMap(({ id }: { id?: string }) =>
            this.adminPanelService.updateBadge(
              workspaceId!,
              badgeId,
              BadgeDetailsPatch.fromBadgeDetails(badgeDetails, id)
            )
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_badge_updated_message');
            void this.router.navigate(['/admin-portal/badges']);
            return AdminPanelActions.updateBadgeSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createBadgeError());
          })
        )
      )
    )
  );

  createInsight = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.createInsight),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      concatMap(([{ insightDetails }, workspaceId]) =>
        this.adminPanelService.uploadInsightImage(insightDetails.image! as File).pipe(
          concatMap(({ file }) =>
            this.adminPanelService.createInsight(
              workspaceId!,
              InsightDetailsPostPayload.fromInsightDetails(insightDetails, file)
            )
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_insight_created_message');
            void this.router.navigate(['/admin-portal/insights']);
            return AdminPanelActions.createInsightSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createInsightError());
          })
        )
      )
    )
  );

  updateInsight = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.updateInsight),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      concatMap(([{ insightDetails, insightId }, workspaceId]) =>
        (insightDetails.image && typeof insightDetails.image === 'object'
          ? this.adminPanelService.uploadInsightImage(insightDetails.image)
          : of({})
        ).pipe(
          concatMap(({ file }: { file?: string }) =>
            this.adminPanelService.updateInsight(
              workspaceId!,
              insightId,
              InsightDetailsPatchPayload.fromInsightDetails(insightDetails, file)
            )
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_insight_updated_message');
            void this.router.navigate(['/admin-portal/insights']);
            return AdminPanelActions.updateInsightSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createBadgeError());
          })
        )
      )
    )
  );

  getInsightDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.getInsightDetails),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ insightId }, workspaceId]) =>
        this.adminPanelService.getInsightDetails(workspaceId!, insightId).pipe(
          map((insightDetails) => AdminPanelActions.getInsightDetailsSuccess({ insightDetails })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getInsightDetailsError());
          })
        )
      )
    )
  );

  deleteInsight = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteInsight),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ insightId }, workspaceId]) =>
        this.adminPanelService.deleteInsight(workspaceId!, insightId).pipe(
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_insight_deleted');
            return AdminPanelActions.deleteInsightSuccess({ insightId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.getInsightDetailsError());
          })
        )
      )
    )
  );

  deleteBadge = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteBadge),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ badgeId }, workspaceId]) =>
        this.adminPanelService.deleteBadge(workspaceId!, badgeId).pipe(
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_badge_deleted');
            return AdminPanelActions.deleteBadgeSuccess({ badgeId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.deleteBadgeError());
          })
        )
      )
    )
  );
  deleteCampaign = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteCampaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignId }, workspaceId]) =>
        this.adminPanelService.deleteCampaign(workspaceId!, campaignId).pipe(
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_campaign_deleted');
            void this.router.navigate(['/admin-portal/campaigns']);
            return AdminPanelActions.deleteCampaignSuccess({ campaignId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.deleteCampaignError());
          })
        )
      )
    )
  );
  deleteCampaignDraft = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteCampaignDraft),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ draftId, campaignCreated }, workspaceId]) =>
        this.adminPanelService.deleteCampaignDraft(workspaceId!, draftId).pipe(
          map(() => {
            if (campaignCreated) {
              this.messageService.showSuccessSnackbar('admin_panel_campaign_created_and_draft_deleted');
            } else {
              this.messageService.showSuccessSnackbar('admin_panel_campaign_draft_deleted');
            }
            void this.router.navigate(['/admin-portal/campaigns']);
            return AdminPanelActions.deleteCampaignDraftSuccess({ draftId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.deleteCampaignDraftError());
          })
        )
      )
    )
  );

  createMarketingNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.createMarketingNotification),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.adminPanelService.createMarketingNotification(workspaceId!, action.notification).pipe(
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_notification_created_message');
            return AdminPanelActions.createMarketingNotificationSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createMarketingNotificationError());
          })
        )
      )
    )
  );

  updateCampaign = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.updateCampaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignDetails, campaignId }, workspaceId]) =>
        this.uploadCampaignImages(campaignDetails, workspaceId!).pipe(
          concatMap((campaignImages) =>
            this.adminPanelService.updateCampaign(
              workspaceId!,
              campaignId,
              CampaignDetailsPatchPayload.fromCampaignDetailsEditorModel(campaignDetails, {
                ...campaignImages
              })
            )
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_campaign_updated_message');
            void this.router.navigate(['/admin-portal/campaigns']);
            return AdminPanelActions.updateCampaignSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.updateCampaignError());
          })
        )
      )
    )
  );

  updateCampaignDraft = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.updateCampaignDraft),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignDetails, campaignId }, workspaceId]) =>
        this.uploadCampaignImages(campaignDetails, workspaceId!).pipe(
          concatMap((campaignImages) =>
            this.adminPanelService.updateCampaignDraft(
              workspaceId!,
              campaignId,
              CampaignDetailsPatchPayload.fromCampaignDetailsEditorModel(campaignDetails, {
                ...campaignImages
              })
            )
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_campaign_draft_updated_message');
            void this.router.navigate(['/admin-portal/campaigns']);
            return AdminPanelActions.updateCampaignDraftSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.updateCampaignDraftError());
          })
        )
      )
    )
  );

  createCampaign = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.createCampaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignDetails, draftId }, workspaceId]) =>
        this.uploadCampaignImages(campaignDetails, workspaceId!).pipe(
          concatMap((campaignImages) =>
            this.adminPanelService.createCampaign(
              workspaceId!,
              CampaignDetailsPostPayload.fromCampaignDetailsEditorModel(campaignDetails, {
                ...campaignImages
              })
            )
          ),
          map(() => {
            if (!draftId) {
              this.messageService.showSuccessSnackbar('admin_panel_campaign_created_message');
            }
            void this.router.navigate(['/admin-portal/campaigns']);
            return AdminPanelActions.createCampaignSuccess({ draftId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createCampaignError());
          })
        )
      )
    )
  );
  deleteCampaignDraftAfterCampaignCreated = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.createCampaignSuccess),
      filter(({ draftId }) => !!draftId),
      map(({ draftId }) => AdminPanelActions.deleteCampaignDraft({ draftId: draftId!, campaignCreated: true }))
    )
  );
  createCampaignDraft = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.createCampaignDraft),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignDetails }, workspaceId]) =>
        this.uploadCampaignImages(campaignDetails, workspaceId!).pipe(
          concatMap((campaignImages) =>
            this.adminPanelService.createCampaignDraft(
              workspaceId!,
              CampaignDetailsPatchPayload.fromCampaignDetailsEditorModel(campaignDetails, {
                ...campaignImages
              })
            )
          ),
          map(() => {
            this.messageService.showSuccessSnackbar('admin_panel_campaign_draft_created_message');
            void this.router.navigate(['/admin-portal/campaigns']);
            return AdminPanelActions.createCampaignDraftSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(AdminPanelActions.createCampaignDraftError());
          })
        )
      )
    )
  );

  private uploadCampaignImages(
    campaignDetails: Partial<CampaignDetailsEditorModel>,
    workspaceId: string
  ): Observable<CampaignDetailsImages<string>> {
    return forkJoin([
      this.adminPanelCampaignImagesService.uploadCampaignImages(workspaceId, campaignDetails),
      campaignDetails.shareImages
        ? this.adminPanelCampaignImagesService.uploadSharedImages(
            workspaceId,
            campaignDetails.shareImages,
            ImagePath.shareImage
          )
        : of(null),
      campaignDetails.activityShareImages
        ? this.adminPanelCampaignImagesService.uploadSharedImages(
            workspaceId,
            campaignDetails.activityShareImages,
            ImagePath.activityShareImage
          )
        : of(null)
    ]).pipe(
      map(([campaignImages, shareImages, activityShareImages]) => ({
        ...campaignImages,
        ...(shareImages ? { shareImages } : {}),
        ...(activityShareImages ? { activityShareImages } : {})
      }))
    );
  }

  refreshCampaignsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteCampaignSuccess, AdminPanelActions.deleteCampaignDraftSuccess),
      map(() => AdminPanelActions.getCampaigns())
    )
  );
  refreshBadgesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteBadgeSuccess),
      map(() => AdminPanelActions.getBadges())
    )
  );
  refreshInsightsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPanelActions.deleteInsightSuccess),
      map(() => AdminPanelActions.getInsights())
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private adminPanelService: AdminPanelService,
    private adminPanelCampaignImagesService: AdminPanelCampaignImagesService,
    private messageService: MessageService,
    private router: Router
  ) {}
}
