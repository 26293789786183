import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';
import {
  CampaignDetails,
  CampaignsData,
  CampaignSharingData,
  CampaignStatistics,
  CampaignStatus,
  CampaignUserData
} from './campaignsData';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  getCampaign(campaignId: string, workspaceId: string): Observable<CampaignDetails> {
    return this.httpService.get(`/workspaces/${workspaceId}/campaigns/${campaignId}`);
  }

  getCampaigns(campaignStatus: CampaignStatus, workspaceId: string): Observable<CampaignsData> {
    return this.httpService.get(`/workspaces/${workspaceId}/campaigns`, { filter: campaignStatus });
  }

  getCampaignUserData(workspaceId: string): Observable<CampaignUserData> {
    return this.httpService.get(`/workspaces/${workspaceId}/me`);
  }

  joinCampaign(campaignId: string, workspaceId: string): Observable<any> {
    return this.httpService.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/join`, {});
  }

  leaveCampaign(campaignId: string, workspaceId: string): Observable<any> {
    return this.httpService.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/leave`, {});
  }

  getCampaignSharingData(campaignId: string, workspaceId: string): Observable<{ data: CampaignSharingData[] }> {
    return this.httpService.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/share`);
  }

  getCampaignUserStatistics(campaignId: string, workspaceId: string): Observable<CampaignStatistics> {
    return this.httpService.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/statistics`);
  }

  constructor(private httpService: HttpService) {}
}
