import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public static readonly GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.viessmann.vimove';

  public static readonly APPLE_STORE_URL = 'https://apps.apple.com/app/vimove/id1603124385';

  public static readonly APP_URL = 'https://app.vimove.com';
  public static readonly FITNESS_PLATFORM_KEY = 'platform';

  public static readonly SHOW_ONLY_HOURS_FROM = 1000;
}
