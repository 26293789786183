import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectCountryService } from './select-country.service';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'vim-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CountrySelectorComponent
    }
  ]
})
export class CountrySelectorComponent implements OnInit, ControlValueAccessor {
  matchingCountries: Observable<CountryModel[]>;
  @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;
  @ViewChild('countryInput', { static: true }) countryInput: MatInput;
  @Input() allOption = false;
  @Input() width = '100%';
  @Input() allowAllOption = false;

  controlCountry = new FormControl<string>('');

  private touched = false;

  constructor(
    private translateService: TranslateService,
    private countriesService: SelectCountryService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function,no-unused-vars
  onChange = (country: string | null) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.controlCountry.disable();
    } else {
      this.controlCountry.enable();
    }
  }

  get countries(): Array<CountryModel> {
    if (this.allowAllOption) {
      return [
        {
          code: null,
          name: this.translateService.instant('admin_panel_insight_details_all_countries') as string
        },
        ...this.countriesService.countries
      ];
    }
    return this.countriesService.countries;
  }

  ngOnInit(): void {
    this.matchingCountries = this.controlCountry.valueChanges.pipe(
      filter((v) => typeof v === 'string'),
      startWith(''),
      filter((v) => !!v),
      map((countryName) =>
        this.countries.filter((country) => country.name.toLowerCase().includes(countryName!.toLowerCase()))
      )
    );
  }

  countrySelected({ option: { value } }: MatAutocompleteSelectedEvent) {
    this.markAsTouched();
    if (value) {
      const code = this.countries.find((country) => country.name === value)?.code;
      this.setCountryCode((code as string) || null);
    }
  }

  private setCountryCode(countryCode: string | null): void {
    if (countryCode === null && this.allOption) {
      this.onChange(null);
    } else if (countryCode) {
      this.onChange(countryCode);
    }
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(countryCode: string): void {
    if (countryCode) {
      const country = this.countries.find((country) => country.code?.toLowerCase() === countryCode?.toLowerCase());
      if (country) {
        this.controlCountry.setValue(country.name);
      }
    }
  }
}

export interface CountryModel {
  name: string;
  code: string | null;
}
