import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'vim-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizedComponent {
  constructor() {}
}
