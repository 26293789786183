import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TeamsItem } from 'src/app/client/teams/teams.model';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-total-distance-summary',
  templateUrl: './total-distance-summary.component.html',
  styleUrls: ['./total-distance-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalDistanceSummaryComponent {
  @Input() team!: TeamsItem;
  @Input() campaignUnit: CampaignUnit;
}
