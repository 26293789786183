<div class="help-container">
  <h2>{{ 'header_help' | translate }}</h2>
  <h3>{{ 'help_answer_to_questions' | translate }}</h3>
  <vim-help-expandable-panel [title]="'help_how_can_i_take_part' | translate | sprintf: appName">
    {{ 'help_how_can_i_take_part_answer' | translate | sprintf: appSettings.APP_URL:appName }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_can_i_take_part_without_sw' | translate | sprintf: appName">
    {{ 'help_can_i_take_part_without_sw_answer' | translate | sprintf: appName }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_which_types' | translate">
    {{ 'help_which_types_answer' | translate }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_how_much_exercise' | translate">
    {{ 'help_how_much_exercise_answer' | translate }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_where_can_i_see' | translate">
    {{ 'help_where_can_i_see_answer' | translate | sprintf: appName }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_how_often' | translate">
    {{ 'help_how_often_answer' | translate }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_does_store_my_data' | translate | sprintf: appName">
    {{ 'help_does_store_my_data_answer' | translate | sprintf: appName }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_when_trees_planted' | translate">
    {{ 'help_when_trees_planted_answer' | translate }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_can_i_support' | translate">
    {{ 'help_can_i_support_answer' | translate }}
  </vim-help-expandable-panel>

  <vim-help-expandable-panel [title]="'help_how_can_i_quickly_access' | translate | sprintf: appName">
    {{ 'help_how_can_i_quickly_access_answer' | translate | sprintf: appName }}
  </vim-help-expandable-panel>
  <vim-help-expandable-panel id="delete-account" #deleteAccountPanel
                             [title]="'help_how_to_delete_account_title' | translate">
    <div [innerHtml]="'help_how_to_delete_account_content' | translate"></div>
    <div *ngIf="isLoggedIn | async" fxLayout>
      <vim-button [style.min-width.px]="270" (click)="deleteAccount()"
                  class="secondary">{{ 'delete_account_title' | translate }}</vim-button>
    </div>
  </vim-help-expandable-panel>
  <vim-whats-new></vim-whats-new>
  <div class="image-container"></div>
</div>
