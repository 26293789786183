import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../../services/converter.service';

@Pipe({
  name: 'minutesOrHours'
})
export class MinutesOrHoursPipe implements PipeTransform {
  transform(seconds: number | null | undefined, showOnlyHoursFrom?: number): string {
    if (!seconds) {
      return '0 min';
    }
    const result = ConverterService.secondsToMinutesOrHours(seconds);
    if (showOnlyHoursFrom && result.hours && result.hours >= showOnlyHoursFrom) {
      return `${result.hours} h`;
    }
    if (result.minutes && result.hours) {
      return `${result.hours} h ${result.minutes} min`;
    }
    if (result.hours && !result.minutes) {
      return `${result.hours} h`;
    }
    if (result.minutes) {
      return `${result.minutes} min`;
    }
    return '0 min';
  }
}
