<div fxLayout fxLayoutAlign="end">
  <button class="close-button" (click)="dialogRef.close()" mat-icon-button tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout fxLayoutAlign="center" [style.margin-bottom]="'12px'">
  <vim-download-picture [fileName]="'contribution.png'">
    <vim-shared-picture #pictureComponent [data]="data"
                        [selectedImageIndex]="activitiesStore.selectedActivityIndex$ | async"></vim-shared-picture>
  </vim-download-picture>
</div>
<div *ngIf="data.images.length > 1" fxLayout fxLayoutAlign="center">
  <div class="image-list">
    <img (click)="selectImage(ind)" class="image-list__image"
         [class.selected]="(activitiesStore.selectedActivityIndex$ | async) === ind"
         *ngFor="let image of data.images, index as ind"
         [src]="image.image"
         alt="Shared image">
  </div>
</div>

<div [ngClass.gt-xs]="'DisplayRegularHeading-6'" [ngClass.xs]="'DisplayRegularHeading-7'"
     [style.margin-bottom]="'40px'" [style.text-align]="'center'">
  <span *ngIf="data.text else defaultText">{{data.text}}</span>
  <ng-template #defaultText>
    <span>{{'activity_share_contribution' | translate}}</span>
  </ng-template>
  <span fxShow fxHide.xs>&nbsp;</span>
  <br fxHide fxShow.xs>
  <span class="hashtag">{{data.hashtag}}</span>
</div>
<div fxLayout fxLayoutAlign="center">
  <vim-button (click)="download()" [style.margin-bottom.px]="16" class="primary"
              id='download-picture-button'>{{'common_download_picture' | translate}}</vim-button>
</div>
