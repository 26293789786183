<div fxLayout='column'>
  <mat-divider></mat-divider>
  <ng-container *ngIf='removedMembers.length > 0; else onlyActiveMembers'>
    <mat-tab-group animationDuration='0ms'>
      <mat-tab label="{{'common_active' | translate }}">
        <ng-container *ngFor='let member of members; let last = last'>
          <vim-team-member-row [member]='member' confirmationText="{{'edit_team_remove_this_person' | translate }}"
                               (changeStatusOfMember)='markMemberAsRemoved($event)'></vim-team-member-row>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-tab>
      <mat-tab label="{{'common_removed' | translate }}">
        <ng-container *ngFor='let member of removedMembers; let last = last'>
          <vim-team-member-row [member]='member' [removedUsers]='true'
                               confirmationText="{{'edit_team_activate_this_person' | translate }}"
                               (changeStatusOfMember)='reInviteMember($event)'></vim-team-member-row>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-template #onlyActiveMembers>
    <ng-container *ngFor='let member of members; let last = last'>
      <vim-team-member-row [member]='member' [removedUsers]='false'
                           confirmationText="{{'edit_team_remove_this_person' | translate }}"
                           (changeStatusOfMember)='markMemberAsRemoved($event)'></vim-team-member-row>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </ng-template>
</div>
