<div class="container" [class.invalid]="hasError">
  <div *ngIf="labelKey" class="label ParagraphParagraph-2">
    {{labelKey | translate}}&nbsp;<span *ngIf="isRequired">*</span>
  </div>
  <vim-image-preview height="300px" *ngIf="(image$ | async) as image else imageUploader" [image]="image"
                     [hideDeleteButton]="disabled" (delete)="deleteImage()"></vim-image-preview>
  <div fxLayout fxLayoutAlign="space-between center" [style.padding]="'8px 0'"
       *ngIf="imageProperties$ | async as imageProperties">
    <div class="Label-Label1">{{imageProperties}}</div>
  </div>
  <ng-template #imageUploader>
    <vim-image-uploader [imageRequirementsDescription]="imageRequirementsDescription"
                        [markAsInvalid]="(invalid || isInvalid) && (isRequired && touched)"
                        [showHeader]="false" [enabled]="true"
                        (addImage)="addImage($event)"></vim-image-uploader>
  </ng-template>
</div>
