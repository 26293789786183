import { select, Store } from '@ngrx/store';
import { AppState } from '../state';
import { BadgesActions } from './badges.actions';
import { BadgesSelectors } from './badges.selectors';
import { Injectable } from '@angular/core';

@Injectable()
export class BadgesStore {
  constructor(private store$: Store<AppState>) {}

  availableBadges$ = this.store$.pipe(select(BadgesSelectors.selectAvailableBadges));
  earnedBadges$ = this.store$.pipe(select(BadgesSelectors.selectEarnedBadges));
  isLoading$ = this.store$.pipe(select(BadgesSelectors.selectIsLoading));

  getBadges(): void {
    this.store$.dispatch(BadgesActions.getBadges());
  }

  clear(): void {
    this.store$.dispatch(BadgesActions.clear());
  }
}
