<vim-dialog-template [title]="'teams_delete' | translate" (closeDialog)="dialogRef.close()">
  <div fxLayout="column" fxFlex="1 1 auto" fxLayoutGap="24px">
    <p>
      {{'teams_delete_will_remove_all_members' | translate}}
    </p>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{'teams_team_name' | translate}}</mat-label>
      <input matInput id='teamName' [formControl]='teamName'
             placeholder="{{'teams_create_input_name_hint' | translate}}"/>
    </mat-form-field>
    <div fxLayout='row' fxLayoutGap='0' fxLayoutGap.lt-sm='24px' fxLayoutAlign='space-between'
         fxLayout.lt-sm='column'>
      <button mat-button color="secondary" (click)='dialogRef.close()'
              vim-button>{{'common_cancel' | translate}}</button>
      <button mat-button color="primary" [disabled]='teamName.invalid' vim-button (click)='deleteTeam()'
              id='deleteTeam'>{{'teams_delete' | translate}}</button>
    </div>
  </div>
</vim-dialog-template>
