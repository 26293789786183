import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TeamMember } from 'src/app/client/teams/teams.model';
import { CampaignUnit } from '../../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-member-row',
  templateUrl: './member-row.component.html',
  styleUrls: ['./member-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberRowComponent {
  @Input() isAdmin: boolean;
  @Input() member!: TeamMember;
  @Input() campaignUnit: CampaignUnit;

  constructor() {}

  get fullName(): string {
    return `${this.member?.name?.firstName} ${this.member?.name?.familyName}`;
  }
}
