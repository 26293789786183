import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { WorkspacesStore } from '../../../../../../store/workspaces/workspaces.store';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignsStore } from '../../../../../../store/campaigns/campaigns.store';
import { TeamPrivacy } from '../../../../../../client/teams/teams.model';

@Component({
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTeamComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  public email = new UntypedFormControl('', Validators.email);
  public form: UntypedFormGroup = new UntypedFormGroup({
    teamName: new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    category: new UntypedFormControl(null, [Validators.required]),
    privacy: new UntypedFormControl('open', [Validators.required])
  });

  teamPrivacy = TeamPrivacy;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: CreateTeamDialogData,
    public dialogRef: MatDialogRef<CreateTeamComponent>,
    public applicationStore: WorkspacesStore,
    public teamsStore: TeamsStore,
    public campaignsStore: CampaignsStore
  ) {}

  setTeamPrivacy(privacy: TeamPrivacy): void {
    this.form.controls.privacy.patchValue(privacy);
  }

  createTeam() {
    this.teamsStore.createTeam({
      name: this.form.controls.teamName.value,
      inviteEmails: this.form.controls.inviteEmails.value,
      privacy: this.form.controls.privacy.value
    });
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.applicationStore.categoriesEnabled$.subscribe((categoriesEnabled) => {
        const validators = categoriesEnabled ? [Validators.required] : [];
        this.form.controls['category'].setValidators(validators);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export interface CreateTeamDialogData {
  campaignTitle: string;
}
