import { Component, ChangeDetectionStrategy } from '@angular/core';
import { WorkspacesStore } from 'src/app/store/workspaces/workspaces.store';

@Component({
  selector: 'vim-social-wall',
  templateUrl: './social-wall.component.html',
  styleUrls: ['./social-wall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialWallComponent {
  constructor(public applicationStore: WorkspacesStore) {}
}
