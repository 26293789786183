import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostBinding
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageService } from '../../../../store/message.service';

@Component({
  selector: 'vim-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
  @Input() enabled: boolean;
  @Input() markAsInvalid = false;
  @Input() showHeader = true;
  @Input() imageRequirementsDescription = 'upload_image_chooser_tips_desc';
  @Output() addImage = new EventEmitter<File>();
  @Output() deleteImage = new EventEmitter<File>();

  @HostBinding('class') get hostClass() {
    return this.enabled ? 'enabled' : 'disabled';
  }

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public deviceDetector: DeviceDetectorService,
    private messageService: MessageService
  ) {}

  get isMobile(): boolean {
    return this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
  }

  public readonly fileUploadControl = new FileUploadControl(
    {
      listVisible: false,
      accept: ['image/png', 'image/jpg', 'image/jpeg'],
      discardInvalid: true,
      multiple: false
    },
    [FileUploadValidators.accept(['image/png', 'image/jpg', 'image/jpeg']), FileUploadValidators.fileSize(20000000)]
  );

  public initImageUploader(): void {
    this.fileUploadControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((v) => v.length > 0),
        map((images) => images[0])
      )
      .subscribe((newImage) => this.addImage.next(newImage));

    this.fileUploadControl.discardedValueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((error) => !!error)
      )
      .subscribe(() => this.messageService.showErrorSnackbar('upload_image_chooser_tips_desc'));
  }

  ngOnInit(): void {
    this.initImageUploader();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
