import { Action, createReducer, on } from '@ngrx/store';
import { LiveTickerState } from './live-ticker.state';
import { LiveTickerActions } from './live-ticker.actions';
import { CampaignsActions } from '../campaigns/campaigns.actions';

export namespace LiveTickerReducer {
  export const initialState: LiveTickerState = {
    campaignLiveTicker: null,
    communityLiveTicker: null,
    loading: false,
    isLoaded: false
  };

  export const statsReducer = createReducer(
    initialState,
    on(LiveTickerActions.getCampaignLiveTickerData, (state) => ({
      ...state,
      loading: true,
      isLoaded: false
    })),
    on(LiveTickerActions.getCampaignLiveTickerDataSuccess, (state, data) => ({
      ...state,
      campaignLiveTicker: data,
      loading: false,
      isLoaded: true
    })),
    on(CampaignsActions.leaveCampaignSuccess, (state) => ({
      ...state,
      campaignLiveTicker: null,
      loading: false,
      isLoaded: true
    })),
    on(LiveTickerActions.getCampaignLiveTickerDataError, (state) => ({
      ...state,
      loading: false,
      isLoaded: false
    })),
    on(LiveTickerActions.getCommunityLiveTickerData, (state) => ({
      ...state,
      loading: true,
      isLoaded: false
    })),
    on(LiveTickerActions.getCommunityLiveTickerDataSuccess, (state, data) => ({
      ...state,
      communityLiveTicker: data,
      loading: false,
      isLoaded: true
    })),
    on(LiveTickerActions.getCommunityLiveTickerDataError, (state) => ({
      ...state,
      loading: false,
      isLoaded: false
    })),

    on(LiveTickerActions.clearLiveTickerData, () => ({
      ...initialState,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: LiveTickerState, action: Action): LiveTickerState {
    return statsReducer(state, action);
  }
}
