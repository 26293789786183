<div *ngIf="leaderboards && leaderboards?.data?.length > 0"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     [infiniteScrollDisabled]="isLoading || leaderboards.data.length === leaderboards.total"
     (scrolled)="getMoreLeaderBoardData.emit(leaderboards.data.length)">
  <mat-divider></mat-divider>
  <ng-container *ngFor="let leaderboard of leaderboards.data; index as i">
    <vim-leaderboard-overview (click)="inviteToTeam(i)" [leaderboardData]='leaderboard'
                              [campaignUnit]="campaignUnit"></vim-leaderboard-overview>
    <mat-divider></mat-divider>
  </ng-container>
</div>

<div *ngIf="!leaderboards || leaderboards?.data?.length === 0">
  <vim-my-teams-empty-state [createTeamEnabled]="createTeamEnabled"
                            [multipleTeamsEnabled]="multipleTeamsEnabled"
                            [userInActiveCampaign]="userInActiveCampaign"
                            (createTeamClicked)="createTeam.next()"></vim-my-teams-empty-state>
</div>

