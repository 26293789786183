<vim-indefinite-progress-bar *ngIf="teamsStore.isLoading$ | async"></vim-indefinite-progress-bar>
<div class='teams-container'>
  <mat-tab-group class="tab-group">
    <mat-tab #myTeamsTab label="{{'teams_my_teams_title' | translate}}">
      <vim-my-teams [isActive]="myTeamsTab.isActive" [teams]='teams$ | async'
                    (goToLeaderBoard)="goToLeaderBoard()" (createTeam)="openCreateTeamDialog()"></vim-my-teams>
    </mat-tab>
    <mat-tab #leaderboardTab label="{{'teams_leaderboard_title' |translate}}">
      <vim-leaderboard [isActive]="leaderboardTab.isActive" (createTeam)="openCreateTeamDialog()"></vim-leaderboard>
    </mat-tab>
  </mat-tab-group>
</div>
