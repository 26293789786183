import { createAction, props } from '@ngrx/store';
import { StravaStatus } from '../../client/strava/strava.model';

export namespace StravaActions {
  export const getStatus = createAction('[STRAVA] Get status');
  export const getStatusSuccess = createAction('[STRAVA] Get status success', props<{ status: StravaStatus }>());
  export const getStatusError = createAction('[STRAVA] Get status error');
  export const getLink = createAction('[STRAVA] Get link');
  export const getLinkSuccess = createAction('[STRAVA] Get link success', props<{ authorizationUrl: string }>());
  export const getLinkError = createAction('[STRAVA] Get link error');
  export const clear = createAction('[STRAVA] Clear');
}
