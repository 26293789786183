import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../shared/shared-ui/services/storage-service/storage.service';
import { WorkspacesStore } from '../../store/workspaces/workspaces.store';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private workspacesService: WorkspacesStore
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const invitation = this.storageService.getInvitationParams();
    if (invitation) {
      if (!invitation.workspaceId) {
        return this.selectWorkspace();
      }
      this.workspacesService.getWorkspaces();
      return this.workspacesService.isLoaded$.pipe(
        filter((loaded) => loaded === true),
        map(() => {
          this.workspacesService.selectWorkspace(invitation.workspaceId!);
          return true;
        })
      );
    }
    return this.selectWorkspace();
  }

  private selectWorkspace(): UrlTree | boolean {
    if (!this.storageService.getWorkspaceId()) {
      return this.router.parseUrl('/workspaces');
    }
    return true;
  }
}
