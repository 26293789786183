import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import packageJson from '../../../../../package.json';
import { WhatsNewDialogComponent } from '../whats-new-dialog/whats-new-dialog.component';

const APP_VERSION_KEY = 'appVersion';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constructor(private dialog: MatDialog) {}

  checkAppVersion(): void {
    if (AppVersionService.newAppVersion) {
      this.dialog.open(WhatsNewDialogComponent, {
        hasBackdrop: true,
        panelClass: 'whats-new-dialog-container',
        maxHeight: '99vh',
        maxWidth: '99vw'
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      localStorage.setItem(APP_VERSION_KEY, packageJson.version);
    }
  }

  static get newAppVersion(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const currentAppVersion = packageJson.version;
    const savedAppVersion = localStorage.getItem(APP_VERSION_KEY);
    return currentAppVersion !== savedAppVersion;
  }
}
