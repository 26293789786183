import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessForbiddenComponent } from './access-forbidden/access-forbidden.component';
import { HomeComponent } from './home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HelpWrapperComponent } from './help/help-wrapper.component';
import { ProfileGuard } from '../../client/guards/profile.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [ProfileGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'teams',
        loadChildren: () => import('./teams/teams.module').then((m) => m.TeamsModule)
      },
      {
        path: 'activities',
        loadChildren: () => import('./activities/activities.module').then((m) => m.ActivitiesModule)
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./campaigns/campaigns.module').then((m) => m.CampaignsModule)
      },
      {
        path: 'achievements',
        loadChildren: () => import('./badges/badges.module').then((m) => m.BadgesModule)
      },
      {
        path: 'manual-upload',
        loadChildren: () => import('./manual-upload/manual-upload.module').then((m) => m.ManualUploadModule)
      },
      {
        path: 'about-us',
        loadChildren: () => import('./about-us/about-us.module').then((m) => m.AboutUsModule)
      },
      {
        path: 'help',
        component: HelpWrapperComponent
      },
      {
        path: 'profile',
        loadChildren: () => import('./edit-profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'access-forbidden',
        component: AccessForbiddenComponent
      },
      {
        path: 'not-found',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
