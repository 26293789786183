import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { MessageService } from '../../../../../../store/message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vim-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteMembersComponent {
  public email = new UntypedFormControl('', Validators.email);
  public form: UntypedFormGroup;

  get emailsControls(): UntypedFormArray {
    return this.form.controls.memberEmails as UntypedFormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<InviteMembersComponent>,
    private teamsStore: TeamsStore,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: InviteMembersDialogData
  ) {
    this.form = new UntypedFormGroup({});
  }

  inviteMembers(): void {
    this.teamsStore.inviteToTeam(this.data?.teamId, this.emailsControls.value as string[]);
    this.dialogRef.close();
  }

  copyLink(): void {
    void navigator.clipboard.writeText(this.data.invitationLink).then(() => {
      this.messageService.showSuccessSnackbar('dashboard_share_modal_copied');
    });
  }
}

export interface InviteMembersDialogData {
  teamId: string;
  invitationLink: string;
  reinvitedMemberEmail?: string;
}
