import { createAction, props } from '@ngrx/store';
import { UpdateMeModel, UsersMeResponse } from '../../client/users/users.model';

export namespace UsersActions {
  export const getMe = createAction('[USERS] Get me');
  export const getMeSuccess = createAction('[USERS] Get me success', props<{ me: UsersMeResponse }>());
  export const getMeError = createAction('[USERS] Get me error');
  export const updateMe = createAction('[USERS] Update me', props<{ me: UpdateMeModel }>());
  export const updateMeSuccess = createAction('[USERS] Update me success', props<{ me: UpdateMeModel }>());
  export const updateMeError = createAction('[USERS] Update me error');
  export const clear = createAction('[USERS] Clear users data');
}
