<ng-container *ngIf="vm$ |async as vm">
  <vim-indefinite-progress-bar *ngIf="vm.isLoading else content"></vim-indefinite-progress-bar>
  <ng-template #content>
    <div
      fxFlex="100"
      *ngIf="vm.currentTeam as team"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="vm.isLoading"
      (scrolled)="onScroll(vm.currentTeamActiveMembers, vm.currentTeam)"
    >
      <div class="team-container" fxLayout="column" fxLayoutGap="16px">
        <div fxFlex.gt-sm="380px" fxLayout.lt-md="column" fxLayout.gt-sm="row">
          <div fxFlex.gt-sm="50%" fxLayout="column">
            <vim-team-name *ngIf=" vm.currentUserId as currentUserId"
                           fxFlex.gt-sm="130px"
                           fxFlex.lt-md="80px"
                           [isAdmin]="vm.currentTeam.admin._id === currentUserId"
                           [team]="team"
                           [userId]="currentUserId"
                           [teamMembers]="vm.currentTeamActiveMembers"
            ></vim-team-name>
            <vim-team-summary fxFlex.lt-md="168px" fxFlex.gt-sm="250px"
                              [campaignUnit]="vm.campaignUnit" [team]="team"></vim-team-summary>
          </div>
          <div fxLayout="row" fxFlex="100%" fxFlex.gt-sm="50%">
            <div fxFlex="50%">
              <vim-total-distance-summary [campaignUnit]="vm.campaignUnit"
                                          [team]="team"></vim-total-distance-summary>
            </div>
            <div fxFlex="50%">
              <vim-total-time-summary [team]="team"
                                      [campaignUnit]="vm.campaignUnit"></vim-total-time-summary>
            </div>
          </div>
        </div>
        <vim-team-members
          [adminName]="team.admin.name"
          [teamId]="team._id"
          [teamMembers]="vm.currentTeamActiveMembers"
          [currentUserId]="vm.currentUserId"
          [showInviteButton]="userInTeam(vm.currentTeamActiveMembers, vm.currentUserId)"
          [campaignUnit]="team.unit"
          [invitationLink]="team.invitationLink"
        ></vim-team-members>
        <br/>
        <ng-container *ngIf="vm.canJoinTeam && (hideButtons$ | async) === false">
          <div class="invite-button-wrapper" fxLayout="row"
               fxLayoutAlign="center end"
               fxLayoutGap="24px">
            <vim-button class="primary" (click)="acceptInvitation(vm.currentUserId, vm.currentTeam)"
                        id="acceptInvitation"
            >{{ 'common_join_team' | translate }}
            </vim-button>
            <vim-button *ngIf="isItInvite" class="secondary" (click)="declineInvitation()" id="declineInvitation">{{
              'invitationScreen_decline_invitation_button' | translate
              }}</vim-button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
