import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metersToKilometers'
})
export class MetersToKilometersPipe implements PipeTransform {
  transform(meters: number | null | undefined): number {
    const isNull = meters === undefined || meters === null;
    const isNumberCorrect = typeof meters === 'number' && !isNaN(meters) && meters >= 0;

    if (isNull || !isNumberCorrect) {
      return 0;
    }

    return Math.floor(meters / 1000);
  }
}
