import { createAction, props } from '@ngrx/store';
import { GarminStatus } from '../../client/garmin/garmin.model';

export namespace GarminActions {
  export const getStatus = createAction('[GARMIN] Get status');
  export const getStatusSuccess = createAction('[GARMIN] Get status success', props<{ status: GarminStatus }>());
  export const getStatusError = createAction('[GARMIN] Get status error');
  export const getLink = createAction('[GARMIN] Get link');
  export const getLinkSuccess = createAction('[GARMIN] Get link success', props<{ authorizationUrl: string }>());
  export const getLinkError = createAction('[GARMIN] Get link error');
  export const clear = createAction('[GARMIN] Clear');
}
