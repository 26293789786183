import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHours'
})
export class SecondsToHoursPipe implements PipeTransform {
  transform(seconds: number | null | undefined): string {
    const isNull = seconds === undefined || seconds === null;
    const isNumberCorrect = typeof seconds === 'number' && !isNaN(seconds) && seconds >= 0;

    if (isNull || !isNumberCorrect) {
      return '0';
    }

    return Math.floor(seconds / 3600).toLocaleString(navigator.language);
  }
}
