import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CampaignsReducer } from './campaigns.reducer';
import { CampaignsStore } from './campaigns.store';
import { CampaignsEffects } from './campaigns.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('campaigns', CampaignsReducer.reducer),
    EffectsModule.forFeature([CampaignsEffects])
  ],
  providers: [CampaignsStore]
})
export class CampaignsStoreModule {}
