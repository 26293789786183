export const icons = [
  'activities',
  'add-user',
  'arrow',
  'bee',
  'bell',
  'calendar',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clock',
  'close',
  'cloud',
  'cross-circle',
  'delete',
  'delete-white',
  'edit',
  'edit-white',
  'edit-grey',
  'green-edit',
  'euro',
  'error',
  'eye',
  'events',
  'faq',
  'home',
  'info',
  'insights',
  'invitations',
  'invite',
  'invite-grey',
  'link-garmin',
  'link-garmin-grey',
  'lock',
  'logout',
  'logout-white',
  'logout-grey',
  'menu-mobile',
  'money',
  'more-horizontal',
  'more-vertical',
  'path',
  'pencil',
  'place',
  'plus',
  'points',
  'policy',
  'profile',
  'remove-user',
  'settings',
  'smartwatch',
  'teams',
  'tree',
  'unlock',
  'upload',
  'zoom-in',
  'zoom-in2',
  //activities
  'american-football',
  'badminton',
  'baseball',
  'baseball-player',
  'basketball',
  'basketball2',
  'bench-press',
  'climbing',
  'climbing-white',
  'cricket',
  'cross-country-skiing',
  'cross-country-skiing-white',
  'curls-with-dumbbells',
  'cycling',
  'cycling-mountain-bike',
  'cycling-white',
  'cyclist',
  'elliptical',
  'elliptical-white',
  'frisbee',
  'golf',
  'handball',
  'horseback-riding',
  'horseback-riding-white',
  'indoor-cycling-white',
  'pilates',
  'plank',
  'plank-white',
  'roller-skating',
  'row-boat',
  'row-boat-white',
  'rowing-machine',
  'rowing-machine-white',
  'running',
  'tennis-ball-500',
  'share',
  'skiing',
  'skipping-rope',
  'skipping-rope-white',
  'snowboarding',
  'soccer',
  'soccer-ball',
  'sports',
  'sports-white',
  'treadmill',
  'treadmill-white',
  'sup',
  'sup-white',
  'swimming',
  'swimming-white',
  'table-tennis',
  'tennis',
  'tennis-white',
  'trekking',
  'trekking-white',
  'voluntary',
  'volleyball-player',
  'walking',
  'walking-white',
  'water-sport',
  'yoga',
  'yoga-white',
  'facebook',
  'twitter',
  'linkedin'
];
