import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../services/converter.service';

@Pipe({
  name: 'plural',
  pure: false
})
export class PluralPipe implements PipeTransform {
  transform(key: string, value: number): string {
    return ConverterService.addPluralization(key, value);
  }
}
