import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { GarminLink, GarminStatus } from './garmin.model';

@Injectable({
  providedIn: 'root'
})
export class GarminService {
  constructor(private httpService: HttpService) {}

  getStatus(): Observable<GarminStatus> {
    return this.httpService.get('/garmin/status');
  }

  getLink(): Observable<GarminLink> {
    return this.httpService.get('/garmin/link', { redirectUrl: `${window.location.origin}/home/dashboard` });
  }
}
