import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TeamsService } from '../../teams.service';
import { TeamsStore } from '../../../../../store/teams/teams.store';
import { TeamMember } from '../../../../../client/teams/teams.model';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMembersComponent {
  @Input() teamId: string;
  @Input() invitationLink: string;
  @Input() adminName: { firstName: string; familyName: string };
  @Input() teamMembers: TeamMember[];
  @Input() currentUserId: string;
  @Input() showInviteButton: boolean;
  @Input() campaignUnit: CampaignUnit;

  constructor(
    public teamsStore: TeamsStore,
    private teamsService: TeamsService
  ) {}

  getTeamMember(id: string): TeamMember | undefined {
    return this.teamMembers.find((member) => member._id === id);
  }

  openInvitationDialog(): void {
    this.teamsService.openInvitationDialog(this.teamId, this.invitationLink);
  }
}
