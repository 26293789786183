import { createSelector, MemoizedSelector } from '@ngrx/store';
import { WorkspacesSelectors } from '../../../../store/workspaces/workspaces.selectors';
import { TeamsSelectors } from '../../../../store/teams/teams.selectors';
import { AppState } from '../../../../store/state';
import { CampaignStatus, CampaignUnit } from '../../../../client/campaigns/campaignsData';
import { CampaignsSelectors } from '../../../../store/campaigns/campaigns.selectors';

export namespace MyTeamsSelectors {
  export const myTeamsViewModel: MemoizedSelector<AppState, MyTeamsViewModel> = createSelector(
    CampaignsSelectors.selectCreateTeamEnabled,
    CampaignsSelectors.selectMultipleTeamsEnabled,
    TeamsSelectors.selectMyTeams,
    CampaignsSelectors.selectCurrentCampaignUnit,
    CampaignsSelectors.selectUserCampaign,
    WorkspacesSelectors.isLoading,
    TeamsSelectors.selectIsLoading,
    (
      createTeamEnabled,
      multipleTeamsEnabled,
      myTeams,
      campaignUnit,
      userCampaign,
      workspacesIsLoading,
      teamsIsLoading
    ) => ({
      createTeamEnabled: createTeamEnabled && (multipleTeamsEnabled || myTeams?.data.length === 0),
      multipleTeamsEnabled: multipleTeamsEnabled,
      campaignUnit: campaignUnit || CampaignUnit.POINTS,
      userInActiveCampaign: !!userCampaign && userCampaign.status !== CampaignStatus.past,
      assignedToTeam: !!myTeams?.data.length,
      isLoading: workspacesIsLoading || teamsIsLoading
    })
  );
}

export interface MyTeamsViewModel {
  createTeamEnabled: boolean | undefined;
  multipleTeamsEnabled: boolean | undefined;
  userInActiveCampaign: boolean;
  assignedToTeam: boolean;
  isLoading: boolean;
  campaignUnit: CampaignUnit;
}
