import { Injectable } from '@angular/core';
import {
  InviteMembersComponent,
  InviteMembersDialogData
} from './team/dialogs/invite-members/invite-members.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  constructor(
    private dialogService: MatDialog,
    private translateService: TranslateService
  ) {}

  openInvitationDialog(teamId: string, invitationLink: string): void {
    this.dialogService.open(InviteMembersComponent, {
      headerSnippet: this.translateService.instant('teams_create_invite_member_header'),
      data: {
        teamId,
        invitationLink
      } as InviteMembersDialogData
    } as MatDialogConfig);
  }
}
