export enum LanguageEnum {
  english = 'en',
  german = 'de',
  french = 'fr',
  italy = 'it',
  polish = 'pl',
  spanish = 'es-ES'
}

export interface Language {
  shortname: string;
  name: string;
}

export const SupportedLanguages: Language[] = [
  { shortname: 'en-GB', name: 'English' },
  { shortname: 'de-DE', name: 'Deutsch / German' },
  { shortname: 'fr-FR', name: 'Français / French' },
  { shortname: 'it-IT', name: 'Italiano / Italian' },
  { shortname: 'es-ES', name: 'Español  / Spanish' },
  { shortname: 'pl-PL', name: 'Polski / Polish' }
];
