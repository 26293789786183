import { createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { StravaState } from './strava.state';

export namespace StravaSelectors {
  const state = (state: AppState) => state.strava;

  export const selectState = createSelector(state, (state: StravaState) => state);

  export const selectStatus = createSelector(state, (state: StravaState) => state?.status);

  export const isStravaConnected = createSelector(state, (state: StravaState) => state?.status?.integrationActive);

  export const isLoaded = createSelector(state, (state: StravaState) => state?.loaded);

  export const loadIndicators = createSelector(state, (state: StravaState) => ({
    loaded: state?.loaded,
    loading: state?.loading
  }));
}
