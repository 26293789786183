<div
  class='total-distance-container'
  fxLayoutGap='16px'
  fxLayout='column'
  ngClass.gt-sm='total-distance-container-desktop'
  ngClass.lt-md='total-distance-container-mobile'
>
  <p ngClass.gt-sm='LabelLabel-4'
     ngClass.lt-md='LabelLabel-5'>{{'common_total_distance' | translate | uppercase}}</p>
  <div fxLayout.lt-md='column' ngClass.gt-sm='DisplayBoldHeading-2'
       ngClass.lt-md='DisplayBoldHeading-3'>
    <div *ngIf="team.distance | singleDistanceUnit: 1 as distance">
      <span>{{distance.value}}&nbsp;</span>
      <span ngClass.lt-md='HeadingHeading-6'>{{distance.unit | translate}}</span>
    </div>
  </div>
  <div fxHide.lt-md='true' class='summary-icons' fxLayout='row' fxLayoutGap='8px'>
  </div>
  <div fxFlex='auto'></div>
  <div fxFlex.gt-sm='34px' fxLayoutAlign='flex-end flex-end' class="summary-trees" ngClass.gt-sm='HeadingHeading-5'
       ngClass.lt-md='LabelLabel-4'>
    {{ team.distancePoints | roundDown}}
    {{campaignUnit | unitToKey| plural:team.distancePoints | translate}}
  </div>
</div>
