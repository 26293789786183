import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as EmailValidator from 'email-validator';

@Component({
  selector: 'vim-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailListComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName = 'email';

  emailsControl: UntypedFormArray;

  public separatorKeysCodes = [ENTER, COMMA, SPACE];

  constructor() {}

  ngOnInit() {
    this.emailsControl = new UntypedFormArray(
      [],
      [Validators.required, Validators.minLength(1), Validators.maxLength(15)]
    );
    this.formGroup.addControl(this.controlName, this.emailsControl);
    this.emailsControl.clearValidators();
  }

  remove(email: AbstractControl): void {
    const index = this.emailsControl.controls.indexOf(email);
    if (index >= 0) {
      this.emailsControl.removeAt(index);
    }
  }

  add(event: MatChipInputEvent): void {
    if (!event.value) {
      return;
    }
    const email = event.value;

    this.emailsControl.push(new UntypedFormControl(email, [EmailListComponent.validateEmail]));
    event.input.value = '';
  }

  private static validateEmail(emailControl: AbstractControl) {
    const passTest = EmailValidator.validate(emailControl.value as string);
    return passTest ? null : { validateEmail: { valid: false } };
  }
}
