import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageFlagService } from '../../../../shared/shared-ui/services/language-flag.service';
import { LanguageEnum } from '../../../../shared/shared-ui/models/language.model';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { GarminStore } from 'src/app/store/garmin/garmin.store';
import { WorkspacesStore } from 'src/app/store/workspaces/workspaces.store';
import { StorageService } from '../../../../shared/shared-ui/services/storage-service/storage.service';

@Component({
  selector: 'vim-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {
  private subscriber = new Subject();
  public currentLanguage$: Observable<LanguageEnum> = of(LanguageEnum.english);
  public isLanguageMenuOpened = false;
  public languageEnum = LanguageEnum;

  get licencesUrl(): string {
    return `${window.location.origin}/3rdpartylicenses.txt`;
  }

  constructor(
    public garminStore: GarminStore,
    public workspacesStore: WorkspacesStore,
    public translateService: TranslateService,
    public languageFlagService: LanguageFlagService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.currentLanguage$ = this.translateService.onLangChange.pipe(
      takeUntil(this.subscriber),
      map((languageEvent) => (languageEvent.lang as LanguageEnum) ?? LanguageEnum.english)
    );
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  selectLanguage(language: LanguageEnum): void {
    this.translateService.use(language);
    this.storageService.setCurrentLanguage(language);
  }

  menuOpened() {
    this.isLanguageMenuOpened = true;
  }

  menuClosed() {
    this.isLanguageMenuOpened = false;
  }

  ngOnDestroy(): void {
    this.subscriber.next();
    this.subscriber.complete();
  }
}
