import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, StoreModule, UPDATE } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { WorkspacesReducer } from './workspaces.reducer';
import { WorkspacesStore } from './workspaces.store';
import { WorkspacesEffects } from './workspaces.effects';
import { WorkspacesState } from './workspaces-state';

export function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: WorkspacesState, action) {
    if (action.type === UPDATE) {
      const selectedWorkspaceId = localStorage.getItem('workspaceId');
      if (selectedWorkspaceId) {
        const nextState = { ...state, selectedWorkspaceId };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return reducer(nextState, action);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return reducer(state, action);
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('workspaces', WorkspacesReducer.reducer, { metaReducers: [metaReducer] }),
    EffectsModule.forFeature([WorkspacesEffects])
  ],
  providers: [WorkspacesStore, WorkspacesEffects]
})
export class WorkspacesStoreModule {}
