import { CampaignUnit } from '../campaigns/campaignsData';

export interface ActivitiesParams {
  campaign?: 'active' | 'past';
  limit?: number;
  skip?: number;
}

export interface ActivitiesData {
  data: ActivitiesItem[];
  total: number;
}

export interface ActivitiesItem {
  _id: string;
  date: string;
  distance?: string;
  time?: number;
  type: string;
  trees: number;
  name: string;
  image?: string;
  points: number;
  unit: CampaignUnit;
  source?: ActivitySource;
  externalId: string;
}

export interface ActivityDetailsResponse {
  _id: string;
  campaignId: string;
  imageAvailable: boolean;
  type: ActivityType;
  kind: ActivityKind;
  name: string;
  date: string;
  shareImage: ShareImage[];
  points: number;
  unit: CampaignUnit;
  time: number;
  distance: number;
  externalId: string;
  source: ActivitySource;
  image: string;
  avgSpeed: number;
  maxSpeed: number;
  avgCadence: number;
  avgHeartRate: number;
  elevationGain: number;
  elevationLoss: number;
  calories: number;
  canBeEdited: boolean;
}

export interface ShareImage {
  image: string;
  textColor: string;
}

export interface ActivityItem {
  kind: ActivityKind;
  type: ActivityType;
  distancePerPoint?: number;
  timePerPoint?: number;
  image?: string;
  name: string;
  occurences?: number;
  requiredFields: RequiredFields[];
}

export enum ActivitySource {
  STRAVA = 'STRAVA',
  GARMIN = 'GARMIN',
  USER_INPUT = 'USER_INPUT'
}

export enum RequiredFields {
  time = 'time',
  distance = 'distance',
  pace = 'pace'
}

export enum ActivityKind {
  DISTANCE = 'distance',
  TIME = 'time'
}

export enum ActivityType {
  RUNNING = 'RUNNING',
  CYCLING = 'CYCLING',
  WALKING = 'WALKING',
  CARDIO = 'CARDIO',
  STRENGTH = 'STRENGTH',
  YOGA = 'YOGA',
  SKATING = 'SKATING',
  TREADMILL_RUNNING = 'TREADMILL_RUNNING',
  INDOOR_RUNNING = 'INDOOR_RUNNING',
  INDOOR_WALKING = 'INDOOR_WALKING',
  XC_CLASSIC_SKIING = 'XC_CLASSIC_SKIING',
  ROWING = 'ROWING',
  HIKING = 'HIKING',
  SWIMMING = 'SWIMMING',
  SNOWBOARDING = 'SNOWBOARDING',
  SKIING = 'SKIING',
  GOLFING = 'GOLFING',
  SUP = 'SUP',
  PILATES = 'PILATES',
  BREATHWORK = 'BREATHWORK',
  TENNIS = 'TENNIS',
  MULTI_SPORT = 'MULTI_SPORT',
  MOUNTAINEERING = 'MOUNTAINEERING',
  HORSEBACK_RIDING = 'HORSEBACK_RIDING',
  AMERICAN_FOOTBALL = 'AMERICAN_FOOTBALL',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  CRICKET = 'CRICKET',
  FRISBEE = 'FRISBEE',
  LACROSSE = 'LACROSSE',
  ROPE_SKIPPING = 'ROPE_SKIPPING',
  RUGBY = 'RUGBY',
  SOCCER = 'SOCCER',
  SOFTBALL = 'SOFTBALL',
  VOLLEYBALL = 'VOLLEYBALL',
  INDOOR_CYCLING = 'INDOOR_CYCLING',
  STEPPER = 'STEPPER',
  INDOOR_ROWING = 'INDOOR_ROWING'
}

export interface ActivityTypes {
  top: ActivityItem[];
  other: ActivityItem[];
  all: ActivityItem[];
}

export interface ActivityToUpload<T> extends Record<string, any> {
  type: ActivityType;
  time?: number;
  distance?: number;
  date: string;
  avgSpeed?: number;
  file?: T;
}

export interface ActivityPatchPayload<T> {
  time?: number;
  distance?: number;
  file?: T | null;
}

export enum UploadStatus {
  None = 'None',
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Failed = 'Failed'
}

export interface ConversionRate {
  value: number;
  activities: ActivityItem[];
}

export interface ConversionRates {
  time: ConversionRate[];
  distance: ConversionRate[];
}
