<div class='container' [formGroup]="formGroup" [ngClass]='{"has-errors" : emailsControl.invalid}'>
  <mat-chip-grid #chipList [formArrayName]='controlName'>
    <mat-chip-row [color]="(!control.valid)?'warn':''" selected
                  *ngFor='let control of  emailsControl.controls; let i=index'
                  [formGroupName]="i"
                  [removable]='true'
                  (removed)='remove(control)'
                  required name='chips'>
      {{control.value}}
      <mat-icon matChipRemove>close</mat-icon>
    </mat-chip-row>
    <input
      placeholder="{{emailsInput.focused ? 'example@email.com': 'teams_create_invite_member_input_hint' | translate}}"
      #emailsInput="matInput"
      class="email-input"
      matInput
      [matChipInputFor]='chipList'
      [matChipInputSeparatorKeyCodes]='separatorKeysCodes '
      [matChipInputAddOnBlur]='true '
      (matChipInputTokenEnd)='add($event) ' id='email-list'/>
  </mat-chip-grid>
</div>
<mat-error class='error'
           *ngIf='emailsControl.dirty && emailsControl.invalid'>{{'teams_create_invite_member_input_wrong_email_error' | translate}}</mat-error>

