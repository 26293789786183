import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { EditTeamMember } from '../edit-team.component';
import { TeamMember } from 'src/app/client/teams/teams.model';
import { TeamsStore } from 'src/app/store/teams/teams.store';

@Component({
  selector: 'vim-edit-team-members',
  templateUrl: './edit-team-members.component.html',
  styleUrls: ['./edit-team-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTeamMembersComponent {
  @Input() members: TeamMember[] = [];
  @Input() removedMembers: TeamMember[] = [];
  @Input() teamId: string;
  @Output() membersChanged: EventEmitter<EditTeamMember[]> = new EventEmitter<EditTeamMember[]>();
  // public teamMembers: EditTeamMember[] = [];
  // public removedTeamMembers: EditTeamMember[] = [];

  constructor(public teamsStore: TeamsStore) {}

  // ngOnInit(): void {
  //   // this.initTeamMembers(this.members);
  // }
  //
  // ngOnChanges(changes: SimpleChanges): void {
  //   const currentValue = changes.members.currentValue;
  //   if (currentValue) {
  //     // this.initTeamMembers(currentValue);
  //   }
  // }

  // private initTeamMembers(members: TeamMember[]): void {
  //   const toEditTeamMember = (member: TeamMember) =>
  //     ({
  //       memberId: member._id,
  //       removeOrAddConfirmationVisible: false,
  //       userFullName: `${member.name.firstName} ${member.name.familyName}`,
  //       userInitials: `${member.name.firstName[0]}${member.name.familyName[0]}`,
  //       email: ''
  //     } as EditTeamMember);
  //
  //   this.teamMembers = members.filter((member) => member.active).conditionsControlsMap(toEditTeamMember);
  //   this.removedTeamMembers = members.filter((member) => !member.active).conditionsControlsMap(toEditTeamMember);
  // }

  public markMemberAsRemoved(memberId: string): void {
    this.teamsStore.removeTeamMember({ teamId: this.teamId, userId: memberId }, { active: false });
  }

  public reInviteMember(memberId: string): void {
    this.teamsStore.reInviteTeamMember({ teamId: this.teamId, userId: memberId }, { active: true });
  }

  handleMembersChange(): void {
    this.membersChanged.emit();
  }
}
