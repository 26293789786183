<div *ngIf="private else openTeamInfo" fxLayoutAlign='center center' fxLayout=' row' fxLayoutGap='5px'>
  <mat-icon svgIcon='lock'></mat-icon>
  <p class="LabelLabel-2">{{ 'team_privacy_private' | translate }}</p>
</div>
<ng-template #openTeamInfo>
  <div fxLayoutAlign='center center' fxLayout='row' fxLayoutGap='5px'>
    <mat-icon svgIcon='unlock'></mat-icon>
    <div class="LabelLabel-2">{{ 'team_privacy_public' | translate }}</div>
  </div>
</ng-template>
