import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ActivitiesReducer } from './activities.reducer';
import { ActivitiesStore } from './activities.store';
import { ActivitiesEffects } from './activities.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('activities', ActivitiesReducer.reducer),
    EffectsModule.forFeature([ActivitiesEffects])
  ],
  providers: [ActivitiesStore]
})
export class ActivitiesStoreModule {}
