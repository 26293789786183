import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { LiveTickerCampaignData, LiveTickerCommunityData } from './live-ticker.model';

@Injectable({
  providedIn: 'root'
})
export class LiveTickerService {
  constructor(private httpService: HttpService) {}

  getLiveTickerCommunityData(workspaceId: string): Observable<LiveTickerCommunityData> {
    return this.httpService.get(`/workspaces/${workspaceId}/live-ticker/community`);
  }

  getLiveTickerCampaignData(campaignId: string, workspaceId: string): Observable<LiveTickerCampaignData> {
    return this.httpService.get(`/workspaces/${workspaceId}/live-ticker/${campaignId}`);
  }
}
