import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VimIconRegistry } from './shared/shared-ui/services/icon-registry.service';
import { WorkspacesStore } from './store/workspaces/workspaces.store';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { StorageService } from './shared/shared-ui/services/storage-service/storage.service';
import { filter } from 'rxjs/operators';
import { LegalDocumentsCheckerService } from './ui/home/legal/legal-documents-checker/legal-documents-checker.service';
import { MobileVersionService } from './ui/common/mobile-version.service';

@Component({
  selector: 'vim-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    public mobileVersionService: MobileVersionService,
    private translate: TranslateService,
    private iconsRegistryService: VimIconRegistry,
    public applicationStore: WorkspacesStore,
    private eventService: PublicEventsService,
    private storageService: StorageService,
    private oidc: OidcSecurityService,
    private legalDocumentsCheckerService: LegalDocumentsCheckerService
  ) {
    this.initLanguage();
    this.iconsRegistryService.registerIcons();
  }

  private authChecked = false;

  ngOnInit() {
    this.checkAuth();
    this.checkLegalDocs();
  }

  private checkLegalDocs() {
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe(() => this.legalDocumentsCheckerService.checkLegalDocs());
  }

  private checkAuth(): void {
    if (!this.authChecked) {
      this.oidc.checkAuth().subscribe((loginResponse) => {
        this.authChecked = true;
        if (!loginResponse.isAuthenticated) {
          return;
        }
      });
    }
  }

  private initLanguage(): void {
    this.translate.setDefaultLang('en');

    const currentLanguage = this.storageService.getCurrentLanguage() ?? 'en';
    this.translate.use(currentLanguage);
  }
}
