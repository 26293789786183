import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NotificationsStore } from './notifications-store.service';
import { NotificationsReducer } from './notifications.reducer';
import { NotificationsEffects } from './notifications.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('notifications', NotificationsReducer.reducer),
    EffectsModule.forFeature([NotificationsEffects])
  ],
  providers: [NotificationsStore]
})
export class NotificationsStoreModule {}
