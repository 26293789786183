import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CampaignsSelectors } from '../../../store/campaigns/campaigns.selectors';
import { CampaignData, CampaignDetails } from '../../../client/campaigns/campaignsData';
import { AppState } from '../../../store/state';

export namespace CampaignBannerSelectors {
  export const campaignBannerViewModel: MemoizedSelector<AppState, CampaignBannerViewModel> = createSelector(
    CampaignsSelectors.selectUserCampaignIsLoaded,
    CampaignsSelectors.selectAllCampaignsIsLoaded,
    CampaignsSelectors.selectUserCampaign,
    CampaignsSelectors.selectActiveCampaigns,
    CampaignsSelectors.selectFutureCampaigns,
    (userCampaignIsLoaded, allCampaignsIsLoaded, userCampaign, activeCampaigns, futureCampaigns) => ({
      isLoaded: userCampaignIsLoaded && allCampaignsIsLoaded,
      userCampaign,
      activeCampaignAvailable: activeCampaigns?.length > 0,
      futureCampaignAvailable: futureCampaigns?.length > 0,
      firstFutureCampaign: futureCampaigns?.length > 0 ? futureCampaigns[0] : null
    })
  );
}

export interface CampaignBannerViewModel {
  isLoaded: boolean | null;
  userCampaign: CampaignDetails | null;
  activeCampaignAvailable: boolean;
  futureCampaignAvailable: boolean;
  firstFutureCampaign: CampaignData | null;
}
