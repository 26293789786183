export interface LegalState {
  updatedDocuments: Document[];
  loading: boolean;
  loaded: boolean;
}

export interface Document {
  title: string;
  revisionId: string;
  phraseappKey: DocumentPhraseappKey;
  locale: string;
  link: string;
  country: string;
}

export enum DocumentPhraseappKey {
  PrivacyPolicy = 'privacyPolicy',
  TermsOfUse = 'termsOfUse'
}
