import { CampaignStatus, CampaignType, CampaignUnit } from '../../campaigns/campaignsData';
import { TranslatedString } from './admin-panel.model';
import { assign, omit, omitBy } from 'lodash';
import { ActivityKind, ActivityType } from '../../activities/activities.model';
import { CampaignDetailsEditorModel } from '../../../store/admin-panel/models/campaign-details.model';

export interface CampaignDetailsImages<T extends File | string> {
  imageDashboardShare?: T;
  image?: T;
  imageSmall?: T;
  imageLarge?: T;
  companyLogo?: T;
  shareImages?: { image: T; color: string }[];
  activityShareImages?: { image: T; color: string }[];
}

export interface CampaignItemLight {
  id: string;
  title: string;
}

export interface CampaignItemLightResponse {
  _id: string;
  title: string;
}

export interface CampaignItem {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  maxPoints: 0;
  type: CampaignType;
  unit: CampaignUnit;
  status: CampaignStatus;
  draft?: boolean;
}

export interface CampaignDetailsBase {
  visibilityRestrictions: {
    type: RestrictionsType;
    countryCode: string[];
  };
  joinRestrictions: {
    type: RestrictionsType;
    countryCode: string[];
  };
  multipleTeamsEnabled: boolean;
  createTeamEnabled: boolean;
  manualUploadPointsLimit: number;
  startDate: string;
  endDate?: string;
  maxPoints?: number;
  type: CampaignType;
  title: string;
  subtitle: string;
  description: TranslatedString[];
  shortDescription: TranslatedString[];

  unit: CampaignUnit;

  colorPrimary: string;
  colorVariant: string;
  textColor: string;
  textColorVariant: string;
  dashboardColor?: string;
  dashboardColorVariant?: string;
  activitiesConfiguration: {
    activityTypes: ActivityTypeConfiguration[];
  };
  teamsMaxMembers: number;
  rankingType: RankingType;
  draft?: boolean;
}

export class CampaignDetailsPostPayload implements CampaignDetailsBase, CampaignDetailsImages<string> {
  key?: string;
  password?: string;
  hashTag?: string;
  activitiesConfiguration: { activityTypes: ActivityTypeConfiguration[] };
  activityShareImages: { image: string; color: string }[];
  colorPrimary: string;
  colorVariant: string;
  createTeamEnabled: boolean;
  description: TranslatedString[];
  endDate?: string;
  imageDashboardShare: string;
  joinRestrictions: { type: RestrictionsType; countryCode: string[] };
  manualUploadPointsLimit: number;
  maxPoints?: number;
  multipleTeamsEnabled: boolean;
  rankingType: RankingType;
  shareImages: { image: string; color: string }[];
  shortDescription: TranslatedString[];
  startDate: string;
  subtitle: string;
  teamsMaxMembers: number;
  textColor: string;
  textColorVariant: string;
  title: string;
  type: CampaignType;
  unit: CampaignUnit;
  visibilityRestrictions: { type: RestrictionsType; countryCode: string[] };

  static fromCampaignDetailsEditorModel(
    details: CampaignDetailsEditorModel,
    images: CampaignDetailsImages<string>
  ): CampaignDetailsPostPayload {
    return assign(
      new CampaignDetailsPostPayload(),
      omit(
        omitBy(details, (v) => v === null),
        [
          'languages',
          'image',
          'imageLarge',
          'imageSmall',
          'imageDashboardShare',
          'companyLogo',
          'shareImages',
          'activityShareImages'
        ]
      ),
      images
    );
  }
}

export class CampaignDetailsPatchPayload
  implements Partial<CampaignDetailsBase>, Partial<CampaignDetailsImages<string>>
{
  id: string;
  key?: string;
  password?: string;
  hashTag?: string;
  activitiesConfiguration?: { activityTypes: ActivityTypeConfiguration[] };
  activityShareImages?: { image: string; color: string }[];
  colorPrimary?: string;
  colorVariant?: string;
  createTeamEnabled?: boolean;
  description?: TranslatedString[];
  endDate?: string;
  imageDashboardShare?: string;
  joinRestrictions?: { type: RestrictionsType; countryCode: string[] };
  manualUploadPointsLimit?: number;
  maxPoints?: number;
  multipleTeamsEnabled?: boolean;
  rankingType?: RankingType;
  shareImages?: { image: string; color: string }[];
  shortDescription?: TranslatedString[];
  startDate?: string;
  subtitle?: string;
  teamsMaxMembers?: number;
  textColor?: string;
  textColorVariant?: string;
  title?: string;
  type?: CampaignType;
  unit?: CampaignUnit;
  visibilityRestrictions?: { type: RestrictionsType; countryCode: string[] };

  static fromCampaignDetailsEditorModel(
    details: Partial<CampaignDetailsEditorModel>,
    images: Partial<CampaignDetailsImages<string>>
  ): CampaignDetailsPatchPayload {
    const result = assign(
      new CampaignDetailsPatchPayload(),
      omit(details, [
        'languages',
        'image',
        'imageLarge',
        'imageSmall',
        'imageDashboardShare',
        'companyLogo',
        'shareImages',
        'activityShareImages'
      ]),
      images
    );
    return result;
  }
}

export interface CampaignDetailsResponse extends CampaignDetailsBase, CampaignDetailsImages<string> {
  id: string;
  password?: string;
  hashTag?: string;
  key?: string;
}

export enum RankingType {
  avg = 'avg',
  sum = 'sum'
}

export interface ActivityTypeConfiguration {
  type: ActivityType;
  kind: ActivityKind;
  distancePerPoint?: number;
  timePerPoint?: number;
}

export enum RestrictionsType {
  all = 'all',
  none = 'none',
  country = 'country'
}
