import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'vim-garmin-link-success-modal',
  templateUrl: './garmin-link-success-modal.component.html',
  styleUrls: ['./garmin-link-success-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GarminLinkSuccessModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LinkSuccessDialogData,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  get platformName(): string {
    return this.data?.platformName || '';
  }
}

export interface LinkSuccessDialogData {
  platformName: string;
}
