<div>
  <mat-icon fxHide.xs class='close-icon' [mat-dialog-close]>close</mat-icon>
  <div>
    <img alt='Fitness platform connection success image'
         src='./assets/images/garmin-link-success/garmin-link-success@3x.webp'
         src.lt-md="./assets/images/garmin-link-success/garmin-link-success@2x.webp"
         src.xs="./assets/images/garmin-link-success/garmin-link-success.webp">
  </div>
  <div class='bottom-container' fxLayout='column' fxLayoutAlign='space-between center'>
    <div fxLayout='column' fxLayoutAlign='start center' fxLayoutGap='16px'>
      <h2 class='DisplayRegularHeading-5'>{{'garmin_integration_congratulations' | translate}}</h2>
      <h3 class='ParagraphParagraph-1'>{{'device_integration_connected' | translate | sprintf: platformName}}</h3>
    </div>
    <vim-button [mat-dialog-close] class='quarternary' id='start'>{{'common_lets_start' | translate}}</vim-button>
  </div>
</div>
