<div>
  <div fxLayout fxLayoutAlign="space-between center">
    <span class="DisplayRegularHeading-6">{{data.title}}</span>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon class='close-icon'>close</mat-icon>
    </button>
  </div>
  <p class="ParagraphParagraph-2" [innerHtml]="data.message"></p>
  <div fxLayout fxLayoutAlign="space-between" *ngIf="!data.hideCancelButton; else singleButton">
    <vim-button class="secondary" [mat-dialog-close]
                (click)="dialogRef.close()">{{data.cancelButtonLabel || (data.cancelButtonLabelKey | translate)}}</vim-button>
    <vim-button class="primary"
                (click)="dialogRef.close(true)">{{data.okButtonLabel || (data.okButtonLabelKey | translate)}}</vim-button>
  </div>
  <ng-template #singleButton>
    <div fxLayout fxLayoutAlign="end">
      <vim-button class="primary"
                  (click)="dialogRef.close(true)"> {{data.okButtonLabel || (data.okButtonLabelKey | translate)}}</vim-button>
    </div>
  </ng-template>
</div>
