import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vim-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() progress: number; // 0..1
  @Input() color = '#d0d0d0';
  @Input() maxValue = 1;

  private get progressPercent(): number {
    return Math.min(100, Math.round((this.progress / this.maxValue) * 100));
  }

  get currentValue(): string {
    return `${this.progressPercent}%`;
  }

  get remainingValue(): string {
    return `${100 - this.progressPercent}%`;
  }
}
