<ng-container *ngIf="chartData; else nodata">
  <canvas baseChart class="chart"
          [data]="chartData"
          [type]="'line'"
          [options]="chartOptions">
  </canvas>
</ng-container>
<ng-template #nodata>
  Missing data for chart
</ng-template>
