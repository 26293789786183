import { ActivitiesState } from './activities.state';
import { Action, createReducer, on } from '@ngrx/store';
import { ActivitiesActions } from './activities.actions';
import {
  ActivitiesData,
  ActivitiesItem,
  ActivityKind,
  ShareImage,
  UploadStatus
} from '../../client/activities/activities.model';
import { groupBy } from 'lodash';

export namespace ActivitiesReducer {
  export const initialState: ActivitiesState = {
    activities: null,
    activityDetails: null,
    activityTypes: null,
    activitiesCurrentMarker: 0,
    conversionRates: { time: [], distance: [] },
    loading: false,
    loaded: false,
    manualUpload: {
      status: UploadStatus.None
    }
  };

  export const activitiesReducer = createReducer(
    initialState,
    on(ActivitiesActions.getActivities, (state) => ({
      ...state,
      activitiesCurrentMarker: 0,
      loading: true,
      loaded: false
    })),
    on(ActivitiesActions.getActivitiesSuccess, (state, { activities }) => ({
      ...state,
      activities: activities,
      activitiesCurrentMarker: activities?.data?.length,
      loading: false,
      loaded: true
    })),
    on(ActivitiesActions.getActivitiesError, (state) => ({
      ...state,
      activitiesCurrentMarker: 0,
      loading: false,
      loaded: false
    })),
    on(ActivitiesActions.getMoreActivities, (state) => ({
      ...state
    })),
    on(ActivitiesActions.getMoreActivitiesSuccess, (state, { activities }) => ({
      ...state,
      activities: {
        data: [...(state?.activities?.data as ActivitiesItem[]), ...activities.data],
        total: activities?.total
      },
      activitiesCurrentMarker: state?.activitiesCurrentMarker + activities?.data?.length
    })),
    on(ActivitiesActions.getMoreActivitiesError, (state) => ({
      ...state
    })),

    on(ActivitiesActions.getActivityDetails, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(ActivitiesActions.getActivityDetailsSuccess, (state, { activityDetails }) => {
      const shareImage: ShareImage[] = activityDetails.shareImage.length
        ? activityDetails.shareImage.map((shareImage) => ({ image: shareImage.image, textColor: shareImage.textColor }))
        : [
            {
              image: '/assets/images/social-media/grey-image.png',
              textColor: '#fff'
            }
          ];
      return {
        ...state,
        activityDetails: {
          ...activityDetails,
          shareImage,
          selectedImageIndex: 0
        },
        activitiesCurrentMarker: 0,
        loading: false,
        loaded: true
      };
    }),

    on(ActivitiesActions.getActivityDetailsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(ActivitiesActions.selectActivityImage, (state, { selectedImageIndex }) => ({
      ...state,
      activityDetails: { ...state.activityDetails!, selectedImageIndex }
    })),
    on(ActivitiesActions.getActivityTypes, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(ActivitiesActions.getActivityTypesSuccess, (state, { activityTypes: { top, other } }) => {
      const all = [...top, ...other];
      const distanceActivities = all.filter((activity) => activity.kind === ActivityKind.DISTANCE);
      const timeActivities = all.filter((activity) => activity.kind === ActivityKind.TIME);
      const distanceGroups = groupBy(distanceActivities, 'distancePerPoint');
      const timeGroups = groupBy(timeActivities, 'timePerPoint');
      return {
        ...state,
        activityTypes: { top, other, all },
        conversionRates: {
          time: Object.keys(timeGroups)
            .map((key: string) => ({ value: +key, activities: timeGroups[key] }))
            .sort(({ value: first }, { value: second }) => first - second),
          distance: Object.keys(distanceGroups)
            .map((key: string) => ({ value: +key, activities: distanceGroups[key] }))
            .sort(({ value: first }, { value: second }) => first - second)
        },
        loading: false,
        loaded: true
      };
    }),
    on(ActivitiesActions.getActivityTypesError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(ActivitiesActions.deleteActivitySuccess, (state, { id }) => ({
      ...state,
      activities: {
        data: (state.activities as ActivitiesData).data.filter((activity) => activity._id !== id),
        total: (state.activities as ActivitiesData).total - 1
      }
    })),
    on(ActivitiesActions.uploadActivity, (state) => ({
      ...state,
      manualUpload: { ...state.manualUpload, status: UploadStatus.InProgress }
    })),
    on(ActivitiesActions.uploadActivitySuccess, (state) => ({
      ...state,
      manualUpload: { ...state.manualUpload, status: UploadStatus.Succeeded }
    })),
    on(ActivitiesActions.uploadActivityError, (state) => ({
      ...state,
      manualUpload: { ...state.manualUpload, status: UploadStatus.Failed }
    })),
    on(ActivitiesActions.updateActivity, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(ActivitiesActions.updateActivitySuccess, (state, { activityData }) => {
      const newState = {
        ...state,
        activityDetails: {
          ...state.activityDetails!,
          ...(activityData.time ? { time: activityData.time } : {}),
          ...(activityData.distance ? { distance: activityData.distance } : {})
        },
        loading: false,
        loaded: true
      };
      if (activityData.file) {
        newState.activityDetails.customImage = activityData.file;
      } else {
        delete newState.activityDetails.customImage;
      }
      return newState;
    }),
    on(ActivitiesActions.updateActivityError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(ActivitiesActions.clearUploadData, (state) => ({
      ...state,
      manualUpload: { ...state.manualUpload, status: UploadStatus.None }
    })),
    on(ActivitiesActions.clearActivityDetails, (state) => ({
      ...state,
      activityDetails: null
    })),
    on(ActivitiesActions.clear, () => ({
      ...initialState
    }))
  );

  export function reducer(state: ActivitiesState, action: Action): ActivitiesState {
    return activitiesReducer(state, action);
  }
}
