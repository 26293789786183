import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../state';
import { LegalActions } from './legal.actions';
import { LegalSelectors } from './legal.selectors';

@Injectable()
export class LegalStore {
  updatedDocuments$ = this.store$.pipe(select(LegalSelectors.selectUpdatedDocuments));
  loading$ = this.store$.pipe(select(LegalSelectors.selectLoading));
  loaded$ = this.store$.pipe(select(LegalSelectors.selectLoaded));

  getUpdatedDocuments(): void {
    this.store$.dispatch(LegalActions.getUpdatedDocuments());
  }

  registerDocumentAgreement(revisionId: string): void {
    this.store$.dispatch(LegalActions.registerDocumentAgreement({ revisionId }));
  }

  constructor(private store$: Store<AppState>) {}
}
