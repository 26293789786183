import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CampaignDetails, CampaignType } from '../../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-campaign-progress',
  templateUrl: './campaign-progress.component.html',
  styleUrls: ['./campaign-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignProgressComponent {
  @Input() campaignDetails: CampaignDetails;
  @Input() isCampaignActive: boolean;

  campaignType = CampaignType;

  now = new Date().toString();

  toDays(milliseconds: number): number {
    return milliseconds / 1000 / 60 / 60 / 24;
  }

  get campaignDuration(): number {
    const endTime = new Date(this.campaignDetails.endDate).getTime();
    const startTime = new Date(this.campaignDetails.startDate).getTime();
    return Math.floor(this.toDays(endTime - startTime));
  }

  get campaignTime(): number {
    const endTime = new Date(this.campaignDetails.endDate).getTime();
    const now = new Date().getTime();
    const startTime = new Date(this.campaignDetails.startDate).getTime();
    return Math.floor(this.toDays(Math.max(0, Math.min(endTime, now) - startTime)));
  }

  get campaignFinished(): boolean {
    if (this.campaignDetails.type === CampaignType.time) {
      return new Date().getTime() - new Date(this.campaignDetails.endDate).getTime() > 0;
    }

    return this.campaignDetails.points >= this.campaignDetails.maxPoints;
  }

  get campaignStatusKey(): string {
    return this.campaignFinished ? 'common_completed' : 'common_ongoing';
  }

  constructor() {}

  get progressBarValue(): number {
    if (this.campaignDetails.type === CampaignType.time) {
      if (!this.campaignDetails.startDate || !this.campaignDetails.endDate) {
        return 0;
      }
      if (!this.isCampaignActive) {
        return 1;
      }
      const startTime = new Date(this.campaignDetails.startDate).getTime(),
        endTime = new Date(this.campaignDetails.endDate).getTime(),
        currentTime = Date.now();
      return (currentTime - startTime) / (endTime - startTime);
    }
    if (!this.campaignDetails.maxPoints) {
      return 0;
    }

    return this.campaignDetails.points / this.campaignDetails.maxPoints;
  }
}
