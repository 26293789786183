import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LanguageEnum } from '../../../../shared/shared-ui/models/language.model';
import { LanguageFlagService } from '../../../../shared/shared-ui/services/language-flag.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageMobileMenuComponent } from '../language-mobile-menu/language-mobile-menu.component';
import { ProfileMobileMenuComponent } from '../profile-mobile-menu/profile-mobile-menu.component';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UsersStore } from '../../../../store/users/users.store';
import { GarminStore } from '../../../../store/garmin/garmin.store';
import { CampaignsStore } from 'src/app/store/campaigns/campaigns.store';
import { WorkspacesStore } from '../../../../store/workspaces/workspaces.store';
import { StoreHelperService } from '../../../../store/store-helper.service';
import { NotificationsListDialogComponent } from './notifications-list-dialog/notifications-list-dialog.component';
import { NotificationsStore } from '../../../../store/notifications/notifications-store.service';

@Component({
  selector: 'vim-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  private ngSubscribe: Subject<void> = new Subject();
  currentLanguage$: Observable<LanguageEnum> = of(LanguageEnum.english);
  languageEnum = LanguageEnum;

  constructor(
    public languageFlagService: LanguageFlagService,
    public dialogRef: MatDialogRef<MobileMenuComponent>,
    public dialog: MatDialog,
    public usersStore: UsersStore,
    public garminStore: GarminStore,
    private translate: TranslateService,
    public campaignsStore: CampaignsStore,
    public workspacesStore: WorkspacesStore,
    public notificationsStore: NotificationsStore,
    public storeHelper: StoreHelperService,
    private router: Router
  ) {}

  openLanguageMenu(): void {
    this.dialog.open(LanguageMobileMenuComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vh',
      hasBackdrop: false,
      panelClass: 'language-dialog-container'
    });
  }

  openProfileMenu(): void {
    this.dialog.open(ProfileMobileMenuComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vh',
      hasBackdrop: false,
      panelClass: 'edit-profile-dialog-container'
    });
  }

  showNotifications(): void {
    this.dialog.open(NotificationsListDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vh',
      hasBackdrop: false,
      panelClass: 'notifications-dialog-container'
    });
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.translate.onLangChange.pipe(
      takeUntil(this.ngSubscribe),
      map((languageEvent) => (languageEvent.lang as LanguageEnum) ?? LanguageEnum.english)
    );
    this.router.events
      .pipe(
        takeUntil(this.ngSubscribe),
        filter((event: Event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }

  ngOnDestroy(): void {
    this.ngSubscribe.next();
    this.ngSubscribe.complete();
  }

  hideMenu(): void {
    this.dialogRef.close();
  }
}
