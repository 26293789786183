import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { NotificationsStore } from '../../../../../store/notifications/notifications-store.service';

@Component({
  selector: 'vim-notifications-list-dialog',
  templateUrl: './notifications-list-dialog.component.html',
  styleUrls: ['./notifications-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListDialogComponent {
  constructor(
    public notificationsStore: NotificationsStore,
    private dialogRef: DialogRef<NotificationsListDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
    this.notificationsStore.markAsRead([]);
  }
}
