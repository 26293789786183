export const environment = {
  production: false,
  reduxDevTool: false,
  oauthClientId: '10f85725-c855-4ed2-8dbc-0f30c7f24492',
  scope: 'https://MoveforClimate2.onmicrosoft.com/api/read',
  oauthAuthorityUrl: 'https://auth-staging.vimove.com/37e46763-cb41-486f-af5d-1cde7e97c45c/v2.0/?p=B2C_1_SignInSignUp',
  authWellknownEndpointUrl:
    'https://auth-staging.vimove.com/37e46763-cb41-486f-af5d-1cde7e97c45c/v2.0/.well-known/openid-configuration?p=B2C_1_SignInSignUp',
  baseUrl: 'https://api-staging.viessmann.com/vimove/v1',
  appId: 'e907ed41-4bb0-4dc7-bdcf-e73687b9933b'
};
