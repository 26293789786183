<div>
  <div class="title-container">
    <div>
      <a [routerLink]="[]" [mat-dialog-close] queryParamsHandling="preserve" class="select-language-text">
        <vim-arrow orientation="left"></vim-arrow>
      </a>
    </div>
    <div>
      <span class="select-language-text">{{'language_menu_select_your_language' | translate}}</span>
    </div>
  </div>
  <mat-nav-list>
    <a
      (click)="changeLanguageAndCloseSideNav(languageEnum.german)"
      [mat-dialog-close]
      [class.active]="translateService.currentLang === 'de'"
      mat-list-item
    ><img alt="ger-flag" class="flag-image" [src]="languageFlagService.getFlagPath(languageEnum.german)"/> Deutsch /
      German</a
    >
    <a
      [class.active]="translateService.currentLang === 'en' || translateService.currentLang === undefined"
      (click)="changeLanguageAndCloseSideNav(languageEnum.english)"
      [mat-dialog-close]
      mat-list-item
    ><img alt="en-flag" class="flag-image" [src]="languageFlagService.getFlagPath(languageEnum.english)"/>English</a
    >
    <a
      [class.active]="translateService.currentLang === languageEnum.french"
      (click)="changeLanguageAndCloseSideNav(languageEnum.french)"
      [mat-dialog-close]
      mat-list-item
    ><img alt="fr-flag" class="flag-image" [src]="languageFlagService.getFlagPath(languageEnum.french)"/> Français /
      French</a
    >
    <a
      [class.active]="translateService.currentLang === languageEnum.italy"
      (click)="changeLanguageAndCloseSideNav(languageEnum.italy)"
      [mat-dialog-close]
      mat-list-item
    ><img alt="it-flag" class="flag-image" [src]="languageFlagService.getFlagPath(languageEnum.italy)"/> Italiano /
      Italian</a
    >
    <a
      [class.active]="translateService.currentLang === languageEnum.polish"
      (click)="changeLanguageAndCloseSideNav(languageEnum.polish)"
      [mat-dialog-close]
      mat-list-item
    ><img alt="pl-flag" class="flag-image" [src]="languageFlagService.getFlagPath(languageEnum.polish)"/> Polski /
      Polish</a
    >
  </mat-nav-list>
</div>
