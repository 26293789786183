import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'vim-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() class: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() type: string = 'button';
  @Input() width: string = '';

  @HostBinding('style.pointer-events')
  public get pointerEvents(): string {
    return this.disabled ? 'none' : 'initial';
  }

  constructor() {}
}
