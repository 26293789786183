import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { MessageService } from '../message.service';
import { NotificationsActions } from './notifications.actions';
import { EventsService } from '../../client/events/events.service';
import { NotificationsSelectors } from './notifications-selectors';

@Injectable()
export class NotificationsEffects {
  getNotificationsCount = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.getNotificationsCount),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      filter(([workspaceId]) => !!workspaceId),
      switchMap(([, workspaceId]) =>
        this.eventsService.getEventsCount(workspaceId!).pipe(
          map(({ count: eventsCount }) =>
            NotificationsActions.getNotificationsCountSuccess({ notificationsCount: eventsCount })
          ),
          catchError(() => of(NotificationsActions.getNotificationsCountError()))
        )
      )
    )
  );
  getNotificationsCountSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.getNotificationsCountSuccess),
      withLatestFrom(this.store$.select(NotificationsSelectors.selectNotificationsCount)),
      filter(([action, notificationsCount]) => action.notificationsCount > notificationsCount),
      map(() => NotificationsActions.getNotifications({}))
    )
  );
  getNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.getNotifications),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      filter(([, workspaceId]) => !!workspaceId),
      switchMap(([, workspaceId]) =>
        this.eventsService.getEvents(workspaceId!).pipe(
          map(({ data: events, total: eventsCount }) =>
            NotificationsActions.getNotificationsSuccess({
              notifications: events,
              notificationsCount: eventsCount
            })
          ),
          catchError(() => of(NotificationsActions.getNotificationsError()))
        )
      )
    )
  );
  markNotificationsAsRead = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.markAsRead),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      filter(([, workspaceId]) => !!workspaceId),
      switchMap(([{ ids }, workspaceId]) =>
        this.eventsService.markAsRead(workspaceId!, ids).pipe(
          map(() => NotificationsActions.markAsReadSuccess()),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(NotificationsActions.markAsReadError());
          })
        )
      )
    )
  );

  markNotificationsAsReadSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.markAsReadSuccess),
      map(() => NotificationsActions.getNotifications({}))
    )
  );

  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
    private store$: Store<AppState>,
    private messageService: MessageService
  ) {}
}
