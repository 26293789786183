<vim-dialog-template [title]="'teams_leave_title' | translate" (closeDialog)="dialogRef.close()">
  <div fxLayout="column" fxFlex="1 1 auto">
    <p>{{'teams_leave_message' | translate}}</p>
    <div fxLayout fxLayoutAlign="space-between">
      <button mat-button color="secondary" (click)='dialogRef.close()' vim-button
              id='cancelLeavingTeam'>{{'common_cancel' | translate}}</button>
      <button mat-button color="primary" vim-button (click)='leaveTeam()'
              id='leaveTeam'>{{'teams_leave' | translate}}</button>

    </div>
  </div>
</vim-dialog-template>
