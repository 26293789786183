import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vim-social-wall-wrapper',
  templateUrl: './social-wall-wrapper.component.html',
  styleUrls: ['./social-wall-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialWallWrapperComponent {
  constructor() {}
}
