import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { CampaignsState } from './campaigns.state';
import { BadgesSelectors } from '../badges/badges.selectors';

export namespace CampaignsSelectors {
  import selectIsLoading = BadgesSelectors.selectIsLoading;
  const state = (state: AppState) => state.campaigns;

  export const selectUserCampaign = createSelector(state, (state: CampaignsState) => state?.userCampaign);
  export const selectDashboardShareImage = createSelector(
    state,
    (state: CampaignsState) => state?.userCampaign?.imageDashboardShare
  );
  export const selectDashboardColor = createSelector(
    state,
    (state: CampaignsState) => state?.userCampaign?.colorPrimary
  );
  export const selectActiveCampaigns = createSelector(state, (state: CampaignsState) => state?.activeCampaigns);
  export const selectFutureCampaigns = createSelector(state, (state: CampaignsState) => state?.futureCampaigns);
  export const selectPastCampaigns = createSelector(state, (state: CampaignsState) => state?.pastCampaigns);
  export const selectAvailableCampaigns = createSelector(state, (state: CampaignsState) => [
    ...(state ? state.futureCampaigns : []),
    ...(state ? state.activeCampaigns : [])
  ]);
  export const selectUserData = createSelector(state, (state: CampaignsState) => state?.campaignUserData);
  export const selectUserCampaignId = createSelector(
    state,
    (state: CampaignsState) => state?.campaignUserData?.campaign
  );
  export const selectCurrentCampaignUnit = createSelector(state, (state: CampaignsState) => state?.userCampaign?.unit);
  export const selectMultipleTeamsEnabled = createSelector(
    state,
    (state: CampaignsState) => state?.userCampaign?.multipleTeamsEnabled
  );
  export const selectCreateTeamEnabled = createSelector(
    state,
    (state: CampaignsState) => state?.userCampaign?.createTeamEnabled
  );
  export const selectNoActiveCampaign = createSelector(state, (state: CampaignsState) => state?.noActiveCampaign);
  export const selectLoadingStatuses = createSelector(state, (state: CampaignsState) => state.loadingStatuses);
  export const selectJoinCampaignData = createSelector(
    selectLoadingStatuses,
    selectUserData,
    selectAvailableCampaigns,
    (loadingStatuses, userData, availableCampaigns) => ({
      isLoaded: Boolean(loadingStatuses.allCampaigns.isLoaded && loadingStatuses.campaignUserData.isLoaded),
      availableCampaigns,
      campaignAvailable: availableCampaigns.length > 0,
      userInOngoingCampaign:
        !!userData?.campaign && availableCampaigns.find((campaign) => campaign._id === userData?.campaign)
    })
  );
  export const selectRandomSharingData = createSelector(state, (state: CampaignsState) => {
    const length = state.campaignSharingData.length;
    const index = Math.round(Math.random() * (length - 1));
    return state.campaignSharingData[index];
  });
  export const isLoaded = createSelector(state, (state: CampaignsState) =>
    Object.values(state.loadingStatuses)
      .map((status) => status.isLoaded === true || status.isLoading === null)
      .reduce((prev, curr) => prev && curr)
  );

  export const selectUserCampaignIsLoaded = createSelector(
    state,
    (state: CampaignsState) => state?.loadingStatuses.campaignUserData.isLoaded
  );
  export const selectAllCampaignsIsLoaded = createSelector(
    state,
    (state: CampaignsState) => state?.loadingStatuses.allCampaigns.isLoaded
  );
  export const isLoading = createSelector(state, (state: CampaignsState) =>
    Object.values(state.loadingStatuses)
      .map((status) => status.isLoading === true)
      .reduce((prev, curr) => prev && curr)
  );
  export const selectCampaignDetailsViewModel = createSelector(
    selectUserCampaign,
    selectIsLoading,
    (userCampaign, loading) => ({
      userCampaign,
      isLoading: loading
    })
  );
}
