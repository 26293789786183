import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TeamMember, TeamsItem } from 'src/app/client/teams/teams.model';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './leave-team-admin.component.html',
  styleUrls: ['./leave-team-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveTeamAdminComponent {
  memberControl = new UntypedFormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: LeaveTeamAdminData,
    private teamsStore: TeamsStore
  ) {}

  get members(): TeamMember[] {
    return this.data?.teamMembers.filter((member) => member._id !== this.data?.userId) || [];
  }

  leaveTeam(): void {
    const newAdminId = this.memberControl.value as string;
    this.teamsStore.leaveTeamAsAdmin(this.data?.team, this.data?.userId, newAdminId);
    this.dialogRef.close(true);
  }
}

export interface LeaveTeamAdminData {
  team: TeamsItem;
  teamMembers: TeamMember[];
  userId: string;
}
