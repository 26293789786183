import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CampaignsStore } from '../../../../store/campaigns/campaigns.store';

@Component({
  selector: 'vim-campaign-name',
  templateUrl: './campaign-name.component.html',
  styleUrls: ['./campaign-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignNameComponent {
  constructor(public campaignsStore: CampaignsStore) {}
}
