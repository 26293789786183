import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignUnit } from '../../../client/campaigns/campaignsData';
import { Router } from '@angular/router';

@Component({
  selector: 'vim-join-team-dialog',
  templateUrl: './join-team-dialog.component.html',
  styleUrls: ['./join-team-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JoinTeamDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: JoinTeamDialogData,
    public dialogRef: MatDialogRef<JoinTeamDialogComponent>,
    private router: Router
  ) {}

  joinTeam(): void {
    void this.router.navigateByUrl('/home/teams?tab=leaderboard').then(() => this.dialogRef.close());
  }
}

export interface JoinTeamDialogData {
  title: string;
  image: string;
  hashTag: string;
  startDate: string;
  endDate?: string;
  unit: CampaignUnit;
  maxPoints?: number;
}
