import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './leave-team.component.html',
  styleUrls: ['./leave-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveTeamComponent {
  constructor(
    public dialogRef: MatDialogRef<LeaveTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LeaveTeamData,
    private teamsStore: TeamsStore
  ) {}

  leaveTeam(): void {
    this.teamsStore.leaveTeam(
      {
        teamId: this.data?.teamId,
        userId: this.data?.userId,
        teamName: this.data?.teamName
      },
      { active: false }
    );
    this.dialogRef.close(true);
  }
}

export interface LeaveTeamData {
  teamId: string;
  userId: string;
  teamName: string;
}
