import { createAction, props } from '@ngrx/store';
import { LiveTickerCampaignData, LiveTickerCommunityData } from '../../client/live-ticker/live-ticker.model';

export namespace LiveTickerActions {
  export const getCampaignLiveTickerData = createAction('[LIVE_TICKER] Get campaign live ticker data');
  export const getCampaignLiveTickerDataSuccess = createAction(
    '[LIVE_TICKER] Get campaign live ticker data success',
    props<LiveTickerCampaignData>()
  );
  export const getCampaignLiveTickerDataError = createAction('[LIVE_TICKER] Get campaign live ticker data error');

  export const getCommunityLiveTickerData = createAction('[LIVE_TICKER] Get community live ticker data');
  export const getCommunityLiveTickerDataSuccess = createAction(
    '[LIVE_TICKER] Get community live ticker data success',
    props<LiveTickerCommunityData>()
  );
  export const getCommunityLiveTickerDataError = createAction('[LIVE_TICKER] Get community live ticker data error');
  export const clearLiveTickerData = createAction('[LIVE_TICKER] Clear live ticker data');
}
