import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../services/converter.service';

@Pipe({
  name: 'metersOrKilometers'
})
export class MetersOrKilometersPipe implements PipeTransform {
  transform(meters: number | string | null | undefined, fractionDigits = 2): string {
    const isNull = meters === undefined || meters === null;
    const isNumberCorrect = typeof meters === 'number' && !isNaN(meters) && meters >= 0;

    if (isNull || !isNumberCorrect) {
      return '';
    }

    meters = Number(meters);
    if (meters < 1000) {
      return `${ConverterService.roundDown(meters, fractionDigits)} m`;
    }

    const kilometers = meters / 1000;

    return `${ConverterService.roundDown(kilometers, fractionDigits)} km`;
  }
}
