import { createAction, props } from '@ngrx/store';
import {
  TeamsData,
  GetTeamsParams,
  CreateTeamModel,
  TeamsItem,
  UpdateTeamMemberModel,
  UpdateTeamMemberParams,
  UpdateTeamModel,
  TeamMember,
  TeamMembersData,
  LeadersData
} from 'src/app/client/teams/teams.model';

export namespace TeamsActions {
  export const getTeams = createAction('[TEAMS] Get myTeams', props<{ params?: GetTeamsParams }>());
  export const getTeamsSuccess = createAction('[TEAMS] Get myTeams success', props<{ teams: TeamsData }>());
  export const getTeamsError = createAction('[TEAMS] Get myTeams error');

  export const getTeam = createAction('[TEAMS] Get team', props<{ id: string }>());
  export const getTeamSuccess = createAction('[TEAMS] Get team success', props<{ team: TeamsItem }>());
  export const getTeamError = createAction('[TEAMS] Get team error');

  export const getTeamMembers = createAction(
    '[TEAMS] Get team members',
    props<{ id: string; skip: number; limit: number }>()
  );
  export const getTeamMembersSuccess = createAction(
    '[TEAMS] Get team members success',
    props<{ teamMembersData: TeamMembersData }>()
  );
  export const getTeamMembersError = createAction('[TEAMS] Get team members error');

  export const getTeamMoreMembers = createAction(
    '[TEAMS] Get team more members',
    props<{ id: string; skip: number; limit: number }>()
  );
  export const getTeamMoreMembersSuccess = createAction(
    '[TEAMS] Get team more members success',
    props<{ teamMembersData: TeamMembersData }>()
  );
  export const getTeamMoreMembersError = createAction('[TEAMS] Get team more members error');

  export const getTeamRemovedMembers = createAction(
    '[TEAMS] Get team removed members',
    props<{ id: string; skip?: number; limit?: number }>()
  );
  export const getTeamRemovedMembersSuccess = createAction(
    '[TEAMS] Get team removed members success',
    props<{ removedMembers: TeamMember[] }>()
  );
  export const getTeamRemovedMembersError = createAction('[TEAMS] Get team removed members error');

  export const createTeam = createAction('[TEAMS] Create team', props<{ team: CreateTeamModel }>());
  export const createTeamSuccess = createAction('[TEAMS] Create team success', props<{ teamName: string }>());
  export const createTeamError = createAction('[TEAMS] Create team error', props<{ errorType: string }>());

  export const updateTeam = createAction('[TEAMS] Update team', props<{ id: string; team: UpdateTeamModel }>());
  export const updateTeamSuccess = createAction(
    '[TEAMS] Update team success',
    props<{ id: string; team: UpdateTeamModel }>()
  );
  export const updateTeamError = createAction('[TEAMS] Update team error');

  export const removeTeamMember = createAction(
    '[TEAMS] Remove team member',
    props<{ params: UpdateTeamMemberParams; model: UpdateTeamMemberModel }>()
  );
  export const removeTeamMemberSuccess = createAction(
    '[TEAMS] Remove team member success',
    props<{ teamId: string; userId: string }>()
  );
  export const removeTeamMemberError = createAction('[TEAMS] Remove team member error');

  export const reInviteTeamMember = createAction(
    '[TEAMS] Re-invite team member',
    props<{ params: UpdateTeamMemberParams; model: UpdateTeamMemberModel }>()
  );
  export const reInviteTeamMemberSuccess = createAction(
    '[TEAMS] Re-invite team member success',
    props<{ teamId: string }>()
  );
  export const reInviteTeamMemberError = createAction('[TEAMS] Re-invite team member error');

  export const leaveTeam = createAction(
    '[TEAMS] Leave team',
    props<{ params: UpdateTeamMemberParams; model: UpdateTeamMemberModel }>()
  );
  export const leaveTeamAsAdmin = createAction(
    '[TEAMS] Leave team as admin',
    props<{ team: TeamsItem; userId: string; newAdminId: string }>()
  );
  export const leaveTeamSuccess = createAction('[TEAMS] Leave team success', props<{ teamId: string }>());
  export const leaveTeamError = createAction('[TEAMS] Leave team error');

  export const acceptInvitation = createAction(
    '[TEAMS] Accept invitation',
    props<{ params: UpdateTeamMemberParams; model: UpdateTeamMemberModel }>()
  );
  export const acceptInvitationSuccess = createAction('[TEAMS] Accept invitation success', props<{ teamId: string }>());
  export const acceptInvitationError = createAction('[TEAMS] Accept invitation error');

  export const inviteToTeam = createAction('[TEAMS] Invite to team', props<{ teamId: string; emails: string[] }>());
  export const inviteToTeamSuccess = createAction('[TEAMS] Invite to team success', props<{ teamId: string }>());
  export const inviteToTeamError = createAction('[TEAMS] Invite to team error');

  export const deleteTeam = createAction('[TEAMS] Delete team', props<{ id: string; name: string }>());
  export const deleteTeamSuccess = createAction('[TEAMS] Delete team success');
  export const deleteTeamError = createAction('[TEAMS] Delete team error');

  export const getLeadersSuccess = createAction('[TEAMS] Get leaders success', props<{ leaders: LeadersData }>());
  export const getLeadersError = createAction('[TEAMS] Get leaders error');

  export const getLeaders = createAction('[TEAMS] Get leaders category');

  export const getMoreLeadersSuccess = createAction(
    '[TEAMS] Get more leaders success',
    props<{ leaders: TeamsData }>()
  );
  export const getMoreLeadersError = createAction('[TEAMS] Get more leaders error');

  export const getMoreLeaders = createAction(
    '[TEAMS] Get more leaders category',
    props<{ skip?: number; limit?: number }>()
  );

  export const clear = createAction('[TEAMS] Clear teams data');
}
