<div class="flex-container" fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="end center">
  <div
    class="left-column"
    fxLayout="column"
    fxLayoutAlign="center start"
    fxLayoutAlign.lt-md="center start"
    fxFlex.gt-md="30%"
    fxFlex.md="50%"
    fxFlex="100%"
  >
    <div class="title DisplayBoldHeading-3" ngClass.lt-md="DisplayBoldHeading-6">
      <div class="logo-vimove">
        <img src="./assets/svg/vimove.svg" alt="vimove-logo"/>
      </div>
      {{ 'dashboard_header_fallback' | translate }} <br/>
      <span>{{ 'loginScreen_header' | translate | sprintf: (workspacesStore.hashtag$ |async) }}</span>
    </div>
    <div fxShow fxHide.lt-sm class="subtitle ParagraphParagraph-2" ngClass.lt-md="ParagraphParagraph-3">
      {{ 'login_subtitle' | translate }}
    </div>
    <div class="button-container" fxLayout="row" fxLayoutAlign="center">
      <vim-button class="primary-cta button-container__login-button"
                  fxFlex.lt-md="100" (click)="onLogin()" id="login">
        <span>{{ 'loginScreen_button_join' | translate }}</span>
      </vim-button>
    </div>
    <div fxFlex="48px" fxFlex.sm="32px" fxFlex.xs="23px"></div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-lg="center center"
      fxLayoutAlign.xs="center center"
      fxLayoutGap="24px"
      fxLayoutGap.xs="12px"
      [style.width.%]="100"
    >
      <a class="store-link" [href]="settings.APPLE_STORE_URL">
        <img class="store-icon" src="./assets/images/login-page/app-store-badge.svg" alt="app-store"/>
      </a>
      <a class="store-link" [href]="settings.GOOGLE_PLAY_URL">
        <img class="store-icon" src="./assets/images/login-page/google-play-badge.svg" alt="google-play"/>
      </a>
    </div>
  </div>
  <div class="right-column" fxFlex.gt-md="70%"
       fxFlex.gt-sm="50%"
       fxFlex="100%" *ngIf="!deviceService.isMobile(); else mobile">
    <carousel
      class="carousel"
      [margin]="0"
      [autoplay]="true"
      [dots]="true"
      [cellsToShow]="1"
      [arrows]="false"
      [autoplayInterval]="5000"
      [loop]="true"
    >
      <div class="carousel-cell">
        <picture>
          <source srcset="assets/images/login-page/slider-1/photo-large-slider-1@3x.webp"
                  media="(min-width: 4310px)">
          <source srcset="
              assets/images/login-page/slider-1/photo-large-slider-1@2x.webp" media="(min-width: 2156px)">
          <img
            src="assets/images/login-page/slider-1/photo-large-slider-1.webp" alt="Login page first image">
        </picture>
        <div class="slider-text DisplayBoldHeading-1" fxShow fxHide.xs>
          {{ 'loginScreen_header_summer_1' | translate }}
        </div>
      </div>
      <div class="carousel-cell">
        <picture>
          <source srcset="assets/images/login-page/slider-2/photo-large-slider-2@3x.webp"
                  media="(min-width: 4310px)">
          <source srcset="
              assets/images/login-page/slider-2/photo-large-slider-2@2x.webp" media="(min-width: 2156px)">
          <img
            src="assets/images/login-page/slider-2/photo-large-slider-2.webp" alt="Login page second image">
        </picture>
        <div class="slider-text DisplayBoldHeading-1" fxShow fxHide.xs>
          {{ 'loginScreen_header_summer_2' | translate }}
        </div>
      </div>
      <div class="carousel-cell">
        <picture>
          <source srcset="assets/images/login-page/slider-3/photo-large-slider-3@3x.webp"
                  media="(min-width: 4310px)">
          <source srcset="
              assets/images/login-page/slider-3/photo-large-slider-3@2x.webp" media="(min-width: 2156px)">
          <img
            src="assets/images/login-page/slider-3/photo-large-slider-3.webp" alt="Login page third image">
        </picture>
        <div class="slider-text DisplayBoldHeading-1" fxShow fxHide.xs>
          {{ 'loginScreen_header_summer_3' | translate }}
        </div>
      </div>
      <div class="carousel-cell">
        <picture>
          <source srcset="assets/images/login-page/slider-4/photo-large-slider-4@3x.webp"
                  media="(min-width: 4310px)">
          <source srcset="
              assets/images/login-page/slider-4/photo-large-slider-4@2x.webp" media="(min-width: 2156px)">
          <img
            src="assets/images/login-page/slider-4/photo-large-slider-4.webp" alt="Login page fourth image">
        </picture>
        <div class="slider-text DisplayBoldHeading-1" fxShow fxHide.xs>
          {{ 'loginScreen_header_summer_4' | translate }}
        </div>
      </div>
    </carousel>
  </div>
  <ng-template #mobile>
    <div class="mobile-picture">
      <picture>
        <source srcset="./assets/images/login-page/mobile/photo-xsmall-slider-1@2x.webp" media="(min-width: 376px)">
        <source srcset="./assets/images/login-page/mobile/photo-xsmall-slider-1@3x.webp" media="(min-width: 751px)">
        <img src="./assets/images/login-page/mobile/photo-xsmall-slider-1.webp" alt="Login picture">
      </picture>
    </div>
  </ng-template>
</div>
