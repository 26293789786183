import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'activityTypeTranslate',
  pure: false
})
export class ActivityTypeTranslate extends TranslatePipe implements PipeTransform {
  constructor(translate: TranslateService, _ref: ChangeDetectorRef) {
    super(translate, _ref);
  }

  transform(activityType: string): string {
    const key = `be_activities_${activityType?.toLowerCase()}`;
    return super.transform(key) as string;
  }
}
