import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CampaignUnit } from '../../../../client/campaigns/campaignsData';
import { ConverterService, MetersOrKilometers, MinutesOrHours } from '../../services/converter.service';
import { ShareImage } from '../../../../client/activities/activities.model';

@Component({
  selector: 'vim-shared-picture',
  templateUrl: './shared-picture.component.html',
  styleUrls: ['./shared-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedPictureComponent {
  @Input() data: SharedPictureData;
  @Input() selectedImageIndex: number;
  @Input() pointsFractionalDigits = 1;
  @Input() distanceFractionalDigits = 1;

  constructor() {}

  get imageAvailable(): boolean {
    return this.data.images.length > 0;
  }

  get textColor(): string {
    return this.imageAvailable ? this.data.images[0].textColor : '#fff';
  }

  get time(): MinutesOrHours {
    if (!this.data.time) {
      return {};
    }
    return ConverterService.secondsToMinutesOrHours(this.data.time);
  }

  get distance(): MetersOrKilometers {
    if (!this.data.distance) {
      return {};
    }
    return ConverterService.metersToMetersAndOrKilometers(this.data.distance);
  }

  get timeInHours(): number {
    if (!this.data.time) {
      return 0;
    }
    return Math.round(this.data.time / 3600);
  }
}

export interface SharedPictureData {
  unit: CampaignUnit;
  points: number;
  distance?: number;
  time?: number;
  hashtag: string;
  images: ShareImage[];
  singleRow?: boolean;
  activityName?: string;
  showFractional?: boolean;
  text?: string;
}
