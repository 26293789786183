import { createAction, props } from '@ngrx/store';
import { Document } from './legal.state';

export namespace LegalActions {
  export const getUpdatedDocuments = createAction('[LEGAL] Get updated documents');
  export const getUpdatedDocumentsSuccess = createAction(
    '[LEGAL] Get updated documents success',
    props<{ documents: Document[] }>()
  );
  export const getUpdatedDocumentsError = createAction('[LEGAL] Get updated documents error');

  export const getCurrentDocuments = createAction('[LEGAL] Get current documents');
  export const getCurrentDocumentsSuccess = createAction(
    '[LEGAL] Get current documents success',
    props<{ documents: Document[] }>()
  );
  export const getCurrentDocumentsError = createAction('[LEGAL] Get current documents error');

  export const registerDocumentAgreement = createAction(
    '[LEGAL] Register document agreement',
    props<{ revisionId: string }>()
  );
  export const registerDocumentAgreementSuccess = createAction(
    '[LEGAL] Register document agreement success',
    props<{ revisionId: string }>()
  );
  export const registerDocumentAgreementError = createAction('[LEGAL] Register document agreement error');
}
