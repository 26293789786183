<div class='container' fxLayout='column' fxLayoutAlign='center center'>
  <img alt='Access forbidden image' class='image'
       src='../../../../assets/images/403/403-image.svg'>
  <div class='title'>{{'forbidden_page_title' | translate}}</div>
  <div class='subtitle'>{{'forbidden_page_subtitle' | translate}}</div>
  <div class='message'>{{'forbidden_page_message' | translate}}</div>
  <button *ngIf="hideButton === false" class='home-page-button' routerLink='/home/dashboard' mat-button
          color='primary' vim-button
          id='home'>{{'common_home_page' | translate}}</button>
</div>
