import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminPanelReducer } from './admin-panel.reducer';
import { AdminPanelStore } from './admin-panel.store';
import { AdminPanelEffects } from './admin-panel.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('adminPanel', AdminPanelReducer.reducer),
    EffectsModule.forFeature([AdminPanelEffects])
  ],
  providers: [AdminPanelStore]
})
export class AdminPanelStoreModule {}
