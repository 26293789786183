import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../state';
import { WorkspacesActions } from './workspaces.actions';
import { WorkspacesSelectors } from './workspaces.selectors';

@Injectable()
export class WorkspacesStore {
  workspaces$ = this.store$.pipe(select(WorkspacesSelectors.selectWorkspaces));
  selectedWorkspaceId$ = this.store$.pipe(select(WorkspacesSelectors.selectSelectedWorkspaceId));
  selectedWorkspace$ = this.store$.pipe(select(WorkspacesSelectors.selectSelectedWorkspace));
  name$ = this.store$.pipe(select(WorkspacesSelectors.selectName));
  favicon$ = this.store$.pipe(select(WorkspacesSelectors.selectFavicon));
  hashtag$ = this.store$.pipe(select(WorkspacesSelectors.selectHashtag));
  logo$ = this.store$.pipe(select(WorkspacesSelectors.selectLogo));
  logoPoweredBy$ = this.store$.pipe(select(WorkspacesSelectors.selectLogoPoweredBy));
  isLoaded$ = this.store$.pipe(select(WorkspacesSelectors.isLoaded));
  isLoading$ = this.store$.pipe(select(WorkspacesSelectors.isLoading));
  co2visible$ = this.store$.pipe(select(WorkspacesSelectors.co2Visible));
  categoriesEnabled$ = this.store$.pipe(select(WorkspacesSelectors.categoriesEnabled));

  getWorkspaces(): void {
    this.store$.dispatch(WorkspacesActions.getWorkspaces());
  }

  selectWorkspace(workspaceId: string): void {
    this.store$.dispatch(WorkspacesActions.selectWorkspace({ workspaceId }));
  }

  constructor(private store$: Store<AppState>) {}
}
