import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { CampaignDetails, CampaignStatistics, CampaignStatus } from '../../../../../client/campaigns/campaignsData';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignsStore } from '../../../../../store/campaigns/campaigns.store';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SocialMediaShareDialogService } from '../../../dashboard/social-media-share-download/social-media-share-dialog.service';
import { CampaignsHelperService } from '../../../../../client/campaigns/campaigns-helper.service';

@Component({
  selector: 'vim-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignDetailsComponent implements OnDestroy {
  @HostBinding('class.mobile') mobileClass = this.deviceDetector.isMobile();
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: CampaignDetailsDialogData,
    public dialogRef: MatDialogRef<CampaignDetailsComponent>,
    public campaignsStore: CampaignsStore,
    public deviceDetector: DeviceDetectorService,
    private campaignsHelperService: CampaignsHelperService,
    private socialMediaShareDialogService: SocialMediaShareDialogService
  ) {}

  isUserAssignedTo(campaignId: string): boolean {
    return this.dialogData.campaignDetails._id === campaignId;
  }

  get title(): string {
    return CAMPAIGN_STATUS_TO_KEY[this.dialogData.campaignDetails.status];
  }

  get campaignAvailable(): boolean {
    return (
      this.dialogData.campaignDetails.status === CampaignStatus.active ||
      this.dialogData.campaignDetails.status === CampaignStatus.future
    );
  }

  get campaignStatistics(): CampaignStatistics {
    return {
      points: this.dialogData.campaignDetails.points,
      distance: this.dialogData.campaignDetails.distance,
      time: this.dialogData.campaignDetails.time
    };
  }

  joinCampaign(): void {
    this.campaignsStore.joinCampaign(this.dialogData.campaignDetails._id);
    this.dialogRef.close();
  }

  leaveCampaign(): void {
    this.campaignsStore.leaveCampaign(this.dialogData.campaignDetails._id);
    this.dialogRef.close();
  }

  shareResults(): void {
    this.campaignsHelperService
      .getRandomCampaignSharingData(this.dialogData.campaignDetails._id)
      .subscribe(({ color: textColor, image }) => {
        this.dialogRef.close();
        this.socialMediaShareDialogService.openDownloadShareImageDialog({
          unit: this.dialogData.campaignDetails.unit,
          points: this.dialogData.campaignUserStatistics.points,
          distance: this.dialogData.campaignUserStatistics.distance,
          time: this.dialogData.campaignUserStatistics.time,
          hashtag: this.dialogData.campaignDetails.hashTag,
          images: [
            {
              image,
              textColor
            }
          ],
          showFractional: true
        });
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

export interface CampaignDetailsDialogData {
  campaignDetails: CampaignDetails;
  isActive: boolean;
  campaignUserStatistics: CampaignStatistics;
}

const CAMPAIGN_STATUS_TO_KEY: { [key: string]: string } = {
  [CampaignStatus.active]: 'common_active_campaign',
  [CampaignStatus.future]: 'common_upcoming_campaign',
  [CampaignStatus.past]: 'common_past_campaign'
};
