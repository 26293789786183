import { Injectable } from '@angular/core';
import { SnackBarColor, ToastService } from '../shared/shared-ui/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public showErrorSnackbar(error: any): void {
    // eslint-disable-next-line no-console
    console.trace(error);
    let key = 'error_something_went_wrong_snackbar';

    if (typeof error === 'string') {
      key = error;
    } else if (this.isError(error)) {
      key = ERROR_MESSAGE_TO_TRANSLATION_KEY[error.message] || 'error_something_went_wrong_snackbar';
    } else if (this.isViError(error)) {
      key = `error_${error.extendedPayload!.reason.toLowerCase()}`;
    }
    const message = this.translateService.instant(key) as string;
    this.toastService.openSnackBar(message, false, SnackBarColor.red);
  }

  private isViError(error: any): error is ViError {
    return (error as ViError).extendedPayload !== undefined && (error as ViError).extendedPayload?.reason !== undefined;
  }

  private isError(error: any): error is Error {
    return (
      (error as Error).errorType !== undefined &&
      (error as Error).message !== undefined &&
      !(error as ViError).extendedPayload
    );
  }

  public showSuccessSnackbar(key: string): void {
    const message = this.translateService.instant(key) as string;
    this.toastService.openSnackBar(message, false, SnackBarColor.green);
  }

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}
}

interface Error {
  errorType: string;
  message: string;
}

export interface ViError {
  errorType?: string;
  message?: string;
  extendedPayload?: {
    reason: ErrorReason;
  };
}

export enum ErrorReason {
  TREES_LIMIT_EXCEEDED = 'TREES_LIMIT_EXCEEDED'
}

const ERROR_MESSAGE_TO_TRANSLATION_KEY: { [key: string]: string } = {
  MAX_MEMBERS: 'error_invite_team_while_team_limit_reached',
  ALREADY_IN_TEAM: 'error_join_team_while_team_limit_reached',
  DUPLICATE_NAME: 'error_team_already_exists',
  ALREADY_BLOCKED: 'error_remove_user_while_already_removed',
  ALREADY_MEMBER: 'error_join_team_while_already_in_team',
  BLOCKED: 'error_join_team_while_on_removed_list'
};
