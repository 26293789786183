<div fxLayout class="stats-row">
  <div fxFlex="33%" class="stats-item">
    <div class="DisplayBoldHeading-6">{{data.points | roundDown:1}}</div>
    <div class="LabelLabel-6">{{unit | unitToKey | plural:data.points | translate | uppercase}}</div>
  </div>
  <div fxFlex="33%" class="stats-item">
    <ng-container *ngIf="data.distance | singleDistanceUnit:1 as distance">
      <div class="DisplayBoldHeading-6">{{distance.value}}</div>
      <div class="LabelLabel-6">{{distance.unit | translate |uppercase}}</div>
    </ng-container>
  </div>
  <div fxFlex="33%" class="stats-item" fxLayout>
    <ng-container *ngIf="getMinutesAndHours(data.time) as time">
      <div *ngIf="time.hours" [style.margin-right.px]="24">
        <div class="DisplayBoldHeading-6">{{time.hours}}</div>
        <div class="LabelLabel-6">{{'unit_hour_si' | translate | uppercase}}</div>
      </div>
      <div *ngIf="time.minutes">
        <div class="DisplayBoldHeading-6">{{time.minutes}}</div>
        <div class="LabelLabel-6">{{'unit_second' | translate | uppercase}}</div>
      </div>
      <div *ngIf="!time.minutes && !time.hours">
        <div class="DisplayBoldHeading-6">0</div>
        <div class="LabelLabel-6">{{'unit_second' | translate | uppercase}}</div>
      </div>
    </ng-container>
  </div>
</div>
