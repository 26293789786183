import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SocialMediaShareDownloadComponent } from './social-media-share-download.component';
import { SharedPictureData } from '../../../../shared/shared-ui/components/shared-picture/shared-picture.component';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaShareDialogService {
  dialogRef: MatDialogRef<SocialMediaShareDownloadComponent>;

  constructor(private matDialog: MatDialog) {}

  openDownloadShareImageDialog(data: SharedPictureData): Observable<any> {
    this.dialogRef = this.matDialog.open(SocialMediaShareDownloadComponent, {
      width: '601px',
      panelClass: 'social-media-share-dialog',
      data: data
    });

    return this.dialogRef.afterClosed();
  }
}
