import { Component, ChangeDetectionStrategy, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '../../../store/message.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'vim-whats-new-dialog',
  templateUrl: './whats-new-dialog.component.html',
  styleUrls: ['./whats-new-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsNewDialogComponent implements OnInit {
  @HostBinding('class.mobile') mobileClass: boolean;

  constructor(
    public dialogRef: MatDialogRef<WhatsNewDialogComponent>,
    private messageService: MessageService,
    public deviceDetector: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.mobileClass = this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
  }

  onClose(): void {
    this.dialogRef.close();
    this.messageService.showSuccessSnackbar('whats_new_notification');
  }
}
