import { LegalState } from './legal.state';
import { Action, createReducer, on } from '@ngrx/store';
import { LegalActions } from './legal.actions';

export namespace LegalReducer {
  export const initialState: LegalState = {
    updatedDocuments: [],
    loading: false,
    loaded: false
  };

  export const legalReducer = createReducer(
    initialState,
    on(LegalActions.getUpdatedDocuments, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(LegalActions.getUpdatedDocumentsSuccess, (state, { documents }) => ({
      ...state,
      updatedDocuments: documents,
      loading: false,
      loaded: true
    })),
    on(LegalActions.getUpdatedDocumentsError, (state) => ({
      ...state,
      updatedDocuments: [],
      loading: false,
      loaded: false
    })),
    on(LegalActions.registerDocumentAgreement, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(LegalActions.registerDocumentAgreementSuccess, (state, { revisionId }) => ({
      ...state,
      documents: state.updatedDocuments.filter((document) => document.revisionId !== revisionId),
      loading: false,
      loaded: true
    })),
    on(LegalActions.registerDocumentAgreementError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: LegalState, action: Action): LegalState {
    return legalReducer(state, action);
  }
}
