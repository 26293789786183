import { Directive, ElementRef, Renderer2 } from '@angular/core';

export type VimButtonType = 'primary' | 'secondary' | 'tertiary' | 'black' | 'white' | 'quaternary';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[vim-button]'
})
export class VimButtonDirective {
  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.element.nativeElement, `vim-button`);
  }
}
