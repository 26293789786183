<ng-container *ngIf="chartData; else nodata">
  <canvas baseChart class="chart"
          [data]="chartData"
          [type]="pieChartType"
          [options]="chartOptions"
          [plugins]="chartPlugins">
  </canvas>
</ng-container>
<ng-template #nodata>
  Missing data for chart
</ng-template>
