import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vim-team-member-confirmation-buttons',
  templateUrl: './team-member-confirmation-buttons.component.html',
  styleUrls: ['./team-member-confirmation-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMemberConfirmationButtonsComponent {
  @Input() confirmationText!: string;
  @Input() memberId: string;
  @Input() changeStatusOfMember!: EventEmitter<string>;

  @Output() hideRemoveOrAddConfirmation = new EventEmitter<boolean>();

  onNoButtonClicked(event: MouseEvent): void {
    this.hideRemoveOrAddConfirmation.emit(true);
    event.preventDefault();
    event.stopPropagation();
  }

  onYesButtonClicked(event: MouseEvent): void {
    this.changeStatusOfMember.emit(this.memberId);
    this.hideRemoveOrAddConfirmation.emit(true);
    event.preventDefault();
    event.stopPropagation();
  }

  constructor() {}
}
