import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vim-insight',
  templateUrl: './insight.component.html',
  styleUrls: ['./insight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightComponent {
  @Input() content: string;
  @Input() previewMode = false;

  constructor() {}
}
