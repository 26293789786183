import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/ui/common/app-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteAccountConfirmationComponent } from './delete-account-confirmation/delete-account-confirmation.component';
import { ActivatedRoute } from '@angular/router';
import { HelpExpandablePanelComponent } from './help-expandable-panel/help-expandable-panel.component';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs/operators';

@Component({
  selector: 'vim-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpComponent implements OnInit, AfterViewInit {
  appSettings = AppSettingsService;

  appName = this.translateService.instant('app_name_vimove');
  @ViewChild('deleteAccountPanel') deleteAccountPanel: HelpExpandablePanelComponent;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private oidc: OidcSecurityService
  ) {}

  isLoggedIn = this.oidc.isAuthenticated();

  ngOnInit() {
    this.setLanguage();
  }

  ngAfterViewInit() {
    const fragment = this.activatedRoute.snapshot.fragment;
    if (fragment) {
      if (fragment === 'delete-account') {
        this.deleteAccountPanel
          .openPanel()
          .pipe(take(1))
          .subscribe(() => document.getElementById(fragment)?.scrollIntoView(true));
      }
    }
  }

  setLanguage(): void {
    if (this.route.snapshot.queryParamMap.has('lang')) {
      this.translateService.use(this.route.snapshot.queryParamMap.get('lang') as string);
    }
  }

  deleteAccount(): void {
    this.dialog.open(DeleteAccountConfirmationComponent, { width: '601px', panelClass: 'dialog-container' });
  }
}
