import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LegalDocumentsCheckerComponent } from './legal-documents-checker.component';
import { Document } from '../../../../store/legal/legal.state';
import { Observable } from 'rxjs';
import { LegalStore } from '../../../../store/legal/legal.store';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LegalDocumentsCheckerService {
  constructor(
    private matDialog: MatDialog,
    private legalStore: LegalStore
  ) {}

  checkLegalDocs(): void {
    this.legalStore.getUpdatedDocuments();
    this.legalStore.loaded$
      .pipe(
        filter((loaded) => loaded),
        switchMap(() => this.legalStore.updatedDocuments$),
        take(1),
        filter((documents) => !!documents.length)
      )
      .subscribe((documents) => {
        this.openDialog(documents);
      });
  }

  private openDialog(data: Document[]): Observable<any> {
    return this.matDialog
      .open(LegalDocumentsCheckerComponent, {
        width: '707px',
        minHeight: '384px',
        panelClass: 'legal-documents-checker-dialog',
        disableClose: true,
        data: data
      })
      .afterClosed();
  }
}
