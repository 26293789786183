import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, flatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { TeamsActions } from './teams.actions';
import { TeamsService } from 'src/app/client/teams/teams.service';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../message.service';
import { sprintf } from 'printj';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';
import { StorageService } from '../../shared/shared-ui/services/storage-service/storage.service';

@Injectable()
export class TeamsEffects {
  getTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getTeams),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.teamsService.getTeams(workspaceId!, action.params).pipe(
          map((teams) => TeamsActions.getTeamsSuccess({ teams })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.getTeamsError());
          })
        )
      )
    )
  );

  getTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getTeam),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.teamsService.getTeam(action.id, workspaceId!).pipe(
          map((team) => TeamsActions.getTeamSuccess({ team })),
          catchError(() => {
            this.storageService.clearInvitationParams();
            void this.router.navigate(['/home/not-found']);
            return of(TeamsActions.getTeamError());
          })
        )
      )
    )
  );

  getTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getTeamMembers),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.teamsService.getTeamMembers(action.id, action.skip, action.limit, workspaceId!).pipe(
          map((teamMembersData) => TeamsActions.getTeamMembersSuccess({ teamMembersData })),
          catchError(() => of(TeamsActions.getTeamMembersError()))
        )
      )
    )
  );

  getTeamMoreMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getTeamMoreMembers),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.teamsService.getTeamMembers(action.id, action.skip, action.limit, workspaceId!).pipe(
          map((teamMembersData) => TeamsActions.getTeamMoreMembersSuccess({ teamMembersData })),
          catchError(() => of(TeamsActions.getTeamMoreMembersError()))
        )
      )
    )
  );
  getTeamRemovedMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getTeamRemovedMembers),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.teamsService.getTeamRemovedMembers(action.id, workspaceId!).pipe(
          map((removedMembers) => TeamsActions.getTeamRemovedMembersSuccess({ removedMembers })),
          catchError(() => of(TeamsActions.getTeamRemovedMembersError()))
        )
      )
    )
  );

  createTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.createTeam),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ team }, workspaceId]) =>
        this.teamsService.createTeam(team, workspaceId!).pipe(
          map(() => TeamsActions.createTeamSuccess({ teamName: team.name })),
          catchError((error: { errorType: string }) => {
            if (error.errorType === 'CONFLICT') {
              this.messageService.showErrorSnackbar('error_team_already_exists');
            } else {
              this.messageService.showErrorSnackbar(error);
            }
            return of(TeamsActions.createTeamError({ errorType: error.errorType }));
          })
        )
      )
    )
  );

  updateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeam),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ id, team }, workspaceId]) =>
        this.teamsService.updateTeam(id, team, workspaceId!).pipe(
          map(() => {
            this.messageService.showSuccessSnackbar('edit_profile_save_success');
            return TeamsActions.updateTeamSuccess({ id, team });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.updateTeamError());
          })
        )
      )
    )
  );

  updateTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeamSuccess),
      map(({ id }) => TeamsActions.getTeam({ id }))
    )
  );

  deleteTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.deleteTeam),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),

      switchMap(([{ id, name }, workspaceId]) =>
        this.teamsService.deleteTeam(id, workspaceId!).pipe(
          map(() => {
            const translation = this.translationService.instant('teams_team_deleted') as string;
            const message = sprintf(translation, name);
            this.messageService.showSuccessSnackbar(message);
            return TeamsActions.deleteTeamSuccess();
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.deleteTeamError());
          })
        )
      )
    )
  );

  removeTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.removeTeamMember),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ params, model }, workspaceId]) =>
        this.teamsService.updateTeamMember(params, model, workspaceId!).pipe(
          map(() => {
            const message = this.translationService.instant('teams_member_removed') as string;
            this.messageService.showSuccessSnackbar(message);
            return TeamsActions.removeTeamMemberSuccess({ teamId: params.teamId, userId: params.userId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.removeTeamMemberError());
          })
        )
      )
    )
  );

  reInviteTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.reInviteTeamMember),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ params, model }, workspaceId]) =>
        this.teamsService.updateTeamMember(params, model, workspaceId!).pipe(
          map(() => {
            const message = this.translationService.instant('teams_member_invited') as string;
            this.messageService.showSuccessSnackbar(message);
            return TeamsActions.reInviteTeamMemberSuccess({ teamId: params.teamId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.reInviteTeamMemberError());
          })
        )
      )
    )
  );

  leaveTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.leaveTeam),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ params, model }, workspaceId]) =>
        this.teamsService.updateTeamMember(params, model, workspaceId!).pipe(
          map(() => {
            const translation = this.translationService.instant('teams_team_left') as string;
            const message = sprintf(translation, params.teamName);
            this.messageService.showSuccessSnackbar(message);
            return TeamsActions.leaveTeamSuccess({ teamId: params.teamId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.leaveTeamError());
          })
        )
      )
    )
  );

  leaveTeamAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.leaveTeamAsAdmin),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ team, userId, newAdminId }, workspaceId]) =>
        this.teamsService
          .updateTeamMember({ teamId: team._id, userId: newAdminId }, { isAdmin: true }, workspaceId!)
          .pipe(
            flatMap(() =>
              this.teamsService.updateTeamMember({ teamId: team._id, userId }, { active: false }, workspaceId!)
            ),
            map(() => {
              const translation = this.translationService.instant('teams_team_left') as string;
              const message = sprintf(translation, team.name);
              this.messageService.showSuccessSnackbar(message);
              return TeamsActions.leaveTeamSuccess({ teamId: team._id });
            }),
            catchError((err) => {
              this.messageService.showErrorSnackbar(err);
              return of(TeamsActions.leaveTeamError());
            })
          )
      )
    )
  );

  acceptInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.acceptInvitation),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ params, model }, workspaceId]) =>
        this.teamsService.updateTeamMember(params, model, workspaceId!).pipe(
          map(() => {
            const translation = this.translationService.instant('my_teams_joined_team_message') as string;
            const message = sprintf(translation, params.teamName);
            this.messageService.showSuccessSnackbar(message);
            return TeamsActions.acceptInvitationSuccess({ teamId: params.teamId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.acceptInvitationError());
          })
        )
      )
    )
  );

  inviteToTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.inviteToTeam),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ teamId, emails }, workspaceId]) =>
        this.teamsService.inviteToTeam(teamId, emails, workspaceId!).pipe(
          map(() => {
            this.messageService.showSuccessSnackbar('team_member_invites_sent_successful');
            return TeamsActions.inviteToTeamSuccess({ teamId });
          }),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.inviteToTeamError());
          })
        )
      )
    )
  );

  inviteToTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.inviteToTeamSuccess),
      map(({ teamId }) => TeamsActions.getTeam({ id: teamId }))
    )
  );

  updateTeamMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.leaveTeamSuccess, TeamsActions.deleteTeamSuccess, TeamsActions.createTeamSuccess),
      tap(() => void this.router.navigate(['/home/teams'])),
      map(() => TeamsActions.getTeams({ params: { filter: 'active', limit: 10, skip: 0 } }))
    )
  );

  createTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.createTeamSuccess),
      map(() => TeamsActions.getLeaders())
    )
  );

  getLeaders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getLeaders),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.teamsService.getLeaders(workspaceId!, { limit: 100, skip: 0 }).pipe(
          map((leaders) => TeamsActions.getLeadersSuccess({ leaders })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.getLeadersError());
          })
        )
      )
    )
  );

  getMoreLeaders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.getMoreLeaders),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ skip, limit }, workspaceId]) =>
        this.teamsService.getTeams(workspaceId!, { limit, skip }).pipe(
          map((leaders) => TeamsActions.getMoreLeadersSuccess({ leaders })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(TeamsActions.getMoreLeadersError());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private teamsService: TeamsService,
    private store$: Store<AppState>,
    private messageService: MessageService,
    private router: Router,
    private translationService: TranslateService,
    private storageService: StorageService
  ) {}
}
