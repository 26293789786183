import { AfterViewInit, Component, DoCheck, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/shared/shared-ui/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'vim-admin-panel-image',
  templateUrl: './admin-panel-image.component.html',
  styleUrls: ['./admin-panel-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AdminPanelImageComponent
    }
  ]
})
export class AdminPanelImageComponent implements ControlValueAccessor, AfterViewInit, DoCheck {
  image$ = new BehaviorSubject<string | null>(null);
  imageProperties$ = new BehaviorSubject<string | null>(null);
  disabled = false;
  touched = false;

  @Input() labelKey: string;
  @Input() invalid = false;

  @Input()
  public set initialImageUrl(v: string | null) {
    this.image$.next(v);
  }

  @Input() width: number;
  @Input() height: number;
  @Input() imageRequirementsDescription: string;
  @Output() imageDeleted = new EventEmitter();
  @Output() valueChange = new EventEmitter<File>();

  private ngControl: NgControl;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private translateService: TranslateService,
    private injector: Injector
  ) {}

  ngAfterViewInit() {
    try {
      this.ngControl = this.injector.get(NgControl);
    } catch (e) {
      /* empty */
    }
  }

  ngDoCheck() {
    if (this.ngControl?.control?.touched) {
      this.markAsTouched();
    }
  }

  get hasError() {
    return this.ngControl?.errors !== null;
  }

  public get isRequired(): boolean {
    return !!this.ngControl?.control?.hasValidator(Validators.required);
  }

  public get isInvalid(): boolean {
    return !!this.ngControl?.control?.invalid;
  }

  public get isDirty(): boolean {
    return !!this.ngControl?.control?.dirty;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function,no-unused-vars
  onChange = (file: File | null) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(image: File | string): void {
    if (!image) {
      return;
    }
    if (typeof image === 'string') {
      this.image$.next(image);
      return;
    }
    if (image) {
      this.valueChange.emit(image);
      this.readImage(image);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  addImage(image: File) {
    this.readImage(image);
    this.markAsTouched();
  }

  private readImage(file: File): void {
    this.valueChange.next(file);
    const fr = new FileReader();
    fr.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const { width, height } = image;
        if (this.width !== width || this.height !== height) {
          this.confirmationDialogService.open({
            title: this.translateService.instant('admin_panel_badge_editor_image_error_title'),
            message: this.translateService.instant('admin_panel_badge_editor_image_size_error'),
            hideCancelButton: true
          });
          this.deleteImage();
          return;
        }
        this.imageProperties$.next(`${width}px x ${height}px ${(file.size / 1024).toFixed(2)}KB`);
      };
      this.image$.next(e.target?.result as string);
      this.onChange(file);
    };
    fr.readAsDataURL(file);
  }

  deleteImage(): void {
    this.image$.next(null);
    this.imageProperties$.next(null);
    this.markAsTouched();
    this.onChange(null);
    this.imageDeleted.emit();
  }
}
