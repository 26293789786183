import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { CampaignsActions } from './campaigns.actions';
import { CampaignsService } from '../../client/campaigns/campaigns.service';
import { CampaignStatus } from '../../client/campaigns/campaignsData';
import { MessageService } from '../message.service';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';

@Injectable()
export class CampaignsEffects {
  getAllCampaignsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.getAllCampaignsData),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      filter(([, workspaceId]) => !!workspaceId),
      switchMap(([, workspaceId]) =>
        forkJoin([
          this.campaignsService.getCampaigns(CampaignStatus.active, workspaceId!),
          this.campaignsService.getCampaigns(CampaignStatus.past, workspaceId!),
          this.campaignsService.getCampaigns(CampaignStatus.future, workspaceId!)
        ]).pipe(
          map(([{ data: activeCampaigns }, { data: pastCampaigns }, { data: futureCampaigns }]) =>
            CampaignsActions.getAllCampaignsDataSuccess({
              activeCampaigns,
              pastCampaigns,
              futureCampaigns
            })
          ),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(CampaignsActions.getAllCampaignsDataError());
          })
        )
      )
    )
  );

  getCampaignUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.getCampaignUserData, CampaignsActions.getAllCampaignsDataSuccess),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.campaignsService.getCampaignUserData(workspaceId!).pipe(
          map((data) => CampaignsActions.getCampaignUserDataSuccess({ campaignUserData: data })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(CampaignsActions.getCampaignUserDataError());
          })
        )
      )
    )
  );

  getUserCampaignWhenUserHasOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.getCampaignUserDataSuccess),
      filter((userData) => !!userData.campaignUserData.campaign),
      map(({ campaignUserData }) => CampaignsActions.getUserCampaign({ campaignId: campaignUserData.campaign! }))
    )
  );

  getUserCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.getUserCampaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignId }, workspaceId]) =>
        forkJoin([
          this.campaignsService.getCampaign(campaignId, workspaceId!),
          this.campaignsService.getCampaignSharingData(campaignId, workspaceId!)
        ]).pipe(
          map(([campaignDetails, { data: campaignSharingData }]) =>
            CampaignsActions.getUserCampaignSuccess({ campaignDetails, campaignSharingData })
          ),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(CampaignsActions.getUserCampaignError());
          })
        )
      )
    )
  );

  joinCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.joinCampaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignId }, workspaceId]) =>
        this.campaignsService.joinCampaign(campaignId, workspaceId!).pipe(
          map(() => CampaignsActions.joinCampaignSuccess({ campaignId })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(CampaignsActions.joinCampaignError());
          })
        )
      )
    )
  );

  joinCampaignSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.joinCampaignSuccess),
      map(() => CampaignsActions.getCampaignUserData())
    )
  );

  leaveCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.leaveCampaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ campaignId }, workspaceId]) =>
        this.campaignsService.leaveCampaign(campaignId, workspaceId!).pipe(
          map(() => CampaignsActions.leaveCampaignSuccess()),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(CampaignsActions.leaveCampaignError());
          })
        )
      )
    )
  );

  leaveCampaignSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.leaveCampaignSuccess),
      map(() => CampaignsActions.getCampaignUserData())
    )
  );

  constructor(
    private actions$: Actions,
    private campaignsService: CampaignsService,
    private store$: Store<AppState>,
    private messageService: MessageService
  ) {}
}
