import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegalService } from '../../client/legal/legal.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vim-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfUseComponent {
  constructor(
    private route: ActivatedRoute,
    private legalService: LegalService
  ) {}

  termsOfUse$ = this.legalService
    .getCurrentTermsOfUse(this.route.snapshot.queryParamMap.get('lang') || 'en')
    .pipe(map((response) => response.content));
}
