import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BadgesReducer } from './badges.reducer';
import { BadgesEffects } from './badges.effects';
import { BadgesStore } from './badges-store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('achievements', BadgesReducer.reducer),
    EffectsModule.forFeature([BadgesEffects])
  ],
  providers: [BadgesStore]
})
export class BadgesStoreModule {}
