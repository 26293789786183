import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StravaActions } from './strava.actions';
import { StravaService } from '../../client/strava/strava.service';
import { AppSettingsService } from '../../ui/common/app-settings.service';

@Injectable()
export class StravaEffects {
  getStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StravaActions.getStatus),
      switchMap(() =>
        this.stravaService.getStatus().pipe(
          map((status) => StravaActions.getStatusSuccess({ status })),
          catchError(() => of(StravaActions.getStatusError()))
        )
      )
    )
  );

  getLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StravaActions.getLink),
      switchMap(() =>
        this.stravaService.getLink().pipe(
          tap(() => localStorage.setItem(AppSettingsService.FITNESS_PLATFORM_KEY, 'Strava')),
          map((response) => {
            window.location.href = response?.authorizationUrl;
            return StravaActions.getLinkSuccess(response);
          }),
          catchError(() => of(StravaActions.getLinkError()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private stravaService: StravaService
  ) {}
}
