import { createAction, props } from '@ngrx/store';
import { WorkspaceData } from 'src/app/client/workspaces/workspaces.model';

export namespace WorkspacesActions {
  export const getWorkspaces = createAction('[WORKSPACES] Get workspaces');
  export const getWorkspacesSuccess = createAction(
    '[WORKSPACES] Get workspaces success',
    props<{ workspaces: WorkspaceData[]; selectedWorkspaceId?: string }>()
  );
  export const getWorkspacesError = createAction('[WORKSPACES] Get workspaces error');

  export const selectWorkspace = createAction(
    '[WORKSPACES] Select workspace',
    props<{ workspaceId: string; returnUrl?: string }>()
  );
  export const selectWorkspaceSuccess = createAction(
    '[WORKSPACES] Select workspace success',
    props<{ workspace: WorkspaceData; returnUrl?: string }>()
  );
  export const selectWorkspaceError = createAction('[WORKSPACES] Select workspace error');
}
