import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { BadgesState } from './badges.state';

export namespace BadgesSelectors {
  const state = (state: AppState) => state.achievements;

  export const selectAvailableBadges = createSelector(state, (state: BadgesState) => state?.availableBadges);

  export const selectEarnedBadges = createSelector(state, (state: BadgesState) => state?.earnedBadges);

  export const selectIsLoading = createSelector(state, (state: BadgesState) => state?.loading);
}
