import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CampaignStatus } from '../../../client/campaigns/campaignsData';
import { CampaignBannerViewModel } from './campaign-banner.selectors';

@Component({
  selector: 'vim-campaign-banner',
  templateUrl: './campaign-banner.component.html',
  styleUrls: ['./campaign-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignBannerComponent {
  @Input() vm: CampaignBannerViewModel;

  constructor() {}

  get showBanner(): boolean {
    return !!this.vm && this.vm.userCampaign?.status !== CampaignStatus.active;
  }

  get bannerContent(): BannerContent {
    if (this.vm.userCampaign?.status === CampaignStatus.future) {
      return {
        title: 'banner_campaign_upcoming_user_present_title',
        message: 'banner_campaign_upcoming_user_present_message',
        date: this.vm.firstFutureCampaign?.startDate,
        link: '/home/campaigns',
        campaignId: this.vm.userCampaign._id
      };
    }
    if (this.vm.activeCampaignAvailable) {
      return {
        title: 'banner_campaign_active_user_absent_title',
        message: 'banner_campaign_active_user_absent_message',
        link: '/home/campaigns'
      };
    }
    if (this.vm.futureCampaignAvailable) {
      return {
        title: 'banner_campaign_upcoming_user_absent_title',
        message: 'banner_campaign_upcoming_user_absent_message',
        link: '/home/campaigns'
      };
    }
    return {
      title: 'banner_campaign_no_campaigns_title',
      message: 'banner_campaign_no_campaigns_message',
      link: '/home/campaigns'
    };
  }
}

interface BannerContent {
  title: string;
  message: string;
  link: string;
  campaignId?: string;
  date?: string;
}
