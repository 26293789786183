import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../services/converter.service';

@Pipe({
  name: 'metersPerSecondToKilometersPerHour'
})
export class MetersPerSecondToKilometersPerHourPipe implements PipeTransform {
  transform(value: number): number {
    return ConverterService.metersPerSecondToKilometersPerHour(value);
  }
}
