import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GarminActions } from './garmin.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GarminService } from '../../client/garmin/garmin.service';
import { of } from 'rxjs';
import { AppSettingsService } from '../../ui/common/app-settings.service';

@Injectable()
export class GarminEffects {
  getStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GarminActions.getStatus),
      switchMap(() =>
        this.garminService.getStatus().pipe(
          map((status) => GarminActions.getStatusSuccess({ status })),
          catchError(() => of(GarminActions.getStatusError()))
        )
      )
    )
  );

  getLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GarminActions.getLink),
      switchMap(() =>
        this.garminService.getLink().pipe(
          tap(() => localStorage.setItem(AppSettingsService.FITNESS_PLATFORM_KEY, 'Garmin')),
          map((response) => {
            window.location.href = response?.authorizationUrl;
            return GarminActions.getLinkSuccess(response);
          }),
          catchError(() => of(GarminActions.getLinkError()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private garminService: GarminService
  ) {}
}
