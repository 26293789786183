<div class='container' fxLayout='row' fxLayoutAlign='start center'>
  <mat-icon
    *ngIf='data.iconLeft'
    [svgIcon]='data.iconLeft'
    class='icon icon-center icon-left'
    [ngClass]="{
      'icon-left-red': data.snackBarColor === snackBarColor.red,
      'icon-left-green': data.snackBarColor === snackBarColor.green,
      'icon-left-black': data.snackBarColor === snackBarColor.yellow
    }"
  ></mat-icon>
  <div class='message' [ngClass]="data.snackBarColor === snackBarColor.yellow ? 'content-black': 'content-white'">
    {{data.message}}
  </div>
  <button
    *ngIf='data.buttonLabel && data.snackBarColor !== snackBarColor.yellow'
    class='button'
    mat-button
    color='tertiary'
    (click)='this.snackBar.dismiss(); data.close()'
    vim-button
    id='snackbarClose'
  >
    {{ data.buttonLabel }}
  </button>
  <button
    *ngIf='data.buttonLabel && data.snackBarColor === snackBarColor.yellow'
    class='button'
    mat-stroked-button
    color='quarternary'
    (click)='this.snackBar.dismiss(); data.close()'
    vim-button
    id='snackbarCloseYellow'
  >
    {{ data.buttonLabel | translate }}
  </button>
  <mat-icon
    [svgIcon]='data.iconRight'
    class='icon icon-right' fxLayout fxLayoutAlign='center center'
    [ngClass]="data.snackBarColor === snackBarColor.yellow ? 'icon-right-black':'icon-right-white'
    "
    (click)='this.snackBar.dismiss(); data.close()'
  ></mat-icon>
</div>
