import { Language, SupportedLanguages } from '../../../shared/shared-ui/models/language.model';
import { AdminItemTarget } from './common.models';
import { InsightDetailsResponse } from '../../../client/admin-panel/models/admin-panel-insight.model';

export interface InsightDetailsTranslatedContent {
  language: Language;
  content: string;
  title: string;
  subtitle: string;
  buttonLabel: string;
}

export class InsightDetails {
  id?: string;
  country?: string | null;
  languages: Language[];
  campaignId?: string | null;
  insightTarget: AdminItemTarget;
  translatedContent: InsightDetailsTranslatedContent[];
  backgroundColor: string;
  backgroundColorVariant: string;
  image?: File | string;

  constructor(init?: Partial<InsightDetails>) {
    if (init) {
      Object.assign(this, init);
    }
  }

  static fromView(data: Partial<InsightDetails>) {
    return new InsightDetails(data);
  }

  static fromJSON(response: InsightDetailsResponse): InsightDetails {
    const { _id: id, image, country, campaignId, backgroundColor, backgroundColorVariant } = response;
    const details = new InsightDetails({ id, image, country, campaignId, backgroundColor, backgroundColorVariant });
    details.insightTarget = campaignId ? AdminItemTarget.campaign : AdminItemTarget.workspace;
    details.languages = response.title.map((title) => ({
      shortname: title.language,
      name: SupportedLanguages.find((ln) => ln.shortname === title.language)?.name || ''
    }));
    details.translatedContent = response.title.map((title, ind) => ({
      title: title.value,
      language: SupportedLanguages.find((ln) => ln.shortname === title.language)!,
      content: response.content[ind].value,
      subtitle: response.subtitle[ind].value,
      buttonLabel: response.buttonLabel[ind].value
    }));
    return details;
  }
}
