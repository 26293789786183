<div fxLayout fxLayoutAlign="space-between center" [style.margin-bottom.px]="32">
  <div class="DisplayRegularHeading-6">{{ 'delete_account_title' | translate }}</div>
  <button class="close-button" (click)="dialogRef.close()" mat-icon-button tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="HeadingHeading-4 color-base-90" [style.margin-bottom.px]="16">
  {{ 'delete_account_header' | translate }}
</div>
<div class="LabelLabel-1 color-base-90" [style.margin-bottom.px]="16">
  {{ 'delete_account_subtitle' | translate }}
</div>
<div class="ParagraphParagraph-1 color-base-90" [style.margin-bottom.px]="62">
  {{ 'delete_account_explanation' | translate }}
</div>
<div fxLayout fxLayoutAlign="center" [style.margin-bottom.px]="16">
  <vim-button (click)="removeMyData()" id="delete-account-button" class="secondary" [style.width.%]="100">{{
    'common_remove' | translate
    }}</vim-button>
</div>
<div fxLayout fxLayoutAlign="center">
  <vim-button (click)="dialogRef.close()" class="secondary" [style.width.%]="100">{{
    'common_no' | translate
    }}</vim-button>
</div>
