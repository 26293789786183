import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';
import { WorkspaceData } from './workspaces.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  constructor(private httpService: HttpService) {}

  getWorkspaces(): Observable<WorkspaceData[]> {
    return this.httpService.get('/workspaces', null, environment.baseUrl);
  }
}
