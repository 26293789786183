<div class="country-input">
  <mat-form-field appearance="outline">
    <mat-label>{{'profile_country'| translate}}</mat-label>
    <input type="text" #countryInput matInput [formControl]="controlCountry" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="countrySelected($event)">
      <mat-option *ngFor="let option of matchingCountries | async" [value]="option.name">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
