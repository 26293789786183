import { Component, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DownloadPictureComponent } from '../../../../shared/shared-ui/components/download-picture/download-picture.component';
import { SharedPictureData } from '../../../../shared/shared-ui/components/shared-picture/shared-picture.component';
import { ActivitiesStore } from '../../../../store/activities/activities.store';

@Component({
  selector: 'vim-social-media-share-download',
  templateUrl: './social-media-share-download.component.html',
  styleUrls: ['./social-media-share-download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialMediaShareDownloadComponent {
  @ViewChild('picture') picture: ElementRef;

  @ViewChild(DownloadPictureComponent) downloadPictureCmp: DownloadPictureComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SharedPictureData,
    public dialogRef: MatDialogRef<SharedPictureData>,
    public activitiesStore: ActivitiesStore
  ) {}

  selectImage(selectedImageIndex: number): void {
    this.activitiesStore.selectActivityImage(selectedImageIndex);
  }

  download(): Promise<any> {
    return this.downloadPictureCmp.download().then(() => this.dialogRef.close());
  }
}
