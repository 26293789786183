import { Pipe, PipeTransform } from '@angular/core';
import { vsprintf } from 'printj';

@Pipe({
  name: 'sprintf',
  pure: false
})
export class SprintfPipe implements PipeTransform {
  transform(value: string, ...params: any[]): string {
    return vsprintf(value, params);
  }
}
