import { BREAKPOINT } from '@angular/flex-layout';

const PRINT_BREAKPOINTS = [
  {
    alias: 'xs',
    suffix: 'Xs',
    mediaQuery: 'screen and (min-width: 0px) and (max-width: 687px)',
    overlapping: false,
    priority: 1000 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'sm',
    suffix: 'Sm',
    mediaQuery: 'screen and (min-width: 688px) and (max-width: 1023px)',
    overlapping: false,
    priority: 900 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'md',
    suffix: 'Md',
    mediaQuery: 'screen and (min-width: 1024px) and (max-width: 1439px)',
    overlapping: false,
    priority: 800 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lg',
    suffix: 'Lg',
    mediaQuery: 'screen and (min-width: 1440px) and (max-width: 1919px)',
    overlapping: false,
    priority: 700 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'xlg',
    suffix: 'Xlg',
    mediaQuery: 'screen and (min-width: 1920px) and (max-width: 2559px)',
    overlapping: false,
    priority: 600 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'xxlg',
    suffix: 'Xxlg',
    mediaQuery: 'screen and (min-width: 2560px)',
    overlapping: false,
    priority: 500 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'gt-xs',
    suffix: 'Gt-xs',
    mediaQuery: 'screen and (min-width: 688px)',
    overlapping: true,
    priority: -950 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'gt-sm',
    suffix: 'Gt-sm',
    mediaQuery: 'screen and (min-width: 1024px)',
    overlapping: true,
    priority: -850 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'gt-md',
    suffix: 'Gt-md',
    mediaQuery: 'screen and (min-width: 1440px)',
    overlapping: true,
    priority: -750 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'gt-lg',
    suffix: 'Gt-lg',
    mediaQuery: 'screen and (min-width: 1920px)',
    overlapping: true,
    priority: -650 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'gt-xlg',
    suffix: 'Gt-xlg',
    mediaQuery: 'screen and (min-width: 2560px)',
    overlapping: true,
    priority: -550 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lt-sm',
    suffix: 'Lt-sm',
    mediaQuery: 'screen and (max-width: 687px)',
    overlapping: true,
    priority: 950 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lt-md',
    suffix: 'Lt-md',
    mediaQuery: 'screen and (max-width: 1023px)',
    overlapping: true,
    priority: 850 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lt-lg',
    suffix: 'Lt-lg',
    mediaQuery: 'screen and (max-width: 1439px)',
    overlapping: true,
    priority: 750 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lt-xlg',
    suffix: 'Lt-xlg',
    mediaQuery: 'screen and (max-width: 1919px)',
    overlapping: true,
    priority: 650 // Needed if overriding the default print breakpoint
  },
  {
    alias: 'lt-xxlg',
    suffix: 'Lt-xxlg',
    mediaQuery: 'screen and (max-width: 2559px)',
    overlapping: true,
    priority: 550 // Needed if overriding the default print breakpoint
  }
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: PRINT_BREAKPOINTS,
  multi: true
};
