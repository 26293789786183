import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CustomBreakPointsProvider } from './custom-breakpoints';

export const MATERIAL_MODULES = [
  CommonModule,
  MatButtonModule,
  MatMenuModule,
  MatDialogModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatTabsModule,
  MatIconModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatChipsModule,
  MatExpansionModule,
  MatInputModule,
  MatDividerModule,
  MatOptionModule,
  MatFormFieldModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule,
  MatButtonToggleModule
];

@NgModule({
  declarations: [],
  imports: [FormsModule, CommonModule, FlexLayoutModule, ...MATERIAL_MODULES, TranslateModule, IvyCarouselModule],
  exports: [FormsModule, CommonModule, FlexLayoutModule, ...MATERIAL_MODULES, TranslateModule, IvyCarouselModule],
  providers: [
    CustomBreakPointsProvider,
    { provide: MatDialogRef, useValue: {} },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ]
})
export class SharedDependenciesModule {}
