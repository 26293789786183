<div class='team-summary-container' ngClass.gt-sm='team-summary-container-desktop'
     ngClass.lt-md='team-summary-container-mobile' fxLayout fxLayoutAlign='space-between'>
  <div fxLayoutAlign.lt-md='space-between flex-start' fxLayout='column' class='trees-summary'>
    <p fxFlex='80px' class='trees-count'>{{ team.points | roundDown:1}}</p>
    <p fxFlex='40px' class='HeadingHeading-4 trees-description'>{{campaignUnit | unitToKey | plural:team.points |
      translate}}</p>
    <div fxFlex="auto"></div>
    <vim-campaign-name fxHide.xs></vim-campaign-name>
  </div>
  <div class='team-additional-information' fxLayoutAlign='space-between flex-end' fxLayout='column'>
    <div fxFlex='auto' fxHide.xs></div>
    <div class='icons-container' ngClass.gt-sm='icons-container-desktop' ngClass.lt-md='icons-container-mobile'
         fxLayout='row' fxLayout.xs="column"
         fxLayoutAlign="end center">
      <vim-campaign-name fxHide.gt-xs></vim-campaign-name>
      <div style="flex-grow:1;"></div>
      <div fxLayout>
        <div fxLayoutAlign='center center' fxLayout='row' fxLayoutGap='5px'>
          <mat-icon svgIcon='teams'></mat-icon>
          <p class="LabelLabel-2">{{ team.membersCount}}</p>
        </div>
        <div fxLayoutAlign='center center' fxLayout='row' fxLayoutGap='5px'>
          <mat-icon svgIcon='place'></mat-icon>
          <p class="LabelLabel-2">{{ team.rankingPlace }}</p>
        </div>
        <vim-privacy-info [private]="team.privacy === teamPrivacy.closed"></vim-privacy-info>
      </div>
    </div>
  </div>
</div>
