import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from './confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private matDialog: MatDialog) {}

  open(data: ConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent, boolean> {
    return this.matDialog.open(ConfirmationDialogComponent, { data });
  }
}
