import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vim-delete-team',
  templateUrl: './delete-team.component.html',
  styleUrls: ['./delete-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteTeamComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeleteTeamDialogData,
    public dialogRef: MatDialogRef<any>,
    private teamsStore: TeamsStore
  ) {}

  ngOnInit() {
    this.teamName = new UntypedFormControl('', [Validators.required, this.validateStringEquality.bind(this)]);
  }

  teamName: UntypedFormControl;

  deleteTeam(): void {
    const { teamId, teamName } = this.data;
    this.teamsStore.deleteTeam(teamId, teamName);
    this.dialogRef.close(true);
  }

  validateStringEquality(formControl: UntypedFormControl) {
    return formControl.value !== this.data?.teamName
      ? {
          validateName: {
            valid: false
          }
        }
      : null;
  }
}

export interface DeleteTeamDialogData {
  teamId: string;
  teamName: string;
}
