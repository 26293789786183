import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './ui/login/login.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared';
import { AbstractSecurityStorage, AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { CustomSnackBarComponent } from './shared/shared-ui/components/custom-snack-bar/custom-snack-bar.component';
import { LiveTickerStoreModule } from './store/live-ticker/live-ticker-store.module';
import { AppStoreModule } from './store/store.module';
import { SharedUiModule } from './shared/shared-ui/shared-ui.module';
import { SocialWallWrapperComponent } from './ui/mobile-wrappers/social-wall-wrapper.component';
import { WhatsNewModule } from './ui/whats-new/whats-new.module';
import { PrivacyPolicyComponent } from './notAuthorized/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './notAuthorized/terms-of-use/terms-of-use.component';
import { JoinCampaignComponent } from './ui/join-campaign/join-campaign.component';
import { StoreModule } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { AuthorizedComponent } from './ui/authorized/authorized.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ViErrorHandler } from './error-handler';
import { AuthStorageService } from './ui/common/auth-storage.service';
import { UseMobileAppBannerComponent } from './ui/common/use-mobile-app-banner/use-mobile-app-banner.component';
import { RedirectComponent } from './redirect/redirect.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime().toString());
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CustomSnackBarComponent,
    SocialWallWrapperComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    JoinCampaignComponent,
    AuthorizedComponent,
    UseMobileAppBannerComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    LiveTickerStoreModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    SharedUiModule,
    AuthModule.forRoot({
      config: {
        authority: environment.oauthAuthorityUrl,
        authWellknownEndpointUrl: environment.authWellknownEndpointUrl,
        redirectUrl: window.location.origin + '/authorized',
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.oauthClientId,
        scope: 'openid offline_access ' + environment.scope,
        autoUserInfo: false,
        silentRenew: true,
        useRefreshToken: true,
        responseType: 'code',
        ignoreNonceAfterRefresh: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        logLevel: environment.production ? LogLevel.None : LogLevel.Warn
      }
    }),
    WhatsNewModule,
    StoreModule.forRoot({}, {}),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    { provide: ErrorHandler, useClass: ViErrorHandler },
    { provide: AbstractSecurityStorage, useClass: AuthStorageService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
