<div *ngIf="campaignDetails.type === campaignType.time else pointsLabel" fxLayout fxLayoutAlign="space-between"
     [style.margin-bottom.px]="10">
  <div>
    <span
      class="LabelLabel-5">{{campaignTime}}&nbsp;{{'unit_day'| plural: campaignTime | translate | uppercase}}
    </span>
    <span
      class="LabelLabel-5 color-base-60">&nbsp;/&nbsp;{{campaignDuration}}&nbsp;{{'unit_day' | plural:campaignDuration | translate |uppercase}}
    </span>
  </div>
  <span class="LabelLabel-5 color-base-60">{{campaignStatusKey | translate | uppercase}}</span>
</div>
<ng-template #pointsLabel>
  <div fxLayout fxLayoutAlign="space-between" [style.margin-bottom.px]="10">
    <div>
      <span
        class="LabelLabel-5">{{campaignDetails.points | roundDown}}&nbsp;{{campaignDetails.unit | unitToKey | plural: campaignDetails.points | translate}}
      </span>
      <span
        class="color-base-60 LabelLabel-5">&nbsp;/&nbsp;{{campaignDetails.maxPoints | roundDown}}&nbsp;{{campaignDetails.unit | unitToKey | plural: campaignDetails.maxPoints | translate}}
      </span>
    </div>
    <span class="LabelLabel-5 color-base-60">{{campaignStatusKey | translate | uppercase}}</span>
  </div>
</ng-template>
<vim-progress-bar [progress]="progressBarValue"></vim-progress-bar>
