import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vim-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogTemplateComponent {
  @Input() title: string;
  @Output() closeDialog = new EventEmitter();

  constructor() {}
}
