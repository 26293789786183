import { Action, createReducer, on } from '@ngrx/store';
import { TeamsActions } from './teams.actions';
import { CreateTeamResult, TeamsLeaderboardData, TeamsState } from './teams.state';

export namespace TeamsReducer {
  export const initialState: TeamsState = {
    myTeams: null,
    currentTeam: null,
    currentTeamActiveMembers: [],
    currentUser: undefined,
    currentTeamRemovedMembers: [],
    currentTeamActiveMembersCount: 0,
    leaders: null,
    createTeamStatus: { result: CreateTeamResult.unknown, name: undefined },
    loadingStatuses: {
      getTeams: { isLoaded: null, isLoading: null },
      getTeam: { isLoaded: null, isLoading: null },
      getTeamMembers: { isLoaded: null, isLoading: null },
      getTeamMoreMembers: { isLoaded: null, isLoading: null },
      getTeamRemovedMembers: { isLoaded: null, isLoading: null },
      createTeam: { isLoaded: null, isLoading: null },
      updateTeam: { isLoaded: null, isLoading: null },
      removeTeamMember: { isLoaded: null, isLoading: null },
      reInviteTeamMember: { isLoaded: null, isLoading: null },
      leaveTeam: { isLoaded: null, isLoading: null },
      leaveTeamAsAdmin: { isLoaded: null, isLoading: null },
      acceptInvitation: { isLoaded: null, isLoading: null },
      inviteToTeam: { isLoaded: null, isLoading: null },
      deleteTeam: { isLoaded: null, isLoading: null },
      getLeaders: { isLoaded: null, isLoading: null },
      getMoreLeaders: { isLoaded: null, isLoading: null }
    }
  };

  export const teamsReducer = createReducer(
    initialState,
    on(TeamsActions.getTeams, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeams: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getTeamsSuccess, (state, { teams }) => ({
      ...state,
      myTeams: teams,
      loadingStatuses: { ...state.loadingStatuses, getTeams: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.getTeamsError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeams: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.getTeamMembers, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeamMembers: { isLoaded: false, isLoading: true } }
    })),
    on(
      TeamsActions.getTeamMembersSuccess,
      (state, { teamMembersData: { data: teamMembers, myPosition, total: activeMembersCount } }) => ({
        ...state,
        currentTeamActiveMembers: teamMembers,
        currentTeamActiveMembersCount: activeMembersCount,
        currentUser: myPosition
          ? teamMembers.find(
              (member) =>
                member.rankingPlace === myPosition.rankingPlace &&
                member.name.firstName === myPosition.name.firstName &&
                member.name.familyName === myPosition.name.familyName
            )
          : undefined,
        loadingStatuses: { ...state.loadingStatuses, getTeamMembers: { isLoaded: true, isLoading: false } }
      })
    ),
    on(TeamsActions.getTeamMembersError, TeamsActions.getTeamMoreMembersError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeamMembers: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.getTeamMoreMembers, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeamMoreMembers: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getTeamMoreMembersSuccess, (state, { teamMembersData: { data: nextTeamMembers } }) => ({
      ...state,
      currentTeamActiveMembers: [...state.currentTeamActiveMembers, ...nextTeamMembers],
      loadingStatuses: { ...state.loadingStatuses, getTeamMoreMembers: { isLoaded: true, isLoading: false } }
    })),

    on(TeamsActions.getTeamRemovedMembers, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeamRemovedMembers: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getTeamRemovedMembersSuccess, (state, { removedMembers }) => ({
      ...state,
      currentTeamRemovedMembers: removedMembers,
      loadingStatuses: { ...state.loadingStatuses, getTeamRemovedMembers: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.getTeamRemovedMembersError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeamRemovedMembers: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.deleteTeam, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, deleteTeam: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.deleteTeamSuccess, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, deleteTeam: { isLoaded: true, isLoading: false } }
    })),

    on(TeamsActions.deleteTeamError, (state) => ({
      ...state
    })),
    on(TeamsActions.getTeam, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeam: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getTeamSuccess, (state, { team }) => ({
      ...state,
      currentTeam: team,
      loadingStatuses: { ...state.loadingStatuses, getTeam: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.getTeamError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getTeam: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.updateTeam, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, updateTeam: { isLoaded: false, isLoading: true } }
    })),

    on(TeamsActions.updateTeamSuccess, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, updateTeam: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.updateTeamError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, updateTeam: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.getLeadersSuccess, (state, { leaders }) => ({
      ...state,
      leaders: {
        data: leaders.data.map((leader) => ({
          teamName: leader.name,
          points: leader.points,
          rankingPlace: leader.rankingPlace,
          unit: leader.unit,
          id: leader._id
        })),
        total: leaders.total
      },
      loadingStatuses: { ...state.loadingStatuses, getLeaders: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.getLeadersError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getLeaders: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.getLeaders, (state) => ({
      ...state,
      leaders: { ...state.leaders } as TeamsLeaderboardData,
      loadingStatuses: { ...state.loadingStatuses, getLeaders: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getMoreLeaders, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getMoreLeaders: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getMoreLeadersSuccess, (state, { leaders }) => ({
      ...state,
      leaders: {
        ...state.leaders!,
        data: [
          ...state.leaders!.data,
          ...leaders.data.map((leader) => ({
            teamName: leader.name,
            points: leader.points,
            rankingPlace: leader.rankingPlace,
            unit: leader.unit,
            id: leader._id
          }))
        ]
      },
      loadingStatuses: { ...state.loadingStatuses, getMoreLeaders: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.getMoreLeadersError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, getMoreLeaders: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.removeTeamMember, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, removeTeamMember: { isLoaded: false, isLoading: true } }
    })),

    on(TeamsActions.removeTeamMemberError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, removeTeamMember: { isLoaded: false, isLoading: false } }
    })),

    on(TeamsActions.removeTeamMemberSuccess, (state, { userId }) => {
      const currentTeamMembers = state.currentTeamActiveMembers;
      if (!currentTeamMembers) {
        return {
          ...state,
          loadingStatuses: { ...state.loadingStatuses, removeTeamMember: { isLoaded: true, isLoading: false } }
        };
      }
      const memberToRemove = currentTeamMembers.find((member) => member._id === userId);
      if (!memberToRemove) {
        return {
          ...state,
          loadingStatuses: { ...state.loadingStatuses, removeTeamMember: { isLoaded: true, isLoading: false } }
        };
      }

      const newActiveMembers = currentTeamMembers.filter((member) => member._id !== userId);

      const newState: TeamsState = {
        ...state,
        currentTeamActiveMembers: newActiveMembers,
        currentTeamActiveMembersCount: newActiveMembers.length,
        currentTeamRemovedMembers: [...state.currentTeamRemovedMembers, memberToRemove],
        loadingStatuses: { ...state.loadingStatuses, removeTeamMember: { isLoaded: true, isLoading: false } }
      };
      return newState;
    }),
    on(TeamsActions.acceptInvitation, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, acceptInvitation: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.acceptInvitationSuccess, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, acceptInvitation: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.acceptInvitationError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, acceptInvitation: { isLoaded: false, isLoading: false } }
    })),
    on(TeamsActions.inviteToTeam, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, inviteToTeam: { isLoaded: false, isLoading: true } }
    })),
    on(TeamsActions.inviteToTeamSuccess, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, inviteToTeam: { isLoaded: true, isLoading: false } }
    })),
    on(TeamsActions.inviteToTeamError, (state) => ({
      ...state,
      loadingStatuses: { ...state.loadingStatuses, inviteToTeam: { isLoaded: false, isLoading: false } }
    })),
    on(TeamsActions.createTeamError, (state) => ({
      ...state,
      createTeamStatus: { result: CreateTeamResult.error }
    })),
    on(TeamsActions.createTeamSuccess, (state, action) => ({
      ...state,
      createTeamStatus: { result: CreateTeamResult.success, name: action.teamName }
    }))
  );

  export function reducer(state: TeamsState, action: Action): TeamsState {
    return teamsReducer(state, action);
  }
}
