<ng-container *ngIf='vm$ | async as vm'>
  <ng-container *ngIf="vm.isLoading === false">
    <div *ngIf='teams?.length > 0; else emptyState'>
      <ng-container *ngFor='let team of teams; let last = last'>
        <vim-team-overview [team]='team' [campaignUnit]="vm.campaignUnit"></vim-team-overview>
        <mat-divider *ngIf='!last'></mat-divider>
      </ng-container>
      <div fxLayout='row' fxLayoutAlign.gt-sm='end center'
           fxLayoutAlign.lt-md='end center'>
        <button
          *ngIf="vm.createTeamEnabled"
          ngClass.lt-md='mobile-create-button'
          ngClass.gt-sm='desktop-create-button'
          mat-button
          color='primary'
          vim-button
          (click)='createTeam.next()'
          id='createTeam'
        >{{'my_teams_empty_state_btn' | translate}}&nbsp;&#8594;
        </button>
      </div>
    </div>
    <ng-template #emptyState>
      <vim-my-teams-empty-state [createTeamEnabled]="vm.createTeamEnabled"
                                [multipleTeamsEnabled]="vm.multipleTeamsEnabled"
                                [userInActiveCampaign]="vm.userInActiveCampaign"
                                (createTeamClicked)="createTeam.next()"
                                (joinTeamClicked)="goToLeaderBoard.emit()"></vim-my-teams-empty-state>
    </ng-template>
  </ng-container>
</ng-container>
