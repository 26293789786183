import { createAction, props } from '@ngrx/store';
import { CampaignWithStatistics, UpcomingCampaign } from '../../client/admin-panel/admin-panel.data';
import { AdminData } from '../../client/admin-panel/models/admin-panel.model';
import { BadgeDetails } from './models/badge-details.model';
import { InsightDetails } from './models/insight-details.model';
import {
  CampaignDetailsResponse,
  CampaignItem,
  CampaignItemLightResponse
} from '../../client/admin-panel/models/admin-panel-campaign.model';
import { InsightDetailsResponse, InsightItem } from '../../client/admin-panel/models/admin-panel-insight.model';
import { BadgeDetailsResponse, BadgeItemResponse } from '../../client/admin-panel/models/admin-panel-badge.model';
import { MarketingNotificationPost } from '../../client/admin-panel/models/admin-panel-marketing-notification.model';
import { CampaignDetailsEditorModel } from './models/campaign-details.model';
import { CampaignUnit } from '../../client/campaigns/campaignsData';

export namespace AdminPanelActions {
  export const getUnits = createAction('[ADMIN_PANEL] Get units');
  export const getUnitsSuccess = createAction('[ADMIN_PANEL] Get units success', props<{ units: CampaignUnit[] }>());
  export const getUnitsError = createAction('[ADMIN_PANEL] Get units error');
  export const getBadges = createAction('[ADMIN_PANEL] Get badges');
  export const getBadgesSuccess = createAction(
    '[ADMIN_PANEL] Get badges success',
    props<{ badges: BadgeItemResponse[] }>()
  );
  export const getBadgesError = createAction('[ADMIN_PANEL] Get badges error');

  export const getCampaignsData = createAction('[ADMIN_PANEL] Get campaigns data');
  export const getCampaignsDataSuccess = createAction(
    '[ADMIN_PANEL] Get campaigns data success',
    props<{
      upcomingCampaigns: UpcomingCampaign[];
      activeCampaigns: CampaignWithStatistics[];
      finishedCampaigns: CampaignWithStatistics[];
    }>()
  );
  export const getCampaignsDataError = createAction('[ADMIN_PANEL] Get campaigns data error');

  export const getCampaignsLight = createAction('[ADMIN_PANEL] Get campaigns light');
  export const getCampaignsLightSuccess = createAction(
    '[ADMIN_PANEL] Get campaigns light success',
    props<{ campaigns: CampaignItemLightResponse[] }>()
  );
  export const getCampaignsLightError = createAction('[ADMIN_PANEL] Get campaign lights error');
  export const getCampaigns = createAction('[ADMIN_PANEL] Get campaigns');
  export const getCampaignsSuccess = createAction(
    '[ADMIN_PANEL] Get campaigns success',
    props<{ campaigns: CampaignItem[] }>()
  );
  export const getCampaignsError = createAction('[ADMIN_PANEL] Get campaign error');

  export const getCampaignDetails = createAction('[ADMIN_PANEL] Get campaign details', props<{ campaignId: string }>());
  export const getCampaignDetailsSuccess = createAction(
    '[ADMIN_PANEL] Get campaign details success',
    props<{ campaignDetails: CampaignDetailsResponse }>()
  );
  export const getCampaignDetailsError = createAction('[ADMIN_PANEL] Get campaign details error');

  export const getAdminData = createAction('[ADMIN_PANEL] Get admin data');
  export const getAdminDataSuccess = createAction(
    '[ADMIN_PANEL] Get admin data success',
    props<{ adminData: AdminData }>()
  );
  export const getAdminDataError = createAction('[ADMIN_PANEL] Get admin data error');

  export const getActivities = createAction('[ADMIN_PANEL] Get activities');
  export const getActivitiesSuccess = createAction(
    '[ADMIN_PANEL] Get activities success',
    props<{ activities: string[] }>()
  );
  export const getActivitiesError = createAction('[ADMIN_PANEL] Get activities error');

  export const createMarketingNotification = createAction(
    '[ADMIN_PANEL] Create marketing notification',
    props<{ notification: MarketingNotificationPost }>()
  );
  export const createMarketingNotificationSuccess = createAction('[ADMIN_PANEL] Create marketing notification success');
  export const createMarketingNotificationError = createAction('[ADMIN_PANEL] Create marketing notification error');

  export const createBadge = createAction('[ADMIN_PANEL] Create Badge', props<{ badgeDetails: BadgeDetails }>());
  export const createBadgeSuccess = createAction('[ADMIN_PANEL] Create badge success');
  export const createBadgeError = createAction('[ADMIN_PANEL] Create badge error');
  export const updateBadge = createAction(
    '[ADMIN_PANEL] Update badge',
    props<{ badgeDetails: Partial<BadgeDetails>; badgeId: string }>()
  );
  export const updateBadgeSuccess = createAction('[ADMIN_PANEL] Update badge success');
  export const updateBadgeError = createAction('[ADMIN_PANEL] Update badge error');
  export const getBadgeDetails = createAction('[ADMIN_PANEL] Get badge details', props<{ badgeId: string }>());
  export const getBadgeDetailsSuccess = createAction(
    '[ADMIN_PANEL] Get badge details success',
    props<{ badgeDetails: BadgeDetailsResponse }>()
  );
  export const getBadgeDetailsError = createAction('[ADMIN_PANEL] Get badge details error');
  export const deleteBadge = createAction('[ADMIN_PANEL] Delete badge', props<{ badgeId: string }>());
  export const deleteBadgeSuccess = createAction('[ADMIN_PANEL] Delete badge success', props<{ badgeId: string }>());
  export const deleteBadgeError = createAction('[ADMIN_PANEL] Delete badge error');

  export const deleteCampaign = createAction('[ADMIN_PANEL] Delete campaign', props<{ campaignId: string }>());
  export const deleteCampaignSuccess = createAction(
    '[ADMIN_PANEL] Delete campaign success',
    props<{ campaignId: string }>()
  );
  export const deleteCampaignError = createAction('[ADMIN_PANEL] Delete campaign error');

  export const deleteCampaignDraft = createAction(
    '[ADMIN_PANEL] Delete campaign draft',
    props<{ draftId: string; campaignCreated?: boolean }>()
  );
  export const deleteCampaignDraftSuccess = createAction(
    '[ADMIN_PANEL] Delete campaign draft success',
    props<{ draftId: string; campaignCreated?: boolean }>()
  );
  export const deleteCampaignDraftError = createAction('[ADMIN_PANEL] Delete campaign draft error');

  export const createInsight = createAction(
    '[ADMIN_PANEL] Create insight',
    props<{ insightDetails: InsightDetails }>()
  );
  export const createInsightSuccess = createAction('[ADMIN_PANEL] Create insight success');
  export const createInsightError = createAction('[ADMIN_PANEL] Create insight error');
  export const updateInsight = createAction(
    '[ADMIN_PANEL] Update insight',
    props<{ insightDetails: Partial<InsightDetails>; insightId: string }>()
  );
  export const updateInsightSuccess = createAction('[ADMIN_PANEL] Update insight success');
  export const updateInsightError = createAction('[ADMIN_PANEL] Update insight error');
  export const deleteInsight = createAction('[ADMIN_PANEL] Delete insight', props<{ insightId: string }>());
  export const deleteInsightSuccess = createAction(
    '[ADMIN_PANEL] Delete insight success',
    props<{ insightId: string }>()
  );
  export const deleteInsightError = createAction('[ADMIN_PANEL] Delete insight error');

  export const getInsights = createAction('[ADMIN_PANEL] Get insights');
  export const getInsightsSuccess = createAction(
    '[ADMIN_PANEL] Get insights success',
    props<{ insights: InsightItem[] }>()
  );
  export const getInsightsError = createAction('[ADMIN_PANEL] Get insights error');
  export const getInsightDetails = createAction('[ADMIN_PANEL] Get insight details', props<{ insightId: string }>());
  export const getInsightDetailsSuccess = createAction(
    '[ADMIN_PANEL] Get insight details success',
    props<{ insightDetails: InsightDetailsResponse }>()
  );
  export const getInsightDetailsError = createAction('[ADMIN_PANEL] Get insight details error');

  export const createCampaign = createAction(
    '[ADMIN_PANEL] Create campaign',
    props<{ campaignDetails: CampaignDetailsEditorModel; draftId?: string }>()
  );
  export const createCampaignSuccess = createAction(
    '[ADMIN_PANEL] Create campaign success',
    props<{ draftId?: string }>()
  );
  export const createCampaignError = createAction('[ADMIN_PANEL] Create campaign error');

  export const createCampaignFromDraft = createAction(
    '[ADMIN_PANEL] Create campaign from draft',
    props<{ campaignDetails: CampaignDetailsEditorModel }>()
  );
  export const createCampaignFromDraftSuccess = createAction('[ADMIN_PANEL] Create campaign from draft success');
  export const createCampaignFromDraftError = createAction('[ADMIN_PANEL] Create campaign from draft error');

  export const createCampaignDraft = createAction(
    '[ADMIN_PANEL] Create campaign draft',
    props<{ campaignDetails: Partial<CampaignDetailsEditorModel> }>()
  );
  export const createCampaignDraftSuccess = createAction('[ADMIN_PANEL] Create campaign draft success');
  export const createCampaignDraftError = createAction('[ADMIN_PANEL] Create campaign draft error');

  export const updateCampaignDraft = createAction(
    '[ADMIN_PANEL] Update campaign draft',
    props<{ campaignDetails: Partial<CampaignDetailsEditorModel>; campaignId: string }>()
  );
  export const updateCampaignDraftSuccess = createAction('[ADMIN_PANEL] Update campaign draft success');
  export const updateCampaignDraftError = createAction('[ADMIN_PANEL] Update campaign draft error');

  export const editCampaign = createAction(
    '[ADMIN_PANEL] Edit campaign',
    props<{ campaignDetails: CampaignDetailsEditorModel }>()
  );
  export const editCampaignSuccess = createAction('[ADMIN_PANEL] Edit campaign success');
  export const editCampaignError = createAction('[ADMIN_PANEL] Edit campaign error');

  export const updateCampaign = createAction(
    '[ADMIN_PANEL] Update campaign',
    props<{ campaignDetails: Partial<CampaignDetailsEditorModel>; campaignId: string }>()
  );
  export const updateCampaignSuccess = createAction('[ADMIN_PANEL] Update campaign success');
  export const updateCampaignError = createAction('[ADMIN_PANEL] Update campaign error');

  export const clearInsightDetails = createAction('[ADMIN_PANEL] Clear insight details');
  export const clearBadgeDetails = createAction('[ADMIN_PANEL] Clear badge details');
}
