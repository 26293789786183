import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LiveTickerActions } from './live-ticker.actions';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { LiveTickerService } from '../../client/live-ticker/live-ticker.service';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { MessageService } from '../message.service';
import { CampaignsActions } from '../campaigns/campaigns.actions';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';

@Injectable()
export class LiveTickerEffects {
  getCampaignLiveTickerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LiveTickerActions.getCampaignLiveTickerData,
        CampaignsActions.getAllCampaignsDataSuccess,
        CampaignsActions.getCampaignUserDataSuccess
      ),
      withLatestFrom(this.store$.select((state) => state.campaigns.campaignUserData)),
      filter(([, campaignUserData]) => !!campaignUserData?.campaign),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([[, campaignUserData], workspaceId]) =>
        this.liveTickerService.getLiveTickerCampaignData(campaignUserData!.campaign!, workspaceId!).pipe(
          map((response) => LiveTickerActions.getCampaignLiveTickerDataSuccess(response)),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(LiveTickerActions.getCampaignLiveTickerDataError);
          })
        )
      )
    )
  );

  getCommunityLiveTickerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LiveTickerActions.getCommunityLiveTickerData),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([, workspaceId]) =>
        this.liveTickerService.getLiveTickerCommunityData(workspaceId!).pipe(
          map((response) => LiveTickerActions.getCommunityLiveTickerDataSuccess(response)),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(LiveTickerActions.getCommunityLiveTickerDataError);
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private liveTickerService: LiveTickerService,
    private messageService: MessageService
  ) {}
}
