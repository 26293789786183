import { Action, createReducer, on } from '@ngrx/store';
import { WorkspacesState } from './workspaces-state';
import { WorkspacesActions } from './workspaces.actions';

export namespace WorkspacesReducer {
  export const initialState: WorkspacesState = {
    workspaces: [],
    selectedWorkspaceId: null,
    loading: false,
    loaded: false
  };

  export const workspacesReducer = createReducer(
    initialState,
    on(WorkspacesActions.getWorkspaces, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(WorkspacesActions.getWorkspacesSuccess, (state, { workspaces }) => ({
      ...state,
      workspaces: workspaces,
      selectedWorkspace: workspaces.find((workspace) => workspace._id === state.selectedWorkspaceId) ?? null,
      loading: false,
      loaded: true
    })),
    on(WorkspacesActions.getWorkspacesError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(WorkspacesActions.selectWorkspaceSuccess, (state, { workspace }) => ({
      ...state,
      selectedWorkspaceId: workspace._id,
      loading: false,
      loaded: true
    }))
  );

  export function reducer(state: WorkspacesState, action: Action): WorkspacesState {
    return workspacesReducer(state, action);
  }
}
