import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService, SingleTimeUnit, TimeUnitKey } from '../services/converter.service';

@Pipe({
  name: 'singleTimeUnit'
})
export class SingleTimeUnitPipe implements PipeTransform {
  transform(seconds: number): SingleTimeUnit {
    if (isNaN(seconds)) {
      return { value: '', unit: TimeUnitKey.seconds };
    }
    return ConverterService.secondsToNearestTimeUnit(seconds);
  }
}
