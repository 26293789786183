<div class='team-name-container' ngClass.lt-md='team-name-container-mobile'
     ngClass.gt-sm='team-name-container-desktop'
     fxLayoutAlign='space-between center' fxLayout='row'>
  <p>{{team.name}}</p>
  <button *ngIf="userInTeam" (menuClosed)='isMenuOpened = false' (menuOpened)='isMenuOpened = true;' color='accent'
          [ngClass.lt-md]="{'menu-opened': isMenuOpened, 'menu-button': true, 'menu-button-mobile': true}"
          [ngClass.gt-sm]="{'menu-opened': isMenuOpened, 'menu-button': true, 'menu-button-desktop': true}" mat-fab
          [matMenuTriggerFor]='menu' id='openTeamMenu'>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu [class]='menuClass' class="menu-dark" xPosition='before' #menu='matMenu'>
    <button *ngIf='isAdmin' mat-menu-item (click)='openEditTeam()' id='openEditTeam'>
      <mat-icon svgIcon='edit-white'></mat-icon>
      <span>{{'teams_edit' | translate}}</span>
    </button>
    <button mat-menu-item (click)='openLeaveTeam()' id='logout'>
      <mat-icon svgIcon='logout'></mat-icon>
      <span>{{'teams_leave' | translate}}</span>
    </button>
    <button *ngIf='isAdmin' mat-menu-item (click)='openDeleteTeam()' id='openDeleteTeam'>
      <mat-icon svgIcon='delete-white'></mat-icon>
      <span>{{'teams_delete' | translate}}</span>
    </button>
  </mat-menu>
</div>
