import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'vim-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent {
  @HostBinding('class.white-background') @Input() noBackground = false;

  constructor() {}
}
