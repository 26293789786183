<div class="dialog-container">
  <div class="dialog-header" fxLayout fxLayoutAlign="space-between center">
    <div class="DisplayRegularHeading-6">{{title}}</div>
    <button (click)="closeDialog.emit()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dialog-content">
    <ng-content></ng-content>
  </div>
</div>
