import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vim-my-teams-empty-state',
  templateUrl: './my-teams-empty-state.component.html',
  styleUrls: ['./my-teams-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyTeamsEmptyStateComponent {
  @Input() createTeamEnabled: boolean;
  @Input() multipleTeamsEnabled: boolean;
  @Input() userInActiveCampaign: boolean;
  @Output() createTeamClicked = new EventEmitter();
  @Output() joinTeamClicked = new EventEmitter();

  constructor(private router: Router) {}

  get buttonText(): string {
    if (!this.userInActiveCampaign) {
      return 'common_join_campaign';
    }
    return this.createTeamEnabled ? 'my_teams_empty_state_btn' : 'common_join_team';
  }

  onAction(): any {
    if (!this.userInActiveCampaign) {
      return this.router.navigateByUrl('/home/campaigns');
    }
    if (this.createTeamEnabled) {
      return this.createTeamClicked.next();
    }
    return this.joinTeamClicked.next();
  }

  get emptyStateContent(): { header: string; message: string } {
    if (!this.userInActiveCampaign) {
      return { header: 'you_have_not_joined_any_campaign', message: 'ranking_you_should_join_any_campaign' };
    }
    if (this.createTeamEnabled) {
      return { header: 'my_teams_empty_state_header', message: 'my_teams_empty_state_create_teams_enabled' };
    }
    if (this.multipleTeamsEnabled) {
      return {
        header: 'teams_start_with_team',
        message: 'my_teams_empty_state_create_teams_disabled_multiple_teams_enabled'
      };
    }
    return {
      header: 'teams_start_with_team',
      message: 'my_teams_empty_state_create_teams_disabled_multiple_teams_disabled'
    };
  }
}
