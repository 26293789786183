import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarColor } from 'src/app/shared/shared-ui/services/toast/toast.service';

export interface CustomSnackBarComponentData {
  snackBarColor: SnackBarColor;
  buttonLabel: string;
  message: string;
  iconLeft: string;
  iconRight: string;
  close: () => void;
}

@Component({
  selector: 'vim-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSnackBarComponent {
  public snackBarColor = SnackBarColor;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackBarComponentData,
    public snackBar: MatSnackBar
  ) {}
}
