import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '../../../../shared/shared-ui/models/language.model';
import { LanguageFlagService } from '../../../../shared/shared-ui/services/language-flag.service';
import { StorageService } from '../../../../shared/shared-ui/services/storage-service/storage.service';

@Component({
  selector: 'vim-language-mobile-menu',
  templateUrl: './language-mobile-menu.component.html',
  styleUrls: ['./language-mobile-menu.component.scss']
})
export class LanguageMobileMenuComponent {
  languageEnum = LanguageEnum;

  constructor(
    public translateService: TranslateService,
    public languageFlagService: LanguageFlagService,
    private storageService: StorageService
  ) {}

  changeLanguageAndCloseSideNav(language: LanguageEnum): void {
    this.translateService.use(language);
    this.storageService.setCurrentLanguage(language);
  }
}
