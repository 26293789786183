import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppSettingsService } from '../common/app-settings.service';
import { StorageService } from '../../shared/shared-ui/services/storage-service/storage.service';
import { WorkspacesStore } from '../../store/workspaces/workspaces.store';
import { DOCUMENT } from '@angular/common';
import { MobileVersionService } from '../common/mobile-version.service';

@Component({
  selector: 'vim-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  settings = AppSettingsService;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public workspacesStore: WorkspacesStore,
    public deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService,
    private storageService: StorageService,
    private deviceDetector: DeviceDetectorService,
    private mobileVersionService: MobileVersionService
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService.logoffLocalMultiple();
    const teamId = this.route.snapshot.queryParamMap.get('teamId');
    const workspaceId = this.route.snapshot.queryParamMap.get('workspaceId') || undefined;
    if (teamId) {
      this.storageService.setInvitationParams({ teamId, workspaceId });
      this.handleTeamInvitation(teamId, workspaceId);
    }
  }

  onLogin(): void {
    this.oidcSecurityService.authorize();
  }

  private handleTeamInvitation(teamId: string, workspaceId = ''): void {
    if (this.deviceDetector.isMobile() === false) {
      return;
    }
    setTimeout(() => {
      this.document.location.href = this.mobileVersionService.storeUrl;
    }, 100);
    if (this.deviceDetector.os === 'Android') {
      this.document.location.href = `vimove://teaminvitation?teamId=${teamId}&workspaceId=${workspaceId}`;
    }
  }
}
