import { AppState } from '../state';
import { select, Store } from '@ngrx/store';
import { GarminSelectors } from './garmin.selectors';
import { GarminActions } from './garmin.actions';
import { Injectable } from '@angular/core';

@Injectable()
export class GarminStore {
  state$ = this.store$.pipe(select(GarminSelectors.selectState));
  status$ = this.store$.pipe(select(GarminSelectors.selectStatus));
  loadIndicators$ = this.store$.pipe(select(GarminSelectors.loadIndicators));
  isLoaded$ = this.store$.pipe(select(GarminSelectors.isLoaded));
  isGarminConnected$ = this.store$.pipe(select(GarminSelectors.isGarminConnected));

  getStatus(): void {
    this.store$.dispatch(GarminActions.getStatus());
  }

  getLink(): void {
    this.store$.dispatch(GarminActions.getLink());
  }

  clear(): void {
    this.store$.dispatch(GarminActions.clear());
  }

  constructor(private store$: Store<AppState>) {}
}
