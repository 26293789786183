import { GarminState } from './garmin.state';
import { Action, createReducer, on } from '@ngrx/store';
import { GarminActions } from './garmin.actions';

export namespace GarminReducer {
  export const initialState: GarminState = {
    status: null,
    authorizationUrl: '',
    loading: false,
    loaded: false
  };

  export const garminReducer = createReducer(
    initialState,
    on(GarminActions.getStatus, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(GarminActions.getStatusSuccess, (state, { status }) => ({
      ...state,
      status: status,
      loading: false,
      loaded: true
    })),
    on(GarminActions.getStatusError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(GarminActions.getLink, (state) => ({
      ...state
    })),
    on(GarminActions.getLinkSuccess, (state, { authorizationUrl }) => ({
      ...state,
      authorizationUrl: authorizationUrl
    })),
    on(GarminActions.getLinkError, (state) => ({
      ...state
    })),
    on(GarminActions.clear, () => ({
      ...initialState,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: GarminState, action: Action): GarminState {
    return garminReducer(state, action);
  }
}
