<div class="container" fxLayout='column' fxLayoutGap='27px'>
  <div ngClass.gt-sm='team-members-container-desktop' ngClass.lt-md='team-members-container-mobile'
       fxLayout.gt-xs='row' fxLayout.lt-sm='column'
       fxLayoutGap='0px'
       fxLayoutGap.lt-md='24px'
       fxLayoutAlign.lt-sm='center flex-start' fxLayoutAlign.gt-xs='space-between center'
       fxFlex.lt-sm='184px' fxFlex.gt-xs='100px'>
    <div fxLayout="column">
      <div class="HeadingHeading-5">{{'teams_team_members' | translate}}</div>
      <div fxFlex="5px"></div>
      <div
        class="admin-name">ADMIN: {{adminName.firstName}}&nbsp;{{adminName.familyName}}</div>
    </div>
    <button *ngIf="showInviteButton" (click)='openInvitationDialog()' ngClass.lt-md='button-mobile'
            ngClass.gt-sm='button-desktop' mat-button
            color='primary' fxFlexAlign="center" vim-button>
      {{'teams_create_invite_member_header' | translate}} &#8594;
    </button>
  </div>
  <div *ngIf="teamMembers as teamMembers" fxLayout='row wrap' fxLayoutAlign='space-between'>
    <div class="current-user" fxFlex='100%'
         *ngIf="(teamMembers.length >= 20) && currentUserId as currentMemberId">
      <mat-divider></mat-divider>
      <vim-member-row [member]='getTeamMember(currentMemberId)' [campaignUnit]="campaignUnit"></vim-member-row>
    </div>

    <ng-container>
      <ng-container *ngFor='let member of teamMembers; let i = index;'>
        <div fxFlex='100%'>
          <mat-divider *ngIf="i === 0"></mat-divider>
          <mat-divider *ngIf="i === 1" fxHide fxShow.gt-sm></mat-divider>
          <vim-member-row [member]='member' [campaignUnit]="campaignUnit"></vim-member-row>
          <mat-divider></mat-divider>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <vim-progress-indicator *ngIf="teamsStore.isLoading$ | async"
                          [noBackground]="true"></vim-progress-indicator>
</div>
