<div class='total-time-container' fxLayoutGap='16px' fxLayout='column' ngClass.gt-sm='total-time-container-desktop'
     ngClass.lt-md='total-time-container-mobile'>
  <p ngClass.gt-sm='LabelLabel-4'
     ngClass.lt-md='LabelLabel-5'>{{'common_total_time' | translate | uppercase}}</p>
  <div fxLayout.lt-md='column' ngClass.gt-sm='DisplayBoldHeading-2'
       ngClass.lt-md='DisplayBoldHeading-3'>
    <div *ngIf="team.time else noTime">
      <div *ngIf="team.time | singleTimeUnit as time">
        <span>{{time.value}}&nbsp;</span>
        <span ngClass.lt-md='HeadingHeading-6'>{{time.unit | translate}}</span>
      </div>
    </div>
    <ng-template #noTime>
      <span>0&nbsp;</span>
      <span ngClass.lt-md='HeadingHeading-6'>{{'unit_minute_si' | translate}}</span>
    </ng-template>
  </div>
  <div fxHide.lt-md='true' class='summary-icons' fxLayout='row' fxLayoutGap='8px'>
  </div>
  <div fxFlex='auto'></div>
  <div fxFlex.gt-sm='34px' fxLayoutAlign='flex-end flex-end' class="summary-trees" ngClass.gt-sm='HeadingHeading-5'
       ngClass.lt-md='LabelLabel-4'>
    {{ team.timePoints | roundDown}}
    {{campaignUnit | unitToKey | plural:team.timePoints | translate}}
  </div>
</div>
