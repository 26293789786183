import { AdminPanelState } from './admin-panel.state';
import { AdminPanelActions } from './admin-panel.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { BadgeDetails } from './models/badge-details.model';
import { InsightDetails } from './models/insight-details.model';

export namespace AdminPanelReducer {
  export const initialState: AdminPanelState = {
    statisticsUpcomingCampaigns: [],
    statisticsActiveCampaigns: [],
    statisticsFinishedCampaigns: [],
    availableCampaigns: [],
    campaigns: [],
    campaignDetails: null,
    activities: [],
    badges: [],
    badgeDetails: null,
    adminData: null,
    insights: null,
    insightDetails: null,
    units: [],
    loading: false,
    loaded: false
  };

  export const adminPanelReducer = createReducer(
    initialState,
    on(AdminPanelActions.getCampaignsData, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(
      AdminPanelActions.getCampaignsDataSuccess,
      (state, { upcomingCampaigns, activeCampaigns, finishedCampaigns }) => ({
        ...state,
        upcomingCampaigns: upcomingCampaigns,
        activeCampaigns: activeCampaigns,
        finishedCampaigns: finishedCampaigns,
        loading: false,
        loaded: true
      })
    ),
    on(AdminPanelActions.getCampaignsDataError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getCampaignsLight, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getCampaignsLightSuccess, (state, { campaigns }) => ({
      ...state,
      availableCampaigns: campaigns.map((campaign) => ({ id: campaign._id, title: campaign.title })),
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getCampaignsLightError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getCampaigns, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getCampaignsSuccess, (state, { campaigns }) => ({
      ...state,
      campaigns,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getCampaignsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getUnits, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getUnitsSuccess, (state, { units }) => ({
      ...state,
      units,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getUnitsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getCampaignDetails, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getCampaignDetailsSuccess, (state, { campaignDetails }) => ({
      ...state,
      campaignDetails,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getCampaignDetailsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getAdminData, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getAdminDataSuccess, (state, { adminData }) => ({
      ...state,
      adminData,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getCampaignsDataError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getInsights, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getInsightsSuccess, (state, { insights }) => ({
      ...state,
      insights,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getInsightsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getBadges, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getBadgesSuccess, (state, { badges }) => ({
      ...state,
      badges,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getBadgesError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getActivities, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getActivitiesSuccess, (state, { activities }) => ({
      ...state,
      activities,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getActivitiesError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getInsightDetails, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getInsightDetailsSuccess, (state, { insightDetails }) => ({
      ...state,
      insightDetails: InsightDetails.fromJSON(insightDetails),
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getInsightDetailsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.getBadgeDetails, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.getBadgeDetailsSuccess, (state, { badgeDetails }) => ({
      ...state,
      badgeDetails: BadgeDetails.fromJSON(badgeDetails),
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.getBadgeDetailsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(
      AdminPanelActions.createInsight,
      AdminPanelActions.updateInsight,
      AdminPanelActions.createBadge,
      AdminPanelActions.createCampaignDraft,
      AdminPanelActions.updateBadge,
      AdminPanelActions.createCampaign,
      AdminPanelActions.updateCampaign,
      AdminPanelActions.createMarketingNotification,
      (state) => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      AdminPanelActions.createInsightSuccess,
      AdminPanelActions.updateInsightSuccess,
      AdminPanelActions.createBadgeSuccess,
      AdminPanelActions.updateBadgeSuccess,
      AdminPanelActions.createCampaignSuccess,
      AdminPanelActions.createCampaignFromDraftSuccess,
      AdminPanelActions.updateCampaignSuccess,
      AdminPanelActions.createMarketingNotificationSuccess,
      (state) => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      AdminPanelActions.createInsightError,
      AdminPanelActions.updateInsightError,
      AdminPanelActions.createBadgeError,
      AdminPanelActions.updateBadgeError,
      AdminPanelActions.createCampaignError,
      AdminPanelActions.createCampaignFromDraftError,
      AdminPanelActions.updateCampaignError,
      AdminPanelActions.createMarketingNotificationError,
      (state) => ({
        ...state,
        loading: false,
        loaded: false
      })
    ),
    on(AdminPanelActions.deleteInsightSuccess, (state, { insightId }) => ({
      ...state,
      insights: state.insights?.length ? state.insights.filter((insight) => insight._id !== insightId) : state.insights,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.deleteInsightError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.deleteBadge, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminPanelActions.deleteBadgeSuccess, (state, { badgeId }) => ({
      ...state,
      badges: state.badges?.length ? state.badges.filter((badge) => badge.id !== badgeId) : state.badges,
      loading: false,
      loaded: true
    })),
    on(AdminPanelActions.deleteBadgeError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.clearInsightDetails, (state) => ({
      ...state,
      insightDetails: null,
      loading: false,
      loaded: false
    })),
    on(AdminPanelActions.clearBadgeDetails, (state) => ({
      ...state,
      badgeDetails: null,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: AdminPanelState, action: Action): AdminPanelState {
    return adminPanelReducer(state, action);
  }
}
