import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Document, DocumentPhraseappKey } from '../../../../store/legal/legal.state';
import { Subject } from 'rxjs';
import { AuthService } from '../../../common/auth.service';
import { LegalStore } from '../../../../store/legal/legal.store';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { LegalActions } from '../../../../store/legal/legal.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './legal-documents-checker.component.html',
  styleUrls: ['./legal-documents-checker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalDocumentsCheckerComponent implements OnInit, OnDestroy {
  privacyPolicyChecked = false;
  termsOfUseChecked = false;
  ngUnsubscribe$ = new Subject<boolean>();

  get privacyPolicyDocument() {
    return this.data.filter((document) => document.phraseappKey === DocumentPhraseappKey.PrivacyPolicy)?.[0];
  }

  get termsOfUseDocument() {
    return this.data.filter((document) => document.phraseappKey === DocumentPhraseappKey.TermsOfUse)?.[0];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Document[],
    public dialogRef: MatDialogRef<LegalDocumentsCheckerComponent, boolean>,
    private authService: AuthService,
    private legalStore: LegalStore,
    private actionsSubject: ActionsSubject
  ) {}

  ngOnInit(): void {
    if (!this.privacyPolicyDocument) {
      this.privacyPolicyChecked = true;
    }
    if (!this.termsOfUseDocument) {
      this.termsOfUseChecked = true;
    }
    this.actionsSubject
      .pipe(ofType(LegalActions.registerDocumentAgreementSuccess), takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.dialogRef.close(true));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  sendConfirmation(): void {
    this.data.forEach((document) => this.legalStore.registerDocumentAgreement(document.revisionId));
  }

  cancel(): void {
    this.dialogRef.close();
    this.authService.logout();
  }
}
