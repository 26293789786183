import { Injectable } from '@angular/core';
import { CampaignsService } from './campaigns.service';
import { Observable } from 'rxjs';
import { CampaignSharingData } from './campaignsData';
import { map, mergeMap } from 'rxjs/operators';
import { WorkspacesStore } from '../../store/workspaces/workspaces.store';

@Injectable({
  providedIn: 'root'
})
export class CampaignsHelperService {
  constructor(
    private campaignsService: CampaignsService,
    private workspaceStore: WorkspacesStore
  ) {}

  getRandomCampaignSharingData(campaignId: string): Observable<CampaignSharingData> {
    return this.workspaceStore.selectedWorkspaceId$.pipe(
      mergeMap((workspaceId) => this.campaignsService.getCampaignSharingData(campaignId, workspaceId!)),
      map((response) => {
        const index = Math.min(response.data.length - 1, Math.round(Math.random() * response.data.length));
        return response.data[index];
      })
    );
  }
}
