import { CampaignsStore } from './campaigns/campaigns.store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CampaignStatus } from '../client/campaigns/campaignsData';
import { Observable } from 'rxjs';
import { WorkspacesStore } from './workspaces/workspaces.store';
import { ActivitiesStore } from './activities/activities.store';
import { BadgesStore } from './badges/badges-store';
import { GarminStore } from './garmin/garmin.store';
import { LegalStore } from './legal/legal.store';
import { LiveTickerStore } from './live-ticker/live-ticker-store';
import { StravaStore } from './strava/strava.store';
import { TeamsStore } from './teams/teams.store';

@Injectable({
  providedIn: 'root'
})
export class StoreHelperService {
  constructor(
    private campaignsStore: CampaignsStore,
    private workspacesStore: WorkspacesStore,
    private activitiesStore: ActivitiesStore,
    private badgesStore: BadgesStore,
    private garminStore: GarminStore,
    private legalStore: LegalStore,
    private liveTickerStore: LiveTickerStore,
    private stravaStore: StravaStore,
    private teamsStore: TeamsStore
  ) {}

  clearAllStores(): void {
    this.campaignsStore.clear();
    this.activitiesStore.clear();
    this.badgesStore.clear();
    this.liveTickerStore.clear();
    this.teamsStore.clear();
  }

  get showManualUploadButton(): Observable<boolean> {
    return this.campaignsStore.userCampaign$.pipe(
      map((userCampaign) => !!userCampaign && userCampaign.status === CampaignStatus.active)
    );
  }
}
