import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { NotificationsState } from './notifications.state';

export namespace NotificationsSelectors {
  const state = (state: AppState) => state.notifications;

  export const selectNotificationsCount = createSelector(
    state,
    (state: NotificationsState) => state?.notificationsCount
  );
  export const selectUnreadNotificationsCount = createSelector(
    state,
    (state: NotificationsState) => state?.unreadNotificationsCount
  );
  export const selectNotifications = createSelector(state, (state: NotificationsState) => state?.notifications);

  export const isLoading = createSelector(state, (state: NotificationsState) => state?.loading);
}
