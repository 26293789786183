<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.leadersIsLoaded">
    <vim-leaderboard-list [showCategories]="vm.categoriesEnabled"
                          [createTeamEnabled]="vm.createTeamEnabled"
                          [multipleTeamsEnabled]="vm.multipleTeamsEnabled"
                          [userInActiveCampaign]="vm.userInActiveCampaign"
                          [leaderboards]='vm.leaderboards'
                          [campaignUnit]="vm.campaignUnit"
                          [isLoading]="vm.isLoading"
                          [canJoinTeam]="vm.createTeamEnabled && (vm.multipleTeamsEnabled || !vm.assignedToTeam)"
                          (getMoreLeaderBoardData)="getMoreLeaderboardData($event)"
                          (createTeam)="createTeam.next()"></vim-leaderboard-list>
  </ng-container>
</ng-container>

