import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { StorageItem, StorageService } from '../../shared/shared-ui/services/storage-service/storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private storageService: StorageService,
    private router: Router
  ) {
    this.registerLogoutInAnotherTab();
  }

  private registerLogoutInAnotherTab(): void {
    addEventListener('storage', (ev) => {
      if (ev.key === StorageItem.logoutMarker) {
        this.logout();
      }
    });
  }

  logout(): void {
    this.oidcSecurityService.logoff();
    this.storageService.clearWorkspaceId();
    this.storageService.clearInvitationParams();
    this.storageService.setLogoutMarker();
    void this.router.navigate(['/login']);
  }
}
