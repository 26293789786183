<div fxLayout="column" fxLayoutAlign="center center">
  <div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <img alt='Team not found image' class='image'
             src='../assets/images/teams/empty-state.svg'>
      </div>
      <ng-container *ngIf="emptyStateContent as content">
        <header class="DisplayBoldHeading-5 header">{{content.header | translate}}</header>
        <p class='ParagraphParagraph-1 message'>
          {{content.message | translate}}
        </p>
      </ng-container>
      <button mat-button color='primary'
              class='action-button' vim-button
              (click)='onAction()' id='joinTeam'>
        {{buttonText | translate}}
        <mat-icon>arrow_forward</mat-icon>
      </button>
      <ng-template #noTemplate>
        <button mat-button color='primary'
                class='action-button' vim-button
                id='joinCampaign'>
          {{buttonText | translate}}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </ng-template>
    </div>
  </div>

</div>
