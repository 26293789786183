import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TeamPrivacy, TeamsItem } from 'src/app/client/teams/teams.model';
import { WorkspacesStore } from '../../../../../store/workspaces/workspaces.store';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-team-summary',
  templateUrl: './team-summary.component.html',
  styleUrls: ['./team-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSummaryComponent {
  @Input() team!: TeamsItem;
  @Input() campaignUnit: CampaignUnit;

  teamPrivacy = TeamPrivacy;

  constructor(public applicationStore: WorkspacesStore) {}
}
