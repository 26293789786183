import { createAction, props } from '@ngrx/store';
import {
  CampaignData,
  CampaignDetails,
  CampaignSharingData,
  CampaignUserData
} from '../../client/campaigns/campaignsData';

export namespace CampaignsActions {
  export const getUserCampaign = createAction('[CAMPAIGNS] Get user campaign', props<{ campaignId: string }>());
  export const getUserCampaignSuccess = createAction(
    '[CAMPAIGNS] Get user campaign success',
    props<{ campaignDetails: CampaignDetails; campaignSharingData: CampaignSharingData[] }>()
  );
  export const getUserCampaignError = createAction('[CAMPAIGNS] Get user campaign error');

  export const getAllCampaignsData = createAction('[CAMPAIGNS] Get all campaigns data');
  export const getAllCampaignsDataSuccess = createAction(
    '[CAMPAIGNS] Get all campaigns data success',
    props<{
      activeCampaigns: CampaignData[];
      pastCampaigns: CampaignData[];
      futureCampaigns: CampaignData[];
    }>()
  );
  export const getAllCampaignsDataError = createAction('[CAMPAIGNS] Get all campaigns data error');
  export const joinCampaign = createAction('[CAMPAIGNS] Join campaign', props<{ campaignId: string }>());
  export const joinCampaignSuccess = createAction('[CAMPAIGNS] Join campaign success', props<{ campaignId: string }>());
  export const joinCampaignError = createAction('[CAMPAIGNS] Join campaign error');

  export const leaveCampaign = createAction('[CAMPAIGNS] Leave campaign', props<{ campaignId: string }>());
  export const leaveCampaignSuccess = createAction('[CAMPAIGNS] Leave campaign success');
  export const leaveCampaignError = createAction('[CAMPAIGNS] Leave campaign error');

  export const getCampaignUserData = createAction('[CAMPAIGNS] Get campaign user data');
  export const getCampaignUserDataSuccess = createAction(
    '[CAMPAIGNS] Get campaign user data success',
    props<{ campaignUserData: CampaignUserData }>()
  );
  export const getCampaignUserDataError = createAction('[CAMPAIGNS] Get campaign user data error');

  export const getCampaignSharingData = createAction('[CAMPAIGNS] Get campaign sharing data');
  export const getCampaignSharingDataSuccess = createAction(
    '[CAMPAIGNS] Get campaign sharing data success',
    props<{ campaignSharingData: CampaignSharingData[] }>()
  );
  export const getCampaignSharingDataError = createAction('[CAMPAIGNS] Get campaign sharing data error');
  export const clearCampaignsData = createAction('[CAMPAIGNS] Clear campaigns data');
}
