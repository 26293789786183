import { Pipe, PipeTransform } from '@angular/core';

const second = 1000,
  minute = 60 * second,
  hour = 60 * minute,
  day = 24 * hour,
  week = 7 * day;

@Pipe({
  name: 'roundTime'
})
export class RoundTimePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    const now = new Date();
    const difference = now.getTime() - date.getTime();
    if (difference < hour) {
      return `${Math.round(difference / minute)} M`;
    }
    if (difference < day) {
      return `${Math.round(difference / hour)} H`;
    }
    if (difference < week) {
      return `${Math.round(difference / day)} D`;
    }
    return `${Math.round(difference / week)} W`;
  }
}
