<div fxLayout fxLayoutAlign="space-between">
  <div class="DisplayRegularHeading-5"
       [style.margin-bottom.px]="48">{{title | translate}}</div>
  <button mat-icon-button (click)="dialogRef.close()" aria-label="Close button">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div *ngIf="deviceDetector.isMobile(); else scrollbar">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
<ng-template #scrollbar>
  <ng-scrollbar [autoHeightDisabled]="false">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-scrollbar>
</ng-template>

<ng-template #content class="content">
  <div class="content">
    <div fxLayout fxLayoutAlign="center">
      <img class="campaign-image" src="{{dialogData.campaignDetails.imageSmall}}"
           [style.margin-bottom.px]="24"
           alt="Campaign logo">
    </div>
    <div class="HeadingHeading-3" [style.margin-bottom.px]="24"
         [style.text-align]="'center'">{{dialogData.campaignDetails.title}}</div>
    <div class="HeadingHeading-5" [style.margin-bottom.px]="24"
         [style.text-align]="'center'">{{dialogData.campaignDetails.shortDescription}}</div>
    <div
      class="date ParagraphParagraph-1">{{'common_start' | translate}}:
      {{dialogData.campaignDetails.startDate | date: 'dd MMMM'}}</div>
    <div class="date ParagraphParagraph-1" [style.margin-bottom.px]="48">
  <span
    *ngIf="dialogData.campaignDetails.endDate else units">{{'common_end' | translate}}:
    {{dialogData.campaignDetails.endDate | date: 'dd MMMM'}}</span>
      <ng-template
        #units>{{'common_end' | translate}}:&nbsp;
        {{dialogData.campaignDetails.maxPoints}}&nbsp;{{dialogData.campaignDetails.unit | unitToKey | plural: dialogData.campaignDetails.maxPoints | translate }}</ng-template>
    </div>
    <vim-campaign-progress [campaignDetails]="dialogData.campaignDetails"
                           [isCampaignActive]="dialogData.isActive"></vim-campaign-progress>
    <div class="HeadingHeading-3" [style.margin-bottom.px]="24"
         [style.text-align]="'center'">{{dialogData.campaignDetails.subtitle}}</div>
    <div class="HeadingHeading-5" [style.margin-bottom.px]="24"
         [style.text-align]="'center'">{{dialogData.campaignDetails.description}}</div>
    <div *ngIf="dialogData.campaignUserStatistics as userStats" [style.margin-bottom.px]="40">
      <div fxLayout fxLayoutAlign="space-between center" [style.margin-bottom.px]="16">
        <div class="DisplayRegularHeading-7">{{'common_your_results' | translate}}</div>
        <button (click)="shareResults()" mat-button vim-button [style.width.px]="130">
          <mat-icon>share</mat-icon>
          {{'common_share_it' | translate}}
        </button>
      </div>
      <vim-campaign-statistics [data]="userStats" [unit]="dialogData.campaignDetails.unit"></vim-campaign-statistics>
    </div>
    <div class="DisplayRegularHeading-7" [style.margin-bottom.px]="24">{{'common_campaign_stats' | translate}}</div>
    <vim-campaign-statistics [data]="campaignStatistics" [unit]="dialogData.campaignDetails.unit"
                             [style.margin-bottom.px]="24"></vim-campaign-statistics>
  </div>
</ng-template>

<ng-container *ngIf="campaignsStore.campaignDetailsViewModel$|async as vm">
  <div class="notification LabelLabel-1" [style.margin-bottom.px]="24"
       *ngIf="!!vm.userCampaign && campaignAvailable">{{'campaigns_only_one_active_campaign' | translate}}
  </div>
  <div fxLayout fxLayoutAlign="center" [style.padding-bottom.px]="16">
    <button *ngIf="isUserAssignedTo(vm.userCampaign?._id) === false && campaignAvailable"
            (click)="joinCampaign()" fxFlex="50%"
            fxFlex.xs="100%" mat-button color="primary"
            vim-button>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span>{{'common_join_campaign' | translate}}</span>
        <mat-spinner *ngIf="vm.isLoading" diameter="30"></mat-spinner>
      </div>
    </button>
    <button *ngIf="isUserAssignedTo(vm.userCampaign?._id)" (click)="leaveCampaign()" fxFlex="50%"
            fxFlex.xs="100%" mat-button vim-button
            color="primary">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span>{{'common_leave_campaign' | translate}}</span>
        <mat-spinner *ngIf="vm.isLoading" diameter="30"></mat-spinner>
      </div>
    </button>
  </div>
</ng-container>
