import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  GetTeamsParams,
  CreateTeamModel,
  UpdateTeamMemberParams,
  UpdateTeamMemberModel,
  UpdateTeamModel,
  TeamsItem
} from 'src/app/client/teams/teams.model';
import { AppState } from '../state';
import { TeamsActions } from './teams.actions';
import { TeamsSelectors } from './teams.selectors';

@Injectable()
export class TeamsStore {
  teams$ = this.store$.pipe(select(TeamsSelectors.selectMyTeams));
  team$ = this.store$.pipe(select(TeamsSelectors.selectTeam));
  teamMembers$ = this.store$.pipe(select(TeamsSelectors.selectTeamMembers));
  removedMembers$ = this.store$.pipe(select(TeamsSelectors.selectTeamRemovedMembers));
  leaders$ = this.store$.pipe(select(TeamsSelectors.selectLeaders));
  isLoaded$ = this.store$.pipe(select(TeamsSelectors.selectIsLoaded));
  isLoading$ = this.store$.pipe(select(TeamsSelectors.selectIsLoading));

  getTeams(params: GetTeamsParams): void {
    this.store$.dispatch(TeamsActions.getTeams({ params }));
  }

  getTeam(id: string): void {
    this.store$.dispatch(TeamsActions.getTeam({ id }));
  }

  getTeamMembers(id: string, skip = 0, limit = 50) {
    this.store$.dispatch(TeamsActions.getTeamMembers({ id, skip, limit }));
  }

  getMoreMembers(id: string, skip: number, limit: number) {
    this.store$.dispatch(TeamsActions.getTeamMoreMembers({ id, skip, limit }));
  }

  getTeamRemovedMembers(id: string) {
    this.store$.dispatch(TeamsActions.getTeamRemovedMembers({ id }));
  }

  createTeam(team: CreateTeamModel): void {
    this.store$.dispatch(TeamsActions.createTeam({ team }));
  }

  deleteTeam(id: string, name: string): void {
    this.store$.dispatch(TeamsActions.deleteTeam({ id, name }));
  }

  updateTeam(id: string, team: UpdateTeamModel): void {
    this.store$.dispatch(TeamsActions.updateTeam({ id, team }));
  }

  removeTeamMember(params: UpdateTeamMemberParams, model: UpdateTeamMemberModel): void {
    this.store$.dispatch(TeamsActions.removeTeamMember({ params, model }));
  }

  reInviteTeamMember(params: UpdateTeamMemberParams, model: UpdateTeamMemberModel): void {
    this.store$.dispatch(TeamsActions.reInviteTeamMember({ params, model }));
  }

  leaveTeam(params: UpdateTeamMemberParams, model: UpdateTeamMemberModel): void {
    this.store$.dispatch(TeamsActions.leaveTeam({ params, model }));
  }

  leaveTeamAsAdmin(team: TeamsItem, userId: string, newAdminId: string): void {
    this.store$.dispatch(TeamsActions.leaveTeamAsAdmin({ team, userId, newAdminId }));
  }

  acceptInvitation(params: UpdateTeamMemberParams, model: UpdateTeamMemberModel): void {
    this.store$.dispatch(TeamsActions.acceptInvitation({ params, model }));
  }

  inviteToTeam(teamId: string, emails: string[]): void {
    this.store$.dispatch(TeamsActions.inviteToTeam({ teamId, emails }));
  }

  getLeaders(): void {
    this.store$.dispatch(TeamsActions.getLeaders());
  }

  getMoreLeaders(skip = 0, limit = 100): void {
    this.store$.dispatch(TeamsActions.getMoreLeaders({ skip, limit }));
  }

  clear(): void {
    this.store$.dispatch(TeamsActions.clear());
  }

  constructor(private store$: Store<AppState>) {}
}
