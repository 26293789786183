import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ActivitiesService } from '../../client/activities/activities.service';
import { ActivitiesActions } from './activities.actions';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivitiesSelectors } from './activities.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { MessageService, ViError } from '../message.service';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';
import { CampaignsService } from '../../client/campaigns/campaigns.service';
import { CampaignsSelectors } from '../campaigns/campaigns.selectors';
import { Router } from '@angular/router';

@Injectable()
export class ActivitiesEffects {
  constructor(
    private actions$: Actions,
    private activitiesService: ActivitiesService,
    private campaignsService: CampaignsService,
    private store$: Store<AppState>,
    private messageService: MessageService,
    private router: Router
  ) {}

  getActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.getActivities),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([action, workspaceId]) =>
        this.activitiesService.getActivities(action.params, workspaceId!).pipe(
          map((activities) => ActivitiesActions.getActivitiesSuccess({ activities })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(ActivitiesActions.getActivitiesError());
          })
        )
      )
    )
  );

  getMoreActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.getMoreActivities),
      withLatestFrom(this.store$.select(ActivitiesSelectors.selectCurrentMarker)),
      distinctUntilChanged(),
      concatLatestFrom(() => this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([[action, currentMarker], workspaceId]) =>
        this.activitiesService
          .getActivities(
            {
              campaign: action.campaign,
              limit: action.limit,
              skip: currentMarker
            },
            workspaceId!
          )
          .pipe(
            map((activities) => ActivitiesActions.getMoreActivitiesSuccess({ activities })),
            catchError(() => of(ActivitiesActions.getMoreActivitiesError()))
          )
      )
    )
  );

  getActivityTypesForActivityDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.getActivityDetails),
      withLatestFrom(this.store$.select(ActivitiesSelectors.selectActivityTypes)),
      filter(([, activityTypes]) => !activityTypes),
      map(() => ActivitiesActions.getActivityTypes())
    )
  );

  getActivityDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.getActivityDetails),
      switchMap((action) =>
        this.store$.select(ActivitiesSelectors.selectActivityTypes).pipe(
          skipWhile((activityTypes) => activityTypes === null),
          take(1),
          withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
          switchMap(([, workspaceId]) =>
            this.activitiesService.getActivity(workspaceId!, action.activityId).pipe(
              switchMap((activityDetails) =>
                (activityDetails.imageAvailable
                  ? this.activitiesService
                      .getActivityImage(workspaceId!, activityDetails._id)
                      .pipe(map((blob) => ({ customImage: new File([blob], 'image') })))
                  : of({})
                ).pipe(
                  switchMap((customImage) =>
                    this.campaignsService.getCampaign(activityDetails.campaignId, workspaceId!).pipe(
                      map(({ hashTag }) =>
                        ActivitiesActions.getActivityDetailsSuccess({
                          activityDetails: { ...activityDetails, ...customImage, ...{ hashTag } }
                        })
                      )
                    )
                  )
                )
              ),
              catchError((err) => {
                this.messageService.showErrorSnackbar(err);
                void this.router.navigate(['/home/activities']);
                return of(ActivitiesActions.getActivityDetailsError());
              })
            )
          )
        )
      )
    )
  );

  getActivityTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.getActivityTypes),
      switchMap(() =>
        this.store$.select(CampaignsSelectors.selectUserCampaignId).pipe(
          filter((id) => !!id),
          withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
          filter(([, workspaceId]) => workspaceId !== null),
          switchMap(([campaignId, workspaceId]) =>
            this.activitiesService.getActivityTypes(workspaceId!, campaignId!).pipe(
              map((activityTypes) => ActivitiesActions.getActivityTypesSuccess({ activityTypes })),
              catchError((err) => {
                this.messageService.showErrorSnackbar(err);
                return of(ActivitiesActions.getActivityTypesError());
              })
            )
          )
        )
      )
    )
  );

  deleteActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.deleteActivity),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      switchMap(([{ id }, workspaceId]) =>
        this.activitiesService.deleteActivity(id, workspaceId!).pipe(
          map(() => ActivitiesActions.deleteActivitySuccess({ id })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(ActivitiesActions.deleteActivityError());
          })
        )
      )
    )
  );

  uploadActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.uploadActivity),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      concatMap(([action, workspaceId]) =>
        (action.activity.file
          ? this.activitiesService.uploadActivityImage(action.activity.file, workspaceId!)
          : of({})
        ).pipe(
          concatMap(({ file }: { file?: string }) =>
            this.activitiesService.uploadActivities([{ ...action.activity, file }], workspaceId!)
          ),
          map((images) => ActivitiesActions.uploadActivitySuccess({ images })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(ActivitiesActions.uploadActivityError());
          })
        )
      )
    )
  );

  updateActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.updateActivity),
      withLatestFrom(this.store$.select(WorkspacesSelectors.selectSelectedWorkspaceId)),
      concatMap(([action, workspaceId]) =>
        (action.activityData.file
          ? this.activitiesService.uploadActivityImage(action.activityData.file, workspaceId!)
          : of({})
        ).pipe(
          concatMap(({ file }: { file?: string }) =>
            this.activitiesService.updateActivity(
              action.activityId,
              {
                ...action.activityData,
                file: file ?? null
              },
              workspaceId!
            )
          ),
          map(() =>
            ActivitiesActions.updateActivitySuccess({
              activityData: action.activityData,
              activityId: action.activityId
            })
          ),
          catchError((err: ViError) => {
            this.messageService.showErrorSnackbar(err);
            return of(ActivitiesActions.updateActivityError());
          })
        )
      )
    )
  );

  refreshActivityDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesActions.updateActivitySuccess),
      map(({ activityId }) => ActivitiesActions.getActivityDetails({ activityId }))
    )
  );
}
