import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamsRoutingModule } from './teams-routing.module';
import { TeamsComponent } from './teams.component';
import { SharedModule } from 'src/app/shared';
import { CreateTeamComponent } from './my-teams/dialogs/create-team/create-team.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TeamOverviewComponent } from './my-teams/team-overview/team-overview.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { MyTeamsEmptyStateComponent } from './my-teams/my-teams-empty-state/my-teams-empty-state.component';
import { MyTeamsComponent } from './my-teams/my-teams.component';
import { InviteMembersComponent } from './team/dialogs/invite-members/invite-members.component';
import { InvitationOverviewComponent } from './invitations/invitation-overview/invitation-overview.component';
import { LeaveTeamAdminComponent } from './team/dialogs/leave-team-admin/leave-team-admin.component';
import { LeaderboardOverviewComponent } from './leaderboard/leaderboard-overview/leaderboard-overview.component';
import { EditTeamComponent } from './team/dialogs/edit-team/edit-team.component';
import { TeamMemberRowComponent } from './team/dialogs/edit-team/team-member-row/team-member-row.component';
import { EditTeamMembersComponent } from './team/dialogs/edit-team/edit-team-members/edit-team-members.component';
import { TeamMemberConfirmationButtonsComponent } from './team/dialogs/edit-team/team-member-row/team-member-confirmation-buttons/team-member-confirmation-buttons.component';
import { TeamComponent } from './team/team.component';
import { MemberRowComponent } from './team/team-members/member-row/member-row.component';
import { TeamNameComponent } from './team/team-name/team-name.component';
import { TeamSummaryComponent } from './team/team-summary/team-summary.component';
import { TotalDistanceSummaryComponent } from './team/total-distance-summary/total-distance-summary.component';
import { TotalTimeSummaryComponent } from './team/total-time-summary/total-time-summary.component';
import { TeamMembersComponent } from './team/team-members/team-members.component';
import { LeaveTeamComponent } from './team/dialogs/leave-team/leave-team.component';
import { DeleteTeamComponent } from './team/dialogs/delete-team/delete-team.component';
import { LeaderboardListComponent } from './leaderboard/leaderboard-list/leaderboard-list.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PrivacyInfoComponent } from './privacy-info/privacy-info.component';
import { MatChipsModule } from '@angular/material/chips';
import { CampaignNameComponent } from './campaign-name/campaign-name.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    TeamsComponent,
    CreateTeamComponent,
    EditTeamComponent,
    LeaveTeamAdminComponent,
    LeaveTeamComponent,
    MyTeamsComponent,
    TeamOverviewComponent,
    MyTeamsEmptyStateComponent,
    InvitationOverviewComponent,
    LeaderboardComponent,
    InviteMembersComponent,
    DeleteTeamComponent,
    LeaderboardOverviewComponent,
    TeamMemberRowComponent,
    EditTeamMembersComponent,
    TeamMemberConfirmationButtonsComponent,
    TeamComponent,
    MemberRowComponent,
    TeamNameComponent,
    TeamSummaryComponent,
    TotalDistanceSummaryComponent,
    TotalTimeSummaryComponent,
    TeamMembersComponent,
    LeaderboardListComponent,
    PrivacyInfoComponent,
    CampaignNameComponent
  ],
  exports: [PrivacyInfoComponent],
  imports: [
    ClipboardModule,
    CommonModule,
    InfiniteScrollModule,
    MatChipsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SharedModule,
    TeamsRoutingModule,
    NgScrollbarModule
  ]
})
export class TeamsModule {}
