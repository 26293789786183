import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TeamPrivacy, TeamsItem } from 'src/app/client/teams/teams.model';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class TeamOverviewComponent {
  @Input()
  public team!: TeamsItem;
  @Input() campaignUnit: CampaignUnit;

  teamPrivacy = TeamPrivacy;

  constructor() {}
}
