import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MobileVersionService } from '../ui/common/mobile-version.service';

@Component({
  selector: 'vim-redirect',
  template: 'Redirecting...'
})
export class RedirectComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private mobileVersionService: MobileVersionService
  ) {}

  ngOnInit() {
    if (!this.mobileVersionService.isMobile) {
      void this.router.navigateByUrl('/home');
    }
    setTimeout(() => (this.document.location.href = this.mobileVersionService.storeUrl), 100);
    this.document.location.href = `${this.route.snapshot.data.externalUrl as string}${this.document.location.search}`;
  }
}
