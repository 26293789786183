import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vim-live-ticker-item',
  templateUrl: './live-ticker-item.component.html',
  styleUrls: ['./live-ticker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveTickerItemComponent {
  @Input() icon: string = '';
  @Input() number: string | null = '';
  @Input() subtitle: string = '';
  @Input() fullScreenMode = false;

  constructor() {}
}
