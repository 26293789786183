import { Pipe, PipeTransform } from '@angular/core';
import { CampaignUnit } from '../../../../client/campaigns/campaignsData';

@Pipe({
  name: 'unitToIcon'
})
export class UnitToIconPipe implements PipeTransform {
  transform(value: CampaignUnit): string {
    if (!value) {
      return '';
    }
    return UNIT_TO_ICON[value];
  }
}

const UNIT_TO_ICON: { [key: string]: string } = {
  [CampaignUnit.POINTS]: 'points',
  [CampaignUnit.TREES]: 'tree',
  [CampaignUnit.EURO]: 'euro',
  [CampaignUnit.BEES]: 'bee'
};
