<div fxLayout='column'>
  <div ngClass.gt-sm='member-row-desktop' ngClass.lt-md='member-row-mobile' fxFlex='1 1 auto' fxLayout='row'>
    <div fxLayoutGap='12px' fxLayoutAlign='center center'>
      <div class='avatar-button'>
        <span>{{userInitials}}</span>
      </div>
      <div fxLayoutAlign='center center'>{{fullName}}</div>
      <mat-chip-option class='admin' *ngIf='isAdmin'
                fxLayoutAlign='center center'>{{'common_admin' | translate}}</mat-chip-option>
    </div>
    <div fxFlex='auto'></div>
    <div fxLayoutAlign='center center' *ngIf='!isAdmin'>
      <button mat-flat-button (click)='onAddOrRemoveClick($event)'
              *ngIf='!removeOrAddConfirmationVisible' id='addOrRemoveMember'>
        <mat-icon svgIcon='{{removedUsers ? "add-user" : "remove-user"}}'></mat-icon>
      </button>
      <vim-team-member-confirmation-buttons fxHide.lt-md *ngIf='removeOrAddConfirmationVisible'
                                            (hideRemoveOrAddConfirmation)="removeOrAddConfirmationVisible = false"
                                            [confirmationText]='confirmationText' [memberId]='member._id'
                                            [changeStatusOfMember]='changeStatusOfMember'>
      </vim-team-member-confirmation-buttons>
    </div>
  </div>
  <mat-divider fxHide.gt-sm *ngIf='removeOrAddConfirmationVisible'></mat-divider>
  <div fxLayoutAlign='center center' class='mobile-confirmation-row' fxHide.gt-sm
       *ngIf='removeOrAddConfirmationVisible'>
    <vim-team-member-confirmation-buttons [confirmationText]='confirmationText' [memberId]='member._id'
                                          (hideRemoveOrAddConfirmation)="removeOrAddConfirmationVisible = false"
                                          [changeStatusOfMember]='changeStatusOfMember'></vim-team-member-confirmation-buttons>
  </div>
</div>
