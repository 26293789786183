import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { WorkspacesSelectors } from '../../../../store/workspaces/workspaces.selectors';
import { TeamsSelectors } from '../../../../store/teams/teams.selectors';
import { CampaignsSelectors } from '../../../../store/campaigns/campaigns.selectors';
import { CampaignStatus, CampaignUnit } from '../../../../client/campaigns/campaignsData';
import { TeamsLeaderboardData } from '../../../../store/teams/teams.state';

export namespace LeaderboardSelectors {
  export const leaderBoardViewModel: MemoizedSelector<AppState, LeaderboardViewModel> = createSelector(
    WorkspacesSelectors.categoriesEnabled,
    CampaignsSelectors.selectCreateTeamEnabled,
    CampaignsSelectors.selectMultipleTeamsEnabled,
    TeamsSelectors.selectMyTeams,
    TeamsSelectors.selectLeaders,
    CampaignsSelectors.selectCurrentCampaignUnit,
    CampaignsSelectors.selectUserCampaign,
    WorkspacesSelectors.isLoading,
    TeamsSelectors.selectIsLoading,
    TeamsSelectors.selectLeadersIsLoaded,
    (
      categoriesEnabled,
      createTeamEnabled,
      multipleTeamsEnabled,
      myTeams,
      leaders,
      campaignUnit,
      userCampaign,
      workspacesIsLoading,
      teamsIsLoading,
      leadersIsLoaded
    ) => ({
      categoriesEnabled: categoriesEnabled,
      createTeamEnabled: createTeamEnabled,
      leaderboards: leaders,
      multipleTeamsEnabled: multipleTeamsEnabled,
      campaignUnit: campaignUnit || CampaignUnit.POINTS,
      assignedToTeam: !!myTeams?.data.length,
      userInActiveCampaign: !!userCampaign && userCampaign.status !== CampaignStatus.past,
      isLoading: workspacesIsLoading || teamsIsLoading,
      leadersIsLoaded
    })
  );
}

export interface LeaderboardViewModel {
  categoriesEnabled: boolean | undefined;
  createTeamEnabled: boolean | undefined;
  leaderboards: TeamsLeaderboardData | null;
  multipleTeamsEnabled: boolean | undefined;
  assignedToTeam: boolean;
  userInActiveCampaign: boolean;
  leadersIsLoaded: boolean | null;
  isLoading: boolean;
  campaignUnit: CampaignUnit;
}
