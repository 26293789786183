import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TeamPrivacy, TeamsItem, UpdateTeamModel } from 'src/app/client/teams/teams.model';
import { TranslateService } from '@ngx-translate/core';
import { WorkspacesStore } from '../../../../../../store/workspaces/workspaces.store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTeamComponent implements OnInit, OnDestroy {
  public team: TeamsItem;
  public form: UntypedFormGroup;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  teamPrivacy = TeamPrivacy;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditTeamDialogData,
    public dialogRef: MatDialogRef<any>,
    public teamsStore: TeamsStore,
    public applicationStore: WorkspacesStore,
    private translateService: TranslateService
  ) {}

  closeDialog(ev: MouseEvent): void {
    ev.preventDefault();
    this.dialogRef.close();
  }

  setTeamPrivacy(privacy: TeamPrivacy): void {
    this.form.controls.privacy.patchValue(privacy);
    this.form.markAsDirty();
  }

  private getTeamData(): void {
    const teamId = this.data?.teamId;
    this.teamsStore.getTeam(teamId);
    this.teamsStore.getTeamRemovedMembers(teamId);
  }

  ngOnInit(): void {
    this.getTeamData();

    this.teamsStore.team$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((team) => {
      this.team = team as TeamsItem;
      this.form = new UntypedFormGroup({
        name: new UntypedFormControl(this.team.name, [Validators.required, Validators.minLength(3)]),
        privacy: new UntypedFormControl(this.team.privacy, [Validators.required])
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get maxMembersText(): string[] {
    return (this.translateService.instant('teams_max_members') as string).split(/(?=\()/);
  }

  membersChanged(): void {
    this.getTeamData();
  }

  saveTeam(): void {
    this.teamsStore.updateTeam(this.team._id, this.form.value as UpdateTeamModel);
    this.dialogRef.close();
  }
}

export interface EditTeamDialogData {
  teamId: string;
}

export interface EditTeamMember {
  memberId: string;
  removeOrAddConfirmationVisible: boolean;
  userFullName: string;
  userInitials: string;
  isAdmin: boolean;
  email: string;
}
