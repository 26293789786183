import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AppSettingsService } from '../../../../ui/common/app-settings.service';

@Component({
  selector: 'vim-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadAppComponent {
  settings = AppSettingsService;

  constructor() {}
}
