import { Inject, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppSettingsService } from './app-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MobileVersionService {
  constructor(
    private deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    public deviceDetector: DeviceDetectorService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  get storeUrl(): string {
    if (this.deviceService.os === 'Android') {
      return AppSettingsService.GOOGLE_PLAY_URL;
    } else if (this.deviceService.os === 'iOS') {
      return AppSettingsService.APPLE_STORE_URL;
    }
    return '';
  }

  get isMobile(): boolean {
    return this.deviceDetector.isMobile();
  }

  get userAgent(): string {
    return this.deviceService.userAgent;
  }

  public showMobileAppBanner(): boolean {
    const { os, userAgent } = this.deviceService;
    const isMobile = os === 'iOS' || os === 'Android';
    if (!isMobile) {
      return false;
    }
    if (os === 'Android') {
      const webView = userAgent.includes('wv');
      return !webView;
    }
    if (os === 'iOS') {
      const webView = !userAgent.includes('Safari');
      return !webView;
    }
    return true;
  }
}
