import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GarminStore } from '../../store/garmin/garmin.store';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { LegalDocumentsCheckerService } from './legal/legal-documents-checker/legal-documents-checker.service';
import { CampaignsStore } from '../../store/campaigns/campaigns.store';
import { AppVersionService } from '../whats-new/app-version-service/app-version.service';
import { MatDialog } from '@angular/material/dialog';
import { CampaignsDialogService } from './campaigns/campaigns-dialog.service';
import { WorkspacesStore } from '../../store/workspaces/workspaces.store';
import { LiveTickerStore } from '../../store/live-ticker/live-ticker-store';
import { TeamsStore } from '../../store/teams/teams.store';
import { JoinTeamDialogComponent, JoinTeamDialogData } from './join-team-dialog/join-team-dialog.component';
import { CampaignDetails, CampaignStatus } from '../../client/campaigns/campaignsData';
import { CampaignBannerSelectors } from './campaign-banner/campaign-banner.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state';
import { LegalStore } from '../../store/legal/legal.store';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'vim-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject();
  private legalDocsChecked$ = new Subject<boolean>();
  campaignBannerViewModel$ = this.store$.select(CampaignBannerSelectors.campaignBannerViewModel).pipe(
    debounceTime(500),
    filter((data) => !!data.isLoaded)
  );

  //ToDo: create viewModel and derived selector
  constructor(
    public campaignsStore: CampaignsStore,
    public deviceDetectorService: DeviceDetectorService,
    private garminStore: GarminStore,
    private workspacesStore: WorkspacesStore,
    private liveTickerStore: LiveTickerStore,
    private teamsStore: TeamsStore,
    private legalDocumentsCheckerService: LegalDocumentsCheckerService,
    private appVersionService: AppVersionService,
    private dialogService: MatDialog,
    private campaignsDialogsService: CampaignsDialogService,
    private store$: Store<AppState>,
    private legalStore: LegalStore
  ) {}

  ngOnInit(): void {
    this.garminStore.getStatus();
    this.campaignsStore.getCampaigns();
    this.workspacesStore.getWorkspaces();
    this.legalStore.getUpdatedDocuments();
    this.teamsStore.getTeams({ filter: 'mine' });
    this.liveTickerStore.getCampaignLiveTickerData();
    this.legalDocumentsCheckerService.checkLegalDocs();
    this.handleNewCampaign();
    this.handleUserWithoutTeam();
  }

  private handleUserWithoutTeam(): void {
    combineLatest([this.campaignsStore.isLoaded$, this.teamsStore.isLoaded$, this.legalDocsChecked$])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(100),
        filter(
          ([campaignsLoading, teamsLoading, legalDocsChecked]) => campaignsLoading && teamsLoading && legalDocsChecked
        ),
        withLatestFrom(this.campaignsStore.userCampaign$, this.teamsStore.teams$),
        filter(
          ([, currentCampaign, userTeams]) =>
            !!currentCampaign &&
            currentCampaign?.status !== CampaignStatus.past &&
            userTeams?.data.length === 0 &&
            !currentCampaign.createTeamEnabled
        ),
        take(1),
        map((result) => result[1] as CampaignDetails)
      )
      .subscribe(({ title, imageSmall: image, hashTag, startDate, endDate, unit, maxPoints }) => {
        const dialogData: JoinTeamDialogData = {
          title,
          image,
          hashTag,
          startDate,
          endDate,
          unit,
          maxPoints
        };
        this.dialogService.open(JoinTeamDialogComponent, { data: dialogData, width: '600px' });
      });
  }

  private handleNewCampaign() {
    this.legalDocsChecked$
      .pipe(
        filter((checked) => checked),
        switchMap(() => this.campaignsStore.joinCampaignData$),
        filter((data) => data.isLoaded),
        take(1),
        filter((data) => !data.userInOngoingCampaign && data.campaignAvailable)
      )
      .subscribe(({ availableCampaigns }) =>
        this.campaignsDialogsService.openNewCampaignInfoDialog(availableCampaigns)
      );
  }

  ngAfterViewInit(): void {
    this.appVersionService.checkAppVersion();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
