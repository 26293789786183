export interface Notification {
  _id: string;
  title: string;
  description: string;
  status: NotificationStatus;
  date: string;
  type: NotificationType;
  workspaceId: string;
  image?: string;
}

export enum NotificationStatus {
  new = 'new',
  read = 'read'
}

export enum NotificationType {
  achievement_earned = 'achievement_earned',
  campaign_has_started = 'campaign_has_started',
  campaign_has_ended = 'campaign_has_ended',
  campaign_will_start_soon = 'campaign_will_start_soon',
  campaign_is_coming_to_its_end = 'campaign_is_coming_to_its_end',
  marketing = 'marketing'
}
