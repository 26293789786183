import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { Document } from '../../store/legal/legal.state';

@Injectable({
  providedIn: 'root'
})
export class LegalService {
  constructor(private httpService: HttpService) {}

  getUpdatedDocuments(): Observable<{ documents: Document[] }> {
    return this.httpService.get(`/legal/updated`, {});
  }

  getCurrentPrivacyPolicy(lang: string): Observable<{ content: string }> {
    return this.httpService.getUnprotected('/legal/privacy-policy', lang);
  }

  getCurrentTermsOfUse(lang: string): Observable<{ content: string }> {
    return this.httpService.getUnprotected('/legal/terms-of-use', lang);
  }

  registerDocumentAgreement(revisionId: string): Observable<any> {
    return this.httpService.post(
      `/legal/updated`,
      {},
      {
        revisionId
      }
    );
  }
}
