import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StravaStore } from './strava.store';
import { StravaReducer } from './strava.reducer';
import { StravaEffects } from './strava.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('strava', StravaReducer.reducer),
    EffectsModule.forFeature([StravaEffects])
  ],
  providers: [StravaStore, StravaEffects]
})
export class StravaStoreModule {}
