import { CampaignUnit } from '../campaigns/campaignsData';

export interface GetTeamsParams {
  limit?: number;
  skip?: number;
  filter?: 'mine' | 'all' | 'active' | 'inactive' | 'past';
}

export interface CreateTeamModel extends Record<string, any> {
  inviteEmails: string[];
  name: string;
}

export interface UpdateTeamModel extends Record<string, any> {
  name: string;
}

export interface UpdateTeamMemberParams {
  teamId: string;
  userId: string;
  teamName?: string;
}

export interface UpdateTeamMemberModel extends Record<string, any> {
  isAdmin?: boolean;
  active?: boolean;
}

export interface TeamsData {
  data: TeamsItem[];
  total: number;
}

export interface TeamsItem {
  _id: string;
  name: string;
  admin: {
    _id: string;
    name: { firstName: string; familyName: string };
  };
  campaign: {
    _id: string;
    title: string;
    color: string;
    colorVariant: string;
    textColor: string;
    textColorVariant: string;
    status: string;
  };
  distance: number;
  time: number;
  unit: CampaignUnit;
  points: number;
  distancePoints: number;
  timePoints: number;
  rankingPlace: number;
  maxMembers: number;
  invitationLink: string;
  membersCount: number;
  privacy: TeamPrivacy;
}

export interface LeadersData {
  data: LeadersItem[];
  total: number;
}

export interface LeadersItem {
  _id: string;
  name: string;
  points: number;
  unit: CampaignUnit;
  rankingPlace: number;
  privacy: TeamPrivacy;
}

export enum TeamPrivacy {
  open = 'open',
  closed = 'closed'
}

export interface TeamMembersData {
  data: TeamMember[];
  myPosition: UserTeamPosition;
  total: number;
}

export interface UserTeamPosition {
  rankingPlace: number;
  total: { points: number };
  name: {
    firstName: string;
    familyName: string;
  };
}

export interface TeamMember {
  _id: string;
  leftCampaign: boolean;
  name: {
    firstName: string;
    familyName: string;
  };
  total: {
    trees: number;
  };
  points: number;
  rankingPlace: number;
}
