import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { AdminPanelState } from './admin-panel.state';
import { WorkspacesSelectors } from '../workspaces/workspaces.selectors';

export namespace AdminPanelSelectors {
  const state = (state: AppState) => state.adminPanel;

  export const selectInsights = createSelector(state, (state: AdminPanelState) => state?.insights);
  export const selectBadges = createSelector(state, (state: AdminPanelState) => state?.badges);
  export const selectActivities = createSelector(state, (state: AdminPanelState) => state?.activities);
  export const selectInsightDetails = createSelector(state, (state: AdminPanelState) => state?.insightDetails);
  export const selectBadgeDetails = createSelector(state, (state: AdminPanelState) => state?.badgeDetails);
  export const selectIsMasterAdmin = createSelector(
    state,
    (state: AdminPanelState) => !!state?.adminData?.roles.find((role) => role === 'masterAdmin')
  );
  export const selectAdminWorkspaces = createSelector(
    state,
    (state: AdminPanelState) => state?.adminData?.adminWorkspaces
  );
  export const selectViewerWorkspaces = createSelector(
    state,
    (state: AdminPanelState) => state?.adminData?.viewerWorkspaces
  );
  export const selectCanViewAdminContent = createSelector(
    AdminPanelSelectors.selectViewerWorkspaces,
    WorkspacesSelectors.selectSelectedWorkspaceId,
    (viewerWorkspaces, selectedWorkspaceId) =>
      viewerWorkspaces && selectedWorkspaceId && viewerWorkspaces.includes(selectedWorkspaceId)
  );
  export const selectCanEditAdminContent = createSelector(
    AdminPanelSelectors.selectIsMasterAdmin,
    AdminPanelSelectors.selectAdminWorkspaces,
    WorkspacesSelectors.selectSelectedWorkspaceId,
    (isMasterAdmin, adminWorkspaces, selectedWorkspaceId) =>
      isMasterAdmin || (adminWorkspaces && selectedWorkspaceId && adminWorkspaces.includes(selectedWorkspaceId))
  );
  export const selectCanViewOrEditAdminContent = createSelector(
    AdminPanelSelectors.selectIsMasterAdmin,
    AdminPanelSelectors.selectAdminWorkspaces,
    AdminPanelSelectors.selectViewerWorkspaces,
    WorkspacesSelectors.selectSelectedWorkspaceId,
    (isMasterAdmin, adminWorkspaces, viewerWorkspaces, selectedWorkspaceId) =>
      isMasterAdmin ||
      (adminWorkspaces && selectedWorkspaceId && adminWorkspaces.includes(selectedWorkspaceId)) ||
      (viewerWorkspaces && selectedWorkspaceId && viewerWorkspaces.includes(selectedWorkspaceId))
  );
  export const selectActiveCampaigns = createSelector(
    state,
    (state: AdminPanelState) => state?.statisticsActiveCampaigns
  );
  export const selectFinishedCampaigns = createSelector(
    state,
    (state: AdminPanelState) => state?.statisticsFinishedCampaigns
  );
  export const selectFirstUpcomingCampaign = createSelector(state, (state: AdminPanelState) => {
    if (state.statisticsUpcomingCampaigns.length === 0) {
      return null;
    }

    return state.statisticsUpcomingCampaigns[0];
  });

  export const selectAvailableCampaigns = createSelector(state, (state: AdminPanelState) => state?.availableCampaigns);
  export const selectCampaigns = createSelector(state, (state: AdminPanelState) => state?.campaigns);
  export const selectCampaignDetails = createSelector(state, (state: AdminPanelState) => state?.campaignDetails);
  export const selectShowStatistics = createSelector(
    state,
    (state: AdminPanelState) =>
      state?.statisticsActiveCampaigns.length > 0 || state?.statisticsFinishedCampaigns.length > 0
  );
  export const selectUnits = createSelector(state, (state: AdminPanelState) => state?.units);

  export const isLoaded = createSelector(state, (state: AdminPanelState) => !state?.loading && state?.loaded);
  export const isLoading = createSelector(state, (state: AdminPanelState) => state?.loading);
}
