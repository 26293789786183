import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { WorkspacesState } from './workspaces-state';
import { WorkspaceData } from '../../client/workspaces/workspaces.model';

export namespace WorkspacesSelectors {
  const state = (state: AppState) => state.workspaces;

  export const selectWorkspaces = createSelector(state, (state: WorkspacesState) => state?.workspaces);
  export const selectSelectedWorkspaceId = createSelector(
    state,
    (state: WorkspacesState) => state?.selectedWorkspaceId
  );
  export const selectSelectedWorkspace = createSelector(
    state,
    (state: WorkspacesState) => state?.workspaces?.find((workspace) => workspace._id === state.selectedWorkspaceId)
  );

  export const selectName = createSelector(
    WorkspacesSelectors.selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.name
  );
  export const selectFavicon = createSelector(
    selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.favicon
  );
  export const selectHashtag = createSelector(
    selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.hashTag
  );
  export const selectLogo = createSelector(
    selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.logo.svg || workspace?.config.logo.png
  );

  export const selectLogoPoweredBy = createSelector(
    selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.logoPoweredBy
  );
  export const isLoaded = createSelector(state, (state: WorkspacesState) => state?.loaded);
  export const isLoading = createSelector(state, (state: WorkspacesState) => state?.loading);

  export const co2Visible = createSelector(
    selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.features.co2Visible
  );
  export const categoriesEnabled = createSelector(
    selectSelectedWorkspace,
    (workspace?: WorkspaceData) => workspace?.config.features.categoriesEnabled
  );
}
