<ng-container *ngIf="showBanner">
  <div class="background-images">
    <img src="../assets/images/campaign-banner/trees.svg" alt="Background image" class="background-images__trees">
    <img src="../assets/images/campaign-banner/pattern.svg" alt="Background image" class="background-images__pattern">
  </div>
  <div class="container" *ngIf="bannerContent as content">
    <div class="container__texts">
      <div class="container__texts__header" ngClass.gt-xs="LabelLabel-1"
           ngClass.xs="Label-5">{{ content.title | translate }} </div>
      <div *ngIf="content.date as date" class="container__texts__message" ngClass.gt-xs="Label-1"
           ngClass.xs="Label-3">{{content.message | translate |sprintf:(content.date|date)}}</div>
      <div *ngIf="!content.date" class="container__texts__message" ngClass.gt-xs="Label-1"
           ngClass.xs="Label-3">{{content.message | translate}}</div>
    </div>
    <vim-button [routerLink]="content.link" [queryParams]="{campaignId: content.campaignId}" fxShow fxHide.lt-md
                class="primary-medium" aria-label="Read more button">{{'common_read_more' | translate}}&nbsp;->
    </vim-button>
    <button [routerLink]="content.link" [queryParams]="{campaignId: content.campaignId}" fxFlexAlign="start" fxHide
            fxShow.lt-md [style.margin-top.px]="14"
            mat-icon-button aria-label="Read more button">
      <mat-icon svgIcon="arrow"></mat-icon>
    </button>
  </div>
</ng-container>
