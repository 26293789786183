import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AdminPanelStore } from '../../store/admin-panel/admin-panel.store';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { iif, of } from 'rxjs';

export const adminGuard: CanActivateFn = () => {
  const store = inject(AdminPanelStore);
  const router = inject(Router);
  return store.isLoaded$.pipe(
    tap((isLoaded) => {
      if (isLoaded !== true) {
        store.getAdminData();
      }
    }),
    filter((loaded) => true === loaded),
    mergeMap(() => store.canViewOrEditAdminContent$),
    mergeMap((canView) => iif(() => !!canView, of(true), of(router.parseUrl('403'))))
  );
};

export const adminEditorGuard: CanActivateFn = () => {
  const store = inject(AdminPanelStore);
  const router = inject(Router);
  store.getAdminData();
  return store.isLoaded$.pipe(
    tap((isLoaded) => {
      if (isLoaded !== true) {
        store.getAdminData();
      }
    }),
    filter((loaded) => true === loaded),
    mergeMap(() => store.canEditAdminContent$),
    mergeMap((canView) => iif(() => !!canView, of(true), of(router.parseUrl('403'))))
  );
};
