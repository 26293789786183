import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamsItem } from 'src/app/client/teams/teams.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MyTeamsSelectors, MyTeamsViewModel } from './my-teams.selectors';
import { AppState } from '../../../../store/state';

@Component({
  selector: 'vim-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyTeamsComponent {
  @Input() teams: TeamsItem[];
  @Input() isActive: boolean;
  @Output() goToLeaderBoard = new EventEmitter();
  @Output() createTeam = new EventEmitter();
  vm$: Observable<MyTeamsViewModel> = this.store$.pipe(select(MyTeamsSelectors.myTeamsViewModel));

  constructor(private store$: Store<AppState>) {}
}
