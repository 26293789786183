import { AdminItemTarget } from '../../../store/admin-panel/models/common.models';
import { BadgeDetails } from '../../../store/admin-panel/models/badge-details.model';
import { TranslatedString } from './admin-panel.model';

export interface BadgeItemResponse {
  id: string;
  title: string;
  imageUrl: string;
}

export class BadgeDetailsPost {
  description: TranslatedString[];
  title: TranslatedString[];
  instructions: TranslatedString[];
  imageId: string;
  scope: AdminItemTarget;
  type: BadgeType;
  activity?: string[];
  startDate?: string;
  endDate?: string;
  campaignId?: string;
  time?: number;
  qty?: number;
  distance?: number;
  points?: number;
  elevationGain?: number;
  days?: number;

  static fromBadgeDetails(details: BadgeDetails, imageId: string) {
    const instance = new BadgeDetailsPost();
    instance.title = details.translatedContent.map(({ language: { shortname }, title }) => ({
      language: shortname,
      value: title
    }));
    instance.description = details.translatedContent.map(({ language: { shortname }, description }) => ({
      language: shortname,
      value: description
    }));
    instance.instructions = details.translatedContent.map(({ language: { shortname }, instructions }) => ({
      language: shortname,
      value: instructions
    }));
    instance.imageId = imageId;
    instance.startDate = details.dates?.startDate || undefined;
    instance.endDate = details.dates?.endDate || undefined;
    instance.campaignId = details.campaignId || undefined;
    instance.type = details.type;
    instance.activity = details.conditions.activity || undefined;
    instance.time = details.conditions.time || undefined;
    instance.qty = details.conditions.quantity || undefined;
    instance.distance = details.conditions.distance || undefined;
    instance.points = details.conditions.points || undefined;
    instance.elevationGain = details.conditions.elevationGain || undefined;
    instance.days = details.conditions.days || undefined;
    instance.scope = details.scope;
    return instance;
  }
}

export class BadgeDetailsPatch {
  description?: TranslatedString[];
  title?: TranslatedString[];
  instructions?: TranslatedString[];
  imageId?: string;
  scope?: AdminItemTarget;
  type?: BadgeType;
  activity?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  campaignId?: string | null;
  time?: number | null;
  qty?: number | null;
  distance?: number | null;
  points?: number | null;
  elevationGain?: number | null;
  days?: number | null;

  static fromBadgeDetails(details: Partial<BadgeDetails>, imageId?: string) {
    const instance = new BadgeDetailsPatch();
    instance.title = details.translatedContent?.map(({ language: { shortname }, title }) => ({
      language: shortname,
      value: title
    }));
    instance.description = details.translatedContent?.map(({ language: { shortname }, description }) => ({
      language: shortname,
      value: description
    }));
    instance.instructions = details.translatedContent?.map(({ language: { shortname }, instructions }) => ({
      language: shortname,
      value: instructions
    }));
    instance.imageId = imageId;
    instance.startDate = details.dates?.startDate;
    instance.endDate = details.dates?.endDate;
    instance.campaignId = details.campaignId;
    instance.type = details.type;
    instance.activity = details.conditions?.activity;
    instance.time = details.conditions?.time;
    instance.qty = details.conditions?.quantity;
    instance.distance = details.conditions?.distance;
    instance.points = details.conditions?.points;
    instance.elevationGain = details.conditions?.elevationGain;
    instance.days = details.conditions?.days;
    instance.scope = details.scope;
    return instance;
  }
}

export interface BadgeDetailsResponse {
  id: string;
  description: TranslatedString[];
  title: TranslatedString[];
  instructions: TranslatedString[];
  imageUrl: string;
  scope: AdminItemTarget;
  type: BadgeType;
  activity: string | string[];
  startDate?: string;
  endDate?: string;
  campaignId?: string;
  time?: number;
  qty?: number;
  distance?: number;
  points?: number;
  elevationGain?: number;
  days?: number;
}

export enum BadgeType {
  time = 'activities_time',
  distance = 'activities_distance',
  quantity = 'activities_qty',
  quantityDistinct = 'activities_qty_distinct',
  points = 'activities_points',
  campaign = 'campaign',
  elevationGain = 'activities_elevation_gain',
  activity_streak = 'activity_streak'
}
