import { Component, ChangeDetectionStrategy, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { TeamsStore } from '../../../store/teams/teams.store';
import { filter, map } from 'rxjs/operators';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { CreateTeamComponent } from './my-teams/dialogs/create-team/create-team.component';
import { MatDialog } from '@angular/material/dialog';
import { ofType } from '@ngrx/effects';
import { TeamsActions } from '../../../store/teams/teams.actions';
import { SnackBarColor, ToastService } from '../../../shared/shared-ui/services/toast/toast.service';
import { ActionsSubject } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'vim-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTabGroup) teamsTab: MatTabGroup;
  private ngUnsubscribe = new Subject<void>();
  teams$ = this.teamsStore.teams$.pipe(
    filter((teamsData) => teamsData?.total !== undefined),
    map((teamsData) => teamsData!.data)
  );

  constructor(
    public teamsStore: TeamsStore,
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private readonly dialogService: MatDialog,
    private actionsSubject: ActionsSubject,
    private toastService: ToastService
  ) {}

  public openCreateTeamDialog(): void {
    this.dialogService.open(CreateTeamComponent, { width: '600px' });
  }

  ngOnInit() {
    this.teamsStore.getTeams({ filter: 'active' });

    this.actionsSubject.pipe(ofType(TeamsActions.createTeamSuccess)).subscribe((response) => {
      const message = this.translationService.instant('teams_create_success_desc') as string;
      this.toastService.openSnackBar(message.replace('%1$s', response.teamName), false, SnackBarColor.green);
    });
  }

  ngAfterViewInit() {
    if (
      this.route.snapshot.queryParamMap.has('tab') &&
      this.route.snapshot.queryParamMap.get('tab') === 'leaderboard'
    ) {
      this.goToLeaderBoard();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToLeaderBoard(): void {
    this.teamsTab.selectedIndex = 1;
  }
}
