import { NgModule } from '@angular/core';
import { LegalStore } from './legal.store';
import { LegalEffects } from './legal.effects';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { LegalReducer } from './legal.reducer';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('legal', LegalReducer.reducer),
    EffectsModule.forFeature([LegalEffects])
  ],
  providers: [LegalStore]
})
export class LegalStoreModule {}
