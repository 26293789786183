import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { EditTeamComponent, EditTeamDialogData } from '../dialogs/edit-team/edit-team.component';
import { LeaveTeamComponent } from '../dialogs/leave-team/leave-team.component';
import { DeleteTeamComponent } from '../dialogs/delete-team/delete-team.component';
import { LeaveTeamAdminComponent, LeaveTeamAdminData } from '../dialogs/leave-team-admin/leave-team-admin.component';
import { TeamMember, TeamsItem } from 'src/app/client/teams/teams.model';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TeamsStore } from '../../../../../store/teams/teams.store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'vim-team-name',
  templateUrl: './team-name.component.html',
  styleUrls: ['./team-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated
})
export class TeamNameComponent implements OnDestroy {
  @Input() team!: TeamsItem;
  @Input() teamMembers: TeamMember[];
  @Input() userId: string;
  @Input() isAdmin: boolean;
  public isMenuOpened = false;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  get userInTeam() {
    return this.teamMembers?.find((member) => member._id === this.userId);
  }

  constructor(
    public teamsStore: TeamsStore,
    private dialogService: MatDialog,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openLeaveTeam(): void {
    const onePersonTeam = this.team.membersCount === 1;
    if (this.isAdmin && onePersonTeam) {
      this.openDeleteTeam();
      return;
    }
    const result = this.isAdmin
      ? this.dialogService.open(LeaveTeamAdminComponent, {
          headerSnippet: this.translateService.instant('teams_leave_title'),
          data: { team: this.team, teamMembers: this.teamMembers, userId: this.userId } as LeaveTeamAdminData
        } as MatDialogConfig)
      : this.dialogService.open(LeaveTeamComponent, {
          headerSnippet: this.translateService.instant('teams_leave_title'),
          data: { userId: this.userId, teamId: this.team._id, teamName: this.team.name }
        } as MatDialogConfig);

    void result
      .afterClosed()
      .pipe(filter((confirmed) => !!confirmed))
      .subscribe(() => void this.router.navigate(['/home/teams']));
  }

  // workaround for not working ngClass with mat-menu
  get menuClass(): string {
    return this.isAdmin ? 'edit-team-menu edit-team-menu-admin' : 'edit-team-menu';
  }

  openEditTeam(): void {
    this.dialogService.open(EditTeamComponent, {
      headerSnippet: this.translateService.instant('teams_edit'),
      data: {
        teamId: this.team._id
      } as EditTeamDialogData
    } as MatDialogConfig);
  }

  openDeleteTeam(): void {
    this.dialogService
      .open(DeleteTeamComponent, {
        headerSnippet: this.translateService.instant('teams_delete'),
        data: { teamId: this.team._id, teamName: this.team.name }
      } as MatDialogConfig)
      .afterClosed()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((confirmed) => !!confirmed)
      )
      .subscribe(() => {
        void this.router.navigate(['/home/teams']);
      });
  }
}
