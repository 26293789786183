import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import {
  ActivitiesData,
  ActivitiesParams,
  ActivityToUpload,
  ActivityDetailsResponse,
  ActivityItem,
  ActivityPatchPayload
} from './activities.model';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(private httpService: HttpService) {}

  getActivities(params: ActivitiesParams, workspaceId: string): Observable<ActivitiesData> {
    const parameters: ActivitiesParams = {
      campaign: params?.campaign || 'active',
      limit: params?.limit || 10,
      skip: params?.skip || 0
    };
    return this.httpService.get(`/workspaces/${workspaceId}/activities`, parameters);
  }

  getActivity(workspaceId: string, activityId: string): Observable<ActivityDetailsResponse> {
    return this.httpService.get(`/workspaces/${workspaceId}/activities/${activityId}`);
  }

  getActivityImage(workspaceId: string, activityId: string): Observable<Blob> {
    return this.httpService.getBlob(`/workspaces/${workspaceId}/activities/${activityId}/file`);
  }

  getActivityTypes(
    workspaceId: string,
    campaignId: string
  ): Observable<{ top: ActivityItem[]; other: ActivityItem[] }> {
    return this.httpService.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/activity-types`);
  }

  uploadActivities(activities: ActivityToUpload<string>[], workspaceId: string): Observable<any> {
    return this.httpService.post(`/workspaces/${workspaceId}/activities`, {}, { activities });
  }

  updateActivity(activityId: string, activityData: ActivityPatchPayload<string>, workspaceId: string): Observable<any> {
    return this.httpService.patch(`/workspaces/${workspaceId}/activities/${activityId}`, {}, { ...activityData });
  }

  uploadActivityImage(file: File, workspaceId: string): Observable<{ file: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService.post(`/workspaces/${workspaceId}/activities/upload`, {}, formData as Record<string, any>);
  }

  deleteActivity(id: string, workspaceId: string): Observable<any> {
    return this.httpService.delete(`/workspaces/${workspaceId}/activities/${id}`, {});
  }
}
