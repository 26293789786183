<mat-accordion>
  <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title class="HeadingHeading-5">
        <div fxLayout fxLayoutAlign="space-between" [style.width.%]="100">
          <div>{{ title }}</div>
          <div fxFlex="8px"></div>
          <div>
            <vim-arrow [orientation]="panelOpenState ? 'up': 'down'" color="green"></vim-arrow>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="inside-text">
      <ng-content></ng-content>
    </p>
  </mat-expansion-panel>
</mat-accordion>
