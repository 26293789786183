import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TeamsItem } from 'src/app/client/teams/teams.model';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-total-time-summary',
  templateUrl: './total-time-summary.component.html',
  styleUrls: ['./total-time-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalTimeSummaryComponent {
  @Input() team!: TeamsItem;
  @Input() campaignUnit: CampaignUnit;

  constructor() {}
}
