import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../state';
import { ActivitiesSelectors } from './activities.selectors';
import { ActivitiesActions } from './activities.actions';
import { ActivitiesParams, ActivityPatchPayload, ActivityToUpload } from '../../client/activities/activities.model';

@Injectable()
export class ActivitiesStore {
  activities$ = this.store$.pipe(select(ActivitiesSelectors.selectActivities));
  activityDetails$ = this.store$.pipe(select(ActivitiesSelectors.selectActivityDetails));
  selectedActivityIndex$ = this.store$.pipe(select(ActivitiesSelectors.selectActivityImageIndex));
  activityTypes$ = this.store$.pipe(select(ActivitiesSelectors.selectActivityTypes));
  allActivityTypes$ = this.store$.pipe(select(ActivitiesSelectors.selectAllActivityTypes));
  distanceActivityTypes$ = this.store$.pipe(select(ActivitiesSelectors.selectDistanceActivityTypes));
  timeActivityTypes$ = this.store$.pipe(select(ActivitiesSelectors.selectTimeActivityTypes));
  isLoaded$ = this.store$.pipe(select(ActivitiesSelectors.isLoaded));
  isLoading$ = this.store$.pipe(select(ActivitiesSelectors.isLoading));
  conversionRates$ = this.store$.pipe(select(ActivitiesSelectors.selectConversionRates));

  getActivities(params: ActivitiesParams): void {
    this.store$.dispatch(ActivitiesActions.getActivities({ params }));
  }

  getMoreActivities(campaign: 'active' | 'past', limit: number): void {
    this.store$.dispatch(ActivitiesActions.getMoreActivities({ campaign, limit }));
  }

  getActivityDetails(activityId: string): void {
    this.store$.dispatch(ActivitiesActions.getActivityDetails({ activityId }));
  }

  clearActivityDetails(): void {
    this.store$.dispatch(ActivitiesActions.clearActivityDetails());
  }

  selectActivityImage(selectedImageIndex: number): void {
    this.store$.dispatch(ActivitiesActions.selectActivityImage({ selectedImageIndex }));
  }

  getActivityTypes(): void {
    this.store$.dispatch(ActivitiesActions.getActivityTypes());
  }

  deleteActivity(id: string): void {
    this.store$.dispatch(ActivitiesActions.deleteActivity({ id }));
  }

  uploadActivity(activity: ActivityToUpload<File>): void {
    this.store$.dispatch(ActivitiesActions.uploadActivity({ activity }));
  }

  updateActivity(activityId: string, activityData: ActivityPatchPayload<File>): void {
    this.store$.dispatch(ActivitiesActions.updateActivity({ activityId, activityData }));
  }

  clearUploadData(): void {
    this.store$.dispatch(ActivitiesActions.clearUploadData());
  }

  clear(): void {
    this.store$.dispatch(ActivitiesActions.clear());
  }

  constructor(private store$: Store<AppState>) {}
}
