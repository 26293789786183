import { CampaignsData } from '../../client/admin-panel/admin-panel.data';
import { CampaignStatus, CampaignType, CampaignUnit } from '../../client/campaigns/campaignsData';

const upcomingCampaignsMock = [
  {
    _id: 1,
    title: 'Upcoming Campaign Title',
    startDate: '2023-01-01',
    endDate: '2023-01-31'
  }
];

const activeCampaignsMock = [
  {
    _id: 2,
    title: 'Active CampaignVERYLONG 1 Title',
    startDate: '2022-12-18',
    status: CampaignStatus.active,
    points: 10000,
    currentProgress: 10000,
    maxProgress: 15000,
    goal: 10000,
    type: CampaignType.points,
    unit: CampaignUnit.EURO,
    collectedUnits: {
      unit: CampaignUnit.EURO,
      total: 100000,
      unitsPerDate: [
        {
          date: '2022-12-19',
          amount: 1000
        },
        {
          date: '2022-12-20',
          amount: 3000
        },
        {
          date: '2022-12-21',
          amount: 6000
        }
      ]
    },
    topActivities: [
      {
        name: 'ACTIVITIES.RUNNING',
        points: 2887,
        distance: 367
      },
      {
        name: 'ACTIVITIES.TENNIS',
        points: 2356,
        time: 267
      },
      {
        name: 'ACTIVITIES.SWIMMING',
        points: 1789,
        time: 233
      },
      {
        name: 'ACTIVITIES.CYCLING',
        points: 1263,
        distance: 278
      },
      {
        name: 'ACTIVITIES.YOGA',
        points: 989,
        time: 189
      }
    ],
    participants: {
      total: 57392,
      active: 57392,
      participantsByCountry: [
        {
          name: 'Poland',
          count: 25002
        },
        {
          name: 'The United Kingdom',
          count: 20089
        },
        {
          name: 'Australia',
          count: 10000
        },
        {
          name: 'Spain',
          count: 2500
        },
        {
          name: 'France',
          count: 319
        }
      ]
    }
  },
  {
    _id: 3,
    title: 'Active Campaign 2 Title',
    startDate: '2022-12-17',
    endDate: '2022-12-26',
    points: 15000,
    currentProgress: 5,
    maxProgress: 9,
    status: CampaignStatus.active,
    goal: 10000,
    type: CampaignType.time,
    unit: CampaignUnit.TREES,
    collectedUnits: {
      unit: CampaignUnit.TREES,
      total: 80000,
      unitsPerDate: [
        {
          date: '2022-12-18',
          amount: 1000
        },
        {
          date: '2022-12-19',
          amount: 3000
        },
        {
          date: '2022-12-20',
          amount: 6000
        }
      ]
    },
    topActivities: [
      {
        name: 'ACTIVITIES.RUNNING',
        points: 2887,
        distance: 367
      },
      {
        name: 'ACTIVITIES.TENNIS',
        points: 2356,
        time: 267
      },
      {
        name: 'ACTIVITIES.SWIMMING',
        points: 1789,
        time: 233
      },
      {
        name: 'ACTIVITIES.CYCLING',
        points: 1263,
        distance: 278
      },
      {
        name: 'ACTIVITIES.YOGA',
        points: 989,
        time: 189
      }
    ],
    participants: {
      total: 57392,
      active: 57392,
      participantsByCountry: [
        {
          name: 'Poland',
          count: 25002
        },
        {
          name: 'The United Kingdom',
          count: 20089
        },
        {
          name: 'Australia',
          count: 10000
        },
        {
          name: 'Spain',
          count: 2500
        },
        {
          name: 'France',
          count: 319
        }
      ]
    }
  }
];

export const adminPanelDataMock: CampaignsData = {
  upcomingCampaigns: upcomingCampaignsMock,
  activeCampaigns: activeCampaignsMock,
  finishedCampaigns: [] //finishedCampaignsMock
};

export const adminPanelDataMockEmpty: CampaignsData = {
  upcomingCampaigns: [],
  activeCampaigns: [],
  finishedCampaigns: []
};

export const adminPanelDataMockOnlyUpcoming: CampaignsData = {
  upcomingCampaigns: [], //upcomingCampaignsMock,
  activeCampaigns: [],
  finishedCampaigns: []
};

export const adminPanelDataMockOnlyActive: CampaignsData = {
  upcomingCampaigns: [],
  activeCampaigns: [], //activeCampaignsMock,
  finishedCampaigns: []
};

export const adminPanelDataMockOnlyFinished: CampaignsData = {
  upcomingCampaigns: [],
  activeCampaigns: [],
  finishedCampaigns: [] //finishedCampaignsMock
};
