import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { ActivitiesState } from './activities.state';
import { ActivityKind } from '../../client/activities/activities.model';

export namespace ActivitiesSelectors {
  const state = (state: AppState) => state.activities;

  export const selectActivities = createSelector(state, (state: ActivitiesState) => state?.activities);

  export const selectActivityDetails = createSelector(state, (state: ActivitiesState) => state?.activityDetails);

  export const selectActivityImageIndex = createSelector(
    state,
    (state: ActivitiesState) => state?.activityDetails?.selectedImageIndex
  );

  export const selectActivityTypes = createSelector(state, (state: ActivitiesState) => state?.activityTypes);

  export const selectAllActivityTypes = createSelector(state, (state: ActivitiesState) => state?.activityTypes?.all);

  export const selectDistanceActivityTypes = createSelector(
    ActivitiesSelectors.selectAllActivityTypes,
    (allActivities) => allActivities?.filter((activity) => activity.kind === ActivityKind.DISTANCE)
  );

  export const selectTimeActivityTypes = createSelector(
    ActivitiesSelectors.selectAllActivityTypes,
    (allActivities) => allActivities?.filter((activity) => activity.kind === ActivityKind.TIME)
  );

  export const isLoaded = createSelector(state, (state: ActivitiesState) => !state?.loading && state?.loaded);

  export const isLoading = createSelector(state, (state: ActivitiesState) => state?.loading);

  export const selectCurrentMarker = createSelector(state, (state: ActivitiesState) => state?.activitiesCurrentMarker);

  export const selectManualUploadStatus = createSelector(state, (state: ActivitiesState) => state?.manualUpload.status);

  export const selectConversionRates = createSelector(state, (state: ActivitiesState) => state?.conversionRates);
}
