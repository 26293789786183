<div fxHide.gt-sm class="nav-footer-mobile" fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start center">
  <div class="viessmann-vimove-for-climate-small" fxLayout fxLayoutAlign="center center">
    <img class="company-logo" [src]="workspacesStore.logoPoweredBy$ | async"
         alt="company-logo"/><span>&bull;</span><span>ViMove for Climate</span>
  </div>
  <div class="viessmann-vimove-for-climate-small">
    <a routerLink="/privacypolicy" [queryParams]="{lang: currentLang}"
       target="_blank">{{ 'profile_option_privacy_policy' | translate }}</a>
  </div>
  <div class="viessmann-vimove-for-climate-small">
    <a routerLink="/termsofuse" [queryParams]="{lang: currentLang}"
       target="_blank">{{ 'privacy_policy_modal_terms_of_use' | translate }}</a>
  </div>
  <div fxLayout="row" fxLayoutGap="16px">
    <div class="social-media">
      <a href="https://www.facebook.com/viessmann/" target="_blank">
        <mat-icon svgIcon="facebook"></mat-icon>
      </a>
    </div>
    <div class="social-media">
      <a href="https://www.instagram.com/viessmann" target="_blank">
        <mat-icon svgIcon="twitter"></mat-icon>
      </a>
    </div>
    <div class="social-media">
      <a href="https://www.linkedin.com/company/viessmann" target="_blank">
        <mat-icon svgIcon="linkedin"></mat-icon>
      </a>
    </div>
  </div>
</div>
<div fxHide.lt-md class="nav-footer" fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="space-between center">
  <div>
    <div class="viessmann-vimove-for-climate" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ng-container *ngIf="workspacesStore.logoPoweredBy$ | async as logoPoweredBy">
        <img class="company-logo" [src]="logoPoweredBy"
             alt="Powered by"/>
        <span aria-hidden="true">&bull;</span>
      </ng-container>
      <span
        aria-label="ViMove for climate application name">ViMove for Climate</span>
      <span aria-hidden="true">&bull;</span>
      <a routerLink="/privacypolicy" [queryParams]="{lang: currentLang}"
         target="_blank">{{ 'profile_option_privacy_policy' | translate }}</a>
      <span>&bull;</span>
      <a routerLink="/termsofuse" [queryParams]="{lang: currentLang}"
         target="_blank">{{ 'privacy_policy_modal_terms_of_use' | translate }}</a>
      <span>&bull;</span>
      <a [href]="licencesUrl" target="_blank">{{ 'profile_option_open_source_licenses' | translate }}</a>
    </div>
  </div>

  <div>
    <div class="social-media" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
        <a class="social-media-link" aria-label="Share on facebook" href="https://www.facebook.com/viessmann/"
           target="_blank">
          <mat-icon svgIcon="facebook"></mat-icon>
        </a>
        <a class="social-media-link" aria-label="Share on Twitter" href="https://twitter.com/Viessmann" target="_blank">
          <mat-icon svgIcon="twitter"></mat-icon>
        </a>
        <a class="social-media-link" aria-label="Share on LinkedIn" href="https://www.linkedin.com/company/viessmann"
           target="_blank">
          <mat-icon svgIcon="linkedin"></mat-icon>
        </a>
      </div>
      <div
        [matMenuTriggerFor]="menu"
        (menuClosed)="menuClosed()"
        (menuOpened)="menuOpened()"
        #t="matMenuTrigger"
        fxLayout="row"
        fxLayoutGap="10px"
        class="language"
      >
        <div>
          <span [class]="isLanguageMenuOpened ? 'white' : 'grey'">{{
            (currentLanguage$ | async)?.toUpperCase() ?? translateService.currentLang.toUpperCase()
            }}</span>
        </div>
        <vim-arrow
          [color]="isLanguageMenuOpened ? 'white' : 'secondary'"
          fontWeight="500"
          [orientation]="isLanguageMenuOpened ? 'up' : 'down'"
        ></vim-arrow>
      </div>
    </div>
  </div>
</div>
<mat-menu #menu="matMenu" class="language-menu menu-dark" yPosition="above">
  <button mat-menu-item (click)="selectLanguage(languageEnum.german)" class="language-menu-item">
    <img alt="de-flag" [src]="languageFlagService.getFlagPath(languageEnum.german)"/><span>&nbsp;Deutsch / German</span>
  </button>
  <button mat-menu-item (click)="selectLanguage(languageEnum.english)" class="language-menu-item">
    <img alt="en-flag" [src]="languageFlagService.getFlagPath(languageEnum.english)"/><span>&nbsp;English</span>
  </button>
  <button mat-menu-item (click)="selectLanguage(languageEnum.french)" class="language-menu-item">
    <img alt="fr-flag"
         [src]="languageFlagService.getFlagPath(languageEnum.french)"/><span>&nbsp;Français / French</span>
  </button>
  <button mat-menu-item (click)="selectLanguage(languageEnum.italy)" class="language-menu-item">
    <img alt="it-flag"
         [src]="languageFlagService.getFlagPath(languageEnum.italy)"/><span>&nbsp;Italiano / Italian</span>
  </button>
  <button mat-menu-item (click)="selectLanguage(languageEnum.spanish)" class="language-menu-item">
    <img alt="it-flag"
         [src]="languageFlagService.getFlagPath(languageEnum.spanish)"/><span>&nbsp;Español  / Spanish</span>
  </button>
  <!-- <button mat-menu-item (click)="selectLanguage(languageEnum.polish)" class="language-menu-item">
    <img alt="pl-flag" [src]="languageFlagService.getFlagPath(languageEnum.polish)" /><span>Polski / Polish</span>
  </button> -->
</mat-menu>
