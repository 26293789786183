<div fxLayout fxLayoutAlign="center" fxLayoutGap="48px">
  <img src="assets/images/qr-codes/app-store-qrcode.png"
       alt="Apple Store qrcode" width="128">
  <div fxLayout="column">
    <div class="DisplayRegularHeading-6">{{'profile_connections_download_mobile_app_title' | translate}}</div>
    <div class="ParagraphParagraph-3"
         fxFlex="0 0  800px" fxFlex.xs="100%">{{'profile_connections_download_mobile_app_text' | translate}}</div>
    <div style="flex-grow: 1; min-height: 8px;"></div>
    <div fxLayout fxLayoutAlign="space-between">
      <a class="store-link" [href]="settings.APPLE_STORE_URL">
        <img class="app-store-img" src="assets/images/profile-connections/app-store.svg" alt="App Store link">
      </a> <a class="store-link" [href]="settings.GOOGLE_PLAY_URL">
      <img class="google-play-img" src="assets/images/profile-connections/google-play.svg" alt="Google play link">
    </a>
    </div>
  </div>
  <img src="assets/images/qr-codes/google-play-qrcode.png"
       alt="Google Play qrcode" width="128">
</div>
