import { Component, ChangeDetectionStrategy, Input, HostBinding, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { WorkspacesStore } from 'src/app/store/workspaces/workspaces.store';

@Component({
  selector: 'vim-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsNewComponent implements OnInit {
  @HostBinding('class.popup-mode') popupModeClass: boolean;
  @Input() popupMode: boolean;
  @Output() finished = new EventEmitter<boolean>();

  public readonly tabCount = 3;

  public textRows$ = this.translateService.get('whats_new_ver_1_1_x').pipe(map((text: string) => text.split('\n')));

  constructor(
    public applicationStore: WorkspacesStore,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.popupModeClass = this.popupMode;
  }
}
