import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { GarminStore } from '../../../../store/garmin/garmin.store';

@Component({
  selector: 'vim-edit-profile-mobile-menu',
  templateUrl: './profile-mobile-menu.component.html',
  styleUrls: ['./profile-mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMobileMenuComponent {
  constructor(
    public garminStore: GarminStore,
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {}

  logout(): void {
    this.oidcSecurityService.logoff();
    void this.router.navigate(['/login']);
  }
}
