import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignData } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-new-campaign-info',
  templateUrl: './new-campaign-info.component.html',
  styleUrls: ['./new-campaign-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCampaignInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public campaign: CampaignData,
    public dialogRef: MatDialogRef<NewCampaignInfoComponent>
  ) {}

  onJoinCampaign() {
    this.dialogRef.close(this.campaign._id);
  }
}
