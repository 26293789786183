import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { AdminData } from './models/admin-panel.model';
import {
  CampaignDetailsPatchPayload,
  CampaignDetailsPostPayload,
  CampaignDetailsResponse,
  CampaignItem,
  CampaignItemLightResponse
} from './models/admin-panel-campaign.model';
import {
  InsightDetailsPatchPayload,
  InsightDetailsPostPayload,
  InsightDetailsResponse,
  InsightItem
} from './models/admin-panel-insight.model';
import {
  BadgeDetailsPatch,
  BadgeDetailsPost,
  BadgeDetailsResponse,
  BadgeItemResponse
} from './models/admin-panel-badge.model';
import { MarketingNotificationPost } from './models/admin-panel-marketing-notification.model';
import { map } from 'rxjs/operators';
import { CampaignUnit } from '../campaigns/campaignsData';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelService {
  constructor(private httpService: HttpService) {}

  getBadges(workspaceId: string): Observable<BadgeItemResponse[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/badges`);
  }

  getCampaignsLight(workspaceId: string): Observable<CampaignItemLightResponse[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/light/campaigns`);
  }

  getCampaigns(workspaceId: string): Observable<{ data: CampaignItem[]; total: number }> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/campaigns`);
  }

  getCampaignDetails(workspaceId: string, campaignId: string): Observable<CampaignDetailsResponse> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/campaigns/${campaignId}`);
  }

  createCampaign(workspaceId: string, campaignDetails: CampaignDetailsPostPayload): Observable<any> {
    return this.httpService.post(
      `/workspaces/${workspaceId}/admin/campaigns`,
      {},
      campaignDetails as unknown as Record<string, unknown>
    );
  }

  createCampaignDraft(workspaceId: string, campaignDetails: Partial<CampaignDetailsPostPayload>): Observable<any> {
    return this.httpService.post(
      `/workspaces/${workspaceId}/admin/draft-campaigns`,
      {},
      campaignDetails as unknown as Record<string, unknown>
    );
  }

  updateCampaign(
    workspaceId: string,
    campaignId: string,
    campaignDetails: CampaignDetailsPatchPayload
  ): Observable<any> {
    return this.httpService.patch(
      `/workspaces/${workspaceId}/admin/campaigns/${campaignId}`,
      {},
      campaignDetails as unknown as Record<string, unknown>
    );
  }

  updateCampaignDraft(
    workspaceId: string,
    campaignId: string,
    campaignDetails: CampaignDetailsPatchPayload
  ): Observable<any> {
    return this.httpService.patch(
      `/workspaces/${workspaceId}/admin/draft-campaigns/${campaignId}`,
      {},
      campaignDetails as unknown as Record<string, unknown>
    );
  }

  deleteCampaign(workspaceId: string, campaignId: string): Observable<any> {
    return this.httpService.delete(`/workspaces/${workspaceId}/admin/campaigns/${campaignId}`, {});
  }

  deleteCampaignDraft(workspaceId: string, campaignId: string): Observable<any> {
    return this.httpService.delete(`/workspaces/${workspaceId}/admin/draft-campaigns/${campaignId}`, {});
  }

  getInsights(workspaceId: string): Observable<InsightItem[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/insights`);
  }

  getInsightDetails(workspaceId: string, insightId: string): Observable<InsightDetailsResponse> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/insights/${insightId}`);
  }

  deleteInsight(workspaceId: string, insightId: string): Observable<any> {
    return this.httpService.delete(`/workspaces/${workspaceId}/admin/insights/${insightId}`, {});
  }

  createInsight(workspaceId: string, insightDetails: InsightDetailsPostPayload): Observable<any> {
    return this.httpService.post(
      `/workspaces/${workspaceId}/admin/insights`,
      {},
      insightDetails as unknown as Record<string, unknown>
    );
  }

  updateInsight(workspaceId: string, insightId: string, insightDetails: InsightDetailsPatchPayload): Observable<any> {
    return this.httpService.patch(
      `/workspaces/${workspaceId}/admin/insights/${insightId}`,
      {},
      insightDetails as Record<string, unknown>
    );
  }

  createBadge(workspaceId: string, badgeDetails: BadgeDetailsPost): Observable<any> {
    return this.httpService.post(
      `/workspaces/${workspaceId}/admin/badges`,
      {},
      badgeDetails as unknown as Record<string, unknown>
    );
  }

  updateBadge(workspaceId: string, badgeId: string, badgeDetails: BadgeDetailsPatch): Observable<any> {
    return this.httpService.patch(
      `/workspaces/${workspaceId}/admin/badges/${badgeId}`,
      {},
      badgeDetails as Record<string, unknown>
    );
  }

  getBadgeDetails(workspaceId: string, badgeId: string): Observable<BadgeDetailsResponse> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/badges/${badgeId}`);
  }

  deleteBadge(workspaceId: string, badgeId: string): Observable<any> {
    return this.httpService.delete(`/workspaces/${workspaceId}/admin/badges/${badgeId}`, {});
  }

  createMarketingNotification(workspaceId: string, notification: MarketingNotificationPost): Observable<any> {
    return this.httpService.post(`/workspaces/${workspaceId}/admin/notifications/marketing`, {}, notification);
  }

  getAdminData(): Observable<AdminData> {
    return this.httpService.get(`/admin/me`);
  }

  getUnits(workspaceId: string): Observable<CampaignUnit[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/units`);
  }

  getActivities(workspaceId: string): Observable<string[]> {
    return this.httpService.get(`/workspaces/${workspaceId}/admin/badges/activity-types`);
  }

  uploadInsightImage(file: File): Observable<{ file: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService.post(`/admin/images`, {}, formData as Record<string, any>);
  }

  uploadBadgeImage(workspaceId: string, file: File): Observable<{ id: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService.post(`/workspaces/${workspaceId}/admin/badges/image`, {}, formData as Record<string, any>);
  }

  uploadCampaignImage(workspaceId: string, file: File, imagePath: string): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpService
      .post(`/workspaces/${workspaceId}/admin/campaigns/${imagePath}`, {}, formData as Record<string, any>)
      .pipe(map((result) => (result as { id: string }).id));
  }
}

export enum CampaignImagePath {
  activityShareImage = 'activity-share-image',
  companyLogo = 'company-logo',
  image = 'image',
  imageDashboardShare = 'image-dashboard-share',
  imageLarge = 'image-large',
  imageSmall = 'image-small',
  shareImage = 'share-image'
}
