import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../state';
import { CampaignsActions } from './campaigns.actions';
import { CampaignsSelectors } from './campaigns.selectors';
import { filter } from 'rxjs/operators';

@Injectable()
export class CampaignsStore {
  userCampaign$ = this.store$.pipe(select(CampaignsSelectors.selectUserCampaign));
  userCampaignId$ = this.store$.pipe(select(CampaignsSelectors.selectUserCampaignId));
  campaignUserData$ = this.store$.pipe(select(CampaignsSelectors.selectUserData));
  dashboardShareImage$ = this.store$.pipe(select(CampaignsSelectors.selectDashboardShareImage));
  dashboardColor$ = this.store$.pipe(select(CampaignsSelectors.selectDashboardColor));
  userCampaignUnit$ = this.store$.pipe(select(CampaignsSelectors.selectCurrentCampaignUnit));
  activeCampaigns$ = this.store$.pipe(select(CampaignsSelectors.selectActiveCampaigns));
  pastCampaigns$ = this.store$.pipe(select(CampaignsSelectors.selectPastCampaigns));
  availableCampaigns$ = this.store$.pipe(select(CampaignsSelectors.selectAvailableCampaigns));
  noActiveCampaign$ = this.store$.pipe(
    select(CampaignsSelectors.selectNoActiveCampaign),
    filter((result) => result !== null)
  );
  isLoaded$ = this.store$.pipe(select(CampaignsSelectors.isLoaded));
  isLoading$ = this.store$.pipe(select(CampaignsSelectors.isLoading));
  userData$ = this.store$.pipe(select(CampaignsSelectors.selectUserData));
  joinCampaignData$ = this.store$.pipe(select(CampaignsSelectors.selectJoinCampaignData));
  randomSharingData$ = this.store$.pipe(select(CampaignsSelectors.selectRandomSharingData));
  campaignDetailsViewModel$ = this.store$.pipe(select(CampaignsSelectors.selectCampaignDetailsViewModel));

  getCampaigns(): void {
    this.store$.dispatch(CampaignsActions.getAllCampaignsData());
  }

  getCampaignUserData(): void {
    this.store$.dispatch(CampaignsActions.getCampaignUserData());
  }

  joinCampaign(campaignId: string): void {
    this.store$.dispatch(CampaignsActions.joinCampaign({ campaignId }));
  }

  leaveCampaign(campaignId: string): void {
    this.store$.dispatch(CampaignsActions.leaveCampaign({ campaignId }));
  }

  clear(): void {
    this.store$.dispatch(CampaignsActions.clearCampaignsData());
  }

  constructor(private store$: Store<AppState>) {}
}
