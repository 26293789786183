import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'vim-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent {
  @Input()
  public chartData: ChartData<'pie', number[], string | string[]> | undefined;

  @Input()
  valuesAsPercentage: boolean = true;
  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        formatter: (value: number, ctx) => {
          if (ctx.chart.data.labels) {
            return [ctx.chart.data.labels[ctx.dataIndex], `${value.toFixed(2)}${this.valuesAsPercentage ? '%' : ''}`];
          }

          return '';
        }
      }
    }
  };

  public pieChartType: ChartType = 'pie';

  public chartPlugins = [DatalabelsPlugin];

  constructor() {}
}
