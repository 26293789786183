<img
  [src]="data.images[selectedImageIndex || 0].image"
  alt="Shared image"
  class="selected-image">
<div class="text-container" [style.color]="textColor">
  <div *ngIf="!data.singleRow" class="text-container__contribution">
    <div class="text LabelLabel-7"
         [style.margin-bottom.px]="8">{{'dashboard_you_contributed' | translate |uppercase}}</div>
    <div class="text DisplayBoldHeading-2">{{data.points | roundDown:1}}</div>
    <div class="LabelLabel-2"
         [style.margin-bottom.px]="48">{{data.unit | unitToKey | plural: data.points | translate}}</div>
  </div>
  <div *ngIf="data.activityName"
       class="text-container__activity-name DisplayBoldHeading-5">{{data.activityName |translate| titlecase}}</div>
  <div fxLayout fxLayoutAlign="space-evenly" class="text-container__activity-details">
    <div *ngIf="data.singleRow" class="text-container__activity-details__item">
      <div class="text LabelLabel-7">{{'common_contributed' | translate | uppercase}}</div>
      <div fxLayout>
        <div [class.DisplayBoldHeading-6]="!data.singleRow"
             [class.DisplayBoldHeading-8]="data.singleRow">{{data.points|roundDown:pointsFractionalDigits}}&nbsp;{{data.unit | unitToKey:true | plural: data.points|translate}}</div>
      </div>
    </div>
    <div *ngIf="data.distance" class="text-container__activity-details__item">
      <div class="text LabelLabel-7">{{'common_distance' | translate | uppercase}}</div>
      <div fxLayout>
        <div *ngIf="data.distance as distance">
          <ng-container *ngIf="distance >= 1000 else distanceInMeters">
            <div [class.DisplayBoldHeading-6]="!data.singleRow"
                 [class.DisplayBoldHeading-8]="data.singleRow">{{ distance / 1000 | roundDown:distanceFractionalDigits }}&nbsp;{{'unit_km' | translate }}</div>
          </ng-container>
          <ng-template #distanceInMeters>
            <div [class.DisplayBoldHeading-6]="!data.singleRow"
                 [class.DisplayBoldHeading-8]="data.singleRow">{{ distance | roundDown:distanceFractionalDigits }}&nbsp;{{'unit_meter' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="data.time" class="text-container__activity-details__item">
      <div class="LabelLabel-7">{{'common_time' | translate | uppercase}}</div>
      <div fxLayout>
        <div *ngIf="time.hours">
          <div [class.DisplayBoldHeading-6]="!data.singleRow"
               [class.DisplayBoldHeading-8]="data.singleRow">{{ time.hours }}{{'unit_hour_si' | translate}}</div>
        </div>
        <div *ngIf="time.hours && time.minutes">&nbsp;</div>
        <div *ngIf="time.minutes">
          <div [class.DisplayBoldHeading-6]="!data.singleRow"
               [class.DisplayBoldHeading-8]="data.singleRow">{{ time.minutes }}{{'unit_minute_si' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

