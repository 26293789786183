import { Directive, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { NgControl } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { ButtonComponent } from '../../components/button/button.component';
import { ProgressButtonComponent } from '../../components/progress-button/progress-button.component';
import { AdminPanelImageComponent } from '../../../../ui/admin-panel/common/admin-panel-image/admin-panel-image.component';
import { AdminPanelStore } from '../../../../store/admin-panel/admin-panel.store';

@Directive({
  selector: '[vimDisableIfNotAdminEditor]'
})
export class DisableIfNotAdminEditorDirective implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private adminPanelStore: AdminPanelStore,
    @Optional() private control: NgControl,
    @Optional() private button: ButtonComponent,
    @Optional() private progressButton: ProgressButtonComponent,
    @Optional() private imageComponent: AdminPanelImageComponent
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.adminPanelStore.canEditAdminContent$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((canEdit) => canEdit === false)
      )
      .subscribe(() => {
        if (this.control) {
          this.control.control?.disable();
        }
        if (this.button) {
          this.button.disabled = true;
        }
        if (this.progressButton) {
          this.progressButton.disabled = true;
        }
        if (this.imageComponent) {
          this.imageComponent.disabled = true;
        }
      });
  }
}
