<div>
  <ng-container *ngIf="showHeader">
    <p class="upload-images-title">{{ 'upload_image_chooser_title' | translate }}</p>
    <p class="upload-images-subtitle">
      {{ 'upload_activity_selector_desc' | translate }}
    </p>
  </ng-container>
  <file-upload [class.enabled]="enabled" [class.invalid]="markAsInvalid" accept="image/png,image/jpg,image/jpeg"
               [control]="fileUploadControl" class="file-upload-control" [multiple]="false">
    <ng-template #placeholder>
      <span class="drag-drop-area-title"><span *ngIf="!isMobile" class="green-text"><img class="cloud-icon"
                                                                                         alt="first-upload-icon"
                                                                                         src="../../../../../assets/svg/icon-cloud-upload.svg"/>
        {{ 'manual_upload_drag' | translate }}&nbsp;
        </span>
        <span *ngIf="!isMobile">{{ 'manual_upload_or_browser' | translate }}</span>
        <span *ngIf="isMobile">{{ 'manual_upload_browse_files' | translate }}</span>
      </span>
      <span class="upload-btn"><img class="upload-icon" alt="upload-icon"
                                    src="../../../../../assets/svg/icon-upload.svg"/>{{
        'manual_upload_upload_files' | translate
        }}</span>
      <span class="drag-drop-area-footer">{{ imageRequirementsDescription | translate }}</span>
    </ng-template>
  </file-upload>
</div>
