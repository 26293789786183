import { createAction, props } from '@ngrx/store';
import { Notification } from '../../client/events/events.models';

export namespace NotificationsActions {
  export const getNotificationsCount = createAction('[NOTIFICATIONS] Get notifications count');
  export const getNotificationsCountSuccess = createAction(
    '[NOTIFICATIONS] Get notifications count success',
    props<{ notificationsCount: number }>()
  );
  export const getNotificationsCountError = createAction('[NOTIFICATIONS] Get notifications count error');
  export const getNotifications = createAction(
    '[NOTIFICATIONS] Get notifications',
    props<{
      skip?: number;
      limit?: number;
    }>()
  );
  export const getNotificationsSuccess = createAction(
    '[NOTIFICATIONS] Get notifications success',
    props<{ notifications: Notification[]; notificationsCount: number }>()
  );
  export const getNotificationsError = createAction('[NOTIFICATIONS] Get notifications error');

  export const markAsRead = createAction('[NOTIFICATIONS] Mark as read', props<{ ids: string[] }>());
  export const markAsReadSuccess = createAction('[NOTIFICATIONS] Mark as read success');
  export const markAsReadError = createAction('[NOTIFICATIONS] Mark as read error');
}
