import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegalService } from '../../client/legal/legal.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vim-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent {
  constructor(
    private route: ActivatedRoute,
    private legalService: LegalService
  ) {}

  privacyPolicy$ = this.legalService
    .getCurrentPrivacyPolicy(this.route.snapshot.queryParamMap.get('lang') || 'en')
    .pipe(map((response) => response.content));
}
