import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kilosToTons'
})
export class KilosToTonsPipe implements PipeTransform {
  transform(kilos: number | null | undefined): string {
    const isNull = kilos === undefined || kilos === null;
    const isNumberCorrect = typeof kilos === 'number' && !isNaN(kilos) && kilos >= 0;

    if (isNull || !isNumberCorrect) {
      return '0';
    }

    return `${kilos / 1000}`;
  }
}
