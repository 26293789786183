import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  getInvitationParams(): Invitation | null {
    const invitation = this.getItem(StorageItem.invitation);
    return invitation ? (JSON.parse(invitation) as Invitation) : null;
  }

  setInvitationParams(invitation: Invitation): void {
    this.setItem(StorageItem.invitation, JSON.stringify(invitation));
  }

  clearInvitationParams(): void {
    this.clearItem(StorageItem.invitation);
  }

  getCurrentLanguage(): string | null {
    return this.getItem(StorageItem.currentLanguage);
  }

  setCurrentLanguage(language: string): void {
    this.setItem(StorageItem.currentLanguage, language);
  }

  clearCurrentLanguage(): void {
    this.clearItem(StorageItem.currentLanguage);
  }

  getWorkspaceId(): string | null {
    return this.getItem(StorageItem.workspaceId);
  }

  setWorkspaceId(workspaceId: string): void {
    this.setItem(StorageItem.workspaceId, workspaceId);
  }

  clearWorkspaceId(): void {
    this.clearItem(StorageItem.workspaceId);
  }

  getAccessToken(): string | null {
    return this.getItem(StorageItem.authToken);
  }

  setLogoutMarker(): void {
    this.setItem(StorageItem.logoutMarker, 'true');
    this.clearItem(StorageItem.logoutMarker);
  }

  private setItem(item: StorageItem, value: string): void {
    localStorage.setItem(item, value);
  }

  private getItem(item: StorageItem): string | null {
    return localStorage.getItem(item);
  }

  private clearItem(item: StorageItem): void {
    localStorage.removeItem(item);
  }
}

export enum StorageItem {
  invitation = 'invitation',
  currentLanguage = 'lang',
  authToken = 'authToken',
  workspaceId = 'workspaceId',
  logoutMarker = 'logoutMarker'
}

export interface Invitation {
  teamId: string;
  workspaceId?: string;
}
