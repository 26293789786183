import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { LiveTickerReducer } from './live-ticker.reducer';
import { LiveTickerStore } from './live-ticker-store';
import { EffectsModule } from '@ngrx/effects';
import { LiveTickerEffects } from './live-ticker.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('liveTicker', LiveTickerReducer.reducer),
    EffectsModule.forFeature([LiveTickerEffects])
  ],
  providers: [LiveTickerStore]
})
export class LiveTickerStoreModule {}
