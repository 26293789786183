<vim-dialog-template [title]="'teams_create_invite_member_header' | translate" (closeDialog)="dialogRef.close()">
  <div fxLayout='column'>
    <form [formGroup]='form' (keydown.enter)='$event.preventDefault();' (ngSubmit)='inviteMembers()'>
      <div fxLayoutGap='24px' fxLayout='column' fxFlex='1 1 auto'>
        <h2>{{'teams_create_invite_member_header' | translate}}&nbsp;<span class='members-max-header'>(Max. 15)</span>
        </h2>
        <h3 class='invite-subtitle'>{{'team_member_invite_via_email' | translate}}</h3>
        <vim-email-list [formGroup]='form' controlName='memberEmails'></vim-email-list>
        <button type='submit' [disabled]='form.invalid || !emailsControls.controls?.length' mat-button vim-button
                fxFlexAlign='stretch' class='invite-button'
                color='primary' id='inviteMember'>{{'team_member_invite_email_invite' | translate}}
        </button>
        <mat-divider></mat-divider>
      </div>
    </form>
    <h3>{{'team_member_invite_share_description' | translate}}</h3>
    <div class='url-input-container' fxLayout='row' fxFlex='1 1 auto' fxLayoutGap='24px' fxLayout.gt-md='row'
         fxLayout.lt-md='column'>
      <mat-form-field fxFlexAlign.gt-md='center' fxFlex='1 1 auto' fxFlexAlign.lt-md='stretch' fxFlex.gt-md='75'
                      appearance='outline'
                      floatLabel='auto'>
        <mat-label></mat-label>
        <input readonly matInput
               value='{{data.invitationLink}}' id='inviteLink'/>
      </mat-form-field>
      <button fxFlex.gt-md='25' fxFlexAlign.gt-md='center' fxFlexAlign.lt-md='stretch' color='secondary' vim-button
              mat-button (click)='copyLink()' id='copyInvitationLink'>
        {{'invite_friend_copy_link' | translate}}
      </button>
    </div>
  </div>
</vim-dialog-template>
