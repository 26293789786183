import { createAction, props } from '@ngrx/store';
import {
  ActivitiesData,
  ActivitiesParams,
  ActivityToUpload,
  ActivityItem,
  ActivityPatchPayload
} from '../../client/activities/activities.model';
import { ActivityDetails } from './activities.state';

export namespace ActivitiesActions {
  export const getActivities = createAction('[ACTIVITIES] Get activities', props<{ params: ActivitiesParams }>());
  export const getActivitiesSuccess = createAction(
    '[ACTIVITIES] Get activities success',
    props<{ activities: ActivitiesData }>()
  );
  export const getActivitiesError = createAction('[ACTIVITIES] Get activities error');
  export const getMoreActivities = createAction(
    '[ACTIVITIES] Get more activities',
    props<{ campaign: 'active' | 'past'; limit: number }>()
  );
  export const getMoreActivitiesSuccess = createAction(
    '[ACTIVITIES] Get more activities success',
    props<{ activities: ActivitiesData }>()
  );
  export const getMoreActivitiesError = createAction('[ACTIVITIES] Get more activities error');

  export const getActivityDetails = createAction('[ACTIVITIES] Get activityDetails', props<{ activityId: string }>());
  export const getActivityDetailsSuccess = createAction(
    '[ACTIVITIES] Get activity details success',
    props<{ activityDetails: ActivityDetails }>()
  );
  export const getActivityDetailsError = createAction('[ACTIVITIES] Get activityDetails error');

  export const getActivityTypes = createAction('[ACTIVITIES] Get activity types');
  export const getActivityTypesSuccess = createAction(
    '[ACTIVITIES] Get activity types success',
    props<{ activityTypes: { top: ActivityItem[]; other: ActivityItem[] } }>()
  );
  export const getActivityTypesError = createAction('[ACTIVITIES] Get activity types error');

  //ToDo: move to separate store
  export const uploadActivity = createAction(
    '[ACTIVITIES] Upload activity',
    props<{ activity: ActivityToUpload<File> }>()
  );
  export const uploadActivitySuccess = createAction('[ACTIVITIES] Upload activity success', props<{ images: any }>());
  export const uploadActivityError = createAction('[ACTIVITIES] Upload activity error');
  export const deleteActivity = createAction('[ACTIVITIES] delete activity', props<{ id: string }>());
  export const deleteActivitySuccess = createAction('[ACTIVITIES] delete activity success', props<{ id: string }>());
  export const deleteActivityError = createAction('[ACTIVITIES] delete activity error');

  export const updateActivity = createAction(
    '[ACTIVITIES] Update activity',
    props<{ activityId: string; activityData: ActivityPatchPayload<File> }>()
  );
  export const updateActivitySuccess = createAction(
    '[ACTIVITIES] Update activity success',
    props<{ activityData: ActivityPatchPayload<File>; activityId: string }>()
  );
  export const updateActivityError = createAction('[ACTIVITIES] Update activity error');

  export const selectActivityImage = createAction(
    '[ACTIVITIES] Select activity image',
    props<{ selectedImageIndex: number }>()
  );
  export const clearUploadData = createAction('[ACTIVITIES] Clear upload data');
  export const clearActivityDetails = createAction('[ACTIVITIES] Clear activity details');
  export const clear = createAction('[ACTIVITIES] Clear activities data');
}
