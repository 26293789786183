export interface CampaignsData {
  data: CampaignData[];
}

export interface CampaignDetails {
  _id: string;
  type: CampaignType;
  startDate: string;
  endDate: string;
  maxPoints: number;
  points: number;
  distance: number;
  time: number;
  unit: CampaignUnit;
  title: string;
  subtitle: string;
  description: string;
  shortDescription: string;
  imageSmall: string;
  imageLarge: string;
  hashTag: string;
  imageDashboardShare: string;
  status: CampaignStatus;
  colorPrimary: string;
  dashboardColor: string;
  textColor: string;
  multipleTeamsEnabled: boolean;
  createTeamEnabled: boolean;
  manualUploadPointsLimit: number;
}

export interface CampaignData {
  _id: string;
  type: CampaignType;
  startDate: string;
  endDate: string;
  maxPoints: number;
  unit: CampaignUnit;
  title: string;
  shortDescription: string;
  imageSmall: string;
  imageLarge: string;
  imageLargeGrayscale: string;
  status: CampaignStatus;
}

export enum CampaignType {
  points = 'points',
  time = 'time'
}

export enum CampaignUnit {
  TREES = 'TREES',
  EURO = 'EUR',
  POINTS = 'POINTS',
  BEES = 'BEES'
}

export enum CampaignStatus {
  active = 'active',
  past = 'past',
  future = 'future'
}

export interface CampaignUserData {
  campaign?: string;
  distance: number;
  time: number;
  points: number;
  timePoints: number;
  distancePoints: number;
  unit: CampaignUnit;
}

export interface CampaignStatistics {
  points: number;
  distance: number;
  time: number;
}

export interface CampaignSharingData {
  image: string;
  color: string;
}
