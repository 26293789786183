import { Injectable } from '@angular/core';
import { CampaignsService } from '../../../client/campaigns/campaigns.service';
import { MatDialog } from '@angular/material/dialog';
import {
  CampaignDetailsComponent,
  CampaignDetailsDialogData
} from './dialogs/campaign-details/campaign-details.component';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { NewCampaignInfoComponent } from './dialogs/new-campaign-info/new-campaign-info.component';
import { CampaignData } from '../../../client/campaigns/campaignsData';
import { CampaignsStore } from '../../../store/campaigns/campaigns.store';
import { MessageService } from '../../../store/message.service';
import { StorageService } from '../../../shared/shared-ui/services/storage-service/storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CampaignsDialogService {
  isLoading$ = new Subject<string>();

  constructor(
    private campaignsService: CampaignsService,
    private matDialog: MatDialog,
    private campaignsStore: CampaignsStore,
    private messageService: MessageService,
    private storageService: StorageService,
    private router: Router
  ) {}

  openCampaignDetailsDialog(campaignId: string): void {
    this.isLoading$.next(campaignId);
    combineLatest([
      this.campaignsService.getCampaign(campaignId, this.storageService.getWorkspaceId()!),
      this.campaignsStore.activeCampaigns$,
      this.campaignsService.getCampaignUserStatistics(campaignId, this.storageService.getWorkspaceId()!)
    ])
      .pipe(
        take(1),
        tap(() => this.isLoading$.next(''))
      )
      .subscribe(
        ([campaignDetails, activeCampaigns, campaignUserStatistics]) => {
          const data: CampaignDetailsDialogData = {
            campaignDetails,
            isActive: !!activeCampaigns?.find((campaign) => campaign._id === campaignDetails._id),
            campaignUserStatistics
          };
          this.matDialog.open(CampaignDetailsComponent, {
            width: '700px',
            panelClass: 'new-campaign-info-dialogRef',
            hasBackdrop: true,
            data
          });
        },
        (e) => {
          this.messageService.showErrorSnackbar(e);
          this.isLoading$.next('');
        }
      );
  }

  openNewCampaignInfoDialog(activeCampaigns: CampaignData[]): void {
    const selectedCampaignId$ = this.matDialog
      .open(NewCampaignInfoComponent, {
        width: '700px',
        panelClass: 'new-campaign-info-dialogRef',
        hasBackdrop: true,
        data: activeCampaigns.reverse()[0]
      })
      .afterClosed()
      .pipe(
        take(1),
        filter((selectedCampaignId) => Boolean(selectedCampaignId))
      );
    selectedCampaignId$.subscribe(
      (selectedCampaignId: string) => {
        if (activeCampaigns.length === 1) {
          this.openCampaignDetailsDialog(selectedCampaignId);
        } else if (activeCampaigns.length > 0) {
          void this.router.navigate(['/home/campaigns']);
        }
      },
      (error) => this.messageService.showErrorSnackbar(error)
    );
  }
}
