import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vim-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowComponent {
  @Input() orientation: 'up' | 'right' | 'down' | 'left' = 'down';
  @Input() height = '100%';
  @Input() width = '100%';
  @Input() fontSize = '20px';
  @Input() fontWeight = '600';
  @Input() color: 'primary' | 'secondary' | 'white' | 'red' | 'green' = 'primary';
  constructor() {}
}
