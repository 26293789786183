<div>
  <mat-nav-list>
    <a routerLinkActive='active' routerLink='/home/dashboard' mat-list-item
       [mat-dialog-close]>{{'header_dashboard' | translate}}</a>
    <a routerLinkActive='active' routerLink='/home/teams' mat-list-item
       [mat-dialog-close]>{{'header_teams' | translate}}</a>
    <a routerLinkActive='active' routerLink='/home/activities' mat-list-item
       [mat-dialog-close]>{{'header_activities' | translate}}</a>
    <a routerLinkActive='active' routerLink='/home/campaigns' mat-list-item
       [mat-dialog-close]>{{'common_campaigns' | translate}}</a>
    <a routerLinkActive='active' routerLink='/home/achievements' mat-list-item
       [mat-dialog-close]>{{'common_achievements' | translate}}</a>
    <a routerLinkActive='active' routerLink='/home/about-us' mat-list-item
       [mat-dialog-close]>{{'header_about_us' | translate}}</a>
    <a mat-list-item (click)="showNotifications()"
       [mat-dialog-close]>
      <div fxLayout='row' fxLayoutGap='16px' fxLayoutAlign='start center'>
        <div class="notifications-image-wrapper">
          <mat-icon class="notifications-image" svgIcon="bell"></mat-icon>
          <div *ngIf="notificationsStore.unreadNotificationsCount$ | async" class="notifications-unread-mark"></div>
        </div>
        <div>
          <span>{{'common_notifications' | translate}}</span>
        </div>
      </div>
    </a>
    <a routerLinkActive='active' routerLink='/home/help' mat-list-item
       [mat-dialog-close]>{{'header_help' | translate}}</a>
    <a routerLinkActive='active' routerLink='/workspaces' mat-list-item
       [mat-dialog-close]><img class="workspace-image" [src]="workspacesStore.favicon$ | async"
                               alt="Workspace icon">{{'common_workspaces' | translate}}</a>
    <a routerLinkActive='active' (click)='openProfileMenu()' mat-list-item>
      <div fxLayout='row' fxLayoutGap='16px' fxLayoutAlign='start center'>
        <div class='header-item avatar-button'>
          <ng-container *ngIf='usersStore.me$ | async as me'>
            <span>{{me?.firstName?.charAt(0)}}{{me?.familyName?.charAt(0)}}</span>
          </ng-container>
        </div>
        <div>
          <span class='option-title'>{{'main_navigation_profile' | translate}}</span>
        </div>
        <vim-arrow orientation='right'></vim-arrow>
      </div>
    </a>
    <a routerLinkActive='active' (click)='openLanguageMenu()' mat-list-item>
      <div fxLayout='row' fxLayoutGap='16px' fxLayoutAlign='start center'>
        <img class='menu-image' alt='selected-flag'
             [src]='languageFlagService.getFlagPath((currentLanguage$ | async) ?? languageEnum.english)'/>
        <div>
          <span class='option-title'>{{'common_language' | translate}}</span>
        </div>
        <vim-arrow orientation='right'></vim-arrow>
      </div>
    </a>
  </mat-nav-list>
</div>
