import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vim-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewComponent {
  @Input() image: string;
  @Input() imageHeight = '200px';
  @Input() height = 'initial';
  @Input() hideDeleteButton = false;
  @Output() delete = new EventEmitter<void>();

  constructor() {}
}
