<ng-container *ngIf="(vm$ | async ) as vm">
  <mat-toolbar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <a routerLink="/home/dashboard/" class="logo-container">
        <img alt="logo" class="logo" [src]="vm.workspaceLogo"/>
      </a>
      <div>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide.lt-md class="header-items">
          <a class="header-item" routerLinkActive="active" [disableRipple]="true" routerLink="dashboard" mat-button>{{
            'header_dashboard' | translate
            }}</a>
          <a class="header-item" routerLinkActive="active" [disableRipple]="true" routerLink="teams" mat-button>{{
            'header_teams' | translate
            }}</a>
          <a class="header-item" routerLinkActive="active" [disableRipple]="true" routerLink="activities/current"
             mat-button>{{
            'header_activities' | translate
            }}</a>
          <a class="header-item" routerLinkActive="active" [disableRipple]="true" routerLink="campaigns"
             mat-button>{{
            'common_campaigns' | translate
            }}</a>
          <a class="header-item" routerLinkActive="active" [disableRipple]="true" routerLink="achievements"
             mat-button>{{
            'common_achievements' | translate
            }}</a>
          <a
            *ngIf="vm.workspaceName !== 'Facebook'; else aboutMenuTemplate"
            class="header-item"
            routerLinkActive="active"
            [disableRipple]="true"
            routerLink="about-us"
            mat-button
          >{{ 'header_about_us' | translate }}</a
          >
          <ng-template #aboutMenuTemplate>
            <a class="header-item" [disableRipple]="true" [matMenuTriggerFor]="aboutMenu"
               mat-button>{{ 'header_about_us' | translate }}</a>
            <mat-menu #aboutMenu="matMenu">
              <button class="profile-menu-item" routerLinkActive="active" routerLink="about-us/emea" mat-menu-item>EMEA
                Green Move
              </button>
              <button class="profile-menu-item" routerLinkActive="active" routerLink="about-us/become-ambassador"
                      mat-menu-item>
                Become a Sustainability Ambassador
              </button>
              <button class="profile-menu-item" routerLinkActive="active" routerLink="about-us/sustainability"
                      mat-menu-item>
                App Content for Sustainability Info Center
              </button>
              <button class="profile-menu-item" routerLinkActive="active" routerLink="about-us/climate-change"
                      mat-menu-item>
                Climate Change
              </button>
            </mat-menu>
          </ng-template>
          <div [mat-menu-trigger-for]="notifications" class="header-item notifications">
            <mat-icon class="notifications-icon" svgIcon="bell"></mat-icon>
            <div class="notifications-count DisplayRegularHeading-10"
                 *ngIf="notificationsStore.unreadNotificationsCount$ | async as notificationsCount">{{notificationsCount < 10 ? notificationsCount : '+9'}}</div>
          </div>
          <a class="header-item" routerLinkActive="active" [disableRipple]="true" routerLink="help" mat-button>{{
            'header_help' | translate
            }}</a>
          <a class="header-item" fxLayout="column" fxLayoutAlign="center" routerLinkActive="active"
             routerLink="/workspaces">
            <img [src]="vm.workspaceFavIcon" alt="Workspace icon" class="workspace-icon">
          </a>
          <div
            class="profile-header-item"
            [matMenuTriggerFor]="profile"
            (menuClosed)="isProfileMenuOpened = false"
            (menuOpened)="isProfileMenuOpened = true"
            fxLayout="row" fxLayoutAlign="start center">
            <div class="header-item avatar-button">
              <span>{{ vm.userInitials}}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout fxHide.gt-sm>
        <div (click)="showNotifications()" class="notifications notifications-mobile-only">
          <mat-icon class="notifications-icon" svgIcon="bell"></mat-icon>
          <div class="notifications-count DisplayRegularHeading-10"
               *ngIf="vm.unreadNotificationsCount as notificationsCount">{{notificationsCount < 10 ? notificationsCount : '+9'}}</div>
        </div>
        <button class="menu-mobile-button" mat-icon-button (click)="toggleDialog()">
          <mat-icon *ngIf="!isMobileMenuOpened" svgIcon="menu-mobile"></mat-icon>
          <mat-icon *ngIf="isMobileMenuOpened">close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-menu (closed)="markNotificationsAsRead()" #notifications class="notification-list">
    <div *ngIf="vm.notifications.length === 0"
         class="notification-list-menu-item">{{'notifications_empty_list_title' | translate}}</div>
    <div *ngFor="let notification of vm.notifications"
         class="menu-item notification-list-menu-item {{notification.status}}"
         fxLayout>
      <div class="notification-list-item-content">
        <img class="notification-list-image" [src]="notification.image" alt="Avatar icon">
        <div class="notification-list-texts">
          <div class="title LabelLabel-3">{{ notification.title }}</div>
          <div class="description LabelLabel-4">{{ notification.description }}</div>
          <div class="time LabelLabel-7">{{ notification.date | roundTime }}</div>
        </div>
      </div>
    </div>
  </mat-menu>
  <mat-menu #profile="matMenu" yPosition="above" class="menu-dark">
    <button
      routerLink="/home/profile/connections"
      mat-menu-item
      class="profile-menu-item"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon svgIcon="link-garmin"></mat-icon>
      <span>{{ 'profile_option_connections' | translate }}</span>
    </button>
    <button mat-menu-item class="profile-menu-item" routerLinkActive="active" routerLink="/home/profile/invite"
            id="invite">
      <mat-icon svgIcon="invite"></mat-icon>
      <span>{{ 'profile_option_invite_friends' | translate }}</span>
    </button>
    <button mat-menu-item class="profile-menu-item" routerLinkActive="active" routerLink="/home/profile" id="profile">
      <mat-icon svgIcon="edit-white"></mat-icon>
      <span>{{ 'profile_menu_edit' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="vm.canViewOrEditAdminContent" class="profile-menu-item"
            routerLinkActive="active"
            routerLink="/admin-portal"
            id="admin-portal">
      <mat-icon svgIcon="settings"></mat-icon>
      <span>{{ 'profile_admin_panel' | translate }}</span>
    </button>
    <button mat-menu-item class="profile-menu-item" (click)="logout()" id="logout">
      <mat-icon svgIcon="logout-white"></mat-icon>
      <span>{{ 'profile_menu_logout' | translate }}</span>
    </button>
  </mat-menu>
</ng-container>
