import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { UsersState } from './users.state';

export namespace UsersSelectors {
  const users = (state: AppState) => state.users;

  export const selectState = createSelector(users, (state: UsersState) => state);
  export const selectMe = createSelector(users, (state: UsersState) => state.me);
  export const selectLoading = createSelector(users, (state: UsersState) => state.loading);
  export const selectLoaded = createSelector(users, (state: UsersState) => state.loaded);
}
