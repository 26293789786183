import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../services/converter.service';

@Pipe({
  name: 'roundDown'
})
export class RoundDownPipe implements PipeTransform {
  transform(value: number, fractionDigits = 0): string {
    return ConverterService.roundDown(value, fractionDigits);
  }
}
