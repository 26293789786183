import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { TeamsLeaderboardData } from 'src/app/store/teams/teams.state';
import { Router } from '@angular/router';
import { CampaignUnit } from '../../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardListComponent {
  @Input() leaderboards!: TeamsLeaderboardData;
  @Input() createTeamEnabled: boolean;
  @Input() multipleTeamsEnabled: boolean;
  @Input() userInActiveCampaign: boolean;
  @Input() isLoading: boolean;
  @Input() showCategories: boolean;
  @Input() canJoinTeam: boolean;
  @Input() campaignUnit: CampaignUnit;
  @Output() getMoreLeaderBoardData = new EventEmitter();
  @Output() createTeam = new EventEmitter();

  constructor(private router: Router) {}

  inviteToTeam(index: number): void {
    const selectedLeaderId = this.leaderboards.data[index].id;
    void this.router.navigateByUrl(`/home/teams/details/${selectedLeaderId}`, { state: { teamSelectedByUser: true } });
  }
}
