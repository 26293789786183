import { Injectable } from '@angular/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import i18n_iso_countries from 'i18n-iso-countries/langs/en.json';
import i18n_iso_countries_de from 'i18n-iso-countries/langs/de.json';
import i18n_iso_countries_fr from 'i18n-iso-countries/langs/fr.json';
import i18n_iso_countries_it from 'i18n-iso-countries/langs/it.json';
import i18n_iso_countries_es from 'i18n-iso-countries/langs/es.json';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SelectCountryService {
  constructor(private translateService: TranslateService) {
    i18nIsoCountries.registerLocale(i18n_iso_countries);
    i18nIsoCountries.registerLocale(i18n_iso_countries_de);
    i18nIsoCountries.registerLocale(i18n_iso_countries_fr);
    i18nIsoCountries.registerLocale(i18n_iso_countries_it);
    i18nIsoCountries.registerLocale(i18n_iso_countries_es);
  }

  get countries(): Array<{ code: string; name: string }> {
    return Object.entries(i18nIsoCountries.getNames(this.translateService.currentLang)).map((entry) => ({
      code: entry[0].toLowerCase(),
      name: entry[1]
    }));
  }

  getAlpha2Code(name: string): string | undefined {
    return i18nIsoCountries.getAlpha2Code(name, this.translateService.currentLang);
  }

  getName(alpha2Code: string): string | undefined {
    return i18nIsoCountries.getName(alpha2Code, this.translateService.currentLang);
  }
}
