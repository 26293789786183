import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vim-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: ConfirmationDialogData,
    public dialogRef: MatDialogRef<boolean>
  ) {}

  get data(): ConfirmationDialogData {
    return { ...{ cancelButtonLabelKey: 'common_cancel', okButtonLabelKey: 'common_ok' }, ...this._data };
  }
}

export interface ConfirmationDialogData {
  cancelButtonLabel?: string;
  cancelButtonLabelKey?: string;
  okButtonLabel?: string;
  okButtonLabelKey?: string;
  title: string;
  message: string;
  hideCancelButton?: boolean;
}
