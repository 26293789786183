<div fxLayout fxLayoutAlign="space-between" fxLayoutGap="20px"
     *ngIf="campaignsStore.userCampaign$ | async as userCampaign">
  <div class="HeadingHeading-6" fxHide.xs>{{'teams_campaign_name' | translate}}</div>
  <mat-chip-listbox>
    <mat-chip-option [style.background-color]="userCampaign.colorPrimary"
                     class="campaign-chip"><span
      [style.color]="userCampaign.textColor">{{userCampaign.title}}</span>
    </mat-chip-option>
  </mat-chip-listbox>
</div>
