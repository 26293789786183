<div class='team' [routerLink]="['details', team._id]" fxHide.lt-md='true'>
  <div fxLayout='row' fxLayoutAlign='space-between stretch'>
    <div class='text' fxFlexAlign='center' fxFlex='50'>
      <div class='DisplayRegularHeading-5'>
        {{ team.name }}
      </div>
      <div fxLayout='row' class='info' fxLayoutGap='15px' fxLayoutAlign='center center'>
        <div class='team-info' fxLayout='row' fxFlex='48px' fxLayoutGap='5px' fxLayoutAlign='center center'>
          <mat-icon class='icon-teams' svgIcon='teams'></mat-icon>
          <span>{{ team.membersCount }}</span>
        </div>
        <div class='team-info' fxLayout='row' fxLayoutAlign='center center'>
          <mat-icon svgIcon='place'></mat-icon>
          <span>{{ team.rankingPlace }}</span>
          <vim-privacy-info [private]="team.privacy === teamPrivacy.closed"></vim-privacy-info>
        </div>
      </div>
    </div>
    <div fxFlex='648px' fxFlex.md='540px' fxLayout='row' fxLayoutGap='72px'>
      <div class='value Display-Bold-3'>
        <span *ngIf='team.distance'>{{ team.distance / 1000 | roundDown }}</span>
        <span *ngIf='!team.distance' class='undefined'>0</span>
        <span class='HeadingHeading-6'>{{'unit_meter' | translate}}</span>
      </div>
      <div class='value Display-Bold-3'>
        <div *ngIf='team.time'>
          <div *ngIf="team.time | singleTimeUnit as time">
            <div>{{time.value}}</div>
            <div class='HeadingHeading-6'>{{time.unit | translate}}</div>
          </div>
        </div>
        <div *ngIf='!team.time' class='undefined'>
          <div>0</div>
          <div class='HeadingHeading-6'>
            {{'unit_minute_si' | translate}}
          </div>
        </div>
      </div>

      <div class='value points-container Display-Bold-3' fxLayoutAlign='space-between start'>
        <div fxLayout='row'>
          <div fxLayout='column'>
            <div fxLayout='row'>
              <span class='icon-unit'><mat-icon svgIcon='{{campaignUnit | unitToIcon}}'></mat-icon></span>
              <span *ngIf='team.points' class='points-count'>{{ team.points | roundDown }}</span>
              <span *ngIf='!team.points' class='undefined Display-Bold-3'>0</span>
            </div>
            <span class='HeadingHeading-6 campaign-unit'>{{campaignUnit | unitToKey | plural:team.points |
              translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex='auto' fxLayoutAlign='flex-end'>
      <button mat-flat-button class='details-button' id='teamDetails'>&#8594;</button>
    </div>
  </div>
  <div fxLayout fxLayoutAlign="flex-end">
    <div class="campaign-label" [style.background-color]="team.campaign.color"
         [style.color]="team.campaign.textColor">{{team.campaign.title}}</div>
  </div>
</div>

<div [routerLink]="['details', team._id]" fxHide.gt-sm='true' fxLayoutAlign='space-between stretch' class='team-mobile'>
  <div class='content'>
    <div class='text' fxLayout='row'>
      <div class='DisplayRegularHeading-5' fxFlex='90'>
        {{ team.name }}
      </div>
      <div class='details-button' fxFlex='10'>
        <mat-icon>arrow_forward</mat-icon>
      </div>
    </div>
    <div class='stats' fxLayout='row' fxLayoutAlign='space-between stretch'>
      <div class='value' fxFlex='33'>
        <span *ngIf='team.distance'>{{ team.distance| metersToKilometers | roundDown }}</span>
        <span *ngIf='!team.distance' class='undefined'>_</span>
        <span class='HeadingHeading-6'>{{'unit_km' | translate}}</span>
      </div>
      <div class='value' fxFlex='33'>
        <span *ngIf='team.time'>{{ team.time | secondsToHours }}</span>
        <span *ngIf='!team.time' class='undefined'>_</span>
        <span class='HeadingHeading-6'>{{('unit_hour_si' | translate)?.other}}</span>
      </div>
      <div class='value' fxFlex='33'>
        <span *ngIf='team.points'>{{ team.points | roundDown }}</span>
        <span *ngIf='!team.points' class='undefined'>_</span>
        <span class='HeadingHeading-6'>{{team.unit | unitToKey | plural:team.points | translate}}</span>
      </div>
    </div>
    <div fxLayout='row wrap' fxLayoutAlign="space-between" class='team-config'>
      <div class='team-info' fxLayout='row' fxFlex='48px' fxLayoutGap='5px' fxLayoutAlign='center center'>
        <mat-icon class='icon-teams' svgIcon='teams'></mat-icon>
        <span>{{ team.membersCount }}</span>
      </div>
      <div class='team-info' fxLayout='row' fxFlex='48px' fxLayoutGap='5px' fxLayoutAlign='center center'>
        <mat-icon svgIcon='place'></mat-icon>
        <span>{{ team.rankingPlace }}</span>
      </div>
      <div>
        <vim-privacy-info [private]="team.privacy === teamPrivacy.closed"></vim-privacy-info>
      </div>
      <div class="campaign-label" [style.background-color]="team.campaign.color"
           [style.color]="team.campaign.textColor">{{team.campaign.title}}</div>
    </div>
  </div>
</div>
