import { createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { TeamsState } from './teams.state';

export namespace TeamsSelectors {
  const state = (state: AppState) => state.teams;

  export const selectState = createSelector(state, (state: TeamsState) => state);
  export const selectMyTeams = createSelector(state, (state: TeamsState) => state?.myTeams);
  export const selectTeam = createSelector(state, (state: TeamsState) => state?.currentTeam);
  export const selectTeamMembers = createSelector(state, (state: TeamsState) => state?.currentTeamActiveMembers);
  export const selectTeamRemovedMembers = createSelector(
    state,
    (state: TeamsState) => state?.currentTeamRemovedMembers
  );
  export const selectLeaders = createSelector(state, (state: TeamsState) => state?.leaders);

  export const selectLeadersIsLoaded = createSelector(
    state,
    (state: TeamsState) => state.loadingStatuses.getLeaders.isLoaded
  );
  export const selectIsLoaded = createSelector(state, (state: TeamsState) =>
    Object.values(state.loadingStatuses)
      .map((status) => status.isLoaded === true || status.isLoading === null)
      .reduce((prev, curr) => prev && curr)
  );
  export const selectIsLoading = createSelector(state, (state: TeamsState) =>
    Object.values(state.loadingStatuses)
      .map((status) => status.isLoading === true)
      .reduce((prev, curr) => prev || curr)
  );
}
