import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TeamMember } from '../../../../../../../client/teams/teams.model';

@Component({
  selector: 'vim-team-member-row',
  templateUrl: './team-member-row.component.html',
  styleUrls: ['./team-member-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TeamMemberRowComponent {
  @Input() member!: TeamMember;
  @Input() isAdmin: boolean;
  @Input() confirmationText!: string;

  @Input() removedUsers: boolean;

  @Output() changeStatusOfMember: EventEmitter<string> = new EventEmitter<string>();
  removeOrAddConfirmationVisible = false;

  get userInitials(): string {
    return this.member.name.firstName[0] + this.member.name.familyName[0];
  }

  get fullName(): string {
    return `${this.member.name.firstName} ${this.member.name.familyName}`;
  }

  onAddOrRemoveClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.removeOrAddConfirmationVisible = true;
  }

  constructor() {}
}
