import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsState } from './notifications.state';
import { NotificationsActions } from './notifications.actions';
import { sortBy } from 'lodash';
import { NotificationStatus, NotificationType } from '../../client/events/events.models';

export namespace NotificationsReducer {
  export const initialState: NotificationsState = {
    notificationsCount: 0,
    unreadNotificationsCount: 0,
    notifications: [],
    loading: false,
    loaded: false
  };

  export const notificationsReducer = createReducer(
    initialState,
    on(NotificationsActions.getNotificationsCount, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(NotificationsActions.getNotificationsCountSuccess, (state) => ({
      ...state,
      loading: false,
      loaded: true
    })),
    on(NotificationsActions.getNotificationsCountError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(NotificationsActions.getNotifications, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(NotificationsActions.getNotificationsSuccess, (state, newState) => ({
      ...state,
      notificationsCount: newState.notificationsCount,
      unreadNotificationsCount: newState.notifications.filter(
        (notification) => notification.status === NotificationStatus.new
      ).length,
      notifications: sortBy(
        newState.notifications.map((notification) => ({
          ...notification,
          image: NOTIFICATION_TYPE_TO_IMAGE[notification.type]
        })),
        [(notification) => notification.status]
      ),
      loading: false,
      loaded: true
    })),
    on(NotificationsActions.getNotificationsError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    }))
  );

  const NOTIFICATION_TYPE_TO_IMAGE = {
    [NotificationType.achievement_earned]: './assets/images/notifications/notifications-achievement.svg',
    [NotificationType.campaign_has_ended]: './assets/images/notifications/notifications-campaign.svg',
    [NotificationType.campaign_has_started]: './assets/images/notifications/notifications-campaign.svg',
    [NotificationType.campaign_is_coming_to_its_end]: './assets/images/notifications/notifications-campaign.svg',
    [NotificationType.marketing]: './assets/images/notifications/notifications-marketing.svg',
    [NotificationType.campaign_will_start_soon]: './assets/images/notifications/notifications-campaign.svg'
  };

  export function reducer(state: NotificationsState, action: Action): NotificationsState {
    return notificationsReducer(state, action);
  }
}
