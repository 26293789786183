import { StoreModule } from '@ngrx/store';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { GarminStoreModule } from './garmin/garmin-store.module';
import { ActivitiesStoreModule } from './activities/activities-store.module';
import { UsersStoreModule } from './users/users-store.module';
import { TeamsStoreModule } from './teams/teams-store.module';
import { LegalStoreModule } from './legal/legal-store.module';
import { CampaignsStoreModule } from './campaigns/campaigns-store.module';
import { WorkspacesStoreModule } from './workspaces/workspaces-store.module';
import { StravaStoreModule } from './strava/strava-store.module';
import { LiveTickerStoreModule } from './live-ticker/live-ticker-store.module';
import { BadgesStoreModule } from './badges/badges-store.module';
import { NotificationsStoreModule } from './notifications/notifications-store.module';
import { AdminPanelStoreModule } from './admin-panel/admin-panel-store.module';

export let STORE_DEV_TOOLS: ModuleWithProviders<StoreDevtoolsModule>[] = [];

if (environment.reduxDevTool) {
  STORE_DEV_TOOLS = [
    StoreDevtoolsModule.instrument({
      maxAge: 256
    })
  ];
}

@NgModule({
  imports: [
    GarminStoreModule,
    StravaStoreModule,
    ActivitiesStoreModule,
    WorkspacesStoreModule,
    UsersStoreModule,
    TeamsStoreModule,
    LegalStoreModule,
    CampaignsStoreModule,
    LiveTickerStoreModule,
    BadgesStoreModule,
    NotificationsStoreModule,
    AdminPanelStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ...STORE_DEV_TOOLS
  ]
})
export class AppStoreModule {}
