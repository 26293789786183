import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';

@Component({
  selector: 'vim-help-expandable-panel',
  templateUrl: './help-expandable-panel.component.html',
  styleUrls: ['./help-expandable-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpExpandablePanelComponent {
  @Input() title: string = '';
  @ViewChild(MatExpansionPanel) expansionnPanel: MatExpansionPanel;
  panelOpenState: boolean = false;

  openPanel(): Observable<void> {
    this.expansionnPanel.open();
    return this.expansionnPanel.afterExpand;
  }

  constructor() {}
}
