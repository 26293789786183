import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LegalService } from '../../client/legal/legal.service';
import { Injectable } from '@angular/core';
import { LegalActions } from './legal.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { UsersSelectors } from '../users/users.selectors';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../message.service';

@Injectable()
export class LegalEffects {
  getUpdatedDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LegalActions.getUpdatedDocuments),
      switchMap(() =>
        this.legalService.getUpdatedDocuments().pipe(
          map((documentsResponse) =>
            LegalActions.getUpdatedDocumentsSuccess({
              documents: documentsResponse?.documents
            })
          ),
          catchError(() => of(LegalActions.getUpdatedDocumentsError()))
        )
      )
    )
  );

  registerDocumentAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LegalActions.registerDocumentAgreement),
      withLatestFrom(this.store$.select(UsersSelectors.selectMe)),
      mergeMap(([action]) =>
        this.legalService.registerDocumentAgreement(action.revisionId).pipe(
          map(() => LegalActions.registerDocumentAgreementSuccess({ revisionId: action.revisionId })),
          catchError((err) => {
            this.messageService.showErrorSnackbar(err);
            return of(LegalActions.registerDocumentAgreementError());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private legalService: LegalService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}
}
