import { Language, SupportedLanguages } from '../../../shared/shared-ui/models/language.model';
import { AdminItemTarget } from './common.models';
import { BadgeDetailsResponse, BadgeType } from '../../../client/admin-panel/models/admin-panel-badge.model';

export interface BadgeTranslatedContent {
  language: Language;
  title: string;
  description: string;
  instructions: string;
}

export class BadgeDetails {
  languages: Language[];
  scope: AdminItemTarget;
  campaignId: string | null;
  imageUrl?: string;
  image: File;
  type: BadgeType;
  conditions: {
    activity?: string[] | null;
    distance?: number;
    quantity?: number;
    points?: number;
    time?: number;
    elevationGain?: number;
    days?: number;
  };
  dates?: {
    startDate?: string;
    endDate?: string;
  };
  translatedContent: BadgeTranslatedContent[];

  constructor(init?: Partial<BadgeDetails>) {
    if (init) {
      Object.assign(this, init);
    }
  }

  static fromView(data: Partial<BadgeDetails>) {
    return new BadgeDetails(data);
  }

  static fromJSON(badgeDetails: BadgeDetailsResponse): BadgeDetails {
    const details = new BadgeDetails();

    details.languages = badgeDetails.title.map((title) => ({
      shortname: title.language,
      name: SupportedLanguages.find((ln) => ln.shortname === title.language)?.name || ''
    }));
    details.campaignId = badgeDetails.campaignId || null;
    details.scope = badgeDetails.scope;
    details.dates = {
      startDate: badgeDetails.startDate,
      endDate: badgeDetails.endDate
    };
    details.imageUrl = badgeDetails.imageUrl;
    details.type = badgeDetails.type;
    details.conditions = {
      activity: typeof badgeDetails.activity === 'string' ? [badgeDetails.activity] : badgeDetails.activity,
      time: badgeDetails.time,
      quantity: badgeDetails.qty,
      distance: badgeDetails.distance,
      points: badgeDetails.points,
      elevationGain: badgeDetails.elevationGain,
      days: badgeDetails.days
    };
    details.translatedContent = badgeDetails.title.map((title, ind) => ({
      title: title.value,
      language: SupportedLanguages.find((ln) => ln.shortname === title.language)!,
      description: badgeDetails.description[ind].value,
      instructions: badgeDetails.instructions[ind].value
    }));
    return details;
  }
}
