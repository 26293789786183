import { createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { LiveTickerState } from './live-ticker.state';

export namespace LiveTickerSelectors {
  const stats = (state: AppState) => state.liveTicker;

  export const selectState = createSelector(stats, (state: LiveTickerState) => state);

  export const selectCampaignLiveTickerData = createSelector(
    stats,
    (state: LiveTickerState) => state?.campaignLiveTicker
  );
  export const selectCommunityLiveTickerData = createSelector(
    stats,
    (state: LiveTickerState) => state?.communityLiveTicker
  );

  export const isLoading = createSelector(stats, (state: LiveTickerState) => state?.loading);
  export const isLoaded = createSelector(stats, (state: LiveTickerState) => state?.isLoaded);
}
