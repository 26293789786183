import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { UsersSelectors } from '../../../../store/users/users.selectors';
import { WorkspacesSelectors } from '../../../../store/workspaces/workspaces.selectors';
import { AdminPanelSelectors } from '../../../../store/admin-panel/admin-panel.selectors';
import { NotificationsSelectors } from '../../../../store/notifications/notifications-selectors';
import { Notification } from '../../../../client/events/events.models';

export namespace HeaderSelectors {
  export const headerViewModel: MemoizedSelector<AppState, any> = createSelector(
    AdminPanelSelectors.selectCanViewOrEditAdminContent,
    AdminPanelSelectors.selectAdminWorkspaces,
    AdminPanelSelectors.selectViewerWorkspaces,
    WorkspacesSelectors.selectSelectedWorkspace,
    UsersSelectors.selectMe,
    NotificationsSelectors.selectUnreadNotificationsCount,
    NotificationsSelectors.selectNotifications,
    WorkspacesSelectors.isLoading,
    AdminPanelSelectors.isLoading,
    NotificationsSelectors.isLoading,
    (
      canViewOrEditAdminContent,
      adminWorkspaces,
      viewerWorkspaces,
      selectedWorkspace,
      currentUser,
      unreadNotificationsCount,
      notifications,
      workspacesIsLoading,
      adminPanelIsLoading,
      notificationsIsLoading
    ) => ({
      canViewOrEditAdminContent,
      userInitials: `${currentUser?.firstName[0] || ''}${currentUser?.familyName[0] || ''}`,
      workspaceName: selectedWorkspace?.config.name,
      workspaceLogo: selectedWorkspace?.config.logo.svg || selectedWorkspace?.config.logo.png,
      workspaceFavIcon: selectedWorkspace?.config.favicon,
      unreadNotificationsCount,
      notifications,
      isLoading: workspacesIsLoading || adminPanelIsLoading || notificationsIsLoading
    })
  );
}

export interface HeaderViewModel {
  canViewOrEditAdminContent: boolean;
  userInitials: string;
  workspaceName?: string;
  workspaceLogo?: string;
  workspaceFavIcon?: string;
  unreadNotificationsCount: number;
  notifications: Notification[];
  isLoading: boolean;
}
