<header [style.margin-bottom.px]="24"
        class="DisplayRegularHeading-4">{{ 'accept_legal_documents_title' | translate }}</header>
<p class="ParagraphParagraph-1">
  {{ 'updated_legal_docs_description' | translate }}
</p>
<div class="links-wrapper">
  <div *ngIf="privacyPolicyDocument" fxLayout fxLayoutAlign="space-between" [style.margin-bottom.px]="20">
  <span class="ParagraphParagraph-2"
        (click)="privacyPolicyChecked = !privacyPolicyChecked"><a [href]="privacyPolicyDocument?.link"
                                                                  target="_blank">{{ 'privacy_policy_modal_privacy_policy' | translate }}</a></span>
    <mat-checkbox disableRipple="true" class="checkbox" [(ngModel)]="privacyPolicyChecked"></mat-checkbox>
  </div>
  <div fxLayout fxLayoutAlign="space-between" style="margin: 8px 0 8px 0" *ngIf="termsOfUseDocument">
  <span class="ParagraphParagraph-2"
        (click)="termsOfUseChecked = !termsOfUseChecked"><a [href]="termsOfUseDocument?.link"
                                                            target="_blank">{{ 'privacy_policy_modal_terms_of_use' | translate }}</a></span>
    <mat-checkbox disableRipple="true" class="checkbox" [(ngModel)]="termsOfUseChecked"></mat-checkbox>
  </div>
</div>
<p class="ParagraphParagraph-1">
  {{ 'updated_legal_docs_tooltip' | translate }}
</p>
<div
  class="button-wrapper"
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap="32px"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start center"
  fxLayoutGap.lt-md="24px"
>
  <vim-button class="secondary cancel-button" (click)="cancel()">{{ 'common_cancel' | translate }}</vim-button>
  <vim-button
    class="primary confirm-button"
    (click)="sendConfirmation()"
    [disabled]=" !privacyPolicyChecked || !termsOfUseChecked"
  >
    {{ 'common_confirm' | translate }} &rarr;
  </vim-button>
</div>

