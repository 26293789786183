import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[vimEmptyToUndefined]'
})
export class UndefinedIfEmptyDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('keyup', ['$event']) onKeyUp() {
    if (this.ngControl.value === '') {
      this.ngControl.control?.setValue(undefined);
    }
  }
}
