import { Pipe, PipeTransform } from '@angular/core';
import { CampaignUnit } from '../../../../client/campaigns/campaignsData';
import { ConverterService } from '../../services/converter.service';

@Pipe({
  name: 'unitToKey'
})
export class UnitToTranslationKeyPipe implements PipeTransform {
  transform(unit: CampaignUnit, short?: boolean): string {
    if (!unit) {
      return '';
    }
    return ConverterService.campaignUnitToTranslationKey(unit, short);
  }
}
