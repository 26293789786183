import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CustomSnackBarComponent,
  CustomSnackBarComponentData
} from '../../components/custom-snack-bar/custom-snack-bar.component';

export enum SnackBarColor {
  red = 'red-snackbar',
  green = 'green-snackbar',
  yellow = 'yellow-snackbar'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly duration: number = 5000;

  constructor(private snackBar: MatSnackBar) {}

  public openSnackBar(message: string, textButtonEnabled: boolean, snackBarColor: SnackBarColor, action?: () => void) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      duration: this.duration,
      panelClass: snackBarColor,
      data: {
        snackBarColor: snackBarColor,
        buttonLabel: textButtonEnabled ? 'Ok' : undefined,
        message: message,
        iconLeft: snackBarColor == SnackBarColor.green ? 'checkmark' : 'error',
        iconRight: !textButtonEnabled ? 'close' : undefined,
        close: () => {
          if (action) {
            action();
          }
        }
      } as CustomSnackBarComponentData
    });
  }
}
