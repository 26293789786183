import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { UsersStore } from '../../store/users/users.store';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {
  constructor(
    private usersStore: UsersStore,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getMe().pipe(
      map((me) => {
        if (me?.familyName && (me.birthYear === null || !isNaN(me.birthYear))) {
          return true;
        }
        return this.router.parseUrl('/create-profile');
      })
    );
  }

  private getMe() {
    return this.usersStore.me$.pipe(
      tap((me) => {
        if (!me) {
          this.usersStore.getMe();
        }
      }),
      filter((me) => !!me),
      take(1)
    );
  }
}
