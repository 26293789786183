import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CampaignStatistics, CampaignUnit } from '../../../../../../client/campaigns/campaignsData';
import { ConverterService, MinutesOrHours } from '../../../../../../shared/shared-ui/services/converter.service';

@Component({
  selector: 'vim-campaign-statistics',
  templateUrl: './campaign-statistics.component.html',
  styleUrls: ['./campaign-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignStatisticsComponent {
  @Input() data: CampaignStatistics;
  @Input() unit: CampaignUnit;

  getMinutesAndHours(time: number): MinutesOrHours {
    return ConverterService.secondsToMinutesAndHours(time);
  }
}
