import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { TeamsStore } from 'src/app/store/teams/teams.store';
import { select, Store } from '@ngrx/store';
import { LeaderboardSelectors, LeaderboardViewModel } from './leaderboard.selectors';
import { AppState } from '../../../../store/state';

@Component({
  selector: 'vim-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardComponent implements OnChanges {
  @Input() isActive: boolean;
  @Output() createTeam = new EventEmitter();

  vm$: Observable<LeaderboardViewModel> = this.store$.pipe(select(LeaderboardSelectors.leaderBoardViewModel));

  constructor(
    private store$: Store<AppState>,
    public teamsStore: TeamsStore
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (true === changes.isActive.currentValue) {
      this.teamsStore.getLeaders();
    }
  }

  public getMoreLeaderboardData(skip: number): void {
    this.teamsStore.getMoreLeaders(skip);
  }
}
