<div>
  <div class="title-container">
    <div>
      <a [routerLink]="[]" [mat-dialog-close] queryParamsHandling="preserve" class="back-arrow">
        <vim-arrow orientation="left"></vim-arrow>
      </a>
    </div>
    <div>
      <span class="profile-text">{{'main_navigation_profile' | translate}}</span>
    </div>
  </div>
  <mat-nav-list>
    <a routerLink="home/profile/connections" [mat-dialog-close] mat-list-item>
      <mat-icon svgIcon="link-garmin-grey"></mat-icon>
      {{'profile_option_connections' | translate}}
    </a>
    <a [mat-dialog-close] mat-list-item routerLinkActive="active" routerLink="/home/profile/invite">
      <mat-icon svgIcon="invite-grey"></mat-icon>
      {{'profile_option_invite_friends' | translate}}
    </a>
    <a [mat-dialog-close] mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
       routerLink="/home/profile">
      <mat-icon svgIcon="edit-grey"></mat-icon>
      {{'profile_menu_edit' | translate}}
    </a>
    <a [mat-dialog-close] mat-list-item (click)="logout()">
      <mat-icon svgIcon="logout-grey"></mat-icon>
      {{'profile_menu_logout' | translate}}
    </a>
  </mat-nav-list>
</div>
