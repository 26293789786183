import { BadgesState } from './badges.state';
import { Action, createReducer, on } from '@ngrx/store';
import { BadgesActions } from './badges.actions';
import { differenceBy } from 'lodash';

export namespace BadgesReducer {
  export const initialState: BadgesState = {
    availableBadges: [],
    earnedBadges: [],
    loading: false,
    loaded: false
  };
  export const badgesReducer = createReducer(
    initialState,
    on(BadgesActions.getBadges, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(BadgesActions.getBadgesSuccess, (state, { allBadges, userBadges }) => ({
      ...state,
      availableBadges: differenceBy(allBadges, userBadges, (badge) => badge.id),
      earnedBadges: userBadges,
      loading: false,
      loaded: true
    })),
    on(BadgesActions.getBadgesError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(BadgesActions.getEarnedBadges, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(BadgesActions.getEarnedBadgesSuccess, (state, { earnedBadges }) => ({
      ...state,
      earnedBadges,
      loading: false,
      loaded: true
    })),
    on(BadgesActions.getEarnedBadgesError, (state) => ({
      ...state,
      loading: false,
      loaded: false
    })),
    on(BadgesActions.clear, () => ({
      ...initialState,
      loading: false,
      loaded: false
    }))
  );

  export function reducer(state: BadgesState, action: Action): BadgesState {
    return badgesReducer(state, action);
  }
}
