import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { HeaderComponent } from './header/header.component';
import { LanguageMobileMenuComponent } from './language-mobile-menu/language-mobile-menu.component';
import { HomeRoutingModule } from '../home-routing.module';
import { SharedModule } from '../../../shared';
import { FooterComponent } from './footer/footer.component';
import { ProfileMobileMenuComponent } from './profile-mobile-menu/profile-mobile-menu.component';
import { NotificationsListDialogComponent } from './mobile-menu/notifications-list-dialog/notifications-list-dialog.component';
import { TeamsModule } from '../teams/teams.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    MobileMenuComponent,
    HeaderComponent,
    LanguageMobileMenuComponent,
    FooterComponent,
    ProfileMobileMenuComponent,
    NotificationsListDialogComponent
  ],
  imports: [CommonModule, HomeRoutingModule, SharedModule, TeamsModule, NgScrollbarModule],
  exports: [MobileMenuComponent, HeaderComponent, LanguageMobileMenuComponent, FooterComponent]
})
export class LayoutModule {}
